<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container fluid grid-list-lg class="legislators">
    <div v-if="!client || !client.id" class="text-center mt-5">
      <v-alert type="info" outlined>
        Select a client to view legislators.
      </v-alert>
    </div>
    <template v-else>
      <v-progress-linear
        style="z-index: 6"
        color="warning"
        indeterminate
        rounded
        height="3"
        fixed
        top
        v-if="loading"
      ></v-progress-linear>
        <v-layout wrap>
          <v-flex class="d-flex align-start legislators_head">
            <div class="legislators_title">
              <div class="legislators_title__title">
                <h1>{{ clientState }} Legislators</h1>
                <legislators-help></legislators-help>
              </div>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center justify-end" v-if="isMobile && committees">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" icon small dark v-on="on">
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    <v-btn color="primary" class="my-1" block small :loading="loading" @click="load">
                      Refresh
                      <v-icon dark class="ml-2">refresh</v-icon>
                    </v-btn>
                    <export-legislators
                        v-if="isSuperAdmin || isVIP"
                        :disabled="list.items.length && !enabledImportExport()"
                        :clientId="client"
                        :params="exportProps"
                    ></export-legislators>
                    <export-constituents
                        v-if="filter.committees.length > 0 && menu === false"
                        class="mr-2"
                        :allConstituents="false"
                        :committees="filter.committees"
                        :subcommittee="filter.subcommitee"
                        :disabled="enabledImportExport()"
                    />
                    <export-constituents
                        v-if="filter.committees.length > 0 && menu === false"
                        class="mr-2"
                        :allConstituents="true"
                        :committees="filter.committees"
                        :subcommittee="filter.subcommitee"
                        :disabled="enabledImportExport()"
                    />
                    <v-btn color="warning" :disabled="!selectedStateId" class="my-1" block small @click="openNewModal" v-if="isSuperAdmin || isLegSupport">
                      New Legislator
                      <v-icon dark class="ml-2">person_add</v-icon>
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex class="d-flex align-center justify-end flex-wrap" v-if="isDesktop || isDevice && committees">
            <v-btn icon color="primary" :loading="loading" @click="load">
              <v-icon dark>refresh</v-icon>
            </v-btn>
            <export-legislators
                class="mr-2"
                v-if="isSuperAdmin || isVIP"
                :disabled="list.items.length && !enabledImportExport()"
                :params="exportProps"
            ></export-legislators>
            <export-constituents
                v-if="filter.committees.length > 0 && menu === false"
                class="mr-2"
                :allConstituents="false"
                :committees="filter.committees"
                :subcommittee="filter.subcommitee"
                :disabled="!enabledImportExport()"
            />
            <export-constituents
                v-if="filter.committees.length > 0 && menu === false"
                class="mr-2"
                :allConstituents="true"
                :committees="filter.committees"
                :subcommittee="filter.subcommitee"
                :disabled="!enabledImportExport()"
            />
            <v-btn color="warning" :disabled="!selectedStateId" small @click="openNewModal" v-if="isSuperAdmin || isLegSupport">
              New Legislator
              <v-icon dark class="ml-2">person_add</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
          <div>
            <v-text-field
              label="Search for Legislator or KPs"
              outlined
              clearable
              rounded
              append-icon="search"
              v-model="filter.search"
              hide-details
            >
            </v-text-field>
          </div>
          <div class="legislators_filter__additional mb-2">
            <ul class="legislators_filter__additional--type">
              <li>
                <a @click="setFilterValue('house')" :class="{ active: filter.house }">
                  House
                </a>
              </li>
              <li>
                <a @click="setFilterValue('senate')" :class="{ active: filter.senate }">
                  Senate
                </a>
              </li>
              <li>
                <a @click="setFilterValue('vip', 1)" :class="{ active: filter.vip }">
                  VIP
                </a>
              </li>
              <li>
                <a @click="setFilterValue('unassigned')" :class="{ active: filter.unassigned }">
                  Unassigned
                </a>
              </li>
              <li>
                <a @click="setFilterValue('lame_duck')" :class="{ active: filter.lame_duck }">
                  Lame Duck
                </a>
              </li>
              <li v-if="isSuperAdmin || isLegSupport">
                <a @click="toggleLegislatorURL()" :class="{ active: useLegislatorUrl }">
                  Dev
                </a>
              </li>
            </ul>
            <v-flex class="d-flex justify-left flex-wrap mt-2" v-if="isMobile && showCommittee && !filter.unassigned">
              <v-btn
              color="primary mx-1 mt-1"
              small
              value="all"
              @click="filterCommittees('all')"
              :class="filter.committees.length ? '' : 'active-button'"
            >
              All
            </v-btn>
            <div v-for="committee in trimmedCommittees" :key="committee.id+'_'+committee.shortname">
              <v-btn
                @click="filterCommittees(committee)"
                color="primary mx-1 mt-1"
                small
                :class="committeeSelected(committee) ? 'active-button' : ''"
              >
                {{ committee.shortname }}
              </v-btn>
            </div>
            </v-flex>
            <div class="legislators_filter__additional--filter">
              <div v-if="filter.unassigned === false" class="legislators_view">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" small icon @click="view = 'grid'" :color="view == 'grid' ?
                    'primary' : 'secondary'">
                      <v-icon>view_comfy</v-icon>
                    </v-btn>
                  </template>
                  <span>Grid view</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" small icon @click="view = 'table'" :color="view == 'table' ? 'primary' :
                    'secondary'">
                      <v-icon>view_list</v-icon>
                    </v-btn>
                  </template>
                  <span>Table view</span>
                </v-tooltip>
              </div>

  <!-- begin filter menu -->

              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                left
                :close-on-click="false"
                offset-y
                min-width="300"
                max-width="450"
              >
                <template v-slot:activator="{ on }">
                  <v-btn small rounded :outlined="isDesktop || isDevice" color="primary" v-on="on">
                    <span>Filters</span>
                    <v-icon>filter_list</v-icon>
                  </v-btn>
                </template>
                <v-card class="py-3">
                  <v-card-text>
                  <v-expansion-panels>

                  <!-- States -->
                  <v-expansion-panel v-if="federalStatesLists && federalStatesLists.length">
                      <v-expansion-panel-header>States</v-expansion-panel-header>
                      <v-expansion-panel-content>
                      <v-autocomplete
                          label="Select States"
                          multiple
                          hide-details
                          outlined
                          dense
                          item-text="state"
                          item-value="id"
                          :clearable=true
                          :items="federalStatesLists"
                          v-model="selectedFilteredStates"
                        ></v-autocomplete>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                  <!-- Regions -->
                    <v-expansion-panel v-if="clientRegionsForFilter && clientRegionsForFilter.length">
                      <v-expansion-panel-header>Regions</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-radio-group v-model="filter.regions">
                          <v-radio
                            color="primary"
                            v-for="region in clientRegionsForFilter"
                            :key="region.title"
                            :label="region.name"
                            :value="region"
                          ></v-radio>
                        </v-radio-group>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                  <!-- Committees -->
                  <v-expansion-panel v-if="filteredCommittees && filteredCommittees.length">
                    <v-expansion-panel-header>Committees</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-checkbox
                        v-if="committee.fullname"
                        color="primary"
                        v-for="(committee, index) in filteredCommittees"
                        :key="index"
                        v-model="filter.committees"
                        :label="committee.fullname"
                        :value="committee"
                        hide-details
                      ></v-checkbox>
                      <div class="legislators_filter__additional--item_wrap mt-4"
                           v-if="filter.committees && filter.committees.length && client && client.state && client.state.has_subcommittees"
                      >
                        <div class="legislators_filter__additional--item_inner elevation-1">
                          <v-select
                            v-model="filter.subcommittee_id"
                            :items="filterSubcommittees"
                            item-text="fullname"
                            item-value="id"
                            label="Select Subcommittee"
                            persistent-hint
                            single-line
                            clearable
                          ></v-select>
                          <!-- <v-checkbox
                            color="primary"
                            v-model="filter.subcommitee"
                            label="Subcommittee"
                          ></v-checkbox> -->
                        </div>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                    <!-- KP Filters -->
                    <v-expansion-panel v-if="filter.customFields && filter.customFields.length">
                      <v-expansion-panel-header >KP Filters</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div v-for="(item, index) in filter.customFields">
                          <div v-if="item.type == 'text' && item.legislator_page" class="mb-4">
                            <h4>{{ item.name }}</h4>
                            <v-text-field v-model="item.value" label="Search" hide-details></v-text-field>
                          </div>
                          <div class="mb-4" v-if="item.type == 'checkbox' && item.metadata && item.metadata.length && item.legislator_page">
                            <v-expansion-panels>
                              <v-expansion-panel>
                                <v-expansion-panel-header>
                                  {{ item.name }}
                                </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <v-radio-group
                                      color="primary"
                                      v-for="(data, j) in item.metadata"
                                      :key="j"
                                      v-model="data.type"
                                      :label="data.name"
                                    ><v-radio
                                      color="primary"
                                      v-for="(label, key) in checkboxLabels"
                                      :key="key"
                                      :label="label.name"
                                      :value="label.value"
                                    ></v-radio>
                                    </v-radio-group>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </div>
                          <div v-if="item.type == 'toggle' && item.legislator_page" class="mb-4">
                            <h4>{{ item.name }}</h4>
                            <v-checkbox
                              color="primary"
                              :label="item.name"
                              class="mt-0"
                              v-model="item.value"
                            ></v-checkbox>
                          </div>
                          <!-- dropdown/select fields start -->
                          <div class="mb-4" v-if="item.type == 'dropdown' && item.metadata && item.metadata.length && item.legislator_page">
                            <v-expansion-panels>
                              <v-expansion-panel>
                                <v-expansion-panel-header>
                                  {{ item.name }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <v-checkbox
                                    color="primary"
                                    v-for="(data, j) in item.metadata"
                                    :key="j"
                                    :label="data.name"
                                    class="mt-0"
                                    v-model="data.checked"
                                  ></v-checkbox>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </div>
                          <!-- dropdown/select fields end -->
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- Party -->
                    <v-expansion-panel>
                      <v-expansion-panel-header >Party</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-checkbox
                          v-if="list.areDemocrats"
                          color="primary"
                          v-model="filter.party.D"
                          label="Democratic"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          v-if="list.areRepublicans"
                          color="primary"
                          v-model="filter.party.R"
                          label="Republican"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          v-if="list.areNonpartisan"
                          color="primary"
                          v-model="filter.party.N"
                          label="Nonpartisan"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          v-if="list.areIndependent"
                          color="primary"
                          v-model="filter.party.I"
                          label="Independent"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          v-if="list.areLibertarian"
                          color="primary"
                          v-model="filter.party.L"
                          label="Libertarian"
                          hide-details
                        ></v-checkbox>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  </v-card-text>
                  <v-card-actions class="align-end">
                    <div class="d-flex flex-wrap">
                      <v-switch
                          color="primary"
                          hide-details
                          label="Archived"
                          class="d-inline-block mt-0 sms-preview-switch full-width"
                          v-model="filter.archived"
                      >
                      </v-switch>
                      <v-switch
                          v-if="isSuperAdmin || isLegSupport"
                          color="primary"
                          hide-details
                          label="No Official Email"
                          class="d-inline-block mt-0 sms-preview-switch full-width"
                          v-model="filter.no_official_email"
                      >
                      </v-switch>
                      <v-switch
                          v-if="isSuperAdmin || isLegSupport"
                          color="primary"
                          hide-details
                          label="No Official Phone"
                          class="d-inline-block mt-0 sms-preview-switch full-width"
                          v-model="filter.no_official_phone"
                      >
                      </v-switch>
                    </div>

                    <v-spacer></v-spacer>
                    <div class="d-flex flex-nowrap">
                      <v-btn text color="secondary" @click="menu = false">Cancel</v-btn>
                      <v-btn color="primary" text @click="doFilter">Apply</v-btn>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-menu>
  <!-- end filter menu -->
            </div>
          </div>
          <v-flex class="d-flex justify-left flex-wrap ml-5 my-2" v-if="(isDesktop || isDevice) && showCommittee && !filter.unassigned">
            <v-btn
              color="primary mx-1 mt-1"
              small
              value="all"
              @click="filterCommittees('all')"
              :class="filter.committees.length ? '' : 'active-button'"
            >
              All
            </v-btn>

          <div v-for="committee in trimmedCommittees" :key="committee.id+'_'+committee.shortname">
            <v-btn
              @click="filterCommittees(committee)"
              color="primary mx-1 mt-1"
              small
              :class="committeeSelected(committee) ? 'active-button' : ''"
            >
              {{ committee.shortname }}
            </v-btn>
          </div>
        </v-flex>
        <div v-if="selected_committees.length || filter.party.length" class="legislators_filter__progress justify-space-between">
          <v-flex class="legislators_filter__additional--filter justify-start flex-wrap">
            <v-chip
              v-if="selectedSubcommittees.length"
              v-for="(subcommittee, index) in selectedSubcommittees"
              :key="subcommittee.id"
              class="mr-2 my-1"
              close
              @click:close="removeSubcommittee(index)"
            >
              <span>{{ getCommitteeById(subcommittee.parent_id) && getCommitteeById(subcommittee.parent_id).fullname }} ({{ subcommittee.fullname }})</span>
            </v-chip>
            <v-chip
              v-if="selected_committees.length && selectedSubcommittees.length === 0"
              v-for="(committee, index) in selected_committees"
              :key="committee.id"
              class="mr-2 my-1"
              close
              @click:close="removeCommittee(index)"
            >
              <span>{{ committee.fullname }}</span>
            </v-chip>
            <v-chip
              v-for="(value, partyName) in filter.party"
              :key="partyName"
              v-if="value"
              class="mr-2 my-1"
              close
              @click:close="removePartyFilter(partyName)"
            >
              <span>{{ getNameParties(partyName) }}</span>
            </v-chip>
          </v-flex>

            <v-spacer></v-spacer>
            <v-flex class="legislators_filter__progress--left justify-end" v-if="selected_committees.length">
              <v-rating2 
                v-model="list.rating"
                v-if="list.rating"
                half-increments
                readonly
                show-rating
                class="justify-end mt-1 mr-4"
              >
            </v-rating2>
              <div class="legislators_title__progress" v-if="progressValue">
                <v-progress-linear
                  background-color="blue"
                  color="error"
                  :value="progressValue"
                  height="25"
                ></v-progress-linear>
                <div class="legislators_title__progress--values">
                  <span>R - {{ list.republicans }}</span>
                  <span>D - {{ list.democrats }}</span>
                </div>
              </div>
            </v-flex>
          </div>

      <div class="mx-4 mb-3" v-if="(isSuperAdmin || isLegSupport) && legislatorsUls && legislatorsUls.length > 0">
        <v-flex class="legislators_filter__additional--filter justify-start flex-wrap">
          <v-chip
              v-for="(url, index) in legislatorsUls"
              class="mr-2 my-1"
              color="primary"
              :key="index"
              x-small
              outlined
              @click="openUrl(url.url)"
          >
            <span>{{ url.name }}</span>
            <v-icon class="ml-1" color="primary" size="10px">fa-thin fa-arrow-up-right-from-square</v-icon>
          </v-chip>
        </v-flex>
      </div>

      <div
        :class="isMobile ? 'px-2' : ''"
        v-if="useLegislatorUrl && list.items && list.items.length"
      >
        <v-data-table
            :loading="loading"
            class="elevation-1"
            :options.sync="list.pagination"
            :server-items-length="list.pagination.totalItems"
            :footer-props="tableFooter"
            :headers="legislatorTableHeader"
            :items="list.items"
        >
          <template v-slot:item.action="{ item }">
            <v-btn icon class="mx-0" @click="$refs.editLegislatorModal.toggle(item.legislator && item.legislator)" v-if="item.legislator">
              <v-icon size="18" color="primary">edit</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.fullname="{ item }">
            <div>{{ item.legislator ? item.legislator.fullname : "Vacant" }}</div>
          </template>

          <template v-slot:item.district="{ item }">
            <div>{{ item.district }}</div>
          </template>

          <template v-slot:item.official_email="{ item }">
            <div v-if="item.legislator && item.legislator.official_email"><v-icon color="success">check_circle</v-icon></div>
            <div v-else><v-icon color="error">cancel</v-icon></div>
          </template>

          <template v-slot:item.personal_email="{ item }">
            <div v-if="item.legislator && item.legislator.personal_email"><v-icon color="success">check_circle</v-icon></div>
            <div v-else><v-icon color="error">cancel</v-icon></div>
          </template>

          <template v-slot:item.official_address="{ item }">
            <div v-if="item.legislator && item.legislator.official_address"><v-icon color="success">check_circle</v-icon></div>
            <div v-else><v-icon color="error">cancel</v-icon></div>
          </template>

          <template v-slot:item.official_phone="{ item }">
            <div v-if="item.legislator && item.legislator.official_phone"><v-icon color="success">check_circle</v-icon></div>
            <div v-else><v-icon color="error">cancel</v-icon></div>
          </template>

          <template v-slot:item.local_phone="{ item }">
            <div v-if="item.legislator && item.legislator.local_phone"><v-icon color="success">check_circle</v-icon></div>
            <div v-else><v-icon color="error">cancel</v-icon></div>
          </template>
        </v-data-table>
      </div>
      <!-- loading graphic -->
      <v-container style="height: 400px;" v-if="loading || !list.items">
        <v-row
          class="fill-height"
          align-content="center"
          justify="center"
        >
          <v-col
            class="text-subtitle-1 text-center"
            cols="12"
          >
            Loading {{ clientState }} legislators
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              color="deep-purple accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
      <!-- end loading graphic -->
        <div v-if="list.items && list.items.length && !useLegislatorUrl">
          <div class="legislators_pagination" v-if="view == 'grid'">
            <div>
              <v-select
                  v-if="list.pagination.last_page > 1"
                  label="Per page"
                  hide-details
                  class="my-0 py-0 mr-2"
                  dense
                  v-model="list.pagination.itemsPerPage"
                  :items="perPageItems"
                  @change="changePerPage"
              >
              </v-select>
            </div>
            <div>
              <v-pagination
                  v-model="list.pagination.page"
                  class="my-4"
                  :length="list.pagination.last_page"
                  @next="list.pagination.page = list.pagination.last_page"
                  @previous="list.pagination.page = 1"
              ></v-pagination>
            </div>
          </div>
          <legislators-grid
            v-if="view == 'grid'  && !filter.unassigned"
            :list="list"
            :membershipList = membershipList
            :client='client'
            @edit="openEditModal"
            @districtOpen="openDistrict"
            @detail="openDetailModal"
            @assignment="openAssignmentsModal"
            @memberModalOpen="openMemberModal"
          >
          </legislators-grid>
          <legislators-table
            :class="isMobile ? 'px-4' : ''"
            v-else-if="view == 'table' && !filter.unassigned"
            :list="list"
            :clientId='client.id'
            @edit="openEditModal"
            @districtOpen="openDistrict"
            @detail="openDetailModal"
            @assignment="openAssignmentsModal"
            @memberModalOpen="openMemberModal"
            @onOrder="setOrder"
            @isDesc="setDesc"
            @changePage="(page) => list.pagination.page = page"
            @changePerPage="(itemsCount) => list.pagination.itemsPerPage = itemsCount"
          ></legislators-table>
          <unassigned-legislators-table
            :class="isMobile ? 'px-4' : ''"
            v-else-if="filter.unassigned && !loading"
            :list="list"
            :clientId='client.id'
            :isLoading="loading"
            @edit="openEditModal"
            @districtOpen="openDistrict"
            @detail="openDetailModal"
            @assignment="openAssignmentsModal"
            @memberModalOpen="openMemberModal"
            @changePage="(page) => list.pagination.page = page"
            @changePerPage="(itemsCount) => list.pagination.itemsPerPage = itemsCount"
            @updateLegislators="load"
          ></unassigned-legislators-table>
          <div class="legislators_pagination" v-if="view == 'grid'">
            <div>
              <v-select
                v-if="list.pagination.last_page > 1"
                label="Per page"
                hide-details
                class="my-0 py-0 mr-2"
                dense
                v-model="list.pagination.itemsPerPage"
                :items="perPageItems"
                @change="changePerPage"
              >
              </v-select>
            </div>
            <div>
              <v-pagination
                  v-model="list.pagination.page"
                  class="my-4"
                  :length="list.pagination.last_page"
                  @next="list.pagination.page = list.pagination.last_page"
                  @previous="list.pagination.page = 1"
              ></v-pagination>
            </div>
          </div>
        </div>

        <!-- Display a message when there are no legislators available or loading is false -->
        <div v-if="!list.items || list.items.length === 0 && !loading">
          <v-alert outlined :value="true" type="info"> No legislators found </v-alert>
        </div>

        <div v-if="!(list.items || list.items.length)">
          <v-alert outlined :value="true" type="info">Loading legislators...</v-alert>
        </div>
    <district-modal v-if="districtModalEnabled"></district-modal>

      <member-detail-modal ref="memberDetailModal" :selected-client="client" @openDistrictModal="openDistrictModal"></member-detail-modal>
      <edit-legislator-modal
        ref="editLegislatorModal"
        :state-list="stateList"
        :country-list="countryList"
        :committee-list="committeeList"
        :clientRegions="clientRegions"
        :occupation-list="occupationList"
        :refresh="load"
      >
      </edit-legislator-modal>
      <legislator-detail-modal ref="legislatorDetailModal" :refresh="init"></legislator-detail-modal>
      <new-legislator-modal
        ref="newLegislatorModal"
        :state-list="stateList"
        :country-list="countryList"
        :committee-list="committeeList"
        :clientRegions="clientRegions"
        :occupation-list="occupationList"
        :refresh="load"
      >
      </new-legislator-modal>
      <assignment-modal
        v-if="client && client.id" 
        ref="assignmentModal"
        @getRefreshedAssignment="refreshAssignment"
        @updateKps="getList()"
        @updateLegislators="init()"
      ></assignment-modal>
    </template>
  </v-container>
</template>

<script>
  import grid from '@/components/legislators/grid'
  import table from '@/components/legislators/table'
  import unassignedTable from '@/components/legislators/unassigned-legislators-table'
  import help from '@/components/help-modals/legislators-page-help-modal'
  import legislatorsPageHelpModal from '@/components/help-modals/legislators-page-help-modal'
  import pagination from '@/components/common/pagination'
  import exportLegislators from '@/components/common/export-legislators-button'
  import exportConstituents from '@/components/legislators/export-constituents-button'

  import ClientService from '@/services/ClientService'
  import StateService from '@/services/StateService'
  import OccupationService from '@/services/OccupationService'
  import DistrictService from '@/services/DistrictService'

  import districtModal from '@/components/common/district-modal'

  import editLegislatorModal from '@/components/legislators/edit-legislator-modal'
  import legislatorDetailModal from '@/components/legislators/legislator-detail-modal'
  import newLegislatorModal from '@/components/legislators/new-legislator-modal'
  import assignmentModal from '@/components/legislators/assignment-modal'
  import memberDetailModal from '@/components/members/member-detail-modal.vue'

  import CustomFieldsFilter from '@/mixins/custom-fields-filter'
  import CommitteeService from "@/services/CommitteeService";
  import {mapGetters} from "vuex";
  import VRating2 from '@/components/common/rating'

  export default {
    name: 'LegislatorsPage',
    mixins: [ClientService, StateService, OccupationService, DistrictService, CustomFieldsFilter, CommitteeService],
    data() {
      return {
        menu: false,
        loading: false,
        timer: false,
        order: 'district',
        orderDesc: 'asc',
        exportProps: {},
        kpPreferenceResults: [],
        initialLegislatorsArray: [],
        useLegislatorUrl: false,
        filter: {
          search: '',
          committees: [],
          party: {
            N: false,
            R: false,
            D: false,
            I: false,
            L: false,
          },
          commiteeTypeId: null,
          regions: null,
          no_official_email: false,
          no_official_phone: false,
          archived: false,
          house: false,
          senate: false,
          vip: false,
          unassigned: false,
          lame_duck: false,
          subcommitee: false,
          subcommittee_id: null,
          customFields: [],
        },
        membershipList: [],
        type: '',
        list: {
          pagination: {
            page: 1,
            itemsPerPage: 24,
          },
          items: [],
          republicans: 0,
          democrats: 0,
          rating: 0,
        },
        tableFooter: {
          'items-per-page-options': [24, 48, 72]
        },
        perPageItems: [24, 48, 72],
        selectedStateId: '',
        committeeList: [],
        stateList: [],
        countryList: [],
        filteredCommittees: [],
        federalStatesLists: [],
        legislatorsUls: [],
        occupationList: [],
        committeeTypeList: [
          {id: 0, title: 'House'},
          {id: 1, title: 'Senate'},
        ],
        listVacantDistricts: [],
        unelectedDistricts: [],
        view: 'grid',
        selected_committees: [],
        clientRegions: [],
        checkboxLabels: [
          { value: 'default', name: 'All' },
          { value: 1, name: 'Yes' },
          { value: 0, name: 'No' },
        ],
        committees: [],
        trimmedCommittees: [],
        showCommittee: false,
        selectedFilteredStates: [],
      }
    },
    computed: {
      ...mapGetters('app', ['superAdminSelectedClient']),
      filterSubcommittees() {
        const subcommittees = [];
        this.filter.committees.forEach(committee => {
          if(committee.subcommittees) {
            subcommittees.push(...committee.subcommittees)
          }
        })
        return subcommittees;
      },
      selectedSubcommittees(){
        const subcommittees = [];
        this.filterSubcommittees.forEach(subcommittee => {
          if(subcommittee.id === this.filter.subcommittee_id) {
            subcommittees.push(subcommittee)
          }
        })
        return subcommittees;
      },
      legislatorTableHeader() {
        return [
          {text: 'edit', value: 'action', align: 'center', width: '50px', sortable: false},
          {text: 'Name', value: 'fullname', align: 'center'},
          {text: 'District', value: 'district', align: 'center'},
          {text: 'O-email', value: 'official_email', align: 'center', sortable: false},
          {text: 'P-email', value: 'personal_email', align: 'center', sortable: false},
          {text: 'O-address', value: 'official_address', align: 'center', sortable: false},
          {text: 'O-phone', value: 'official_phone', align: 'center', sortable: false},
          {text: 'L-phone', value: 'local_phone', align: 'center', sortable: false},
        ]
      },
      client() {
        return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
      },
      isFederalClient() {
        return this.client && this.client.is_use_state === 0
      },
      clientState() {
        if (!this.selectedStateId) return ''
        let state = this.stateList.find(state => state.id == this.selectedStateId)
        return state ? state.state : ''
      },
      progressValue() {
        let total = this.list.republicans + this.list.democrats
        return Math.floor(100 * this.list.republicans / total)
      },
      clientRegionsForFilter() {
        return this.clientRegions && this.clientRegions.length ?
          [{ name: 'Show All', id: '' }, ...this.clientRegions] : []
      }
    },
    methods: {
      enabledImportExport() {
        return !(this.isSuperAdmin || (this.userData && this.userData.allow_import_export));
      },
      getCommitteeById(id) {
        return this.committeeList.find(committee => committee.id === id)
      },
      init() {
        this.federalStatesLists = [];
        if (!(this.client && this.client.id)) return

        this.selectedStateId = this.client.state_id
        if (this.isSuperAdmin || this.isLegSupport) {
          this.refreshLegislatorUrl()
        }
        this.getCommitteesList().then(() => {
          this.filteredCommittees = this.setCommitteesList()
        })
        if(!this.client.is_use_state){
          this.getFederalStates(this.client.id).then((result) => {
            this.federalStatesLists = result.data
          })
        }
        this.loadCustomFields(this.client.id)
        this.getDistricts()
        this.getClientRegionsList()
        this.getMemberships()
        this.load()
        this.filter.party = {
          N: false,
          R: false,
          D: false,
          I: false,
          L: false,
        }
        this.getOccupationsList()
        this.getStatesList()
      },
      openUrl(url) {
        window.open(url, "_blank");
      },
      async refreshLegislatorUrl() {
        this.legislatorsUls = (await this.getLegislatorsUrls(this.selectedStateId)).data
      },
      getMemberships() {
        this.getCommitteeMemberships(this.selectedStateId).then(result => {
          this.membershipList = result.data
        })
      },
      getNameParties(value) {
        const data = {
          N: 'Nonpartisan',
          R: 'Republican',
          D: 'Democratic',
          I: 'Independent',
          L: 'Libertarian',
        }
        return data[value]
      },
      async changePerPage() {
        await this.load()
        this.list.pagination.page = 1
      },
      load() {
        if (this.client) {
          this.list.items = []
          return this.getList().then(() => {
            this.listVacantDistricts = this.unelectedDistricts.filter(vacantDistrict => ['H', 'S'].includes(vacantDistrict.district[0]))
          })
        }
      },
      setOrder(field) {
        this.order = field
      },
      setDesc(isDesc) {
        this.orderDesc = isDesc ? 'desc' : 'asc'
      },
      getList() {
        this.loading = true
        let params = {
          page: this.list.pagination.page,
          limit: this.list.pagination.itemsPerPage,
          committees: this.filter.committees.map(committee => committee.id),
          search: this.filter.search ? this.filter.search : undefined,
          commiteeTypeId: this.filter.commiteeTypeId !== false && this.filter.commiteeTypeId !== null ? this.filter.commiteeTypeId : undefined,
          unassigned: this.filter.unassigned ? this.filter.unassigned : undefined,
          archived: this.filter.archived ? this.filter.archived : undefined,
          lame_duck: this.filter.lame_duck ? this.filter.lame_duck : undefined,
          house: this.filter.house ? this.filter.house : undefined,
          senate: this.filter.senate ? this.filter.senate : undefined,
          vip: this.filter.vip ? this.filter.vip : undefined,
          subcommitee: this.filter.committees.length && this.filter.subcommitee ? this.filter.subcommitee : undefined,
          subcommittee_id: this.filter.subcommittee_id ? this.filter.subcommittee_id : undefined,
          regions: this.filter.regions && this.filter.regions.id ? [this.filter.regions.id] : undefined,
          memberCustomFields: this.collectCustomFieldsFilter(),
          kp_preference: this.kpPreferenceResults,
          parties: this.filter.party,
          no_official_email: this.filter.no_official_email,
          no_official_phone: this.filter.no_official_phone,
          order: this.order,
          direction: this.orderDesc,
          selectedFilteredStates: this.selectedFilteredStates,
        }
        this.exportProps = _.cloneDeep(params)
        delete this.exportProps['page']
        delete this.exportProps['limit']
        return this.getClientDistrictsWithPagination(this.client.id, params).then((response) => {
          this.committees = (response.data && response.data.allCommittees) ? response.data.allCommittees : []
          this.list.items = (response.data && response.data.data) ? response.data.data : response.data
          this.initialLegislatorsArray = (response.data && response.data.data) ? response.data.data : response.data
          this.list.items.map(legislator => {
            let vip = legislator.legislator ? legislator.legislator.vip_status.filter(item => item.end_date === null) : []
            if(legislator.legislator) {
              legislator.legislator.vip_note = vip && vip.length > 0 ? vip[0].note : null
              legislator.legislator.vip_start = vip && vip.length > 0 ? vip[0].created_at : null
              legislator.legislator.member_legislators.map(item => {
                if (item.kp_position == 1) {
                  item.member.activeKP1 = true
                }

                item.isKpHomeInLegDistrict = false

                if (legislator.district === item.member.home_house_district || legislator.district === item.member.home_sen_district) {
                  item.isKpHomeInLegDistrict = true
                }

                return item
              })
            }
            return legislator
          })
          this.list.pagination.totalItems = response.data.total ? response.data.total : response.data.length
          this.list.pagination.current_page = response.data.current_page
          this.list.pagination.per_page = response.data.per_page
          this.list.pagination.last_page = response.data.last_page
          this.list.republicans = response.data.republicans ? response.data.republicans : 0,
          this.list.democrats = response.data.democrats ? response.data.democrats : 0,
          this.list.rating = response.data.rating ? response.data.rating : 0
          this.list.areDemocrats = !!response.data.areDemocrats
          this.list.areRepublicans = !!response.data.areRepublicans
          this.list.areNonpartisan = !!response.data.areNonpartisan
          this.list.areIndependent = !!response.data.areIndependent
          this.list.areLibertarian = !!response.data.areLibertarian

          this.selected_committees = this.filter.committees

          this.loading = false
        }, () => {
          this.loading = false
        })
      },
      getCommitteesList() {
        return this.getStateCommittees(this.selectedStateId, {
          with_subcommittees: true,
          only_parent_committees: true,
          client_id: this.client.id,
        }).then(response => {
          this.committeeList = response.data
        })
      },
      getStatesList() {
        return this.getStates().then(response => {
          const states = response.data;

          if(!Array.isArray(states)) {
            return;
          }

          this.stateList = states.filter(state => state.is_country === 0)
          this.countryList = states.filter(state => state.is_country === 1)
        })
      },
      getDistricts() {
        this.getStateUnelectedDistricts(this.selectedStateId).then(response => {
          this.unelectedDistricts = response.data
        })
      },
      getOccupationsList() {
        this.getOccupations().then(response => {
          this.occupationList = response.data
        })
      },
      setCommitteesList() {
        this.filteredCommittees = []

        let houseCommittees = this.client.state.house_districts ? this.client.house_committees : []
        let senateCommittees = this.client.state.sen_districts ? this.client.senate_committees : []
        let jointCommittees = this.client.joint_committees || []

        let committeesList = [...houseCommittees, ...senateCommittees, ...jointCommittees]
            .filter(committeeId => this.committeeList.some(committee => committee.id === committeeId))
            .map((committeeId) => this.committeeList.find(committee => committee.id === committeeId))

        if(committeesList) {
          return committeesList.map(item => {
            let committee = this.committeeTypeList.find(committee => committee.id === item.type)
            let title = committee ? committee.title : ''

            item.fullname = item.fullname + ' - ' + title

            return item
          })
        }

        return []
      },
      doFilter() {
        this.list.pagination.page = 1
        this.menu = false
        this.load()
      },
      editKpPreferenceResults(event, value) {
        if (event) {
          this.kpPreferenceResults.push(value)
        } else {
          const index = this.kpPreferenceResults.indexOf(value)
          if (index >= 0) {
            this.kpPreferenceResults.splice(index,1)
          }
        }
      },
      setFilterValue(filter, newValue = true) {
        this.useLegislatorUrl = false
        if(filter === 'house') {
          this.trimmedCommittees = this.committees.filter(committee => committee.type === 0)
        } else if(filter === 'senate') {
          this.trimmedCommittees = this.committees.filter(committee => committee.type === 1)
        } else {
          this.trimmedCommittees = this.committees;
        }
        const currentValue = this.filter[filter]
        this.resetFilters()

        if (currentValue !== newValue) {
          this.filter[filter] = newValue
        }

        this.showCommittee = this.filter[filter]
        this.list.pagination.page = 1
        this.load()
      },
      resetFilters() {
        this.filter.commiteeTypeId = false
        this.filter.archived = false
        this.filter.unassigned = false
        this.filter.house = false
        this.filter.senate = false
        this.filter.vip = false
        this.filter.lame_duck = false
      },
      //modals
      openEditModal(legislator) {
        legislator.leg_data = legislator.leg_data && legislator.leg_data[0]  ? legislator.leg_data[0] : legislator.leg_data;
        legislator.vip_ao_note = legislator.leg_data && legislator.leg_data[0]  && legislator.leg_data[0].vip_notes ? legislator.leg_data[0].vip_notes : legislator.leg_data && legislator.leg_data.vip_notes ? legislator.leg_data.vip_notes : legislator.vip_note;

        this.$refs.editLegislatorModal.toggle(legislator)
      },
      openMemberModal(member) {
        this.$refs.memberDetailModal.toggle(member)
      },
      openDistrict(item) {
        this.openDistrictModal(item.district, item.id)
      },
      openDetailModal(legislator) {
        this.$refs.legislatorDetailModal.toggle(legislator)
      },
      openNewModal() {
        this.$refs.newLegislatorModal.toggle()
      },
      openAssignmentsModal(legislator) {
        this.$refs.assignmentModal.toggle(legislator)
      },
      openDistrictModal(district, district_id = null) {
        if (typeof district == "undefined") return
        this.$store.dispatch('app/setSelectedModalDistrict', {'district': district, 'district_id': district_id})
        this.$store.dispatch('app/setDistrictModalEnabled', {'status': true})
        this.$store.dispatch('app/setSuperAdminSelectedClient', this.client)
      },
      removeCommittee(index) {
        this.selected_committees.splice(index, 1)
        this.doFilter()
      },
      removeSubcommittee(index) {
        this.filter.subcommittee_id = null
        this.doFilter()
      },
      removePartyFilter(value) {
        this.filter.party[value] = false
        this.doFilter()
      },
      getClientRegionsList() {
        this.clientRegions = []

        if (this.client && this.client.uses_regions) {
          return this.getClientRegions(this.client.id).then(response => {
            if (response.data && response.data.length) {
              this.clientRegions = response.data
            }
          })
        } else {
          this.clientRegions = []
        }
      },
      refreshAssignment(leg_id) {
        this.load().then(()=> {
          let refreshedAssignment = this.list.items.find(leg => leg.id == leg_id)
          if (refreshedAssignment) {
            this.$refs.assignmentModal.refreshAssign(refreshedAssignment)
          }
        })
      },
      filterCommittees(committee) {
        if(committee === 'all') {
          this.filter.committees = [];
        } else {
          const index = this.filter.committees.findIndex(fCommittee => fCommittee.id === committee.id);
          if(index === -1) {
            this.filter.committees.push(committee)
          } else {
            this.filter.committees.splice(index, 1)
          }
        }
        this.doFilter();
      },
      committeeSelected(committee) {
        return this.filter.committees.some(fCommittee => fCommittee.id === committee.id);
      },
      toggleLegislatorURL() {
        this.showCommittee = false
        this.resetFilters()
        this.useLegislatorUrl = !this.useLegislatorUrl
        !this.useLegislatorUrl && this.load()
      },
    },
    mounted() {
      this.init()
    },
    components: {
      'legislators-grid': grid,
      'legislators-table': table,
      'unassigned-legislators-table': unassignedTable,
      'legislators-help': help,
      'app-pagination': pagination,
      'edit-legislator-modal': editLegislatorModal,
      'district-modal': districtModal,
      'legislator-detail-modal': legislatorDetailModal,
      'legislators-page-help-modal': legislatorsPageHelpModal,
      'new-legislator-modal': newLegislatorModal,
      'assignment-modal': assignmentModal,
      'member-detail-modal': memberDetailModal,
      'export-legislators': exportLegislators,
      'export-constituents': exportConstituents,
      VRating2,
    },
    watch: {
      'client': function () {
        this.init()
      },
      'list.pagination.page'() {
        this.load()
      },
      'list.pagination.itemsPerPage'() {
        this.load()
      },
      'filter.search'() {        
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.list.pagination.page = 1
          this.load()
        }, 500)
      },
      'order'() {
        this.list.pagination.page = 1
        this.load()
      },
      'orderDesc'() {
        this.list.pagination.page = 1
        this.load()
      },
      'view'() {
        this.order = 'district'
        this.orderDesc = 'asc'
        this.load()
      },
    },
  }
</script>
