import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"500"},on:{"click:outside":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"message-modal-content"},[_c(VCardTitle,[_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.form.id > 0 ? 'Edit system message' : 'Create system message')+" "),(_vm.loading)?_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary","size":"20","width":"2"}}):_vm._e()],1)]),_c(VDivider),_c(VCardText,{staticClass:"py-3"},[(_vm.isEditorInit)?[_c('editor',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"api-key":_vm.TINY_MCE_API_KEY,"init":_vm.tinyInit,"plugins":_vm.tinyPlugins,"toolbar":_vm.tinyToolbars},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})]:_vm._e(),_c(VSelect,{attrs:{"label":"Style","clearable":"","items":_vm.stylesList,"item-value":"value","item-text":"title"},model:{value:(_vm.form.style),callback:function ($$v) {_vm.$set(_vm.form, "style", $$v)},expression:"form.style"}}),_c(VCheckbox,{staticClass:"mt-0 mb-6 admins-copies_checkbox user-invitation_Checkbox",attrs:{"color":"primary","hide-details":"","label":"Active"},model:{value:(_vm.form.active),callback:function ($$v) {_vm.$set(_vm.form, "active", $$v)},expression:"form.active"}})],2),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"outlined":"","color":"secondary"},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }