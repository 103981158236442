import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"d-flex justify-space-between align-center"},[_c('h4',[_vm._v("Session Notes")])]),_c(VExpansionPanels,{attrs:{"multiple":""},model:{value:(_vm.expandedPanels),callback:function ($$v) {_vm.expandedPanels=$$v},expression:"expandedPanels"}},_vm._l((_vm.formattedNotes),function(notes,session){return _c(VExpansionPanel,{key:session},[_c(VExpansionPanelHeader,[_vm._v(" "+_vm._s(session)+" ("+_vm._s(notes.length)+") ")]),_c(VExpansionPanelContent,[_c('ul',_vm._l((notes),function(note,index){return _c('li',{key:index,staticClass:"note"},[(note.length <= 100)?_c('span',[_vm._v(_vm._s(note))]):_c('span',[_vm._v(" "+_vm._s(_vm.truncatedNote(note))+" "),_c('a',{staticStyle:{"color":"#1976D2","cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.showFullNote(session, index)}}},[_vm._v("Read more")])]),(_vm.isNoteExpanded(session, index))?_c(VDialog,{attrs:{"max-width":"500"},model:{value:(_vm.fullNoteDialog[session][index]),callback:function ($$v) {_vm.$set(_vm.fullNoteDialog[session], index, $$v)},expression:"fullNoteDialog[session][index]"}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(session)+" - Note")]),_c(VCardText,[_vm._v(_vm._s(note))]),_c(VCardActions,{staticClass:"sticky-bottom"},[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.closeFullNote(session, index)}}},[_vm._v("Close")])],1)],1)],1):_vm._e()],1)}),0)])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }