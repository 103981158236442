<template>
  <v-container grid-list-sm class="kp-tasks-page" :style="width">
    <v-progress-linear
      style="z-index: 6"
      color="warning"
      indeterminate
      rounded
      height="3"
      fixed
      top
      v-if="isBusy || loading"
    ></v-progress-linear>
    <div
      v-if="session.alert"
      class="kp-tasks-page__alert mb-4"
    >
      <v-alert
        color="primary"
        dismissible
        outlined
        :value="true"
      >
        <div class="text-h4">
          KP Instructions
        </div>
        <span v-html="session['alert']"></span>
      </v-alert>
      <v-btn
        v-if="isSuperAdmin || isVIP"
        class="kp-tasks-page__edit mx-0"
        icon
        @click="$refs.editSessionModal.toggle(selectedClient, session)"
      >
        <v-icon size="18" color="primary">edit</v-icon>
      </v-btn>
    </div>
    <v-expansion-panels
      :readonly="formChanged"
      v-model="panel"
      focusable
      :popout="isDesktop || isDevice"
    >
      <v-expansion-panel
        v-for="(legislator, index) in legislators"
        :key="legislator.id"
        :value="legislator.id"
      >
      <v-expansion-panel-header
        v-if="legislator.tasks.length > 0 && legislators.length > 0 || !legislator.kp1"
        disable-icon-rotate
        class="pa-2"
        @click="openAlert(index)"
      >
        <div class="kp-tasks-page_legislator">
          <v-avatar color="grey" size="62" class="kp-tasks-page_legislator__avatar mr-3">
            <img :src="getThumbImageMedium(legislator.official_image)">
          </v-avatar>
          <vip-chip
            v-if="legislator.vip"
            class="kp-tasks-page_legislator__vip"
            :legislator="legislator"
            :show="'badge_code'"
          ></vip-chip>
          <div class="d-flex flex-wrap">
            <span class="kp-tasks-page_legislator__name">{{ legislator.salutation }}</span>
            <span class="Caption kp-tasks-page_legislator__under-string" v-if="legislator.primary_kp.kp_position == 1">
              You are KP{{ legislator.primary_kp.kp_position }}
            </span>
            <span class="Caption kp-tasks-page_legislator__under-string" v-else-if="legislator.kp1">
              You are KP{{ legislator.primary_kp.kp_position }}, {{legislator.kp1.fullname}} is KP1
            </span>
            <span class="Caption kp-tasks-page_legislator__under-string" v-else>
              You are KP{{ legislator.primary_kp.kp_position }}, KP1 not assigned
            </span>
          </div>
          <v-tooltip bottom v-if="legislator.lame_duck">
            <template v-slot:activator="{ on }">
              <a v-on="on">
                <v-icon color="#EECB23" size="18" class="mx-2">fa-solid fa-duck</v-icon>
              </a>
            </template>
            <span>Lame Duck</span>
          </v-tooltip>
        </div>
        <div
            v-if="(panel == index && formChanged) || (panel == index && noteChanged)"
            class="kp-tasks-page_save-buttons"
          >
            <v-btn outlined color='secondary' @click.native.stop="refresh" :x-small="isMobile" class="ma-1">Cancel
            </v-btn>
            <v-btn
              v-if="isMobile"
              class="warning ma-1"
              :disabled="isBusy"
              @click.native.stop="onSave(legislator, index)"
              small
            >
              <x-small>Save<br />when done</x-small>
            </v-btn>
            <v-btn
              v-if="isDesktop || isDevice"
              class="ma-1"
              :disabled="isBusy"
              color="primary"
              @click.native.stop="onSave(legislator, index)"
            >
              Save when done
            </v-btn>
          </div>
          <template v-slot:actions>
            <v-tooltip bottom v-if="successAllTasks(legislator)">
              <template v-slot:activator="{ on }">
                <v-icon size="28" color="success">
                  check_circle
                </v-icon>
              </template>
              <span>All Tasks Completed</span>
            </v-tooltip>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <v-icon size="28" color="secondary" >
                  list
                </v-icon>
              </template>
              <span>Unfinished Tasks</span>
            </v-tooltip>
          </template>
      </v-expansion-panel-header>
        <v-expansion-panel-content>
          <my-tasks-tag
            ref="myTasksTag"
            :legislator="legislator"
            :member="member"
            @handleFormChange="handleFormChange"
            @handleNoteChange="handleNoteChange($event)"
            @updatingTaskResult="updatingTaskResult($event)"
            @refresh="refresh"
          ></my-tasks-tag>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-layout row class="kp-tasks-page_actions">
      <v-flex xs12 sm6>
        <v-btn block class="mt-2" :disabled="loading" @click="$refs.SendMessageModal.openModal()" color="primary">
          Email {{ selectedClient.assoc_abbrev }} Team
        </v-btn>
      </v-flex>
      <v-flex xs12 sm6>
        <v-btn block class="mt-2" :disabled="loading" @click="dialogArticle = true" color="primary">
          KP Resources
          <v-badge
            v-if="latestArticle && getBadge(latestArticle) == 'New'"
            inline
            color="success"
            class="mt-0"
            style="flex:unset;"
          >
            <template v-slot:badge>
              <span>New</span>
            </template>
          </v-badge>
          <v-badge
            v-if="latestArticle && getBadge(latestArticle) == 'Updated'"
            inline
            color="primary"
            class="mt-0"
            style="flex:unset;"
          >
            <template v-slot:badge>
              <span>Updated</span>
            </template>
          </v-badge>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-if="legislators.length > 0 && dialogSnackbar"
      v-model="dialogSnackbar"
      color="error"
      top
      :timeout="2000"
    >
      <div>
        <span>
          Please Save or Cancel current changes for
          {{
            legislators && legislators[panel].fullname
              ? legislators[panel].fullname
              : 'legislator'
          }}
          before moving on to another legislator
        </span>
        <v-btn
          text
          @click="dialogSnackbar = false"
        >
          OK
        </v-btn>
      </div>

    </v-snackbar>
    <v-dialog v-model="dialogArticle" :fullscreen="isMobile">
      <articles-index
        :dialog="dialogArticle"
        @closeModal="closeArticleModal"
      ></articles-index>
    </v-dialog>
    <send-message-modal ref="SendMessageModal"></send-message-modal>
    <edit-session-modal
      ref="editSessionModal"
      :active-session="session"
      :session-list="sessionList"
      :committee-list="committeeList"
      :refresh="refresh"
    ></edit-session-modal>
  </v-container>
</template>

<script>
import MemberService from '@/services/MemberService'
import StateService from '@/services/StateService'
import ResourcesService from '@/services/ResourcesService'
import ClientService from "@/services/ClientService";

import filterCommitteeList from '@/mixins/filterCommitteeList'

import MyTasksTag from '@/components/my-tasks/my-tasks-tag';
import editSessionModal from '@/components/sessions/edit-session-modal'
import ArticlesIndex from '@/components/articles/articles-index';
import SendMessageModal from '@/components/my-tasks/send-message-modal';
import TaskService from "@/services/TaskService";
import VipChip from "@/components/legislators/vip-setting/vip-chip";
import {mapGetters} from "vuex";

export default {
  name: 'KpTasksComponent',
  mixins: [MemberService, ResourcesService, ClientService, filterCommitteeList, StateService, TaskService],
  components: {
    'edit-session-modal': editSessionModal,
    'my-tasks-tag' : MyTasksTag,
    'articles-index': ArticlesIndex,
    'send-message-modal': SendMessageModal,
    'vip-chip': VipChip,
  },
  props: {
    member: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isFullScreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      legislators: [],
      isBusy: false,
      loading: false,
      dialog: false,
      session: {},
      taskResult: 0,
      dialogArticle: false,
      sessionList: [],
      tab: null,
      currentTab: null,
      formChanged: false,
      noteChanged: false,
      panel: null,
      dialogSnackbar: false,
      newNote: {}
    }
  },
  mounted() {
    this.refresh()
    if (!this.isSuperAdmin) {
      this.getArticles()
    }
  },
  computed: {
    width() {
      if (this.isFullScreen) {
        return 'width:80%'
      }
      return this.isDesktop || this.isDevice ? 'width:50%' : 'width:100%'
    },
    alertMessage() {
      return 'These are your legislator(s) for which you are the primary KP. ' +
        'Please learn about them, complete assigned tasks by deadlines, ' +
        'record any donations, and keep us updated with Notes. Thanks!'
    },
    ...mapGetters('app', ['superAdminSelectedClient']),
    selectedClient() {
        return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    isFederalClient(){
      return this.selectedClient && this.selectedClient.is_use_state === 0;
    },
    memberId() {
      return this.member && this.member.id ? this.member.id : this.userMemberId
    }
  },
  methods: {
    openAlert(index) {
      if (this.formChanged && index !== this.panel) {
        this.dialogSnackbar = true;
      }
    },
    openOnlyOnePanel() {
      let indexLegislator = 0;
      let legislatorsWithTasks = this.legislators.filter((legislator,index) => {
        if (legislator.tasks.length > 0) { indexLegislator = index }
        return legislator.tasks.length > 0
      })
      if (legislatorsWithTasks.length === 1) {
        this.panel = indexLegislator
      }
    },
    updatingTaskResult(taskResultId)
    {
      this.taskResult = taskResultId
    },
    closeArticleModal() {
      this.dialogArticle = false;
    },
    successAllTasks(legislator) {
      let taskWithoutNotes = legislator.tasks.filter(tasks => {
        return tasks.task_type.data_type !== 'Notes' && tasks.task_type.data_type !== 'Currency'
      })

      let countTask = taskWithoutNotes.length
      let countResults = taskWithoutNotes.filter(task => { return task.result }).length

      return countTask === countResults
    },
    titleTask(legislator) {
      const reg = /^[HS]/;
      let district = legislator.district.district.match(reg)
      let titleTask = ''
      if (district && district[0] === 'H' || (this.isFederalClient && this.selectedClient.formal_house)) {
        titleTask = `${this.selectedClient.formal_house} ${legislator.fullname}`
      } else if (district && district[0] === 'S') {
        titleTask = `${this.selectedClient.formal_senate} ${legislator.fullname}`
      } else {
        titleTask = `${legislator.fullname}`
      }
      return titleTask
    },
    async refresh() {
      this.loading = true
      this.taskResult = 0
      try {
        await this.filterCommitteeList()
        this.sessionList = (await this.getClientSessions(this.selectedClient.id)).data
        let result = (await this.getKPTasks(this.memberId)).data
        this.session = (await this.getClientActiveSession(this.selectedClient.id)).data
        if(!this.session) this.session = {}
        this.formChanged = false
        this.noteChanged = false
        this.loading = false
        this.legislators = result.map((legislator) => {
          let primaryKp = Array.isArray(legislator.member_legislators)
            ? legislator.member_legislators.filter(
                (memberLegislator) =>
                  memberLegislator.member_id === this.memberId
              )
            : [];

          let kp1 = Array.isArray(legislator.member_legislators)
            ? legislator.member_legislators.filter(
                (memberLegislator) => memberLegislator.kp_position == 1 && memberLegislator.member_id === this.memberId
              )
            : [];
            
          primaryKp = primaryKp && primaryKp.length ? primaryKp[0] : { session_tasks: [] };

          return {
            ...legislator,
            kp1: kp1 && kp1[0] && kp1[0].member,
            salutation: this.titleTask(legislator),
            primary_kp: primaryKp,
            tasks: primaryKp.session_tasks && primaryKp.session_tasks.length ? primaryKp.session_tasks.map((task) => ({
              ...task,
              result:
                task.task_results ? task.task_results.filter(
                  (taskResult) => taskResult.legislator_id == legislator.id
                )[0] : null,
            })) : [],
          };
        });
        this.openOnlyOnePanel()
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    handleFormChange() {
      this.formChanged = true
    },
    handleNoteChange(task) {
      this.newNote = task.result
      this.noteChanged = true
    },
    editTaskResult() {
      const payload = {
        result: this.newNote.result,
      }

      this.updateTaskResult(payload, this.taskResult)
        .then(() => {
          this.formChanged = false
          this.noteChanged = false
          this.newNote = {}
          this.$refs.myTasksTag.forEach(tag => {
            tag.clearNoteField()
          })
          this.isBusy = false
        })
        .catch((err) => {
          this.isBusy = false
          this.handleError(err, true)
        })

    },
    async onSave(legislator, index) {
      this.isBusy = true
      let results = []
      if (this.taskResult > 0) {
        await this.editTaskResult()
      }

      if (this.newNote && this.newNote.result && this.newNote.result.length && this.taskResult === 0) {
          results = [...this.getTasksWithoutNotes(legislator), {...this.newNote}]
      } else {
        results = this.getTasksWithoutNotes(legislator)
      }

      const payload = {
        legislator_id: legislator.id,
        results: results,
        id: this.memberId
      }

      this.addKPTaskResults(payload)
        .then(() => {
          this.formChanged = false
          this.noteChanged = false
          this.$refs.myTasksTag.forEach(tag => {
            tag.clearNoteField()
          })
          this.onCallToastr('success', `Success! Task Saved for ${legislator && legislator.fullname ? legislator.fullname : 'legislator'}`, 'Success!')
          this.refresh()
          this.isBusy = false
        })
        .catch((err) => {
          this.isBusy = false
          this.handleError(err, true)
        })
    },
    getTasksWithoutNotes(legislator) {
     return legislator.tasks.filter( task => task.name !== 'Notes' && task.result).map(task => {
         return {
           task_id: task.id,
           result: task.result.result
         }
      })
    }
  }
}
</script>