<template>
    <v-card flat>
        <v-card-title class="d-flex justify-space-between align-center">
            <h4>Session Notes</h4>
        </v-card-title>

        <v-expansion-panels v-model="expandedPanels" multiple>
            <v-expansion-panel v-for="(notes, session) in formattedNotes" :key="session">
                <v-expansion-panel-header>
                    {{ session }} ({{ notes.length }})
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <ul>
                        <li v-for="(note, index) in notes" :key="index" class="note">
                            <span v-if="note.length <= 100">{{ note }}</span>
                            <span v-else>
                                {{ truncatedNote(note) }}
                                <a @click.stop="showFullNote(session, index)" style="color: #1976D2; cursor: pointer;">Read more</a>
                            </span>
                            <v-dialog v-if="isNoteExpanded(session, index)" v-model="fullNoteDialog[session][index]" max-width="500">
                                <v-card>
                                    <v-card-title>{{ session }} - Note</v-card-title>
                                    <v-card-text>{{ note }}</v-card-text>
                                    <v-card-actions class="sticky-bottom">
                                        <v-spacer></v-spacer>
                                        <v-btn text @click="closeFullNote(session, index)">Close</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </li>
                    </ul>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-card>
</template>

<script>
import ClientService from "@/services/ClientService"
import LegislatorService from "@/services/LegislatorService"

export default {
    mixins: [ClientService, LegislatorService],
    props: {
        legislatorId: {
            type: Number,
            required: true
        },
        clientId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            notesData: {},
            fullNoteDialog: {},
            expandedPanels: []
        };
    },
    computed: {
        formattedNotes() {
            return this.notesData;
        }
    },
    methods: {
        async fetchNotes() {
            if (!(this.clientId || this.legislatorId)) {
                return;
            }

            this.notesData = {};
            this.fullNoteDialog = {};
            this.expandedPanels = [];

            let params = {
                legislator_id: this.legislatorId,
                client_id: this.clientId,
            };

            await this.getLegislatorSessionNotes(params)
                .then((response) => {
                    this.notesData = response.data ? response.data : {};
                    this.fullNoteDialog = Object.keys(this.notesData).reduce((acc, session) => {
                        acc[session] = {};
                        return acc;
                    }, {});
                })
                .catch((err) => {
                    this.handleError(err);
                });
        },
        truncatedNote(note) {
            return note.substring(0, 100) + '...';
        },
        showFullNote(session, index) {
            this.$set(this.fullNoteDialog[session], index, true);
        },
        closeFullNote(session, index) {
            this.$set(this.fullNoteDialog[session], index, false);
        },
        isNoteExpanded(session, index) {
            return this.fullNoteDialog[session] && this.fullNoteDialog[session][index];
        }
    },
    mounted() {
        this.fetchNotes();
    },
    watch: {
        clientId(oldValue, newValue) {
            if (oldValue !== newValue) {
                this.fetchNotes();
            }
        },
        legislatorId(oldValue, newValue) {
            if (oldValue !== newValue) {
                this.fetchNotes();
            }
        }
    }
};
</script>

<style scoped>
.note {
    margin-bottom: 8px;
}
.sticky-bottom {
    position: sticky;
    bottom: 0;
    background-color: white;
    z-index: 1;
}
</style>