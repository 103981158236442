<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container fluid grid-list-lg>
    <v-flex class="d-flex align-center">
      <v-btn class="mr-3" icon color="primary" @click="goTo('members-page')">
        <v-icon large>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <h1>Advocate Import</h1>
    </v-flex>
    <v-alert
      v-if="successRows > 0 && importErrors.length === 0 && !isImporting"
      class="text-center"
      type="success"
      outlined
      dense
      text
    >
      Success! You are ready to import {{ successRows }} profiles
    </v-alert>
    <v-alert
      v-if="successRows > 0 && importErrors && importErrors.length && !isImporting"
      class="text-center"
      color="warning"
      outlined
      dense
      text
    >
      Some errors were found, but {{successRows}}
      profiles can be imported now, or you can update the data below and try again by double clicking the bad cell, editing, then return to save each
    </v-alert>
    <v-alert
        v-if="successRows === 0 && importErrors.length === 0 && canImport"
      class="text-center"
      color="error"
      outlined
      dense
      text
    >
      Failure! Contact the administrator!
    </v-alert>
    <v-alert
      v-if="successRows === 0 && importErrors.length > 0 && !importErrors[0].row"
      class="text-center"
      color="error"
      outlined
      dense
      text
    >
      Failure!
      <div v-for="error in errors">{{ error }}</div>
    </v-alert>
    <v-layout wrap>
      <v-flex sm6 xs12>
        <v-card
          class="mx-auto"
        >
          <v-card-text class="pt-3">
            <div>
            <span>You can use the Import tool to either import new Advocates en mass, or to update your current database. To update current database, Export your database being sure to keep
            the email field untouched (this is the key field and MUST be unique), and then edit the field(s) you'd like to edit. You can then import using the "Update" function. BE SURE NOT
             TO UPDATE YOUR CURRENT DATABASE WITH EMPTY DATA!</span>
              <ul>
                <li>Download the template Excel file below OR export your current database.</li>
                <li>Either add your data to the template or update the export file being sure to structure the data correctly.</li>
                <li><strong>For Custom Profile Fields</strong>, you MUST exactly match the options you have in your Advocate field.
                  <ul type="a">
                    <li>i.e., male ≠ Male</li>
                  </ul>
                </li>
                <li>All addresses must be in single-field format with commas between Street, Street2, City, State, Zip
                  <ul type="a">
                    <li>Example: 123 Main St, Springfield, MO, 34505</li>
                  </ul>
                </li>
                <li>For Single Checkbox fields
                  <ul>
                    <li>1 = on/yes</li>
                    <li>empty = off/no</li>
                  </ul>
                </li>
                <li>For Single Select (dropdown) (option)
                  <ul>
                    <li>Use actual option text exactly as typed in the field (example: 'Anthem Blue Cross', not 'Anthem blue cross')</li>
                  </ul>
                </li>
                <li>For Text Fields
                  <ul>
                    <li>Most any text works for these < 255 characters</li>
                    <li>DOB or any date MUST be in this format: (1900-04-01 or YYYY-mm-dd). If Excel changes dates, change column's data type to TXT first</li>
                    <li>currency/money: '494.00'</li>
                    <li>phones will only accept 10 numbers and it will disregard other characters: '585 020 0293'</li>
                    <li>emails: 'name@domain.com'</li>
                    <li>other</li>
                  </ul>
                </li>
                <li>Multi-select fields
                  <ul>
                    <li>Use this format: "empty" if nothing chosen - leave cell blank</li>
                    <li>"option1" (no quotes) if only one option chosen or</li>
                    <li>"option1||option2||option3" for multiple options chosen (no quotes)</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="d-flex justify-center mt-4">
              <v-btn 
                  v-if="isFederalClient" 
                  color="primary" class="text-center" small @click="onExportMemberBtnClickHandler">
                  Export Advocates
                  <v-icon dark class="ml-2">download</v-icon>
              </v-btn>
              <export-members
                  v-else
                  :title="'Export Advocates'"
                  :customClass="'mr-1'"
                  :block="false"
                  :client="selectedClient"
              ></export-members>
              <v-btn class="ml-1" color="primary" small @click="$refs.memberFileTemplateModal.toggle()" :disabled="!selectedClient">
                Download Template
                <v-icon dark class="ml-2">cloud_download</v-icon>
              </v-btn>
            </div>
            <div class="mt-4 text-center">
              <v-layout wrap v-if="(isMemberExporting || isMemberExportReadyToDownload || isMemberExportFailed)" class="d-flex justify-center">
                <div>
                  <span>
                    {{ exportMessage }}
                  </span>
                  <v-progress-linear
                    v-model="memberExportProgress"
                    height="25"
                    v-if="isMemberExporting"
                  >
                    <strong>{{ Math.ceil(memberExportProgress) }}%</strong>
                  </v-progress-linear>
                  <a v-if="isMemberExportReadyToDownload" class="mt-2" :href="memberExportFileUrl" :download="memberExportFileName">
                    <v-btn color="green" block small>Download</v-btn>
                  </a>
                </div>
              </v-layout>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex sm6 xs12>
        <v-card
          class="mx-auto"
        >
          <v-card-text class="pt-3">
            <div class="d-flex justify-center my-4">
              <div v-if="!file && !isEdit && !isAnalyzeDone">
                <v-btn
                  color="primary"
                  class="ma-1"
                  small
                  :loading="isImporting"
                  :disabled="!selectedClient || !!isMemberImport || !!file || isImporting"
                  @click="$refs.memberFileUploader.click()"
                  @change="onDropFile"
                >
                  Choose File
                </v-btn>
                <input
                  v-if="!file"
                  id="upload"
                  ref="memberFileUploader"
                  type="file"
                  accept=".xlsx"
                  class="d-none"
                  @change="onUploadFile"
                >
              </div>
              <div v-if="canImport">
                <v-btn
                  color="green"
                  class="ma-1"
                  small
                  :loading="isImporting"
                  :disabled="successRows === 0 || !selectedClient || !!isMemberImport || isImporting"
                  @click="onImportMember"
                >
                  Import {{ successRows }} Profiles
                  <v-icon size="18" class="ml-2">fa-file-magnifying-glass</v-icon>
                </v-btn>
              </div>
              <div v-if="importErrors.length > 0 || importRows.length > 0">
                <v-btn
                  color="error"
                  class="ma-1"
                  small
                  :loading="isImporting"
                  :disabled="isImporting"
                  @click="removeResult"
                >
                Start Over
                </v-btn>
              </div>
              <div v-if="isEdit && !file && !activePreloader">
                <v-btn
                  color="warning"
                  class="ma-1"
                  small
                  :loading="isImporting"
                  :disabled="!selectedClient || !!isMemberImport || isImporting"
                  @click="onAnalyseJson"
                >
                  Data table Analyze
                  <v-icon size="18" class="ml-2">fa-file-magnifying-glass</v-icon>
                </v-btn>
              </div>
              <v-btn
                v-if="checkUndoButton && notification && notification.data && notification.data.payload && notification.data.payload.success !== 0 && !activePreloader"
                color="error"
                class="mx-4 my-1"
                small
                :disabled="isImporting"
                @click="onUndo"
              >
                Undo Last Successful Import
              </v-btn>
            </div>

            <v-alert
                v-if="skippedRows > 0 && canImport"
                class="text-center"
                color="success"
                outlined
                dense
                text
            >
              Have been skipped {{ skippedRows }} rows!
            </v-alert>
            <div
              v-if="!file && !isImporting && !isEdit && !isAnalyzeDone"
              class="d-flex justify-center drop-area"
            >
              <div
                class="drop-area_dashboard bg-gray-100"
                @dragover="dragover"
                @dragleave="dragleave"
                @drop="drop"
              >
                <input
                  type="file"
                  multiple
                  class="d-none"
                  @change="onDropFile"
                  ref="file"
                  accept=".xlsx"
                />
                <label class="block">
                  <div>
                    Or drop file here.
                  </div>
                </label>
              </div>
            </div>
            <div v-if="!file && activePreloader" class="d-flex flex-wrap align-center pb-2">
              <v-progress-linear
                v-model="progress"
                height="25"
              >
                <strong>{{ Math.ceil(progress) }}%</strong>
              </v-progress-linear>
            </div>
            <div v-if="file">
              <h4>Import method when matches are found?</h4>
              <v-radio-group
                  class="mt-0"
                  v-model="importMatches"
              >
                <v-radio label="Skip (existing Advocates will be ignored)" :value="true" color="primary"></v-radio>
                <v-radio label="Update (existing Advocates will be updated)" :value="false" color="primary"></v-radio>
              </v-radio-group>
              <div
                v-if="!importMatches"
                class="d-flex flex-nowrap justify-space-between align-center"
              >
                <v-switch
                  color="primary"
                  class="mt-0"
                  v-model="isOverwrite"
                  label="Overwrite existing data if blank field exists in imported file"
                  hide-details
                ></v-switch>
                <v-tooltip bottom max-width="450">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="primary"
                      size="24"
                      class="ml-2"
                      v-on="on"
                    >help</v-icon>
                  </template>
                  <span>Turning this on will will overwrite field data in your current database if the data you are importing is blank. Be careful! You may lose a lot of good data that you have collected such as cell phones, updated emails, etc.</span>
                </v-tooltip>
              </div>
            </div>
            <div v-if="file" class="full-width mb-4">
              Selected file:
              <v-divider></v-divider>
              <div class="d-flex flex-wrap justify-space-between">
                <div class="d-flex align-center">
                  {{ fileName }}
                  <h6 class="red--text ml-2" v-if="fileType !== 'xlsx' && file">The file must have an XLSX extension!</h6>
                </div>
                <div class="d-flex flex-wrap align-center">
                  <v-progress-linear
                    v-if="activePreloader"
                    v-model="progress"
                    class="progress_line"
                    height="25"
                  >
                    <strong>{{ Math.ceil(progress) }}%</strong>
                  </v-progress-linear>
                  <div v-if="file">
                    <v-btn
                      color="warning"
                      class="ma-1"
                      small
                      :loading="isImporting"
                      :disabled="isImporting || fileType !== 'xlsx' || !!isMemberImport || activePreloader || (successRows === 0 && importErrors.length > 0)"
                      @click="onAnalyze"
                    >
                      Analyze
                    </v-btn>
                  </div>
                  <v-icon small @click="removeResult">close</v-icon>
                </div>
              </div>
            </div>


            <v-alert
              v-if="activePreloader && !isImporting"
              class="text-center"
              dense
              color="primary"
              outlined
              :value="true"
            >
              Analyzing now.
              You may safely navigate away from this page or wait until
              completed (may be up to 15 minutes for large files).
              Once finished, the result will show below this area.
            </v-alert>
            <v-alert  v-if="file && !activePreloader" class="text-center" dense color="primary" outlined :value="true">
              After upload, please wait as system analyzes your file.
              Only after successful upload, will you be able to import your data.
            </v-alert>
            <div class="mt-2" v-if="importErrors && importErrors.length && importErrors[0].row">
              <v-data-table
                :headers="headerError"
                :items="importErrors"
                :items-per-page="5"
                class="elevation-1"
              >
                <template v-slot:item.row="{ item }">
                  <div>{{ item.row }}</div>
                </template>

                <template v-slot:item.field="{ item }">
                  <div>{{ item.field }}</div>
                </template>

                <template v-slot:item.data="props">
                  <v-edit-dialog :return-value.sync="props.item.data">
                    {{ props.item.data }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.data"
                        label="Edit"
                        single-line
                        @input="isEdit = true"
                        counter
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:item.error="{ item }">
                  <div :class="item.severity ? 'warning--text' : 'error--text'">{{ item.error }}</div>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <member-file-template-modal
      ref="memberFileTemplateModal"
      :client="selectedClient"
    ></member-file-template-modal>
  </v-container>
</template>

<script>

import MemberFileTemplateModal from "@/components/members/member-file-template-modal";
import ClientService from "@/services/ClientService";
import {mapGetters} from "vuex";
import swal from "sweetalert2";
import MemberService from "@/services/MemberService";
import Echo from "laravel-echo";
import token from "@/utils/storage/token";
import ExportMembers from "@/components/common/export-button";

export default {
  name: 'ImportMembersPage',
  mixins: [ClientService, MemberService],
  components: {
    'member-file-template-modal': MemberFileTemplateModal,
    'export-members': ExportMembers,
  },
  data() {
    return {
      isMemberExporting: false,
      memberExportProgress: 0,
      isMemberExportReadyToDownload: false,
      isMemberExportFailed: false,
      memberExportFileUrl: null,
      updateMemberExportProgressSetTimeoutId: null,
      isAnalyzeDone: false,
      isImporting: false,
      activePreloader: false,
      selectedClient: null,
      importMatches: true,
      isOverwrite: false,
      skippedRows: 0,
      isEdit:false,
      $echo: {},
      importErrors: [],
      importRows: [],
      canImport: false,
      successRows: 0,
      progress: 0,
      tableRows:[],
      clientFields: [],
      filelist: [],
      file: null,
    }
  },
  computed: {
    ...mapGetters('app', [
      'superAdminSelectedClient'
    ]),
    ...mapGetters('notifications', [
      'notifications',
      'loading'
    ]),
    errors() {
      return this.importErrors.map(error => error.error);
    },
    headerError() {
      return [
        { text: 'row', value: 'row', align: 'left' },
        { text: 'field name ', value: 'field', align: 'left' },
        { text: 'error data ', value: 'data', align: 'left' },
        { text: 'reason ', value: 'error', align: 'left' },
      ]
    },
    fileName() {
      if(!!this.file) {
        return this.file.name;
      }
      return ''
    },
    fileType() {
      if(!!this.file) {
        return this.file.name.split(".").pop();
      }
      return ''
    },
    checkUndoButton() {
      if (this.selectedClient && this.selectedClient.finish_member_import_at) {
        const valueToFormat = this.selectedClient.finish_member_import_at
        const lastTime = moment.utc(valueToFormat).add(1, 'hours')
        const utcTimeUTC = moment.utc(new Date()).format()
        return !(lastTime.isBefore(utcTimeUTC) || this.isMemberImport)
      }
      return false
    },
    checkLastNotification() {
      if (this.notification) {
        const valueToFormat = this.notification.created_at
        const lastTime = moment.utc(valueToFormat).add(1, 'hours')
        const utcTimeUTC = moment.utc(new Date()).format()
        return !(lastTime.isBefore(utcTimeUTC))
      }
      return false
    },
    notification() {
      this.notifications.filter(notification => {
        return notification.data.type === 'ImportMembers' && notification.data.status === 'finished'
      })

      if (this.notifications && this.notifications.length) {
        let importNotify = [...this.notifications]
        importNotify = importNotify.map(item => item.data.jobId)
        let lastJobId = Math.max(...importNotify)
        const lastNotify = this.notifications.filter(notification => notification.data.jobId == lastJobId)
        return lastNotify[0]
      }

      return {}
    },
	  isMemberImport() {
		 return this.selectedClient && this.selectedClient.start_member_import;
	  },
    isFederalClient(){
      return this.selectedClient && this.selectedClient.is_use_state === 0;
    },
    memberExportFileName(){
      let name = this.selectedClient.assoc_abbrev;
      name += '_KPD_advocate_export';
      name += '_' + this.getFormattedDate(new Date(), 'YYYY-MM-DD');
      return name + '.xlsx';
    },
    exportMessage(){
      if(this.isMemberExportFailed){
        return 'Export failed'
      }
      if(this.isMemberExporting){
        return 'Exporting members...'
      }
      if(this.isMemberExportReadyToDownload){
        return 'Export ready to download'
      }
    },
  },
  watch: {
    'selectedClient': function () {
      console.log('selectedClient')
      this.isMemberExportFailed = false
      this.isMemberExportReadyToDownload = false
      this.isMemberExporting = false
      this.memberExportProgress = 0
      this.showExportProgressIfAnyInProgress();
    },
    'superAdminSelectedClient': async function () {
      console.log('superAdminSelectedClient')
      this.isMemberExportFailed = false
      this.isMemberExportReadyToDownload = false
      this.isMemberExporting = false
      this.memberExportProgress = 0
      await this.getClient()
      this.showExportProgressIfAnyInProgress();
    },
  },
  beforeDestroy: function () {
    if (this.selectedClient) {
    this.$echo.private(`memberImport.${this.selectedClient.id}`)
      .stopListening('MemberImportEvent', () => {
      });
      this.$echo.private(`memberImportAnalyze.${this.userData.id}`)
        .stopListening('MemberImportAnalyzeEvent', () => {
        });
    }

    this.clearMemberExportTimeout()
  },
  created() {
    const socketUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : 'http://kp-dashboard.local:6001';
    this.$echo = new Echo({
      broadcaster: "socket.io",
      host: socketUrl,
      auth: {
        headers: {
          Authorization: 'Bearer ' + token.get(),
        },
      },
      transports: ['websocket', 'polling', 'flashsocket']
    })
  },
  async mounted() {
    this.isEdit = false
    this.canImport = false
    this.isImporting = false
    this.isOverwrite = false
    this.isAnalyzeDone = false
    await this.getClient()
    await this.getImportAnalyzeResult()
    if (this.selectedClient) {
      await this.getClientCustomFields()
      this.$echo.private(`memberImport.${this.selectedClient.id}`)
        .listen('MemberImportEvent', () => {
          this.reloadClient()
          this.activePreloader = false
          this.isEdit = false
          this.progress = 0
        });
      this.$echo.private(`memberImportAnalyze.${this.userData.id}`)
        .listen('MemberImportAnalyzeEvent', () => {
          this.activePreloader = false
          this.file = null
          this.isEdit = false
          this.progress = 0
          this.getImportAnalyzeResult()
        });
      this.$echo.private(`totalRow.${this.userData.id}`)
        .listen('MemberImportTotalRow', (event) => {
          if (event.client_id === this.selectedClient.id && event.percent) {
            this.progress = event.percent
            this.activePreloader = true
            this.isEdit = true
          }
        });
    }

    this.showExportProgressIfAnyInProgress();
  },
  methods: {
    async checkAnyExportInProgressOrCompleted(){
      if(!this.selectedClient){
        return false;
      }
      if(!this.isFederalClient){
        return false;
      }
      const response = await this.getExportMemberProgress(this.selectedClient.id)
      const data = response.data.data
      if (data && (data.status == 'pending' || data.status == 'processing' || data.status == 'completed')) {
        return true
      }
      return false
    },
    async showExportProgressIfAnyInProgress(){
      const isExportInProgress = await this.checkAnyExportInProgressOrCompleted()
      if(isExportInProgress){
        this.updateMemberExportProgress()
      }
    },
    clearMemberExportTimeout(){
      if(this.updateMemberExportProgressSetTimeoutId){
        clearTimeout(this.updateMemberExportProgressSetTimeoutId)
      }
    },
    async onExportMemberBtnClickHandler(){
      console.log('onExportMemberBtnClickHandler')
      if(!this.selectedClient){
        return;
      }
      
      /*
      this.isMemberExportFailed = false
      this.isMemberExportReadyToDownload = false
      this.isMemberExporting = true
      this.memberExportProgress = 0
      const response = await this.exportMembers(this.client.id, {
        type: type
      })
      if(response.data == 'Export started'){
        this.updateMemberExportProgress();
      }
      */

      swal({
        title: 'Select Export Type?',
        html: `
        <ul style="text-align: left; padding: 20px;">
          <li>
            <b>Basic:</b> This export includes minimum fields for each advocate. It takes less time to generate.
          </li>
          <li>
            <b>Full:</b> This export includes all the available fields for each advocate. But it takes longer to generate.
          </li>
        </ul>
      `,
        type: 'info',
        showConfirmButton: true,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: '#4caf50',
        cancelButtonColor: '#9155fd',
        confirmButtonText: 'Basic',
        cancelButtonText: 'Full',
      }).then((result) => {
        if (result.value) {
          this.startExportingMembers('basic')
        } else if (result.dismiss === 'cancel') {
          this.startExportingMembers('full')
        }
      })
    },
    async startExportingMembers(type){
      this.isMemberExportFailed = false
      this.isMemberExportReadyToDownload = false
      this.isMemberExporting = true
      this.memberExportProgress = 0
      const response = await this.exportMembers(this.selectedClient.id, {
        type: type
      })
      if(response.data == 'Export started'){
        this.updateMemberExportProgress();
      }
    },
    async updateMemberExportProgress(){
      const response = await this.getExportMemberProgress(this.selectedClient.id)
      const data = response.data.data
      if(data.status == 'pending' || data.status == 'processing'){
        this.isMemberExporting = true
        this.memberExportProgress = data.progress_percentage
        this.updateMemberExportProgressSetTimeoutId = setTimeout(async () => {
          await this.updateMemberExportProgress()
        }, 2000)
        return;
      }

      if(data.status == 'failed'){
        this.isMemberExportFailed = true
        this.isMemberExporting = false
        return;
      }

      if(data.status == 'completed'){
        if(!data.file_url){
          this.updateMemberExportProgressSetTimeoutId = setTimeout(async() => {
            await this.updateMemberExportProgress()
          }, 2000)
          return;
        }
        this.memberExportProgress = 100
        this.isMemberExporting = false
        this.isMemberExportReadyToDownload = true
        this.memberExportFileUrl = data.file_url
        if(this.updateMemberExportProgressSetTimeoutId == null){
          return;
        }
        const link = document.createElement('a')
        link.href = this.memberExportFileUrl
        link.download = this.selectedClient.assoc_abbrev + '_members.xlsx'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    downloadMemberExportedFileHandler(){
      window.location.href = this.memberExportFileUrl
    },
    goTo (rout) {
      this.$router.push({ name: rout }).catch(err => {})
    },
    async getClient() {
      if (this.isSuperAdmin) {
        if (!this.superAdminSelectedClient) {
          await this.$router.push({path: '/members'})
        }
        this.clientList = (await this.getClients()).data
        this.selectedClient = this.superAdminSelectedClient
      } else {
        this.clientList = (await this.getClients()).data
        this.selectedClient = this.clientList.find(client => client.id === this.userClientId)
      }
    },
    getClientCustomFields() {
      this.getClientFields(this.selectedClient.id).then(response => {
        this.clientFields = response.data.map(field => field.name.replace(/\s+/g, '_').replace(/[?!']+/g, '').toLowerCase())
      })
    },
    getImportAnalyzeResult() {
      if (!this.selectedClient || !this.selectedClient.id) return

      this.getImportRows(this.selectedClient.id).then((result) => {
        this.importErrors = result.data.errors
        this.importRows = result.data.rows
        this.successRows = result.data.successRows
        this.skippedRows = result.data.skipped
        if ((result.data.errors && result.data.errors.length > 0) || (result.data.rows && result.data.rows.length > 0)) {
          this.isAnalyzeDone = true
        }
        if (this.importErrors.length > 0 || this.importRows.length > 0) this.canImport = true
      })
    },
    onUploadFile() {
      this.file = this.$refs.memberFileUploader.files[0]
      this.preAnalyzeFile()
    },
    onDropFile() {
      this.file = this.$refs.file.files[0]
      this.preAnalyzeFile()
    },
    preAnalyzeFile() {
      if (!this.file) return
      this.isImporting = true
      const body = new FormData
      body.append('file', this.file)
      body.append('import_matches', +this.importMatches)
      this.preImportAnalyze(this.selectedClient.id, body).then(() => {
        this.isImporting = false
        this.canImport = false

        this.getImportAnalyzeResult()
      })
        .catch(e => {
          this.getImportAnalyzeResult()
          this.isImporting = false
          this.canImport = false
          console.log(e)
          this.onCallToastr('error', 'Invalid file.', 'Error!')
        })
    },
    remove() {
      this.file = null
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-gray-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-gray-300');
      }
    },
    removeResult() {
      swal({
        title: 'Are you sure about that?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.removeAnalyzeResult()
            .then(() => {
              this.isAnalyzeDone = false
              this.importErrors = []
              this.importRows = []
              this.successRows = 0
              this.file = null
              this.canImport = false
              this.activePreloader = false
              this.isEdit = false
            })
            .catch(e => {
              console.log(e)
              this.activePreloader = false
              this.onCallToastr('error', 'Something went wrong.', 'Error!')
            })
        }
      })

    },
    dragleave(event) {
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-gray-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onDropFile();
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-gray-300');
    },
    async reloadClient() {
      this.clientList = (await this.getClients()).data

      if (this.isSuperAdmin && !this.superAdminSelectedClient) {
        await this.$router.push({path: '/members'})
      } else {
        this.selectedClient = this.clientList.find(client => client.id === this.selectedClient.id)
        if(!this.selectedClient.start_member_import) {
          this.isImporting = false
          this.file = null
          this.importErrors = []
          this.importRows = []
          this.successRows = 0
          this.isEdit = false
          this.canImport = false
        }
      }
    },
    onUndo() {
      swal({
        title: 'Are you sure?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.isImporting = true
          this.cancelImport(this.selectedClient.id)
        }
      })
    },
    onAnalyze() {
      if (!this.file) return
      const body = new FormData
      body.append('file', this.file)
      body.append('import_matches', +this.importMatches)
      body.append('is_overwrite', +this.isOverwrite)
      this.activePreloader = true
      this.importMembersAnalyze(this.selectedClient.id, body)
        .then(() => {

        })
        .catch(e => {
          console.log(e)
          this.activePreloader = false
          this.onCallToastr('error', 'Invalid file.', 'Error!')
        })
    },
    onAnalyseJson() {
      this.activePreloader = true
      this.importMembersAnalyzeJson(this.selectedClient.id, this.importErrors)
        .catch(e => {
          console.log(e)
          this.activePreloader = false
          this.onCallToastr('error', 'Something went wrong.', 'Error!')
        })
    },
    onImportMember() {
      const payload = {
        "ids": [...this.importRows
          .filter(row => !this.importErrors.some(error => (error.row === row.row && error.severity === 0)))
          .map(row => row.id)]
      }
      this.isImporting = true
      this.importClientMembers(this.selectedClient.id, payload)
      this.isAnalyzeDone = false
      const $update = document.getElementById('upload');
      if ($update) $update.value = '';
      this.getClient();
    },
  },
}
</script>
