<template>
  <v-list-item
    color="primary"
    :block="block"
    class="my-1"
    :disabled="!disabled"
    small
    @click="onDownloadTemplate"
    :loading="isBusy"
  >
    Export
  </v-list-item>
</template>

<script>
  import ClientService from '@/services/ClientService'

  export default {
    name: "export-button-list",
    props: {
      client: {
        type: Object,
        required: false,
        default: null
      },
      disabled: {
        type: [Number, Boolean],
        required: true
      },
      params: {
        type: Object,
        required: false,
        default: null
      },
      district: {
        type: String,
        required: false,
        default: ''
      },
      block: {
        type: Boolean,
        default: true
      }
    },
    mixins: [ClientService],
    data() {
      return {
        isBusy: false,

      }
    },
    methods: {
      onDownloadTemplate () {
        this.isBusy = true
        if(this.district) this.params.district = this.district
        if(/^CD/.test(this.district)) this.params.withFederal = 1;
        this.downloadMembers(this.client.id, this.params).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          let abbr = this.userClient ? this.userClient.assoc_abbrev : this.client.assoc_abbrev
          if(this.params.region){
            this.getClientRegionDistricts(this.client.id, this.params.region).then((response) => {
              let regionName = response.data.name.replace(/ /g, "-");
              link.setAttribute('download', `${abbr}_${regionName}_KPD_member_export_${moment().format('MM-DD-YYYY_HH-mm-ss')}.xlsx`)
            }).catch((err) =>{
              console.error(err)
              link.setAttribute('download', `${abbr}_KPD_member_export_${moment().format('MM-DD-YYYY_HH-mm-ss')}.xlsx`)
            }).finally(() =>{
              document.body.appendChild(link)
              link.click()
              this.isBusy = false
            })
          }else{
            link.setAttribute('download', `${abbr}_KPD_member_export_${moment().format('MM-DD-YYYY_HH-mm-ss')}.xlsx`)
            document.body.appendChild(link)
            link.click()
            this.isBusy = false
          }
        },error => {
          this.isBusy = false
          console.error(error)
        })
      },
    }
  }
</script>

