<template>
  <div>
    <v-form ref="textForm">
      <v-flex xs12 class="d-flex justify-end mb-2 align-end px-2 body_variables">
        <v-flex
            xs12 sm8
          :class="!isMobile ? 'd-flex justify-space-between align-end' : 'd-flex justify-space-between align-end w-100'">
          <div class="caption grey--text">Message*</div>
          <v-spacer />
          <v-btn
              v-if="badVariables"
              class="mr-2"
              x-small
              outlined
              color="primary"
              @click="onCheckVariables"
          >
            Show Error
          </v-btn>
          <v-switch
            color="primary"
            v-model="isPreview"
            :label="isPreview ? 'Edit' : 'Show Preview'"
            :loading="isBusy"
            class="d-inline-block mt-0 mr-4"
            hide-details
            :disabled="isBusy || !(members && members.length)"
            @change="onPreviewMessage"
          >
          </v-switch>
        </v-flex>
        <v-flex xs12 sm4>
          <v-select
              v-model="variable"
              :items="variableList"
              class="subject_variable-select text_subject_variable-select"
              label="Body Variables"
              hide-details
              dense
          >
            <template v-slot:item="data">
              <v-list-item-content @click="onAddVariableSms(data.item)" v-text="data.item"></v-list-item-content>
            </template>
          </v-select>
        </v-flex>
      </v-flex>
      <p v-if="badVariables" class="red--text mb-0">You have bad variables! Please click on 'Show Error' button and fix them.</p>
      <v-flex xs12 id="editorContainer" class="px-2">
        <div v-show="!isPreview" class="mt-1 tiny_editor">
          <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
          <editor
              v-if="!isBusy"
              v-model="message"
              :api-key="TINY_MCE_API_KEY"
              :plugins="tinyPlugins"
              :toolbar="tinyToolbars"
              :init="tinyInit"
              :disabled="disabledEditor"
              @click="onChangeText"
              @keyup="onChangeText"
          ></editor>
        </div>
        <div v-show="isPreview" class="mt-1">
          <editor
              v-if="!isBusy"
              :value="text.formattedMessage"
              :api-key="TINY_MCE_API_KEY"
              :plugins="tinyPlugins"
              :toolbar="tinyToolbars"
              :init="tinyInit"
              disabled
          ></editor>
        </div>
        <div>
      <span class="text-counter" :class="text.finalMessageLength > 320 ? 'error--text' : ''">
        {{ text.finalMessageLength }}/320
      </span>
        </div>
      </v-flex>
    </v-form>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import TinyConfig from "@/mixins/tiny-mce-config"
import AppService from '@/services/AppService'
import he from "he";
import ClientService from "@/services/ClientService";
import ShorterUrl from "@/mixins/shorterUrl";

export default {
  name: 'SendText',
  mixins: [AppService, TinyConfig, ShorterUrl],
  components: {
    'editor': Editor, // Do not change, this is a requirement of tinyMCE,
  },
  props: {
    propMessage: {
      type: String,
      default: ''
    },
    text: {
      type: Object,
      default: function () {
        return {}
      }
    },
    members: {
      type: Array,
      default: function () {
        return []
      }
    },
    isEmail: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isPreview: false,
      variable: '',
      isBusy: false,
      selection: 14,
    }
  },
  computed: {
    message: {
      get() {
        return this.propMessage
      },
      set(newValue) {
        this.$emit('setMessage', newValue)
      }
    },
    tinyToolbars() {
      return []
    },
    variableList() {
      return [
        '{ez_login}',
        '{kp_first}',
        '{kp_last}',
      ]
    },
  },
  methods: {
    async onPreviewMessage() {
      if (!this.isPreview || this.message === '') {
        return
      }
      let payload = {}
      payload = {
        legislator_id: this.members.length > 0 ? this.members[0].member_legislator[0].legislator.id : 0,
        member_id: this.members.length > 0 ? this.members[0].id : 0,
        message: this.message,
      }

      try {
        this.isBusy = true
        this.text.formattedMessage = (await this.getAppFormattedMessage(payload)).data
      } catch {
        this.onCallToastr('error', 'Something went wrong, please try again later!', 'Failed!')
      } finally {
        this.isBusy = false
      }
    },
    onAddVariableSms(variable) {
      variable += ' '
      tinymce.activeEditor.execCommand('mceInsertContent', false, variable);

      this.finalMessageLength()
    },
    html2Text(html) {
      let find = /(<br>|<\/p><p>)/,
        re = new RegExp(find, 'g');

      let txt = html.replace(re, '\n');
      txt = txt.replace(/<[^>]*>?/gm, '')
      return he.decode(txt)
    },
    finalMessageLength() {
      const ezLogin = 'https://kpda.sh/ez/xxxxxxxx'
      let kpNick = ''
      let kpLast = ''
      this.members.forEach(member => {
        if (kpNick.length < (member.nickname || '').length) kpNick = member.nickname
        if (kpLast.length < (member.lastname || '').length) kpLast = member.lastname
      })
      let message = this.message || ''
      message = message.replace(/{ez_login}/g, ezLogin)
      message = message.replace(/{kp_first}/g, kpNick)
      message = message.replace(/{kp_last}/g, kpLast)

      message = this.html2Text(message)
      this.text.finalMessageLength = message.length
    },
    onCheckVariables() {
      this.checkVariablesForEditor(this.message, this.variableList)
    },
    async onChangeText() {
      this.searchIncorrectVariables(this.message, this.variableList)
      this.finalMessageLength()

      this.$nextTick(async () => {
        const newContent = await this.parsUrl(this.message)
        if (newContent !== this.message) {
          document.activeElement.blur()
          this.message = newContent
          this.disabledEditor = false
        }
      })
      this.$emit('error')
    },
  },
  mounted() {
    this.isBusy = true
    this.isPreview = false
    this.badVariables = false
    setTimeout(() => {
      this.isBusy = false
    }, 500)
  },
  watch: {
    badVariables: function () {
      this.searchIncorrectVariables(this.message, this.variableList)
      if (!this.badVariables) {
        this.checkVariablesForEditor(this.message, this.variableList)
      }
      this.$emit('badVariables', this.badVariables)
    },
    'message': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.finalMessageLength()
        this.$emit('finalMessageLength', this.text.finalMessageLength)
      }
    },
  }
}
</script>
