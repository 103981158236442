<template>
  <div>
    <v-layout align-center class="mb-2">
      <v-flex xs12 sm8 class="d-flex px-2 align-center">
        <div class="caption grey--text">Message text*</div>
        <v-spacer/>
        <v-btn
            v-if="badVariables"
            class="mr-1"
            x-small
            outlined
            color="primary"
            @click="onCheckVariables"
        >
          Show Error
        </v-btn>
        <v-switch
            color="primary"
            hide-details
            :label="isPreview ? 'Edit' : 'Show Preview'"
            class="d-inline-block px-2 pt-0 sms-preview-switch"
            :disabled="isBusy"
            @change="getFormatMessage()"
            v-model="isPreview"
            :loading="isBusy"
        ></v-switch>
      </v-flex>
      <v-flex xs12 sm4 class="d-flex px-2">
        <v-select
            :disabled="isPreview"
            v-model="variable"
            label="Variables"
            :items="variableList"
            hide-details
            outlined
            dense
            class="subject_variable-select text_subject_variable-select"
        >
          <template v-slot:item="data">
            <v-list-item-content @click="onAddVariable(data.item)" v-text="data.item"></v-list-item-content>
          </template>
        </v-select>
      </v-flex>
    </v-layout>
    <div>
      <p v-if="badVariables" class="red--text mb-0">You have bad variables! Please click on 'Show Error' button and
        fix them.</p>
      <div class="tiny_editor" v-show="!isPreview">
        <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
        <editor
            v-if="!isBusy"
            v-model="message"
            :api-key="TINY_MCE_API_KEY"
            :plugins="tinyPlugins"
            :toolbar="tinyToolbars"
            :init="tinyInit"
            :disabled="disabledEditor"
            @keyup="inputText"
        ></editor>
      </div>
      <div v-show="isPreview">
        <editor
            v-if="!isBusy"
            :value="formattedMessage"
            :api-key="TINY_MCE_API_KEY"
            :plugins="tinyPlugins"
            :toolbar="tinyToolbars"
            :init="tinyInit"
            disabled
        ></editor>
      </div>
      <div>
        <span
            class="text-counter"
            :class="finalMessageLength > 320 ? 'error--text' : ''"
        >{{ finalMessageLength }}/320</span>
      </div>
      <div>
        <a @click="onOpenTestMessageModal" class="mr-4">
          Send test to {{ userData.cell_phone }}
        </a>
      </div>
      <div class="mb-2">
        <span class="error--text">{{ errorMessage }}</span>
      </div>
    </div>
    <TestMessageModal
        ref="TestMessageModal"
        :legislatorId="districtLegislator && districtLegislator.id ? districtLegislator.id : 0"
        :memberId="memberId"
        :isEmail="false"
        :email="emailToTest"
        :message="message"
    >
    </TestMessageModal>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import TinyConfig from "@/mixins/tiny-mce-config"
import ShorterUrl from "@/mixins/shorterUrl"

import AppService from '@/services/AppService'
import he from "he";
import TestMessageModal from '@/components/global/test-message-modal'
import ClientService from "@/services/ClientService";
import {mapGetters} from "vuex";


export default {
  name: 'SendText',
  mixins: [AppService, TinyConfig, ClientService, ShorterUrl],
  components: {
    'editor': Editor, // Do not change, this is a requirement of tinyMCE
    TestMessageModal
  },
  props: {
    districtLegislator: {
      type: Object,
      default: function () {
        return {}
      }
    },
    propMessage: {
      type:String,
      default: ''
    },
    selectedDistrict: {
      type: String,
      default: ''
    },
    memberId: {
      type: Number,
      default: 0
    },
    errorMessage: {
      type: String,
      default: ''
    },
    selectMembers: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      isPreview: false,
      variable: '',
      formattedMessage: '',
      emailToTest: {},
      isBusy: false,
    }
  },
  computed: {
    ...mapGetters('app', [
      'superAdminSelectedClient'
    ]),
    selectedClient() {
      if (this.isSuperAdmin) {
        return this.superAdminSelectedClient
      }
      return this.userClient
    },
    message: {
      get() {
        return this.propMessage
      },
      set(newValue) {
        this.$emit('setMessage', newValue)
      }
    },
    tinyToolbars() {
      return []
    },
    variableList() {
      let variable = [
        '{advocate_first}',
        '{assoc_abbrev}',
        '{sender_firstname}',
        '{sender_lastname}',
        '{sender_fullname}',
        '{sender_address}',
        '{sender_cell}',
      ]

      if (this.districtLegislator && this.districtLegislator.id) {
        let legVariable = [
          '{district}',
          '{live/work}',
          '{leg_nick}',
          '{leg_fullname}',
          '{leg_formal_address}',
          '{leg_bio}',
          '{leg_salutation}',
          '{leg_salutation_fullname}',
          '{leg_email}',
          '{leg_ph}',
          '{leg_local_phone}',
          '{leg_official_image}',
        ]

        variable = variable.concat(legVariable)
      }

      return variable;
    },
    finalMessageLength() {
      let legSalutation = '';
      if (this.selectedDistrict[0] === 'H') {
        legSalutation = this.selectedClient.formal_house + ' ' + this.districtLegislator.lastname;
      }
      if (this.selectedDistrict[0] === 'S') {
        legSalutation = this.selectedClient.formal_senate + ' ' + this.districtLegislator.lastname;
      }
      const legNick = this.districtLegislator.nickname;
      const legFullname = this.districtLegislator.fullname;
      const legBio = this.districtLegislator.occupation;
      const legEmail = this.districtLegislator.official_email;
      const legPh = this.districtLegislator.official_phone;
      const legLocalPhone = this.districtLegislator.local_phone;
      const district = this.selectedDistrict;
      let memberNick = ''
      const liveWork = 'see many patients that live'
      this.selectMembers.forEach(member => {
        if (memberNick.length < (member.nickname || '').length) memberNick = member.nickname
      })

      let message = this.message || ''
      message = message.replace(/{leg_nick}/g, legNick)
      message = message.replace(/{leg_fullname}/g, legFullname)
      message = message.replace(/{leg_bio}/g, legBio)
      message = message.replace(/{leg_salutation}/g, legSalutation)
      message = message.replace(/{leg_email}/g, legEmail)
      message = message.replace(/{leg_ph}/g, legPh)
      message = message.replace(/{leg_local_phone}/g, legLocalPhone)
      message = message.replace(/{district}/g, district)
      message = message.replace(/{advocate_first}/g, memberNick)
      message = message.replace(/{live\/work}/g, liveWork)

      message = this.html2Text(message)
      return message.length
    },
  },
  methods: {
    onCheckVariables() {
      this.checkVariablesForEditor(this.message, this.variableList)
    },
    getFormatMessage() {
      this.isBusy = true

      let payload = {
        member_id: this.memberId,
        message: this.message,
      }

      if (this.districtLegislator && this.districtLegislator.id) {
        payload.legislator_id = this.districtLegislator.id
      }

      this.getAppFormattedMessage(payload)
          .then((response) => {
            this.formattedMessage = response.data
            this.isBusy = false
          })
          .catch((err) => {
            this.handleError(err)
          })
    },
    onAddVariable(variable) {
      variable += ' '
      tinymce.activeEditor.execCommand('mceInsertContent', false, variable);
    },
    async inputText(message) {
      this.$nextTick(async () => {
        this.searchIncorrectVariables(message, this.variableList)
        const newContent = await this.parsUrl(message)

        if (newContent !== message) {
          this.message = newContent
          this.disabledEditor = false
          document.activeElement.blur()
        } else {
          this.message = message
        }
      })
    },
    onOpenTestMessageModal() {
      this.emailToTest = {
        is_sms: true,
        clientId: this.selectedClient.id,
        message: this.message
      }
      this.$refs.TestMessageModal.open()
    },
    html2Text(html) {
      let find = /(<p><br[\/]?><[\/]?p>)/,
          re = new RegExp(find, 'g');

      let txt = html.replace(re, '\n');
      txt = txt.replace(/<[^>]*>?/gm, '')
      return he.decode(txt)
    },
  },
  mounted() {
    this.isBusy = true
    this.isPreview = false
    this.badVariables = false
    setTimeout(() => {
      this.isBusy = false
    }, 500)
  },
  watch: {
    badVariables: function () {
      this.searchIncorrectVariables(this.message, this.variableList)
      if (!this.badVariables) {
        this.checkVariablesForEditor(this.message, this.variableList)
      }
      this.$emit('badVariables', this.badVariables)
    },
    'message': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('finalMessageLength', this.finalMessageLength)
      }
    },
  }
}
</script>
