<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container fluid grid-list-lg>
    <v-layout wrap>
      <v-flex class="d-flex align-center">
        <h1>Find New Federal Legislators</h1>
        <v-spacer></v-spacer>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex class="d-flex align-center justify-end flex-wrap" v-if="isDesktop || isDevice">
        <v-btn color="primary" class="ml-2" small :loading="isLoading" :disabled="isLoading"  @click="findNewFederalLegislators">
          Find New Federal Legislators
        </v-btn>
        <v-btn color="info" class="ml-2" small :loading="isLoading" :disabled="selected.length === 0" @click="importFederalLegislators" v-if="filterAction != 'all'">
          {{ filterAction }} Selected Legislators
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout wrap>
      <v-flex class="d-flex align-center flex-wrap">
        <v-select
            label="Country"
            hide-details
            class="state-selector mr-2"
            item-text="state"
            item-value="id"
            :items="countryList"
            v-model="selectedCountryId"
            @input="changeCountry"
            v-if="isSuperAdmin"
        >
        </v-select>
        <v-select
            label="State"
            hide-details
            class="state-selector mr-2"
            item-text="state"
            item-value="id"
            :items="stateList"
            v-model="selectedStateId"
            @input="refresh"
            v-if="isSuperAdmin"
        >
        </v-select>
        <v-text-field
          v-model="searchName"
          label="Search Legislator"
          prepend-inner-icon="search"
          clearable
          hide-details
          @input="filterByName"
        ></v-text-field>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex class="d-flex align-center justify-end flex-wrap" v-if="isDesktop || isDevice">
        <v-radio-group v-model="filterAction" row @change="onChangeFilterAction">
          <v-radio label="All" value="all" ></v-radio>
          <v-radio label="Update" value="update" ></v-radio>
          <v-radio label="Replace" value="replace" ></v-radio>
          <v-radio label="Import" value="import" ></v-radio>
        </v-radio-group>
      </v-flex>
    </v-layout>

    <v-data-table
      :show-select="filterAction != 'all' && legislatorList.length > 0"
      v-model="selected"
      :loading="isLoading"
      class="elevation-1"
      :options.sync="pagination"
      :headers="legislatorTableHeader"
      :items="legislatorList"
      item-key="id"
      show-expand
    >
    <template v-slot:item.active_legislator.nickname="{ item }">
        <div class="d-flex align-center mb-1" v-if="item.active_legislator">
          <div class="mr-1 no-line-break error--text">{{ item.active_legislator.nickname }} {{ item.active_legislator.lastname }}</div>
          <div class="table_item__avatar">
            <img
              :src="getThumbImage(item.active_legislator.official_image)"
              :alt="item.active_legislator.nickname + ' ' + item.active_legislator.lastname"
              v-if="item.active_legislator.official_image"
            />
          </div>
          <!-- <div class="ml-4"> <b>Elected:</b>  {{ item.active_legislator.elected_at }}</div> -->
        </div>
        <div class="d-flex align-center" v-if="item.imported_federal_legislator && (!item.active_legislator || item.active_legislator.ls_member_id != item.imported_federal_legislator.ls_member_id)">
          <div class="mr-1 no-line-break success--text">{{ item.imported_federal_legislator.nickname }} {{ item.imported_federal_legislator.lastname }}</div>
          <div class="table_item__avatar">
            <img
              :src="getThumbImage(item.imported_federal_legislator.official_image)"
              :alt="item.imported_federal_legislator.nickname + ' ' + item.imported_federal_legislator.lastname"
              v-if="item.imported_federal_legislator.official_image"
            />
          </div>
          <!-- <div class="ml-4"> <b>Elected:</b>  {{ item.imported_federal_legislator.elected_at }}</div> -->
        </div>
      </template>

      <template v-slot:item.state.state="{ item }">
          {{ item.state.state }}
      </template>
      <template v-slot:item.district="{ item }">
          {{ item.district ? item.district : "-" }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div>
          <v-card-actions>
              <v-btn small color="error" @click="updateLegislatorAction(item.active_legislator.id, item.imported_federal_legislator.id)" v-if="item.active_legislator && item.imported_federal_legislator && item.active_legislator.ls_member_id == item.imported_federal_legislator.ls_member_id"  >Update</v-btn>
              <v-btn small color="info" @click="replaceLegislatorAction(item.active_legislator.id, item.imported_federal_legislator.id)" v-if="item.active_legislator && item.imported_federal_legislator && item.active_legislator.ls_member_id != item.imported_federal_legislator.ls_member_id">Replace</v-btn>
              <v-btn small color="success" @click="createLegislatorAction(item.imported_federal_legislator.id)" v-if="!item.active_legislator && item.imported_federal_legislator">Import</v-btn>
            </v-card-actions>
        </div>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="legislatorTableHeader.length">
        <v-simple-table class="elevation-1" v-if="item.active_legislator && item.imported_federal_legislator && item.active_legislator.ls_member_id == item.imported_federal_legislator.ls_member_id">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Field</th>
                    <th>Old Data</th>
                    <th>New Data</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="item.active_legislator.lastname != item.imported_federal_legislator.lastname">
                    <td><b>Last Name</b></td>
                    <td>{{ item.active_legislator.lastname }}</td>
                    <td>{{ item.imported_federal_legislator.lastname }}</td>
                  </tr>
                  <tr v-if="item.active_legislator.nickname != item.imported_federal_legislator.nickname">
                    <td><b>Nickname</b></td>
                    <td>{{ item.active_legislator.nickname }}</td>
                    <td>{{ item.imported_federal_legislator.nickname }}</td>
                  </tr>
                  <tr v-if="item.active_legislator.party != item.imported_federal_legislator.party">
                    <td><b>Party</b></td>
                    <td>{{ item.active_legislator.party }}</td>
                    <td>{{ item.imported_federal_legislator.party }}</td>
                  </tr>
                  <tr v-if="!compareNumbers(item.active_legislator.official_phone, item.imported_federal_legislator.official_phone)">
                    <td><b>Official Phone</b></td>
                    <td>{{ item.active_legislator.official_phone }}</td>
                    <td>{{ item.imported_federal_legislator.official_phone }}</td>
                  </tr>
                  <tr v-if="item.active_legislator.official_address != item.imported_federal_legislator.official_address">
                    <td><b>Official Address</b></td>
                    <td>{{ item.active_legislator.official_address }}</td>
                    <td>{{ item.imported_federal_legislator.official_address }}</td>
                  </tr>
                  <tr v-if="item.active_legislator.state_room != item.imported_federal_legislator.state_room">
                    <td><b>State Room</b></td>
                    <td>{{ item.active_legislator.state_room }}</td>
                    <td>{{ item.imported_federal_legislator.state_room }}</td>
                  </tr>
                  <tr v-if="item.active_legislator.elected_at != item.imported_federal_legislator.elected_at">
                    <td><b>Elected at</b></td>
                    <td>{{ item.active_legislator.elected_at }}</td>
                    <td>{{ item.imported_federal_legislator.elected_at }}</td>
                  </tr>
                  <tr v-if="item.active_legislator.website_url != item.imported_federal_legislator.website_url">
                    <td><b>Website Url</b></td>
                    <td>{{ item.active_legislator.website_url }}</td>
                    <td>{{ item.imported_federal_legislator.website_url }}</td>
                  </tr>
                  <tr v-if="item.active_legislator.campaign_url != item.imported_federal_legislator.campaign_url">
                    <td><b>Campaign Url</b></td>
                    <td>{{ item.active_legislator.campaign_url }}</td>
                    <td>{{ item.imported_federal_legislator.campaign_url }}</td>
                  </tr>
                  <tr v-if="item.active_legislator.twitter_url != item.imported_federal_legislator.twitter_url">
                    <td><b>X Url</b></td>
                    <td>{{ item.active_legislator.twitter_url }}</td>
                    <td>{{ item.imported_federal_legislator.twitter_url }}</td>
                  </tr>
                  <tr v-if="item.active_legislator.facebook_url != item.imported_federal_legislator.facebook_url">
                    <td><b>Facebook Url</b></td>
                    <td>{{ item.active_legislator.facebook_url }}</td>
                    <td>{{ item.imported_federal_legislator.facebook_url }}</td>
                  </tr>
                  <tr v-if="item.active_legislator.instagram_url != item.imported_federal_legislator.instagram_url">
                    <td><b>Instagram Url</b></td>
                    <td>{{ item.active_legislator.instagram_url }}</td>
                    <td>{{ item.imported_federal_legislator.instagram_url }}</td>
                  </tr>
                  <tr v-if="item.active_legislator.linkedin_url != item.imported_federal_legislator.linkedin_url">
                    <td><b>Linkedin Url</b></td>
                    <td>{{ item.active_legislator.linkedin_url }}</td>
                    <td>{{ item.imported_federal_legislator.linkedin_url }}</td>
                  </tr>
                  <tr v-if="item.active_legislator.youtube_url != item.imported_federal_legislator.youtube_url">
                    <td><b>Youtube Url</b></td>
                    <td>{{ item.active_legislator.youtube_url }}</td>
                    <td>{{ item.imported_federal_legislator.youtube_url }}</td>
                  </tr>
                  <tr v-if="item.active_legislator.contact_form_url != item.imported_federal_legislator.contact_form_url">
                    <td><b>Contact Form</b></td>
                    <td>{{ item.active_legislator.contact_form_url }}</td>
                    <td>{{ item.imported_federal_legislator.contact_form_url }}</td>
                  </tr>
                  <tr v-if="item.active_legislator.opensecrets_url != item.imported_federal_legislator.opensecrets_url">
                    <td><b>OpenSecrets Url</b></td>
                    <td>{{ item.active_legislator.opensecrets_url }}</td>
                    <td>{{ item.imported_federal_legislator.opensecrets_url }}</td>
                  </tr>
                  <tr v-if="item.active_legislator.truthsocial_url != item.imported_federal_legislator.truthsocial_url">
                    <td><b>Truth Social Url</b></td>
                    <td>{{ item.active_legislator.truthsocial_url }}</td>
                    <td>{{ item.imported_federal_legislator.truthsocial_url }}</td>
                  </tr>
                  <tr v-if="item.active_legislator.bluesky_url != item.imported_federal_legislator.bluesky_url">
                    <td><b>Bluesky Url</b></td>
                    <td>{{ item.active_legislator.bluesky_url }}</td>
                    <td>{{ item.imported_federal_legislator.bluesky_url }}</td>
                  </tr>
                  <tr v-if="item.active_legislator.tiktok_url != item.imported_federal_legislator.tiktok_url">
                    <td><b>Tiktok Url</b></td>
                    <td>{{ item.active_legislator.tiktok_url }}</td>
                    <td>{{ item.imported_federal_legislator.tiktok_url }}</td>
                  </tr>
                  <tr v-if="item.active_legislator.threads_url != item.imported_federal_legislator.threads_url">
                    <td><b>Threads Url</b></td>
                    <td>{{ item.active_legislator.threads_url }}</td>
                    <td>{{ item.imported_federal_legislator.threads_url }}</td>
                  </tr>
                  <tr v-if="item.active_legislator.eventbright_url != item.imported_federal_legislator.eventbright_url">
                    <td><b>Eventbrite Url</b></td>
                    <td>{{ item.active_legislator.eventbright_url }}</td>
                    <td>{{ item.imported_federal_legislator.eventbright_url }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
      </template>
      <template v-slot:no-data>
        <p class="text-xs-center mb-0">No federal legislator registered yet.</p>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import swal from 'sweetalert2'

import ClientService from '@/services/ClientService'
import LegislatorService from '@/services/LegislatorService'
import StateService from '@/services/StateService'
import { partyColorList } from '@/entities/party-color-list'
import ApiSourcesService from "@/services/ApiSourcesService";

export default {
    name: 'LegislatorsPage',
    mixins: [ClientService, LegislatorService, StateService, ApiSourcesService],
    data() {
      return {
        isLoading: false,
        isImporting: false,
        isIgnoring: false,
        countryList: [],
        stateList: [],
        allStateList: [],
        selectedCountryId: null,
        selectedStateId: null,
        legislatorList: [],
        originalLegislatorList: [], // Store original list for filtering
        isUpdate: true,
        selected: [],
        pagination: {
          itemsPerPage: 10,
        },
        partyColorList: partyColorList(),
        apiSourceName: '',
        searchQuery: '',
        filterAction: 'all',
        filteredLegislatorList: [],
        searchName: '', // New property for search
      }
    },
    computed: {
      legislatorTableHeader() {
        return [
          {text: '', value: '', align: 'left', sortable: false},
          {text: 'Name', value: 'active_legislator.nickname', align: 'left'},
          {text: 'State', value: 'state.state', align: 'center'},
          {text: 'District', value: 'district', align: 'center'},
          // {text: 'Party', value: 'party', align: 'center'},
          {text: 'Actions', value: 'actions', sortable: false},
          { text: '', value: 'data-table-expand', sortable: false }
        ]
      },
    },
    async mounted() {
      this.isLoading = true
      if (!this.isSuperAdmin) {
        this.selectedStateId = this.userClient.state_id
      }
      this.stateList = []
      this.allStateList = (await this.getStates()).data
      this.countryList = this.allStateList.filter(state => state.is_country == 1)
      if (this.countryList.length > 0) {
          this.selectedCountryId = this.countryList[0].id;
          this.stateList = this.allStateList.filter(state => state.state_of == this.selectedCountryId)
        }
      this.refresh()
    },
    methods: {
      changeCountry(){
        this.selectedStateId = null
        this.stateList = this.allStateList.filter(state => state.state_of == this.selectedCountryId)
        this.refresh()
      },
      refresh() {
        this.isLoading = true
        this.isSelected = []
        this.selected = []
        this.legislatorList = []
        this.filterAction = 'all'
        this.filteredLegislatorList = []
        this.searchName = '' // Reset search when refreshing
        this.getImportedFederalLegislators({stateId: this.selectedStateId, countryId: this.selectedCountryId}).then(res => {
          this.legislatorList = res.data;
          this.filteredLegislatorList = res.data;
          this.originalLegislatorList = [...res.data]; // Store original data
          this.isLoading = false
        })
      },
      filterByName() {
  if (!this.searchName) {
    // If search is empty, restore the filtered list based on current action
    this.onChangeFilterAction();
    return;
  }
  
  const searchTerm = this.searchName.toLowerCase();
  
  // Filter from original list first
  let filtered = this.filteredLegislatorList.filter(item => {
    // Check active legislator name
    if (item.active_legislator) {
      const fullName = `${item.active_legislator.nickname} ${item.active_legislator.lastname}`.toLowerCase();
      if (fullName.includes(searchTerm)) return true;
    }
    
    // Check imported legislator name
    if (item.imported_federal_legislator) {
      const fullName = `${item.imported_federal_legislator.nickname} ${item.imported_federal_legislator.lastname}`.toLowerCase();
      if (fullName.includes(searchTerm)) return true;
    }
    
    return false;
  });
  
  // Apply current action filter on top of name filter
  if (this.filterAction === 'update') {
    filtered = filtered.filter(
      item =>
        item.active_legislator &&
        item.imported_federal_legislator &&
        item.active_legislator.ls_member_id === item.imported_federal_legislator.ls_member_id
    );
  } else if (this.filterAction === 'replace') {
    filtered = filtered.filter(
      item =>
        item.active_legislator &&
        item.imported_federal_legislator &&
        item.active_legislator.ls_member_id !== item.imported_federal_legislator.ls_member_id
    );
  } else if (this.filterAction === 'import') {
    filtered = filtered.filter(
      item => !item.active_legislator && item.imported_federal_legislator
    );
  }
  
  this.legislatorList = filtered;
},
      findNewFederalLegislators() {
        this.isImporting = true
        swal({
          title: 'Are you sure?',
          html: `
          This action will fetch the federal legislators from the Legistorm API
        `,
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, proceed',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.fetchNewFederalLegislators()
            this.onCallToastr('success', "Find New Federal Legislators in progress...")
            this.$modals.get('notifications').openAndLoad()
            this.isImporting = false
            this.refresh()
          }
        })
      },

      updateLegislatorAction(oldLegislatorId, newLegislatorId) {
        
        this.isImporting = true

        swal({
          title: 'Are you sure?',
          html: `
          This action will Update selected federal legislators in our database. However, legislators without districts will not be created.
        `,
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, proceed',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            const payload = {
              oldLegislatorId: oldLegislatorId,
              newLegislatorId: newLegislatorId,
            }
            this.updateFederalLegislators(payload)
            this.onCallToastr('success', "Update Selected Federal Legislators in progress...")
            this.$modals.get('notifications').openAndLoad()
            this.isImporting = false
            this.refresh()
          }
        })
      },

      replaceLegislatorAction(oldLegislatorId, newLegislatorId) {
        this.isImporting = true

        swal({
          title: 'Are you sure?',
          html: `
          This action will Replace selected federal legislators in our database. However, legislators without districts will not be created.
        `,
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, proceed',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            const payload = {
              oldLegislatorId: oldLegislatorId,
              newLegislatorId: newLegislatorId,
            }
            this.replaceFederalLegislators(payload)
            this.onCallToastr('success', "Replace Selected Federal Legislators in progress...")
            this.$modals.get('notifications').openAndLoad()
            this.isImporting = false
            this.refresh()
          }
        })
      },
      createLegislatorAction(item) {
        this.isImporting = true

        swal({
          title: 'Are you sure?',
          html: `
          This action will create selected federal legislators in our database. However, legislators without districts will not be created.
        `,
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, proceed',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.createFederalLegislators(item)
            this.onCallToastr('success', "Create Selected Federal Legislators in progress...")
            this.$modals.get('notifications').openAndLoad()
            this.isImporting = false
            this.refresh()
          }
        })
      },

      importFederalLegislators() {
        this.isImporting = true
        let importedFedIds = this.selected.map(item => item.imported_federal_legislator ? item.imported_federal_legislator.id : null);
        let activeLegIds = this.selected.map(item => item.active_legislator ? item.active_legislator.id : null);

        // alert(this.filterAction);
        swal({
          title: 'Are you sure?',
          html: `
          This action will ${this.filterAction} selected federal legislators in our database.
        `,
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, proceed',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            const payload = {
              oldLegislatorIds: activeLegIds,
              newLegislatorIds: importedFedIds,
            }
            if(this.filterAction == 'update'){
              this.updateMultipleFederalLegislators(payload)
            }else if(this.filterAction == 'replace'){
              this.replaceMultipleFederalLegislators(payload)
            }else if(this.filterAction == 'import'){
              this.createMultipleFederalLegislators(payload)
            }
            // this.createMultipleFederalLegislators({importedIds: importedIds})
            this.onCallToastr('success', 'Selected Federal Legislators in progress...')
            this.$modals.get('notifications').openAndLoad()
            this.isImporting = false
            this.refresh()
          }
        })
      },

      onChangeFilterAction() {
  this.selected = [];
  
  // If we have a search term, run the name filter which will also apply the action filter
  if (this.searchName) {
    this.filterByName();
    return;
  }
  
  switch (this.filterAction) {
    case 'update':
      this.legislatorList = this.filteredLegislatorList.filter(
        (item) =>
          item.active_legislator &&
          item.imported_federal_legislator &&
          item.active_legislator.ls_member_id === item.imported_federal_legislator.ls_member_id
      );
      break;
    case 'replace':
      this.legislatorList = this.filteredLegislatorList.filter(
        (item) =>
          item.active_legislator &&
          item.imported_federal_legislator &&
          item.active_legislator.ls_member_id !== item.imported_federal_legislator.ls_member_id
      );
      break;
    case 'import':
      this.legislatorList = this.filteredLegislatorList.filter(
        (item) => !item.active_legislator && item.imported_federal_legislator
      );
      break;
    default:
      this.legislatorList = this.filteredLegislatorList;
      break;
  }
},
      compareNumbers(num1, num2){
        const normalize = (num) => num.replace(/\D/g, "");
        return normalize(num1) === normalize(num2);
      }
    }
  }
</script>
