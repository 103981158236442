<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container fluid grid-list-lg style="max-width: 1600px">
    <v-layout wrap>
      <v-flex class="d-flex align-center">
        <h1 class="heading">KP Leaderboard</h1>
        <kp-leaderboard-page-help-modal></kp-leaderboard-page-help-modal>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <div v-if="client && client.id">
      <v-layout wrap class="py-3">
        <v-flex md10 class="d-flex align-center flex-wrap">
          <v-text-field
              prepend-inner-icon="search"
              label="Search KP1s"
              clearable
              class="search-input search-input_members"
              v-model="filter.search"
              hide-details
          ></v-text-field>
          <div class="district_lookup_filters_list pt-3" v-if="checkSelectedFilters && checkSelectedFilters.length">
            <div v-for="(filter, index) in checkSelectedFilters" :key="index" class="district_lookup_filters_list_item">
              <v-chip
                  v-if="filter.type == 'checkbox'"
                  v-for="(data, i) in filter.value"
                  :key="i"
                  class="ma-2"
                  small
                  close
                  color="primary"
                  outlined
                  @click:close="removeFilterItem(filter, data)"
              >
                {{ data }}
              </v-chip>
              <v-chip
                  v-if="filter.type == 'static'"
                  class="ma-2"
                  small
                  close
                  color="primary"
                  outlined
                  @click:close="removeFilterItem(filter, filter.name)"
              >
                {{ getFieldText(filter.name) }}
              </v-chip>
              <v-chip
                  v-if="filter.type == 'text'"
                  class="ma-2"
                  small
                  close
                  color="primary"
                  outlined
                  @click:close="removeFilterItem(filter)"
              >
              <span v-if="filter.type == 'text'">
                {{ filter.value }}
              </span>
                <span v-else>{{ filter.name }}</span>
              </v-chip>
              <v-chip
                  v-if="filter.type == 'dropdown'"
                  v-for="(data, j) in filter.value"
                  :key="j"
                  class="ma-2"
                  small
                  close
                  color="primary"
                  outlined
                  @click:close="removeFilterItem(filter, data)"
              >
                {{ data }}
              </v-chip>
            </div>
          </div>
        </v-flex>
        <v-flex md2 class="d-flex align-end justify-end">
          <v-spacer></v-spacer>
          <div class="legislators_filter__additional--filter" :class="{ 'w-100': isMobile }">
            <v-menu
                v-model="menu"
                :close-on-content-click="false"
                left
                offset-y
                min-width="300"
                max-width="300"
            >
              <template v-slot:activator="{ on }">
                <v-btn small rounded :outlined="isDesktop || isDevice" color="primary" v-on="on">
                  <span>Filters</span>
                  <v-icon>filter_list</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text class="members_filter-overflow">
                  <div class="legislators_filter__additional--item_wrap">
                    <h4>Filters:</h4>
                    <v-checkbox
                        color="primary"
                        label="Show Inactive"
                        class="mt-0"
                        v-model="filter.showInactive"
                    ></v-checkbox>
                    <v-checkbox
                        color="primary"
                        label="No valid email or cell"
                        class="mt-0"
                        v-model="filter.showInvalid"
                    ></v-checkbox>
                    <v-checkbox
                        color="primary"
                        label="Districts not set"
                        class="mt-0"
                        v-model="filter.districtNotSet"
                    ></v-checkbox>
                    <v-checkbox
                        color="primary"
                        label="Show past KP1s"
                        class="mt-0"
                        v-model="filter.showPastKP1s"
                    ></v-checkbox>
                    <div class="mt-0">
                      <h4>KP preference</h4>
                    </div>
                    <div v-for="(item, index) in kpPreference" :key="index">
                      <v-checkbox
                          color="primary"
                          hide-details
                          class="ma-0"
                          :label="item.title"
                          :value="kpPreferenceResults.indexOf(item.value) >= 0"
                          @change="editKpPreferenceResults($event, item.value)"
                      ></v-checkbox>
                    </div>
                  </div>
                  <br/>
                  <div
                      v-if="filter.customFields && filter.customFields.length"
                      class="legislators_filter__additional--item_wrap"
                      v-for="(item, index) in filter.customFields"
                      :key="index"
                  >
                    <div v-if="item.type == 'text'" class="mb-4">
                      <h4>{{ item.name }}</h4>
                      <v-text-field v-model="item.value" label="Search" hide-details></v-text-field>
                    </div>
                    <div v-if="item.type == 'checkbox' && item.metadata && item.metadata.length">
                      <h4>{{ item.name }}</h4>
                      <v-radio-group
                          color="primary"
                          v-for="(data, i) in item.metadata"
                          :key="i"
                          v-model="data.type"
                          :label="data.name"
                      >
                        <v-radio
                            color="primary"
                            v-for="(label, key) in checkboxLabels"
                            :key="key"
                            :label="label.name"
                            :value="label.value"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                    <div v-if="item.type == 'toggle'">
                      <h4>{{ item.name }}</h4>
                      <v-checkbox
                          color="primary"
                          :label="item.name"
                          class="mt-0"
                          v-model="item.value"
                      ></v-checkbox>
                    </div>
                    <div v-if="item.type == 'dropdown' && item.metadata && item.metadata.length">
                      <h4 class="my-2">{{ item.name }}</h4>
                      <v-checkbox
                          color="primary"
                          v-for="(data, j) in item.metadata"
                          :key="j"
                          :label="data.name"
                          class="mt-0"
                          v-model="data.checked"
                      >
                      </v-checkbox>
                    </div>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="error" text @click="clearFilter">Clear</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text color="secondary" @click="menu = false">Cancel</v-btn>
                  <v-btn color="primary" text @click="doFilter">Apply</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>
        </v-flex>
      </v-layout>

      <v-data-table
          :loading="isLoading"
          :options.sync="list.pagination"
          :server-items-length="list.pagination.total_items"
          :headers="kpLeaderboardTableHeader"
          :items="list.items"
          :footer-props="tableFooter"
          @update:options="refresh"
      >
        <template v-slot:item.lastname="{ item }">
          <div class="d-flex align-center">
            <a class="mr-1 no-line-break" @click="$refs.memberDetailModal.toggle(item)">
              {{ item.nickname }} {{ item.lastname }}
            </a>
            <v-tooltip bottom v-if="item.kp_preference !== 'unknown'">
              <template v-slot:activator="{ on }">
                <v-avatar v-on="on" :color="kpPreferenceColor(item)" size="10"></v-avatar>
              </template>
              <span>KP Preference: {{ kpPreferenceTitle(item) }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.political_leaning_id !== null">
              <template v-slot:activator="{ on }">
                <v-avatar class="mx-1" v-on="on" :color="politicalLearningsColor(item)" size="10"></v-avatar>
              </template>
              <span>Political Leaning: {{ politicalLearningsTitle(item) }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!!item.cell">
              <template v-slot:activator="{ on }">
                <a :href="'tel:' + item.cell" v-on="on">
                  <v-icon v-if="item.bad_cell" color="error" size="18" class="mx-1">no_cell</v-icon>
                  <v-icon v-else color="primary" size="18" class="mx-1">phone_iphone</v-icon>
                </a>
              </template>
              <span>{{ item.cell }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!!item.email">
              <template v-slot:activator="{ on }">
                <a :href="'mailto:' + item.email" v-on="on">
                  <v-icon :color="isBad(item.email) ? 'error' : 'primary'" size="18" class="mx-1">email</v-icon>
                </a>
              </template>
              <span>{{ item.email }}</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:item.current_legislator_count="{ item }">
        <span>
          {{ item.current_legislator_count ? item.current_legislator_count : '—' }}
        </span>
        </template>

        <template v-slot:item.lifetime_legislator_count="{ item }">
        <span>
          {{ item.lifetime_legislator_count ? item.lifetime_legislator_count : '—' }}
        </span>
        </template>

        <template v-slot:item.inactive_days="{ item }">
          <v-chip label small dark :color="getDaysInactiveColor(item.inactive_days)">
            {{ item.inactive_days ? item.inactive_days : 'Never' }}
          </v-chip>
        </template>

        <template v-slot:item.total_donation="{ item }">
          <v-chip :color="item.total_donation > 0 ? 'success' : 'grey'" text-color="white" class="pa-1">
            <v-avatar left size="28" :color="item.total_donation > 0 ? 'success' : 'grey'" class="darken-4">$</v-avatar>
            {{ item.total_donation ? item.total_donation : '0' }}
          </v-chip>
        </template>

        <template v-slot:no-data>
          <p class="text-xs-center mb-0">No KP1 registered yet.</p>
        </template>
      </v-data-table>

      <member-detail-modal ref="memberDetailModal" :selected-client="client"
                           @openDistrictModal="openDistrictModal"></member-detail-modal>

      <district-modal v-if="districtModalEnabled"></district-modal>
    </div>
  </v-container>
</template>

<script>
import ClientService from '@/services/ClientService'
import DistrictService from '@/services/DistrictService'

import leaderboardHelpModal from '@/components/help-modals/leaderboard-help-modal'
import MemberDetailModal from "@/components/members/member-detail-modal";
import districtModal from "@/components/common/district-modal";

import CustomFieldsFilter from '@/mixins/custom-fields-filter'
import {mapGetters} from "vuex";
import politicalLeaningsBorder from "@/mixins/politicalLeaningsBorder";
import MemberService from "@/services/MemberService";

export default {
  name: 'KPLeaderboardPage',
  mixins: [ClientService, CustomFieldsFilter, DistrictService, MemberService, politicalLeaningsBorder],
  components: {
    'kp-leaderboard-page-help-modal': leaderboardHelpModal,
    'member-detail-modal': MemberDetailModal,
    'district-modal': districtModal,
  },
  data () {
    return {
      isLoading: false,
      kpPreferenceResults: [],
      politicalLearnings: [],
      list: {
        pagination: {
          page: 1,
          last_page: '',
          itemsPerPage: 10,
          total_items: null,
          sortBy: [],
          sortDesc: [],
        },
        items: []
      },
      filter: {
        search: null,
        showInactive: null,
        showInvalid: null,
        districtNotSet: null,
        showPastKP1s: null,
        customFields: []
      },
      searchTimer: null,
      menu: false,
      checkboxLabels: [
        { value: 'default', name: 'All' },
        { value: 1, name: 'Yes' },
        { value: 0, name: 'No' },
      ],
      checkSelectedFilters: [],
      tableFooter: {
        'items-per-page-options': [10, 20, 40]
      },
    }
  },

  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    kpLeaderboardTableHeader () {
      return [
        { text: 'Key Person', value: 'lastname' },
        { text: 'Current Legislators', value: 'current_legislator_count', align: 'center' },
        { text: 'Lifetime Legislators', value: 'lifetime_legislator_count', align: 'center' },
        { text: 'Days Inactive', value: 'inactive_days' },
        { text: '$ Given', value: 'total_donation', align: 'right' },
      ]
    },
  },
  async mounted () {
    if (!(this.client && this.client.id)) return
    this.politicalLearnings = (await this.getPoliticalLeanings()).data
    await this.refresh()
  },
  methods: {
    async refresh () {
      this.isLoading = true

      let params = {
        page: this.list.pagination.page,
        limit: this.list.pagination.itemsPerPage,
        per_page: this.list.pagination.itemsPerPage,
        search: this.filter.search,
        order: this.list.pagination.sortBy[0],
        direction: this.list.pagination.sortDesc[0] ? 'desc' : 'asc',
        showInactive: this.filter.showInactive ? this.filter.showInactive : null,
        showInvalid: this.filter.showInvalid ? this.filter.showInvalid : null,
        districtNotSet: this.filter.districtNotSet ? this.filter.districtNotSet : null,
        showPastKP1s: this.filter.showPastKP1s ? 1 : 0,
        custom_fields: this.collectCustomFieldsFilter(),
        kp_preference: this.kpPreferenceResults
      }

      try {
        this.saveData(await this.getClientKPLeaderboardData(this.client.id, params))
        this.checkSelectedFilters = this.getCheckSelectedFilters()
        this.isLoading = false
      } catch (error) {
        console.error(error)
        this.isLoading = false
      }
    },
    saveData(data) {
      this.list.items = data.data.data.map(member => {
        member.activeKP1 = member.member_legislator.some(item => item.kp_position == 1)
        return member
      })
      this.list.pagination.total_items = data.data.total ? data.data.total : data.data.length
      this.list.pagination.from = data.data.current_page
      this.list.pagination.itemsPerPage = parseInt(data.data.per_page)
      this.list.pagination.last_page = data.data.last_page
    },
    getDaysInactiveColor (daysInactive) {
      if (daysInactive === 0 || daysInactive === null) return 'primary'
      if (daysInactive <= 30) return 'success'
      if (daysInactive <= 60) return 'warning'
      return 'error';
    },
    doFilter() {
      this.list.pagination.page = 1
      this.menu = false
      this.refresh()
    },
    editKpPreferenceResults(event, value) {
      if (event) {
        this.kpPreferenceResults.push(value)
      } else {
        const index = this.kpPreferenceResults.indexOf(value)
        if (index >= 0) {
          this.kpPreferenceResults.splice(index,1)
        }
      }
    },
    clearFilter() {
      this.filter.showInactive = null
      this.filter.showInvalid = null
      this.filter.districtNotSet = null
      this.filter.showPastKP1s = null
      this.kpPreferenceResults = []
      this.loadCustomFields(this.client.id).then(() => {
        this.doFilter()
      })
    },
    getCheckSelectedFilters () {
      let selectedFilters = []

      this.filter.customFields.forEach( item => {
        if (item.type == 'text' || item.type == 'toggle')
          if (item.value)
            selectedFilters.push({ id: item.id, name: item.name, type: item.type, value: item.value })
        if (item.type == 'checkbox' || item.type == 'dropdown') {
          let value = item.metadata.filter( meta => meta.checked ).map( meta => meta.name)
          if (value && value.length)
            selectedFilters.push({ id: item.id, type: item.type, name: item.name, value: value })
        }
      })

      if (this.filter.showInactive) selectedFilters.push({ type: "static", name: "showInactive" })
      if (this.filter.showInvalid) selectedFilters.push({ type: "static", name: "showInvalid" })
      if (this.filter.districtNotSet) selectedFilters.push({ type: "static", name: "districtNotSet" })
      if (this.filter.showPastKP1s) selectedFilters.push({ type: "static", name: "showPastKP1s" })

      return selectedFilters
    },
    removeFilterItem (item, key = null) {
      if (item.type == 'static') {
        this.filter[key] = false
      }

      this.filter.customFields.forEach( field => {
        if (field.id == item.id) {
          if (item.type == 'checkbox' || item.type == 'dropdown') {
            field.metadata.forEach( data => {
              if (data.name == key) data.checked = false
            })
          } else if (item.type == 'text') {
            field.value = ''
          } else {
            field.value = false
          }
        }
      })

      this.doFilter()
    },
    getFieldText(name) {
      if(name == 'showInactive') return 'Show Inactive'
      if(name == 'showInvalid') return 'No valid email or cell'
      if(name == 'districtNotSet') return 'Districts not set'
      if(name == 'showPastKP1s') return 'Show past KP1s'
    }
  },
  watch: {
    'client': function () {
      this.loadCustomFields(this.client.id)
      this.refresh()
    },
    'filter.search'() {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(() => {
        this.list.pagination.page = 1
        this.refresh()
      }, 700)
    },
  },
}
</script>
<!--TODO-->
<style lang="scss" scoped>
.client-selector {
  max-width: 200px;
}
.session-selector {
  margin-left: 16px;
  max-width: 200px;
}
</style>
