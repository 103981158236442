<template>
  <v-dialog persistent max-width="700" :fullscreen="isMobile" v-model="dialog">
    <v-form class="fill-height message-form-container" ref="messageForm">
      <v-card class="fill-height" tile>
        <v-card-title>
          <span class="title">Send a message to {{ client.assoc_abbrev }} Team</span>
          <v-spacer></v-spacer>
          <v-icon
            class="ml-3"
            @click.native="closeModal()"
          >
            close
          </v-icon>
          <v-card-subtitle class="pa-0">This will send a message to the {{ client.assoc_abbrev }}'s KP Dashboard
            Admin team.
          </v-card-subtitle>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert dense type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>
          <v-flex xs12 class="px-2" id="messageBlock">
            <v-layout align-end class="mb-2">
              <v-flex xs12 class="d-flex body_variables">
                <div class="caption grey--text" :class="messageValidate ? 'error--text' : ''">Message*</div>
                <v-spacer/>
              </v-flex>
            </v-layout>
              <div class="tiny_editor">
                <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
                <editor
                    v-if="!isBusy"
                    :api-key="TINY_MCE_API_KEY"
                    :plugins="tinyPlugins"
                    :toolbar="tinyToolbars"
                    :init="tinyInit"
                    v-model="message"
                    :disabled="disabledEditor"
                    @keyup="inputEmail"
                />
              </div>
              <span v-if="messageValidate" class="caption error--text">{{ messageValidate }}</span>
          </v-flex>
          <v-alert v-if="maxImgsSize" class="mt-3" dense type="error" outlined :value="true">
            The size of the image is more than 25MB
          </v-alert>
          <v-alert
              v-if="isSummaryUploadLimit && !maxImgsSize"
              class="mt-3"
              dense
              type="error"
              outlined
              :value="true">
            The size of images and attached files is more than 25MB
          </v-alert>
        </v-card-text>
        <v-divider class="mt-3"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="secondary"
            @click.native="closeModal()"
          >
            Cancel
          </v-btn>
          <v-btn
              color="primary"
              :loading="isBusy"
              @click.native="onSend"
              :disabled="isSummaryUploadLimit || maxImgsSize"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import TinyConfig from "@/mixins/tiny-mce-config"

import MemberService from "@/services/MemberService";
import AppService from "@/services/AppService";
import he from "he";
import ShorterUrl from "@/mixins/shorterUrl";
import {mapGetters} from "vuex";

export default {
  name: 'SendMessageModal',
  mixins: [MemberService, AppService, TinyConfig, ShorterUrl],
  components: {
    'editor': Editor, // Do not change, this is a requirement of tinyMCE
  },
  data() {
    return {
      dialog: false,
      error: '',
      subject: 'Message from {kp_first} {kp_last}',
      message: '',
      isBusy: false,
      messageValidate: ''
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
        return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    messageLength() {
      return this.html2Text(this.message).length
    },
    isSummaryUploadLimit() {
      return (this.currentImgSize + this.updatedFilesSize) > this.imgSizeLimit
    }
  },
  methods: {
    openModal() {
      this.isBusy = true
      this.dialog = true
      setTimeout(() => {
        this.isBusy = false
      }, 500)
    },
    closeModal() {
      this.dialog = false
    },
    async inputEmail() {
      this.$nextTick(async () => {
        this.messageValidate = ''
        const newContent = await this.parsUrl(this.message)

        if (newContent !== this.message) {
          this.message = newContent
          this.disabledEditor = false
          document.activeElement.blur()
        }
      })
    },
    html2Text(html) {
      let find = /(<p><br[\/]?><[\/]?p>)/,
          re = new RegExp(find, 'g');

      let txt = html.replace(re, '\n');
      txt = txt.replace(/<[^>]*>?/gm, '')
      return he.decode(txt)
    },
    async onSend() {
      if (!this.$refs.messageForm.validate()) return
      if (this.message.length === 0) {
        this.messageValidate = 'Message is required.';
        return
      }
      this.isBusy = true

      let message = {
        member_id: this.userMemberId,
        message: this.subject
      }

      let payload = {
        subject: (await this.getAppFormattedMessage(message)).data,
        message: this.message,
      }
      payload.id = this.userMemberId

      this.sendMessageToClient(payload).then(() => {
        this.closeModal()
        this.message = ''
        this.error = ''
        this.isBusy = false
        this.onCallToastr('success', 'Message sent', 'Success!')
      }, (error) => {
        this.isBusy = false
        this.error = error.response.data.error
      })
    }
  }
}
</script>

<style lang="scss">
.message-form-container {
  .tox.tox-tinymce {
    height: 180px !important;
    @media (max-width: 767px){
      height: 230px !important;
    }
  }
}
</style>
