<template>
  <v-container fluid grid-list-lg style="max-width: 1600px">
    <v-progress-linear
      style="z-index: 6"
      color="warning"
      indeterminate
      rounded
      height="3"
      fixed
      top
      v-if="isLoading"
    ></v-progress-linear>
      <v-layout wrap>
        <v-flex class="d-flex align-center">
          <h1>Regions</h1>
          <v-spacer></v-spacer>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex class="d-flex align-center justify-end flex-wrap">
          <v-btn color="warning" small @click="openRegionModal(0)" :disabled="!(client && client.id)">
            New region
            <v-icon dark class="ml-2">playlist_add</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    <div v-if="client && client.id">

      <v-data-table
          class="elevation-1 relationships_table"
          :options.sync="pagination"
          hide-default-footer
          :headers="tableHeader"
          :items="readyList"
      >
        <template v-slot:item.handle="{ item }">
          <v-icon class="handle">drag_indicator</v-icon>
        </template>

          <template style="max-width: 70px" v-slot:item.action="{ item }">
            <div class="d-flex">
              <v-btn icon class="mx-0" @click="openRegionModal(item.id)">
                <v-icon size="18" color="primary">edit</v-icon>
              </v-btn>
              <v-btn icon class="mx-0" @click="onDeleteRegion(item.id)">
                <v-icon size="18" color="error">delete_forever</v-icon>
              </v-btn>
            </div>
          </template>

        <template v-slot:item.districts="{ item }">
          <a class="ml-2">
            <span>{{ formatDistricts(item.districts) }}</span>
          </a>
        </template>

        <template v-slot:item.name="{ item }">
          <div>
            {{ item.name }}
          </div>
        </template>
        <template v-slot:item.team="{ item }">
          <v-chip
            v-if="!(item.member && item.member.last_login_at)"
            small
            ma-2
            color="error"
            outlined
          >
            No Team Account
            <v-icon color="error" class="method_icon">person_outlined</v-icon>
          </v-chip>
        </template>
        <template v-slot:item.coordinator="{ item }">
            {{ item.member | getText('nickname', '') }} {{ item.member | getText('lastname', '') }}
        </template>
        <template v-slot:no-data>
          <p class="text-xs-center mb-0">
            No regions created yet.
          </p>
        </template>
      </v-data-table>

    <template v-if="!client.is_use_state">
      <v-layout class="mt-3 ml-0 flex-wrap" v-if="orphanedDistricts && orphanedDistricts.length">
        <div class="mt-1 full-width mb-2">
          Orphaned districts:
        </div>
        <v-expansion-panels multiple>
          <v-expansion-panel
            v-for="(stateInfo,i) in orphanedDistricts"
            :key="i"
          >
            <v-expansion-panel-header>
              {{stateInfo.state}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-chip
                  v-for="district in stateInfo.districts"
                  :key="district.id"
                  class="ma-1"
                  small
              >
                {{ district.district }}
              </v-chip>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-layout>
    </template>
    <template v-else="">
      <v-layout class="mt-3 ml-0 flex-wrap" v-if="orphanedDistricts && orphanedDistricts.length">
        <div class="mt-1 full-width">
          Orphaned districts:
        </div>
        <v-flex xs12 :sm6="existsOrphanedDistricts('S')" v-if="existsOrphanedDistricts('H')">
          <v-chip
              v-for="district in orphanedDistricts"
              :key="district.id"
              v-if="district.district[0] === 'H'"
              class="ma-1"
              small
          >
            {{ district.district }}
          </v-chip>
        </v-flex>
        <v-flex xs12 :sm6="existsOrphanedDistricts('H')" v-if="existsOrphanedDistricts('S')">
          <v-chip
              v-for="district in orphanedDistricts"
              :key="district.id"
              v-if="district.district[0] === 'S'"
              class="ma-1"
              small
          >
            {{ district.district }}
          </v-chip>
        </v-flex>
      </v-layout>
    </template>

    <region-form-modal 
      ref="form" 
      @update="load" 
      :members="members" 
      :selectedClientId="client.id" 
      :isFederalClient="!client.is_use_state"
      :existRegionsWithDistricts="list"
      :fedStateDistrict="federalStateList"
    ></region-form-modal>

    <region-form-modal 
      ref="editForm"
      @update="load" 
      :members="editMembers" 
      :selectedClientId="client.id" 
      :isFederalClient="!client.is_use_state"
      :existRegionsWithDistricts="list"
      :fedStateDistrict="federalStateList"
    ></region-form-modal>

    </div>
  </v-container>
</template>

<script>
  import Sortable from 'sortablejs'
  import swal from 'sweetalert2'
  import ClientService from '@/services/ClientService'

  import modal from '@/components/regions/regions-form-modal'
  import {mapGetters} from "vuex";

  export default {
    name: "RegionsPage",
    mixins: [ClientService],
    components: {
      'region-form-modal': modal
    },
    data() {
      return {
        isLoading: false,
        list: [],
        orphanedDistricts: [],
        pagination: {
          itemsPerPage: -1
        },
        tableHeader: [
          { text: '', value: 'handle', align: 'center', sortable: false },
          { text: '', value: 'action', align: 'center', sortable: false },
          { text: 'Name', value: 'name', align: 'left', sortable: false },
          { text: 'Districts', value: 'districts', align: 'center', sortable: false, 'width': '50%' },
          { text: '', value: 'team', align: 'center', sortable: false },
          { text: 'Coordinator', value: 'coordinator', align: 'center', sortable: false },
        ],
        members: [],
        editMembers: [],
        federalStateList: []
      }
    },
    computed: {
      readyList() {
        return this.list.map(item => {return {...item, action: ''}})
      },
      ...mapGetters('app', ['superAdminSelectedClient']),
      client() {
        return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
      },
      isFederalClient(){
        return this.client && this.client.is_use_state === 0;
      }
    },
    methods: {
      load() {
        this.isLoading = true
        
        this.getDistrictsWithoutRegion(this.client && this.client.id).then(response => {
          this.orphanedDistricts = response.data
        })

        this.getClientRegions(this.client && this.client.id).then(response => {
          this.list = response.data
          this.isLoading = false
          this.dragInit()
        }, () => {
          this.isLoading = false
        })
      },
      existsOrphanedDistricts(type) {
        return this.orphanedDistricts.some(district => district.district[0] === type)
      },
      formatDistricts(districts) {
        return districts.map( item => {
          let districtName = item.district
          if(this.isFederalClient && item.state && item.state.state_abbrev){
            const stateAbbrev = item.state.state_abbrev.toUpperCase()
            if (!districtName.startsWith(stateAbbrev)) {
              districtName += ` (${stateAbbrev})`
            }
          }
          return districtName
        }).join(', ')
      },
      openRegionModal(id) {
        if(id == 0){
          this.$refs.form.open(id)
        }else{
          this.$refs.editForm.open(id)
        }
      },
      deleteRegion(id) {
        return this.deleteClientRegions(this.client && this.client.id, id)
      },
      onDeleteRegion (id) {
        swal({
          title: 'Delete this Region?',
          text: 'Note: if there is an active Coordinator for this Region, after deletion you must either assign them a new Region or delete their team profile. Coordinators without a Region cannot log in.',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Delete',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.deleteRegion(id)
              .then(() => {
                this.onCallToastr('success', 'Region has been deleted.', 'Success!')
                this.load()
              })
              .catch(() => {
                this.onCallToastr('error', 'Something went wrong.', 'Error!')
              })
          }
        })
      },
      dragInit() {
        let table = document.querySelector('table tbody')
        let self = this
        Sortable.create(table, {
          handle: ".handle",
          onEnd({ newIndex, oldIndex}) {
            const rowSelected = self.list.splice(oldIndex, 1)[0]
            self.list.splice(newIndex, 0, rowSelected)

            let startFromIndex = oldIndex < newIndex ? oldIndex : newIndex
            let result = []

            for (let i = 0; i < self.list.length; i++) {
              if(i >= startFromIndex) {
                if(i == newIndex) {
                  result.push({
                    id: self.list[i].id,
                    sort_order: newIndex
                  })
                } else {
                  result.push({
                    id: self.list[i].id,
                    sort_order: i
                  })
                }
              }
            }

            self.saveSort(result)
          }
        })
      },
      saveSort(result) {
        this.sortClientRegions(this.client && this.client.id, result).then(()=> {
          this.load()
        })
      },
      init() {
        if (!(this.client && this.client.id)) return
        this.load()
        // this.getMembers()
      },
      getMembers() {
        this.members = []
        this.editMembers = []
        this.getClientMembers(this.client && this.client.id).then(response => {
          this.members = response.data.filter(item => item.active == 1 && !item.has_region).map( item => {
            return {...item, nickNameAndLastName: `${item.nickname} ${item.lastname}`}
          })
          this.editMembers = response.data.filter(item => item.active == 1).map( item => {
            return {...item, nickNameAndLastName: `${item.nickname} ${item.lastname}`}
          })
        })
      },
      getFederalClientStates() {
        this.getFederalClientStatesDistricts(this.client && this.client.id).then((result) => {
          this.federalStateList = result.data;
        })
      },
    },
    mounted() {
      this.init()
      if(!(this.client && this.client.is_use_state)) {
        this.getFederalClientStates()
      }
    },
    watch: {
      'client': function () {
        this.init()
        if(!(this.client && this.client.is_use_state)) {
          this.getFederalClientStates()
        }
      }
    }
  }
</script>
