<template>
  <v-dialog width="900" v-model="isModalOpen">
    <v-form v-if="isModalOpen" ref="memberForm" class="flex-1" @submit.prevent="onUpdateMember()">
      <v-card :loading="isBusy">
        <v-tabs
          v-model="tabs"
          :show-arrows="isMobile"
        >
          <v-tab>
            <v-icon :size="isMobile? 18 : 24" left>fa-thin fa-square-user</v-icon>
            Account
          </v-tab>
          <v-tab>
            <v-icon :size="isMobile? 18 : 24" left>fa-thin fa-house-building</v-icon>
            Addresses
          </v-tab>
          <v-tab>
            <v-icon :size="isMobile? 18 : 24" left>fa-thin fa-id-card</v-icon>
            Custom Fields
          </v-tab>
          <!-- removed this from next tab: v-if="isClientUsesPoliticalTab" -->
          <v-tab>
            <v-icon :size="isMobile? 18 : 24" left>fa-thin fa-book-user</v-icon>
            Personal
          </v-tab>
        </v-tabs>
        <v-alert type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>

        <v-card-text>
          <v-tabs-items v-model="tabs" class="full-height-tab">

          <!-- account -->
            <v-tab-item class="pt-6">
              <v-layout wrap>
                <v-flex
                  xs3
                  sm4
                  md2
                  class="px-2 xs-mb-2"
                  :class="{ 'mb-2': isMobile }"
                >
                  <div>
                    <v-avatar
                      size="120"
                      class="bordered_avatar"
                      :style="kpPreferenceBorder(member)"
                    >
                      <img
                        v-if="member.avatar_url"
                        :alt="member.fullname"
                        :src="getThumbImageMedium(member.avatar_url)"
                      />
                      <img v-else :alt="member.fullname" src="images/member-avatar.jpg"/>
                    </v-avatar>
                  </div>
                </v-flex>
                <v-flex md4 class="upload-btn px-2" :class="isMobile? '' : 'ml-4'">
                  <v-btn large color="primary" @click="openUploadImagePopup(member)">Upload new photo</v-btn>
                  <!--<p class="mt-4">Allowed JPG, GIF or PNG. Max size of 800K</p>-->
                  <input ref="avatarUploader" type="file" accept="image/*" class="d-none" @change="onUploadAvatar()">
                </v-flex>
                <v-flex md5 class="mb-0 ml-auto px-2" :class="{ 'mt-2': isMobile }">
                  <v-textarea
                    outlined label="Advocate Notes (seen by Admins and Coordinators only)"
                    v-model="member.notes"
                    dense
                    rows="4"
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex sm6 xs12 class="px-2">
                  <v-checkbox
                    dense
                    color="primary"
                    class="check-active mt-0"
                    v-model="member.active"
                    label="Active"
                    :disabled="Boolean(assignedLegislators.length > 0 && member.active)"
                  ></v-checkbox>
                  <span v-if="assignedLegislators.length > 0">This advocate is an active KP and cannot be deactivated until
                    un-assigned from all legislators: </span>
                  <div v-for="leg in assignedLegislators" class="d-inline">
                    <a @click="openLegislatorDetailModal(leg)" style="white-space: nowrap">
                      <span>{{leg.fullname}}
                        <sup class="text--secondary">KP{{getKpPosition(leg)}}</sup></span>
                    </a>
                    <span v-if="assignedLegislators.indexOf(leg) !== assignedLegislators.length -1">, </span>
                  </div>
                </v-flex>
                <v-flex sm6 xs12 class="px-2">
                  <v-tooltip bottom max-width="300px">
                    <template v-slot:activator="{ on }">
                      <v-checkbox
                        dense
                        color="primary"
                        v-model="member.silence"
                        style="margin:0!important;"
                        hide-details
                        v-on="on"
                        @change="openEditSilenceModal"
                      >
                        <span slot="label" v-on="on">Silence all messages to this advocate</span>
                      </v-checkbox>
                    </template>
                    <span>This will stop all outgoing messages to Advocates/KPs; however, it does not affect Advocacy Messages</span>
                  </v-tooltip>
                </v-flex>
                <v-flex sm6 xs12 class="px-2">
                  <v-tooltip bottom max-width="300px">
                    <template v-slot:activator="{ on }">
                      <v-checkbox
                        dense
                        color="primary"
                        v-on="on"
                        v-model="member.eligible"
                      >
                        <span slot="label" v-on="on">KP Eligible?</span>
                      </v-checkbox>
                    </template>
                    <span>Check this if advocate has either quit the KP program or expressed that they have no interest. This will remove them from the Volunteer Request and KP Assignment lists.</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex v-if="isSuperAdmin" sm6 class="px-2">
                  <v-select
                    outlined
                    disabled
                    label="Client"
                    item-text="association"
                    item-value="id"
                    :items="clientList"
                    :rules="clientRules"
                    v-model="member.client_id"
                    @input="onSelectClient"
                    dense
                  >
                  </v-select>
                </v-flex>
                <v-flex sm6 v-if="member.regions && member.regions.length > 0" class="px-2">
                  <v-select
                    outlined
                    multiple
                    chips
                    label="Regions this member is coordinator"
                    :items="member.regions"
                    item-text="name"
                    v-model="member.regions"
                    readonly
                    dense
                  >
                  </v-select>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-text-field
                      outlined
                      label="Preferred First Name*"
                      :rules="nickNameRules"
                      v-model="member.nickname"
                      :error-messages="errorMessages('nickname')"
                      dense
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-text-field
                    outlined
                    label="Last Name*"
                    :rules="lastNameRules"
                    v-model="member.lastname"
                    :error-messages="errorMessages('lastname')"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-text-field
                    outlined
                    prepend-icon="email"
                    label="Email*"
                    :rules="emailRules"
                    v-model="member.email"
                    :error-messages="errorMessages('email')"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-text-field
                    :messages="member.bad_cell ? 'Error: Landline. Contact Advocate for correct number.' : ''"
                    outlined
                    :error="!!member.bad_cell"
                    prepend-icon="phone_iphone"
                    label="Cell Phone"
                    v-mask="'(###) ###-####'"
                    :value="member.cell"
                    :rules="member.bad_cell ? [] : phoneNumberRules"
                    @change="changeMemberCell"
                    :error-messages="errorMessages('cell')"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 class="px-2">
                  <v-text-field
                    outlined
                    prepend-icon="phone"
                    label="Office Phone"
                    v-mask="'(###) ###-####'"
                    :rules="isBad(member.office_phone) ? [] : phoneNumberRules"
                    :error-messages="errorMessages('office_phone')"
                    v-model="member.office_phone"
                    dense
                  ></v-text-field>
                </v-flex>
                </v-layout>
            </v-tab-item>

            <!-- addresses -->
            <v-tab-item class="pt-6">

              <h3>Home Address</h3>
              <div class="d-flex flex-wrap align-center mb-2">
                <span
                  v-if="isPoBoxes(member.home_address)"
                  class="error--text text-caption"
                >PO Boxes are not allowed as they cannot be properly geolocated. Please enter a physical address and choose an option from the dropdown.</span>
                <div class="d-flex align-center full-width">
                  <address-input
                    placeholder="Enter Home Address"
                    v-model="member.home_address"
                    prepend-icon="home"
                    :dense="true"
                    @change="onInputAddress($event, 0)"
                    @input="onChangeAddress(0)"
                  ></address-input>
                  <v-tooltip bottom v-if="member.home_address">
                    <template v-slot:activator="{ on }">
                      <v-btn icon @click="openDeleteAddressDialog('home')" class="mb-3">
                        <div v-on="on">
                          <v-icon size="18" color="error">delete</v-icon>
                        </div>
                      </v-btn>
                    </template>
                    <span>Remove Home address and districts</span>
                  </v-tooltip>
                </div>
              </div>

              <v-layout wrap>
                <v-flex v-if="!isFederalClient" xs12 sm4 class="px-3">
                  <v-select
                    label="Home House District"
                    append-outer-icon="close"
                    :item-text="(district) => {
                      return district.district + (isFederalClient && district.state && district.state.state_abbrev ? ` (${district.state.state_abbrev.toUpperCase()})` : '')
                    }"
                    :item-value="isFederalClient ? 'id' : 'district'"
                    :items="houseDistrictList"
                    v-model="member.home_house_district"
                    outlined
                    dense
                    @click:append-outer="onClear('home_house_district')"
                  >
                  </v-select>
                </v-flex>
                <v-flex v-if="!isFederalClient" xs12 sm4 class="px-3">
                  <v-select
                    label="Home Senate District"
                    append-outer-icon="close"
                    :item-text="(district) => {
                      return district.district + (isFederalClient && district.state && district.state.state_abbrev ? ` (${district.state.state_abbrev.toUpperCase()})` : '')
                    }"
                    :item-value="isFederalClient ? 'id' : 'district'"
                    :items="senDistrictList"
                    v-model="member.home_sen_district"
                    outlined
                    dense
                    @click:append-outer="onClear('home_sen_district')"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 :sm4="!isFederalClient" :class="{'px-3': !isFederalClient}">
                  <v-select
                    label="Home Congr District"
                    append-outer-icon="close"
                    :item-text="(district) => {
                      return district.district + (isFederalClient && district.state && district.state.state_abbrev ? ` (${district.state.state_abbrev.toUpperCase()})` : '')
                    }"
                    :item-value="isFederalClient ? 'id' : 'district'"
                    :items="conDistrictList"
                    v-model="member.home_con_district"
                    outlined
                    dense
                    @click:append-outer="onClear('home_con_district')"
                  >
                  </v-select>
                </v-flex>
              </v-layout>

              <h3>1st Work Address</h3>
              <div class="d-flex flex-wrap align-center mb-2">
                 <span
                   v-if="isPoBoxes(member.work_address)"
                   class="error--text text-caption"
                 >PO Boxes are not allowed as they cannot be properly geolocated. Please enter a physical address and choose an option from the dropdown.
                 </span>
                <div class="d-flex align-center full-width">
                  <address-input
                    placeholder="Enter 1st Work Address"
                    v-model="member.work_address"
                    :dense="true"
                    prepend-icon="business"
                    @change="onInputAddress($event,1)"
                    @input="onChangeAddress(1)"
                  ></address-input>
                  <v-tooltip bottom v-if="member.work_address">
                    <template v-slot:activator="{ on }">
                      <v-btn icon @click="openDeleteAddressDialog('work')" class="mb-3">
                        <div v-on="on">
                          <v-icon size="18" color="error">delete</v-icon>
                        </div>
                      </v-btn>
                    </template>
                    <span>Remove Work address and districts</span>
                  </v-tooltip>
                </div>
              </div>
              <v-layout wrap>
                <v-flex v-if="!isFederalClient" xs12 sm4 class="px-3">
                  <v-select
                    v-if="houseDistrictList.length"
                    label="Work House District"
                    append-outer-icon="close"
                    :item-text="(district) => {
                      return district.district + (isFederalClient && district.state && district.state.state_abbrev ? ` (${district.state.state_abbrev.toUpperCase()})` : '')
                    }"
                    :item-value="isFederalClient ? 'id' : 'district'"
                    :items="houseDistrictList"
                    v-model="member.work_house_district"
                    outlined
                    dense
                    @click:append-outer="onClear('work_house_district')"
                  >
                  </v-select>
                </v-flex>
                <v-flex v-if="!isFederalClient" xs12 sm4 class="px-3">
                  <v-select
                    label="Work Senate District"
                    append-outer-icon="close"
                    :item-text="(district) => {
                      return district.district + (isFederalClient && district.state && district.state.state_abbrev ? ` (${district.state.state_abbrev.toUpperCase()})` : '')
                    }"
                    :item-value="isFederalClient ? 'id' : 'district'"
                    :items="senDistrictList"
                    v-model="member.work_sen_district"
                    outlined
                    dense
                    @click:append-outer="onClear('work_sen_district')"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 :sm4="!isFederalClient" :class="{'px-3': !isFederalClient}">
                  <v-select
                    label="Work Congr District"
                    append-outer-icon="close"
                    :item-text="(district) => {
                      return district.district + (isFederalClient && district.state && district.state.state_abbrev ? ` (${district.state.state_abbrev.toUpperCase()})` : '')
                    }"
                    :item-value="isFederalClient ? 'id' : 'district'"
                    :items="conDistrictList"
                    v-model="member.work_con_district"
                    outlined
                    dense
                    @click:append-outer="onClear('work_con_district')"
                  >
                  </v-select>
                </v-flex>
              </v-layout>

              <template v-if="client && client.uses_2nd_work">
                <h3>2nd Work Address</h3>
                <div class="d-flex flex-wrap align-center mb-2">
                   <span
                     v-if="isPoBoxes(member.work2_address)"
                     class="error--text text-caption"
                   >
                  PO Boxes are not allowed as they cannot be properly geolocated. Please enter a physical address and choose an option from the dropdown.
                 </span>
                  <div class="d-flex align-center full-width">
                    <address-input
                      placeholder="Enter 2nd Work Address"
                      v-model="member.work2_address"
                      :dense="true"
                      prepend-icon="business"
                      @change="onInputAddress($event,2)"
                      @input="onChangeAddress(2)"
                    ></address-input>

                    <v-tooltip bottom v-if="member.work2_address">
                      <template v-slot:activator="{ on }">
                        <v-btn icon @click="openDeleteAddressDialog('work2')" class="mb-3">
                          <div v-on="on">
                            <v-icon size="18" color="error">delete</v-icon>
                          </div>
                        </v-btn>
                      </template>
                      <span>Remove Work2 address and districts</span>
                    </v-tooltip>
                  </div>
                </div>
                <v-layout wrap>
                  <v-flex v-if="!isFederalClient" xs12 sm4 class="px-3">
                    <v-select
                      v-if="houseDistrictList.length && client && client.uses_2nd_work"
                      label="Work2 House District"
                      append-outer-icon="close"
                      :item-text="(district) => {
                        return district.district + (isFederalClient && district.state && district.state.state_abbrev ? ` (${district.state.state_abbrev.toUpperCase()})` : '')
                      }"
                      :item-value="isFederalClient ? 'id' : 'district'"
                      :items="houseDistrictList"
                      v-model="member.work2_house_district"
                      dense
                      outlined
                      @click:append-outer="onClear('work2_house_district')"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex v-if="!isFederalClient" xs12 sm4 class="px-3">
                    <v-select
                      label="Work2 Senate District"
                      append-outer-icon="close"
                      :item-text="(district) => {
                        return district.district + (isFederalClient && district.state && district.state.state_abbrev ? ` (${district.state.state_abbrev.toUpperCase()})` : '')
                      }"
                      :item-value="isFederalClient ? 'id' : 'district'"
                      :items="senDistrictList"
                      v-model="member.work2_sen_district"
                      dense
                      outlined
                      @click:append-outer="onClear('work2_sen_district')"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 :sm4="!isFederalClient" :class="{'px-3': !isFederalClient}">
                    <v-select
                      label="Work2 Congr District"
                      append-outer-icon="close"
                      :item-text="(district) => {
                        return district.district + (isFederalClient && district.state && district.state.state_abbrev ? ` (${district.state.state_abbrev.toUpperCase()})` : '')
                      }"
                      :item-value="isFederalClient ? 'id' : 'district'"
                      :items="conDistrictList"
                      v-model="member.work2_con_district"
                      dense
                      outlined
                      @click:append-outer="onClear('work2_con_district')"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
              </template>

              <template v-if="client && client.uses_3rd_work">
                <h3>3rd Work Address</h3>
                <div class="d-flex flex-wrap align-center mb-2">
                  <span
                    v-if="isPoBoxes(member.work3_address)"
                    class="error--text text-caption"
                  >
                  PO Boxes are not allowed as they cannot be properly geolocated. Please enter a physical address and choose an option from the dropdown.
                 </span>
                  <div class="d-flex align-center full-width">
                    <address-input
                      placeholder="Enter 3rd Work Address"
                      v-model="member.work3_address"
                      :dense="true"
                      prepend-icon="business"
                      @change="onInputAddress($event,3)"
                      @input="onChangeAddress(3)"
                    ></address-input>
                    <v-tooltip bottom v-if="member.work3_address">
                      <template v-slot:activator="{ on }">
                        <v-btn icon @click="openDeleteAddressDialog('work3')" class="mb-3">
                          <div v-on="on">
                            <v-icon size="18" color="error">delete</v-icon>
                          </div>
                        </v-btn>
                      </template>
                      <span>Remove Work3 address and districts</span>
                    </v-tooltip>
                  </div>
                </div>
                <v-layout wrap>
                  <v-flex v-if="!isFederalClient" xs12 sm4 class="px-3">
                    <v-select
                      v-if="houseDistrictList.length"
                      label="Work3 House District"
                      append-outer-icon="close"
                      :item-text="(district) => {
                        return district.district + (isFederalClient && district.state && district.state.state_abbrev ? ` (${district.state.state_abbrev.toUpperCase()})` : '')
                      }"
                      :item-value="isFederalClient ? 'id' : 'district'"
                      :items="houseDistrictList"
                      v-model="member.work3_house_district"
                      dense
                      outlined
                      @click:append-outer="onClear('work3_house_district')"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex v-if="!isFederalClient" xs12 sm4 class="px-3">
                    <v-select
                      label="Work3 Senate District"
                      append-outer-icon="close"
                      :item-text="(district) => {
                        return district.district + (isFederalClient && district.state && district.state.state_abbrev ? ` (${district.state.state_abbrev.toUpperCase()})` : '')
                      }"
                      :item-value="isFederalClient ? 'id' : 'district'"
                      :items="senDistrictList"
                      v-model="member.work3_sen_district"
                      dense
                      outlined
                      @click:append-outer="onClear('work3_sen_district')"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 :sm4="!isFederalClient" :class="{'px-3': !isFederalClient}">
                    <v-select
                      label="Work3 Congr District"
                      append-outer-icon="close"
                      :item-text="(district) => {
                        return district.district + (isFederalClient && district.state && district.state.state_abbrev ? ` (${district.state.state_abbrev.toUpperCase()})` : '')
                      }"
                      :item-value="isFederalClient ? 'id' : 'district'"
                      :items="conDistrictList"
                      v-model="member.work3_con_district"
                      dense
                      outlined
                      @click:append-outer="onClear('work3_con_district')"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
              </template>

              <template v-if="client && client.uses_4th_work">
                <h3>4th Work Address</h3>
                <div class="d-flex flex-wrap align-center mb-2">
                   <span
                     v-if="isPoBoxes(member.work4_address)"
                     class="error--text text-caption"
                   >PO Boxes are not allowed as they cannot be properly geolocated. Please enter a physical address and choose an option from the dropdown.</span>
                  <div class="d-flex align-center full-width">
                    <address-input
                      placeholder="Enter 4th Work Address"
                      v-model="member.work4_address"
                      :dense="true"
                      prepend-icon="business"
                      @change="onInputAddress($event,4)"
                      @input="onChangeAddress(4)"
                    ></address-input>
                    <v-tooltip bottom v-if="member.work4_address">
                      <template v-slot:activator="{ on }">
                        <v-btn icon @click="openDeleteAddressDialog('work4')" class="mb-3">
                          <div v-on="on">
                            <v-icon size="18" color="error">delete</v-icon>
                          </div>
                        </v-btn>
                      </template>
                      <span>Remove Work4 address and districts</span>
                    </v-tooltip>
                  </div>
                </div>
                <v-layout wrap>
                  <v-flex v-if="!isFederalClient" xs12 sm4 class="px-3">
                    <v-select
                      v-if="houseDistrictList.length"
                      label="Work4 House District"
                      append-outer-icon="close"
                      :item-text="(district) => {
                        return district.district + (isFederalClient && district.state && district.state.state_abbrev ? ` (${district.state.state_abbrev.toUpperCase()})` : '')
                      }"
                      :item-value="isFederalClient ? 'id' : 'district'"
                      :items="houseDistrictList"
                      v-model="member.work4_house_district"
                      dense
                      outlined
                      @click:append-outer="onClear('work4_house_district')"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex v-if="!isFederalClient" xs12 sm4 class="px-3">
                    <v-select
                      label="Work4 Senate District"
                      append-outer-icon="close"
                      :item-text="(district) => {
                        return district.district + (isFederalClient && district.state && district.state.state_abbrev ? ` (${district.state.state_abbrev.toUpperCase()})` : '')
                      }"
                      :item-value="isFederalClient ? 'id' : 'district'"
                      :items="senDistrictList"
                      v-model="member.work4_sen_district"
                      dense
                      outlined
                      @click:append-outer="onClear('work4_sen_district')"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 :sm4="!isFederalClient" :class="{'px-3': !isFederalClient}">
                    <v-select
                      label="Work4 Congr District"
                      append-outer-icon="close"
                      :item-text="(district) => {
                        return district.district + (isFederalClient && district.state && district.state.state_abbrev ? ` (${district.state.state_abbrev.toUpperCase()})` : '')
                      }"
                      :item-value="isFederalClient ? 'id' : 'district'"
                      :items="conDistrictList"
                      v-model="member.work4_con_district"
                      dense
                      outlined
                      @click:append-outer="onClear('work4_con_district')"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
              </template>
            </v-tab-item>

<!-- custom fields -->
            <v-tab-item class="pt-6">
              <div class="d-flex flex-wrap">
                <v-flex
                  sm6
                  class="pa-2"
                  v-if="totalFields && totalFields.length"
                  v-for="(field, index) in totalFields"
                  :key="field.id + index"
                >
                <!-- this is the text field option -->
                  <div v-if="field.type == 'text'" class="d-flex my-2">
                    <v-tooltip bottom v-if="field.show_member && field.edit_member" max-width="275px">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye</v-icon>
                        </div>
                      </template>
                      <span>Can be seen and edited by user when sending a profile update request</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="field.show_member && !field.edit_member" max-width="275px">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user-slash</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye</v-icon>
                        </div>
                      </template>
                      <span>Can be seen but not edited by user when sending a profile update request</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="!field.show_member && field.edit_member" max-width="275px">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye-slash</v-icon>
                        </div>
                      </template>
                      <span>Only seen by admins - advocates cannot see or edit this field</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="!field.show_member && !field.edit_member" max-width="275px">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user-slash</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye-slash</v-icon>
                        </div>
                      </template>
                      <span>Only seen by admins - advocates cannot see or edit this field</span>
                    </v-tooltip>
                    <v-text-field
                      dense
                      outlined
                      :label="field.name"
                      :value="field.pivot ? field.pivot.metadata.value : ''"
                      @input="(val) => fieldHandler(val, field)"
                      hide-details
                    >
                    </v-text-field>
                    <v-tooltip bottom v-if="field.hint" max-width="300px" class="ml-1">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">help</v-icon>
                      </template>
                      <span>{{ field.hint }}</span>
                    </v-tooltip>
                  </div>
                  <!-- this is single select option -->
                  <div v-if="field.type == 'dropdown'" class="custom-selector d-flex my-2">
                    <v-tooltip bottom v-if="field.show_member && field.edit_member" max-width="275px">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye</v-icon>
                        </div>
                      </template>
                      <span>Can be seen and edited by advocates when sending a profile update request</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="field.show_member && !field.edit_member" max-width="275px">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user-slash</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye</v-icon>
                        </div>
                      </template>
                      <span>Can be seen but not edited by advocates when sending a profile update request</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="!field.show_member && field.edit_member" max-width="275px">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye-slash</v-icon>
                        </div>
                      </template>
                      <span>Only seen by admins - advocates cannot see or edit this field</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="!field.show_member && !field.edit_member" max-width="275px">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user-slash</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye-slash</v-icon>
                        </div>
                      </template>
                      <span>Only seen by admins - advocates cannot see or edit this field</span>
                    </v-tooltip>
                    <v-select
                      dense
                      outlined
                      :value="field.pivot ? field.pivot.metadata : []"
                      @input="(val) => fieldHandler(val, field)"
                      :items="field.metadata"
                      item-text="name"
                      item-value="name"
                      :label="field.name"
                      return-object
                      hide-details
                    >
                    </v-select>
                    <v-tooltip bottom v-if="field.hint" max-width="300px" class="ml-1">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">help</v-icon>
                      </template>
                      <span>{{ field.hint }}</span>
                    </v-tooltip>
                  </div>
                  <!-- this is multiselect option -->
                  <div v-if="field.type == 'checkbox'" class="d-flex my-2">
                    <v-tooltip bottom v-if="field.show_member && field.edit_member" max-width="275px">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye</v-icon>
                        </div>
                      </template>
                      <span>Can be seen and edited by advocates when sending a profile update request</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="field.show_member && !field.edit_member" max-width="275px">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user-slash</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye</v-icon>
                        </div>
                      </template>
                      <span>Can be seen but not edited by advocates when sending a profile update request</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="!field.show_member && field.edit_member" max-width="275px">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye-slash</v-icon>
                        </div>
                      </template>
                      <span>Only seen by admins - advocates cannot see or edit this field</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="!field.show_member && !field.edit_member" max-width="275px">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user-slash</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye-slash</v-icon>
                        </div>
                      </template>
                      <span>Only seen by admins - advocates cannot see or edit this field</span>
                    </v-tooltip>
                    <v-select
                      hide-details
                      dense
                      outlined
                      :value="field.pivot ? field.pivot.metadata : []"
                      @input="(val) => fieldHandler(val, field)"
                      :items="field.metadata"
                      item-text="name"
                      item-value="name"
                      :label="field.name"
                      multiple
                      return-object
                      chips
                    >
                    </v-select>
                    <v-tooltip bottom v-if="field.hint" max-width="300px" class="ml-1">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">help</v-icon>
                      </template>
                      <span>{{ field.hint }}</span>
                    </v-tooltip>
                  </div>
                  <!-- this is the checkbox or yes/no option -->
                  <div v-if="field.type == 'toggle'" class="d-flex my-2">
                    <v-tooltip bottom v-if="field.show_member && field.edit_member" max-width="275px">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye</v-icon>
                        </div>
                      </template>
                      <span>Can be seen and edited by user when sending a profile update request</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="field.show_member && !field.edit_member" max-width="275px">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user-slash</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye</v-icon>
                        </div>
                      </template>
                      <span>Can be seen but not edited by user when sending a profile update request</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="!field.show_member && field.edit_member" max-width="275px">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye-slash</v-icon>
                        </div>
                      </template>
                      <span>Only seen by admins - members cannot see or edit this field</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="!field.show_member && !field.edit_member" max-width="275px">
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column align-self-center mr-2" >
                          <v-icon v-on="on" x-small color="primary">fa-user-slash</v-icon>
                          <v-icon v-on="on" x-small color="primary" class="mt-1">fa-eye-slash</v-icon>
                        </div>
                      </template>
                      <span>Only seen by admins - members cannot see or edit this field</span>
                    </v-tooltip>
                    <v-switch
                      dense
                      color="primary"
                      :label="field.name"
                      :input-value="field.pivot ? field.pivot.metadata.value : false"
                      @change="(val) => fieldHandler(val, field)"
                    >
                    </v-switch>
                    <v-tooltip bottom v-if="field.hint" max-width="300px" class="ml-1">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">help</v-icon>
                      </template>
                      <span>{{ field.hint }}</span>
                    </v-tooltip>
                  </div>
                </v-flex>
              </div>
            </v-tab-item>

            <!-- Personal removed v-if="isClientUsesPoliticalTab"-->
            <v-tab-item class="pt-6">
                <v-layout wrap>
                  <v-flex sm6 class="px-2 mb-2">
                    <v-select
                        outlined
                        label="KP Preference"
                        persistent-hint
                        prepend-icon="fa-light fa-user-tie-hair"
                        hint="The Legislator party this member is willing to accept as a KP."
                        :items="kpPreference"
                        item-text="title"
                        item-value="value"
                        v-model="member.kp_preference"
                        dense
                    >
                    </v-select>
                  </v-flex>
                  <v-flex v-if="client.uses_political_leaning" xs12 sm6 class="px-2">
                    <v-select
                      dense
                      outlined
                      label="Political Leanings"
                      hint=""
                      :items="politicalLeanings"
                      item-text="name"
                      item-value="id"
                      v-model="member.political_leaning_id"
                      :prepend-icon="getIcon(member.political_leaning_id)"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex v-if="client.uses_political_issues" xs12 sm6 class="px-2">
                    <v-select
                      dense
                      outlined
                      label="Political Issues"
                      hint=""
                      :items="politicalIssues"
                      item-text="name"
                      item-value="id"
                      v-model="member.political_issues"
                      multiple
                      return-object
                    >
                    </v-select>
                  </v-flex>
                  <v-flex v-if="client && client.uses_dob" sm6 class="px-2">
                    <v-date-picker2
                        label="Date of Birth"
                        v-model="member.dob"
                        :dense="true"
                    ></v-date-picker2>
                  </v-flex>
                  <v-flex v-if="client && client.uses_grad_year" xs12 sm6 class="px-2">
                    <v-select
                      dense
                      outlined
                      label="Graduation Year"
                      prepend-icon="fa-light fa-graduation-cap"
                      :items="gradYearList"
                      v-model="member.grad_year"
                      @click:append-outer="onClear('grad_year')"
                    ></v-select>
                  </v-flex>

                  <v-flex col-12 class="px-2">
                    <h5 class="ml-1">You may enter as many as necessary</h5>
                    <v-autocomplete
                      label="Colleges or Universities Attended"
                      color="blue-grey lighten-2 mt-3"
                      :menu-props="{closeOnContentClick:true}"
                      :value="member.universities"
                      @change="setUniversity"
                      :search-input.sync="searchUniversities"
                      :items="universities"
                      item-text="name"
                      item-value="id"
                      :loading="isBusy"
                      hide-details
                      return-object
                      outlined
                      multiple
                      filled
                      dense
                    >
                      <template v-slot:selection="{item}"></template>
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.name"></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                    <div class="mb-2 pt-1">
                      <div class="d-flex flex-wrap">
                        <div v-for="(item, index) of member.universities" class="mr-2">
                          <v-menu class="my-1"
                                  v-model="displaySelected[getIndex(item.id, member.universities)]"
                                  :key="index"
                                  bottom
                                  left
                                  :close-on-content-click="false"
                                  origin="top left"
                                  content-class="role_card"
                          >
                            <template v-slot:activator="{ on }">
                              <v-chip
                                class="my-1"
                                v-on="on"
                                close
                                outlined
                                color="primary"
                                :input-value="item.name"
                                @click:close="removeUniversityFromMember(index)"
                              >
                                {{ item.name }}
                              </v-chip>
                            </template>
                          </v-menu>
                        </div>
                      </div>
                    </div>
                  </v-flex>


                  <v-flex xs12 sm12 v-if="client.uses_interests">
                    <h4>Hobbies/Interests:</h4>
                    <div
                      v-for="interest in hobbiesInterests"
                      :key="interest.id"
                    >
                      <v-checkbox
                        v-if="interest.parent_id === null"
                        color="primary"
                        :label="interest.name"
                        class="mt-0"
                        :input-value="isEmptyInterest(interest)"
                        @change="editMemberInterests($event, interest)"
                      ></v-checkbox>
                      <div
                        class="ml-4"
                        v-for="childInterest in hobbiesInterests"
                        :key="childInterest.id"
                      >
                        <v-checkbox
                          v-if="interest.id === childInterest.parent_id
                            && isEmptyInterest(interest)
                            && childInterest.type !== 'favorite_team'
                            && childInterest.type !== 'note_hobby'"
                          color="primary"
                          :label="childInterest.name"
                          class="mt-0"
                          :input-value="isEmptyInterest(childInterest)"
                          @change="editMemberInterests($event, childInterest)"
                        ></v-checkbox>
                        <div
                          v-if="interest.id === childInterest.parent_id
                          && childInterest.type === 'watching_sports'
                          && isEmptyInterest(childInterest)
                          && isEmptyInterest(interest)"
                        >
                          <favorite-teams-autocomplete
                            :clientId="client.id"
                            :memberFavoriteTeams="memberFavoriteTeams"
                            @response="changeMemberFavoriteTeams"
                            @remove="removeMemberFavoriteTeam"
                          ></favorite-teams-autocomplete>
                        </div>
                        <div
                          v-if="childInterest.type === 'other'
                                  && interest.id === childInterest.parent_id
                                  && isEmptyInterest(interest)
                                  && isEmptyInterest(childInterest)"
                        >
                          <v-textarea
                            v-model="otherHobbies[childInterest.id]"
                            label="Your Hobby"
                            auto-grow
                            outlined
                            rows="1"
                            row-height="15">
                          </v-textarea>
                        </div>
                      </div>
                      <div
                        v-if="interest.parent_id === null
                          && interest.type === 'other'
                          && isEmptyInterest(interest)"
                      >
                        <v-textarea
                          v-model="otherHobbies[interest.id]"
                          label="Your Hobby"
                          auto-grow
                          outlined
                          rows="1"
                          row-height="15">
                        </v-textarea>
                      </div>
                    </div>
                  </v-flex>
                </v-layout>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn outlined color="secondary" @click="isModalOpen = false" class="mt-2">Cancel</v-btn>
          <v-btn type="submit" color="primary" class="mr-3 mt-2">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <edit-silence-modal
      ref="EditSilenceModal"
      @onSave="saveSilenceFields"
      @cancel="unsilence"
    ></edit-silence-modal>
    <upload-avatar-modal
      ref="UploadAvatar"
    ></upload-avatar-modal>
    <legislator-detail-modal ref="legislatorDetailModal"></legislator-detail-modal>
  </v-dialog>
</template>

<script>
	import ClientService from '@/services/ClientService'
	import CloudinaryService from '@/services/CloudinaryService'
	import DistrictService from '@/services/DistrictService'
	import MemberService from '@/services/MemberService'

	import AddressInput from '@/components/common/address-input.vue'
	import VDatePicker2 from '@/components/common/date-picker'
	import EditSilenceModal from '@/components/members/edit-silence-modal'

  import UploadAvatar from "@/components/my-kp/upload-avatar-modal"
  import legislatorDetailModal from '@/components/legislators/legislator-detail-modal.vue'

  import kpPreference from '@/entities/kp-preference'
  import _ from 'lodash'
  import swal from "sweetalert2";
  import InterestService from "@/services/InterestService";
  import FavoriteTeamsAutocomplete from "@/components/auth/favorite-teams-autocomplete";
  import UniversityService from "@/services/UniversityService";

	export default {
	  name: "EditMemberModal",
    props: {
      clientList: {
        type: Array,
        default: () => { return []}
      },
      refresh: {
        type: Function,
      },
      selectedClient: {
        type: Object,
        default: () => { return {}}
      },
    },
		mixins: [ClientService, CloudinaryService, DistrictService, MemberService, InterestService, UniversityService],
		components: {
			AddressInput,
			VDatePicker2,
      EditSilenceModal,
      'legislator-detail-modal': legislatorDetailModal,
      'upload-avatar-modal': UploadAvatar,
      "favorite-teams-autocomplete": FavoriteTeamsAutocomplete,
		},
		data() {
			return {
			  politicalLeanings: [
                { id: 1, name: 'Team Blue', icon: 'fa-light fa-democrat', color: 'donkey' },
                { id: 2, name: 'Lean Blue', icon: 'fa-light fa-democrat', color: 'donkey lighten-3' },
                { id: 3, name: 'Independent/Other/Unknown', icon: 'fa-light fa-square-question', color: '#9c27b0' },
                { id: 4, name: 'Lean Red', icon: 'fa-light fa-republican', color: 'elephant lighten-3' },
                { id: 5, name: 'Team Red', icon: 'fa-light fa-republican', color: 'elephant' },
              ],
                member: {
                political_leaning_id: null,
            },
              tabs: null,
		      isModalOpen: false,
		      activeIndex: 0,
              districtList: [],
              isCellMask: true,
		      assignedLegislators: [],
              kpPreference: kpPreference,
              politicalLeanings: [],
              politicalIssues: [],
              hobbiesInterests: [],
              universities: [],
              searchUniversities: '',
              displaySelected: {},
              memberFavoriteTeams: [],
              otherHobbies: {},
		      member: {
		      custom_fields: [],
              interests: [],
              political_issues: []
            },
				error: '',
				isBusy: false,
				clientFields: [],
			}
		},
    computed: {
			clientRules() {
				return [
					v => !!v || 'Client is required.',
				]
			},
			lastNameRules() {
				return [
					v => !!v || 'Last name is required.',
				]
			},
			nickNameRules() {
				return [
					v => !!v || 'Nickname is required.',
				]
			},
			emailRules() {
				return [
					v => !!v || 'E-mail address is required.',
					v => this.validateEmail(v) || 'E-mail address must be valid.',
				]
			},
			gradYearList() {
				const thisYear = moment().year()
				return _.range(1940, thisYear + 1)
			},
			client() {
				return this.selectedClient
			},
      isFederalClient(){
        return this.client && this.client.is_use_state === 0;
      },
			houseDistrictList() {
				// return this.districtList.filter(district => !district.is_federal && /^H\d+\w*/.test(district.district))
				return this.districtList.filter(district => /^H[A-Za-z0-9_]+\w*/.test(district.district))
			},
			senDistrictList() {
				// return this.districtList.filter(district => !district.is_federal && /^S\d+\w*/.test(district.district))
				return this.districtList.filter(district => /^S\d+\w*/.test(district.district))
			},
			conDistrictList() {
				return this.districtList.filter(district => {
				  // return district.is_federal && /^CD\d+\w*/.test(district.district)
				  return /^CD\d+\w*/.test(district.district)
				})
			},
			federalSenateList() {
				return this.districtList.filter(district => district.is_federal && /^[^\d]{2}$/.test(district.district))
			},
			totalFields() {
				let result = []

				for (let i = 0; i < this.clientFields.length; i++) {
					let suggest = this.member.custom_fields.find(item => item.id == this.clientFields[i].id)
          
					if (suggest) {
						result.push(suggest)
					} else {
						result.push(this.clientFields[i])
					}
				}
        const filteredCustomFields = this.getConditionalFields(result)

				return filteredCustomFields
			},
		},
		methods: {
			async toggle(member) {
				this.isModalOpen = true
				this.error = ''
        this.member = _.cloneDeep(this.parseCustomFieldMetadata(member))
        const otherInterests = this.member && this.member.interests ? this.member.interests.filter(item => item.type === 'note_hobby') : [];
        otherInterests && otherInterests.forEach(hobby => {
          this.otherHobbies[hobby.parent_id] = hobby.name
        })
        this.memberFavoriteTeams = this.member && this.member.interests ? this.member.interests.filter(item => item.type === 'favorite_team') : [];
        this.updateDistricts()
				this.onSelectClient()
				this.getClientCustomFields()
        await this.getUniversity()
        await this.getPoliticalLeaningsList()
        await this.getPoliticalIssuesList()
        await this.getInterestsList()
        this.assignedLegislators = this.member && this.member.active && this.member.is_kp1 && this.member.activeKP1 ? (await this.getMemberAssignedLegislators(this.member.id)).data : []
      },
      getIcon(politicalLeaningId) {
        const selected = this.politicalLeanings.find(item => item.id === politicalLeaningId);
        return selected ? selected.icon : null;
    },
    getColor(politicalLeaningId) {
      const selected = this.politicalLeanings.find(item => item.id === politicalLeaningId);
      return selected ? selected.color : null;
    },
      isPoBoxes(text) {
        if (text) return text.toUpperCase().includes('PO B')
        return false
      },
      getUniversity() {
        const params = {
          'search': this.searchUniversities,
          'limit': 5,
        }
        this.getUniversities(params).then(response => {
          this.universities = response.data.data
        })
      },
      setUniversity(value) {
        this.member.universities = value
      },
      removeUniversityFromMember(value) {
        this.member.universities.splice(value, 1)
      },
      getIndex(id, array) {
        return array.findIndex(item => item.id === id)
      },
      getKpPosition(legislator) {
        const memberLegislator = this.member && this.member.member_legislator || [];
        let leg = memberLegislator.find(item => item.leg_id === legislator.id);

        if (leg) {
          return leg.kp_position
        }
      },
      editMemberInterests(event, value) {
        if (event) {
          this.member.interests.push(value)
        } else {
          this.removeInterest(value)
        }
      },
      removeInterest(value) {
        const index = this.member.interests.findIndex(object => object.id === value.id);
        if (index >= 0) {
          const childInterests = this.member.interests.filter(object => object.parent_id === value.id)
          childInterests.forEach(object => this.removeInterest(object))
          this.member.interests.splice(index, 1)
        }
        if (value.type === 'watching_sports') {
          this.memberFavoriteTeams = [];
        }
      },
      isEmptyInterest(interest) {
        const memberInterest = this.member.interests.findIndex(item => item.id === interest.id)
        return memberInterest >= 0
      },
      changeMemberFavoriteTeams(value) {
        this.memberFavoriteTeams = value
      },
      removeMemberFavoriteTeam(value) {
        const index = this.memberFavoriteTeams.findIndex(object => object.id === value.id);
        this.memberFavoriteTeams.splice(index, 1)
      },
      openEditSilenceModal() {
			  if(this.member.silence) {
			    this.$refs.EditSilenceModal.isModalOpen = true
        }
      },
      openLegislatorDetailModal(person) {
        this.$refs.legislatorDetailModal.toggle(person)
      },
      openDeleteAddressDialog(value) {
        swal({
          title: 'Delete this address and clear districts?',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.onDeleteAddress(value)
          }
        })
      },
      onDeleteAddress(value) {
			  let address = value + '_address'
			  let house_district = value + '_house_district'
			  let sen_district = value + '_sen_district'
			  let con_district = value + '_con_district'

			  this.member[address] = ''
        this.member[house_district] = ''
        this.member[sen_district] = ''
        this.member[con_district] = ''
      },
			onUploadAvatar() {
				const file = this.$refs.avatarUploader.files[0]
				if (!file) return
				this.uploadToCloudinary(file)
					.json((res) => {
						this.member.avatar_url = res.secure_url
						this.member = {...this.member}
						this.onUpdateMember(true)
					})
					.catch(() => {
            this.onCallToastr('error', 'Something went wrong!', 'Error!')
					})
			},
      saveSilenceFields(value) {
        this.member.silence_reason = value[0]
        this.member.unsilence_date = value[1]
      },
      unsilence() {
			  this.member.silence = false
        this.member.silence_reason = null
        this.member.unsilence_date = null
      },
      getArrayOfUniqDistricts(array) {
			  return _.uniqBy(array, function (e) {
          return e.district;
        });
      },
      getUniqDistricts(array) {
        if (array === null) {
          return
        }
			  return _.uniqBy(array)[0];
      },
			async onSelectClient() {
			  let districts = (await this.getClientDistricts(this.member.client_id, false, this.isFederalClient)).data

			  let client = this.clientList.find(client => client.id == this.member.client_id)
        if (client && client.state && client.state.few_districts) {
          if(this.isFederalClient) {
            this.districtList = districts
          } else {
            this.districtList = this.getArrayOfUniqDistricts(this.removeDuplicateDistricts(districts))
          }
        } else {
				  this.districtList = districts
        }
			},
      removeDuplicateDistrict(array) {
        if (!Array.isArray(array)) {
            // If array is null, undefined, or not an array, return an empty array or handle accordingly
            return [];
        }
        return array.map(district =>
            district != null && typeof district === 'string' && district.match(/^[A-Z][A-Za-z0-9_]+\w/i)
                ? district.match(/^[A-Z][A-Za-z0-9_]+\w/i)[0]
                : ''
        );
      },
      removeDuplicateDistricts(array) {
        return array.map(district => ({
          ...district,
          district: district.district.match(/^[A-Z][A-Za-z0-9_]+\w/i) ?
            district.district.match(/^[A-Z][A-Za-z0-9_]+\w/i)[0] :
            district.district
        }))
      },
      openUploadImagePopup(member) {
        if (this.isSuperAdmin || this.isVIP) {
          this.$refs.UploadAvatar.open(member)
        }
      },
      onInputAddress(event, index) {
        if (!this.client) {
          return
        }
        const prefixList = [
          'home_',
          'work_',
          'work2_',
          'work3_',
          'work4_',
        ]
        const prefix = prefixList[index]
        this.member[`${prefix}address`] = event
        this.member[`${prefix}lat`] = null
        this.member[`${prefix}lng`] = null
        this.member[`${prefix}house_district`] = null
        this.member[`${prefix}sen_district`] = null
        this.member[`${prefix}con_district`] = null
      },
      onChangeAddress(index) {
				if (!this.client) {
					return
				}
				const prefixList = [
					'home_',
					'work_',
					'work2_',
					'work3_',
					'work4_',
				]
				const prefix = prefixList[index]
				const payload = {
					state_id: this.client.state_id,
					address: this.member[`${prefix}address`],
          is_federal_client: this.isFederalClient,
				}

				this.searchDistrictByAddress(payload)
					.then((res) => {
						if (!res.data.house_district && !res.data.sen_district && !res.data.con_district) {
							this.member[`${prefix}lat`] = null
							this.member[`${prefix}lng`] = null
              this.onCallToastr('error', 'Sorry, no valid district found for this address, please add manually', 'Error!')
							return
						}

						let houseDistrict = res.data.house_district
						let senateDistrict = res.data.sen_district
						let conDistrict = res.data.con_district

            let client = this.clientList.find(client => client.id === this.member.client_id)
            if (client && client.state && client.state.few_districts) {
              if (houseDistrict !== null) {
                houseDistrict.district = houseDistrict.district.match(/^[A-Z][A-Za-z0-9_]+\w/i) ? houseDistrict.district.match(/^[A-Z][A-Za-z0-9_]+\w/i)[0] : houseDistrict.district
              }
              if (senateDistrict !== null) {
                senateDistrict.district = senateDistrict.district.match(/^[A-Z]\d+\w/i) ? senateDistrict.district.match(/^[A-Z]\d+\w/i)[0] : senateDistrict.district
              }
              if (conDistrict !== null) {
                conDistrict.district = conDistrict.district.match(/^[A-Z]\d+\w/i) ? conDistrict.district.match(/^[A-Z]\d+\w/i)[0] : conDistrict.district
              }
            }

						if (this.houseDistrictList.length > 0 && houseDistrict) {
              if(this.isFederalClient) {
               //  this.member[`${prefix}house_district`] = houseDistrict.id
              } else {
                this.member[`${prefix}house_district`] = houseDistrict.district
              }
						} else {
							this.member[`${prefix}house_district`] = null
						}
						if (this.senDistrictList.length > 0 && senateDistrict) {
              if(this.isFederalClient) {
                // this.member[`${prefix}sen_district`] = senateDistrict.id
              } else {
                this.member[`${prefix}sen_district`] = senateDistrict.district
              }
						} else {
							this.member[`${prefix}sen_district`] = null
						}
						if (this.conDistrictList.length > 0 && conDistrict) {
              if(this.isFederalClient) {
                this.member[`${prefix}con_district`] = conDistrict.id
              } else {
                this.member[`${prefix}con_district`] = conDistrict.district
              }
						} else {
							this.member[`${prefix}con_district`] = null
						}
						this.member[`${prefix}lat`] = res.data.location.lat
						this.member[`${prefix}lng`] = res.data.location.lng
						this.member = {...this.member}
					})
					.catch((err) => {
            console.log(err)
            this.member[`${prefix}lat`] = null
            this.member[`${prefix}lng`] = null
            this.member[`${prefix}house_district`] = null
            this.member[`${prefix}sen_district`] = null
            this.member[`${prefix}con_district`] = null
					  if (err.response.status === 424) {
              this.onCallToastr('error', `Warning. This address is outside ${this.selectedClient.assoc_abbrev}. It is OK to save; however, it will have blank districts and be excluded from all KP Dashboard systems.`, 'Error!')
            } else {
              this.handleError(err)
            }
					})
			},
			onClear(key) {
				this.member[key] = ''
				this.member = {...this.member}
      },
      clearAddress() {
				const prefixList = [
					'home_',
					'work_',
					'work2_',
					'work3_',
					'work4_',
				]

        for(let i = 0; i <= (prefixList.length-1); i++) {
          let prefix = prefixList[i]

          if(this.isFederalClient) {
            this.member[`${prefix}con_district`] = this.member[`${prefix}con_district_id`]
            this.member[`${prefix}federal_senate`] = this.member[`${prefix}federal_senate_id`]
            this.member[`${prefix}house_district`] = this.member[`${prefix}house_district_id`]
            this.member[`${prefix}sen_district`] = this.member[`${prefix}sen_district_id`]
          } else {
            this.member[`${prefix}con_district`]
              ? this.member[`${prefix}con_district`] = this.member[`${prefix}con_district`]
              : this.member[`${prefix}con_district`] = ''
            this.member[`${prefix}federal_senate`]
              ? this.member[`${prefix}federal_senate`] = this.member[`${prefix}federal_senate`]
              : this.member[`${prefix}federal_senate`] = ''
            typeof(this.member[`${prefix}house_district`]) == 'string'
              ? this.member[`${prefix}house_district`] = this.member[`${prefix}house_district`]
              : this.member[`${prefix}house_district`] = ''
            typeof(this.member[`${prefix}sen_district`]) == 'string'
              ? this.member[`${prefix}sen_district`] = this.member[`${prefix}sen_district`]
              : this.member[`${prefix}sen_district`] = ''
          }
        }
      },
      updateDistricts() {
				const prefixList = [
					'home_',
					'work_',
					'work2_',
					'work3_',
					'work4_',
				]

        for(let i = 0; i <= (prefixList.length-1); i++) {
          let prefix = prefixList[i]
          if(this.isFederalClient){
            this.member[`${prefix}con_district`] = this.member[`${prefix}con_district_id`];

            this.member[`${prefix}federal_senate`] = null;

            this.member[`${prefix}house_district`] = null;

            this.member[`${prefix}sen_district`] = null;
          }else {
            typeof(this.member[`${prefix}con_district`]) == 'string'
            ? this.member[`${prefix}con_district`] = this.member[`${prefix}con_district`]
            : this.member[`${prefix}con_district`] = this.getUniqDistricts(this.removeDuplicateDistrict(this.member[`${prefix}con_district`]))
          typeof(this.member[`${prefix}federal_senate`]) == 'string'
            ? this.member[`${prefix}federal_senate`] = this.member[`${prefix}federal_senate`]
            : this.member[`${prefix}federal_senate`] = this.getUniqDistricts(this.removeDuplicateDistrict(this.member[`${prefix}federal_senate`]))
          typeof(this.member[`${prefix}house_district`]) == 'string'
            ? this.member[`${prefix}house_district`] = this.member[`${prefix}house_district`]
            : this.member[`${prefix}house_district`] = this.getUniqDistricts(this.removeDuplicateDistrict(this.member[`${prefix}house_district`]))
          typeof(this.member[`${prefix}sen_district`]) == 'string'
            ? this.member[`${prefix}sen_district`] = this.member[`${prefix}sen_district`]
            : this.member[`${prefix}sen_district`] = this.getUniqDistricts(this.removeDuplicateDistrict(this.member[`${prefix}sen_district`]))
          }
        }
      },
			onUpdateMember(isModalOpen) {
				if (!this.$refs.memberForm.validate()) return
        this.isBusy = true
        this.member.other_interests = []
        for (const [id, hobby] of Object.entries(this.otherHobbies)) {
          this.member.other_interests.push({
            "parent_id": id,
            "name": hobby
          })
        }
        this.member.universities = this.member.universities.map(university => university.id)
        this.member.interests = this.member.interests.filter(item => item.type !== 'favorite_team')
        this.member.interests = this.member.interests.concat(this.memberFavoriteTeams)

        if(this.isFederalClient) {
          // home address
          this.member.home_house_district_id = this.member.home_house_district
          const homeHouseDistrict = this.districtList.find(district => district.id === this.member.home_house_district_id)  
          if(homeHouseDistrict) {
            this.member.home_house_district = homeHouseDistrict.district
          }
          this.member.home_sen_district_id = this.member.home_sen_district
          const homeSenateDistrict = this.districtList.find(district => district.id === this.member.home_sen_district_id)
          if(homeSenateDistrict) {
            this.member.home_sen_district = homeSenateDistrict.district
          }
          this.member.home_con_district_id = this.member.home_con_district
          const homeConDistrict = this.districtList.find(district => district.id === this.member.home_con_district_id)
          if(homeConDistrict) {
            this.member.home_con_district = homeConDistrict.district
          }

          // work address
          this.member.work_house_district_id = this.member.work_house_district
          const workHouseDistrict = this.districtList.find(district => district.id === this.member.work_house_district_id)
          if(workHouseDistrict) {
            this.member.work_house_district = workHouseDistrict.district
          } 
          this.member.work_sen_district_id = this.member.work_sen_district
          const workSenateDistrict = this.districtList.find(district => district.id === this.member.work_sen_district_id)
          if(workSenateDistrict) {
            this.member.work_sen_district = workSenateDistrict.district
          }  
          this.member.work_con_district_id = this.member.work_con_district
          const workConDistrict = this.districtList.find(district => district.id === this.member.work_con_district_id)
          if(workConDistrict) {
            this.member.work_con_district = workConDistrict.district
          }   

          // work2 address
          this.member.work2_house_district_id = this.member.work2_house_district
          const work2HouseDistrict = this.districtList.find(district => district.id === this.member.work2_house_district_id)
          if(work2HouseDistrict) {
            this.member.work2_house_district = work2HouseDistrict.district
          } 

          this.member.work2_sen_district_id = this.member.work2_sen_district
          const work2SenateDistrict = this.districtList.find(district => district.id === this.member.work2_sen_district_id)
          if(work2SenateDistrict) {
            this.member.work2_sen_district = work2SenateDistrict.district
          } 

          this.member.work2_con_district_id = this.member.work2_con_district
          const work2ConDistrict = this.districtList.find(district => district.id === this.member.work2_con_district_id)
          if(work2ConDistrict) {
            this.member.work2_con_district = work2ConDistrict.district
          } 

          // work3 address
          this.member.work3_house_district_id = this.member.work3_house_district
          const work3HouseDistrict = this.districtList.find(district => district.id === this.member.work3_house_district_id)
          if(work3HouseDistrict) {
            this.member.work3_house_district = work3HouseDistrict.district
          }

          this.member.work3_sen_district_id = this.member.work3_sen_district
          const work3SenateDistrict = this.districtList.find(district => district.id === this.member.work3_sen_district_id)
          if(work3SenateDistrict) {
            this.member.work3_sen_district = work3SenateDistrict.district
          }

          this.member.work3_con_district_id = this.member.work3_con_district
          const work3ConDistrict = this.districtList.find(district => district.id === this.member.work3_con_district_id)
          if(work3ConDistrict) {
            this.member.work3_con_district = work3ConDistrict.district
          }

          // work4 address
          this.member.work4_house_district_id = this.member.work4_house_district
          const work4HouseDistrict = this.districtList.find(district => district.id === this.member.work4_house_district_id)
          if(work4HouseDistrict) {
            this.member.work4_house_district = work4HouseDistrict.district
          }

          this.member.work4_sen_district_id = this.member.work4_sen_district
          const work4SenateDistrict = this.districtList.find(district => district.id === this.member.work4_sen_district_id)
          if(work4SenateDistrict) {
            this.member.work4_sen_district = work4SenateDistrict.district
          }

          this.member.work4_con_district_id = this.member.work4_con_district
          const work4ConDistrict = this.districtList.find(district => district.id === this.member.work4_con_district_id)
          if(work4ConDistrict) {
            this.member.work4_con_district = work4ConDistrict.district
          }
        }

				this.updateMember(this.member)
					.then(() => {
            this.clearAddress()
						this.isBusy = false
            this.onCallToastr('success', 'Member info has been updated.', 'Success!')
						this.refresh()
						this.isModalOpen = false
					})
					.catch((err) => {
            if (err.response) {
              this.errors = err.response.data.errors
              this.handleError(err)
            } else {
              this.errors = err
            }
					})
			},
      errorMessages(field) {
        return (this.errors && this.errors[field]) ? this.errors[field][0] : []
      },
      getPoliticalLeaningsList(){
			  this.isBusy = true
        this.getPoliticalLeanings().then(response => {
          this.politicalLeanings = response.data
          this.isBusy = false
        })
        .catch((err) => {
          this.handleError(err)
          this.isBusy = false
        })
      },
      getPoliticalIssuesList(){
			  this.isBusy = true
        this.getPoliticalIssues().then(response => {
          this.politicalIssues = response.data
          this.isBusy = false
        })
        .catch((err) => {
          this.handleError(err)
          this.isBusy = false
        })
      },
      changeMemberCell(event) {
			  this.member.cell = event
      },
      getInterestsList(){
			  this.isBusy = true
        this.getInterests(this.selectedClient.id).then(response => {
          this.hobbiesInterests = response.data
          this.isBusy = false
        })
        .catch((err) => {
          this.handleError(err)
          this.isBusy = false
        })
      },
			getClientCustomFields() {
				this.isBusy = true
				this.getClientFields(this.member.client_id).then(response => {
					this.clientFields = response.data
					this.isBusy = false
				})
			},
			addField(field, val) {
				let meta = val
				if (field.type === 'toggle') meta = {value: val}
				if (field.type === 'text') meta = {value: val}
				this.member.custom_fields.push({
					...field,
					pivot: {
						metadata: meta
					}
				})
			},
			parseCustomFieldMetadata(member) {
				return {
					...member,
					custom_fields: member.custom_fields ? member.custom_fields.map(field => {
						return {
							...field,
							pivot: {metadata: JSON.parse(field.pivot.metadata), member_custom_field_id: field.pivot.member_custom_field_id}
						}
					}) : []
				}
			},
			onSelectTab (tabIndex) {
				this.activeIndex = tabIndex
			},
			fieldHandler(val, field) {
				let test = this.member.custom_fields.some(item => item.id == field.id)
				if (test) {
					this.member.custom_fields = this.member.custom_fields.map(item => {
						let meta = val
						if (field.type === 'toggle') meta = {value: val}
						if (field.type === 'text') meta = {value: val}

						if (item.id == field.id) {
							return {
								...field,
								pivot: {
									metadata: meta
								}
							}
						} else {
							return {...item}
						}
					})
				} else {
					this.addField(field, val)
				}
			},
      getConditionalFields(customFields) {
        const filteredCustomFields = customFields.filter(customField => {
            if (customField.is_conditional) {              
                const memberCustomField = this.member.custom_fields.find(memberCustomField => memberCustomField.pivot.member_custom_field_id === customField.member_custom_field_id);
                
                if (memberCustomField) {
                    return this.compareConditionalMetadata(customField, memberCustomField);
                }
                return false;
            }
            return true;
        });

				return filteredCustomFields
      },
      compareConditionalMetadata(customField, memberCustomField) {

        if (customField.is_conditional && memberCustomField) {
              const firstMetadata = JSON.parse(customField.conditional_field_metadata);
              const secondMetadata = memberCustomField.pivot.metadata;
              if (Array.isArray(firstMetadata) && Array.isArray(secondMetadata)) {
                  if (firstMetadata.length !== secondMetadata.length) {
                  return false;
              }

              const allItemsMatch = firstMetadata.every(firstItem => {
                  return secondMetadata.some(secondItem => {
                      return JSON.stringify(firstItem) === JSON.stringify(secondItem);
                  });
              });
              
              return allItemsMatch;
            } else if (typeof firstMetadata === 'object' && typeof secondMetadata === 'object') {
              return JSON.stringify(firstMetadata) === JSON.stringify(secondMetadata);
            } else {              
              return firstMetadata === secondMetadata;
            }
        }
        return false;
      }
		},
    watch: {
      searchUniversities: function () {
        clearTimeout(this.timer)
        this.timer =  setTimeout(() => {
          this.getUniversity()
        }, 500)
      },
    }
	}
</script>

<style scoped lang="scss">
 .v-dialog {
  background-color: white;
}
.v-dialog--fullscreen .v-toolbar__title {
  padding-left: 0 !important;
}

.upload-btn {
  @media (min-width: 600px) {
    padding-top: 20px;
  }
}

 .v-tabs-items.full-height-tab  .v-window-item {
   height: 55vh;
   overflow-y: auto;
 }
 </style>
