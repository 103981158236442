<template>
  <v-dialog max-width="600" v-model="isModalOpen">
    <v-form ref="downloadForm" @submit.prevent="onDownloadTemplate()">
      <v-card>
        <v-card-title>
          <span class="title">Download Advocate File Template</span>
          <v-progress-circular
              indeterminate
              color="primary"
              size="20"
              width="2"
              v-if="isBusy"
              class="ml-2"
          ></v-progress-circular>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout wrap>
            <v-flex xs6 md4 class="px-2" v-for="(column, index) in cols" :key="column">
              <v-checkbox
                color="primary"
                :label="column"
                v-model="fields[index]"
                :value="fields[index]"
                :readonly="index < 6"
              ></v-checkbox>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="secondary" @click.native="isModalOpen = false">Close</v-btn>
          <v-btn type="submit" color="primary" :loading="isBusy">Download</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  import downloadFile from 'downloadjs'
  import * as XLSX from 'xlsx/xlsx.mjs';
  import ClientService from '@/services/ClientService'

  export default {
    props: ['client'],
    mixins: [ClientService],
    data() {
      return {
        isModalOpen: false,
        filterList: [],
        columnList: [],
        colsToRemove: [],
        fieldsToRemove: [],
        stringToRemove: [],
        fieldList: [],
        isBusy: false,
        clientFields: []
      }
    },
    computed: {
      cols() {
        this.columnList = this.columnList.filter(value => !this.colsToRemove.includes(value))
        return [...this.columnList, ...this.clientFields.map(field => field.name)]
      },
      fields() {
        this.fieldList = this.fieldList.filter(value => !this.fieldsToRemove.includes(value))
        return [...this.fieldList, ...this.clientFields.map(field => field.name.replace(/\s+/g, '_').replace(/[?!']+/g, '').toLowerCase())]
      },
      string() {
        this.stringList = this.stringList.filter(value => !this.stringToRemove.includes(value))
        return [...this.stringList, ...this.clientFields.map(field => {
          if (field.metadata && field.metadata[0] && field.metadata[0].name) {
            return field.metadata[0].name
          }
          if (field.type == 'toggle') {
            return 1
          }
          return 'some text'
        })
        ]
      }
    },
    methods: {
      toggle() {
        this.isModalOpen = true
        this.columnList = [
          'Active',
          'Last Name',
          'Nick Name',
          'Email',
          'Cell',
          'Office Phone',
          //'Coordinator',
          'Eligible',
          'Date Of Birth',
          'Grad Year',
          'KP Preference',
          //'Instagram Url',
          //'Linkedin Url',
          //'Twitter Url',
          //'Facebook Url',
          'Notes',
          'Home Address',
          'Office Address',
          '2nd Office Address',
          '3rd Office Address',
          '4th Office Address'
        ]
        this.fieldList = [
          'active',
          'lastname',
          'nickname',
          'email',
          'cell',
          'office_phone',
          //'coordinator',
          'eligible',
          'dob',
          'grad_year',
          'kp_preference',
          //'instagram_url',
          //'linkedin_url',
          //'twitter_url',
          //'facebook_url',
          'notes',
          'home_address',
          'work_address',
          'work2_address',
          'work3_address',
          'work4_address'
        ]
        this.stringList = [
          '1',
          'Smith',
          'John',
          'smith@gmail.com',
          '402 000 0000',
          '402 000 0001',
          //'0',
          '1',
          '1995-07-12',
          '2021',
          'unknown',
          //'https://www.instagram.com/test',
          //'https://www.linkedin.com/in/test',
          //'https://twitter.com/test',
          //'https://www.facebook.com/profile.php?id=000000000000001',
          'some text',
          '2823 Clarendon Blvd, Arlington, VA 22201',
          '141 N Main St, Chase City, VA 23924',
          '213 Connor Dr, Charlottesville, VA 22911',
          '10101 Brook Rd, Glen Allen, VA 23059',
          '55 Catoctin Cir NE, Leesburg, VA 20176'
        ]

        let indexToRemove = []
        const indexOfDOB = this.columnList.indexOf('Date Of Birth');
        const indexOfGradYear = this.columnList.indexOf('Grad Year');
        const indexOf2ndOfficeAddress = this.columnList.indexOf('2nd Office Address');
        const indexOf3rdOfficeAddress = this.columnList.indexOf('3rd Office Address');
        const indexOf4thOfficeAddress = this.columnList.indexOf('4th Office Address');

        if (!this.client.uses_dob && indexOfDOB !== -1) indexToRemove.push(indexOfDOB);
        if (!this.client.uses_grad_year && indexOfGradYear !== -1) indexToRemove.push(indexOfGradYear);
        if (!this.client.uses_2nd_work && indexOf2ndOfficeAddress !== -1) indexToRemove.push(indexOf2ndOfficeAddress);
        if (!this.client.uses_3rd_work && indexOf3rdOfficeAddress !== -1) indexToRemove.push(indexOf3rdOfficeAddress);
        if (!this.client.uses_4th_work && indexOf4thOfficeAddress !== -1) indexToRemove.push(indexOf4thOfficeAddress);
        this.fields.forEach((field, index) => {
          this.filterList[index] = true
        })
        this.filterList = [...this.filterList]
        this.colsToRemove = this.columnList.filter((value, key) => indexToRemove.includes(key))
        this.fieldsToRemove = this.fieldList.filter((value, key) => indexToRemove.includes(key))
        this.stringToRemove = this.stringList.filter((value, key) => indexToRemove.includes(key))
        this.getClientCustomFields()
      },
      onDownloadTemplate() {
        this.isBusy = true
        const columns = this.fields.filter((field, index) => this.fields[index])
        const stringExample = this.string.filter((field, index) => this.fields[index])
        this.params = {
          "fields": columns,
          "string": stringExample
        }
        this.downloadMemberImportExample(this.client.id, this.params).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${this.client.assoc_abbrev}_import_template_${moment().format('MM-DD-YYYY')}.xlsx`)
          document.body.appendChild(link)
          link.click()
          this.isBusy = false
        },error => {
          this.isBusy = false
          console.error(error)
        })
      },
      getClientCustomFields() {
        this.isBusy = true
        this.getClientFields(this.client.id).then(response => {
          this.clientFields = response.data
          this.isBusy = false
        })
      },
    }
  }
</script>
