<template>
  <v-dialog max-width="500" v-model="dialog" @click:outside="close">
    <v-card class="message-modal-content">
      <v-card-title>
        <span class="title">
          {{ form.id > 0 ? 'Edit system message' : 'Create system message' }}
          <v-progress-circular
            indeterminate
            color="primary"
            size="20"
            width="2"
            v-if="loading"
          ></v-progress-circular>
        </span>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="py-3">
        <template v-if="isEditorInit">
          <editor
            v-show="!loading"
            :api-key="TINY_MCE_API_KEY"
            v-model="form.content"
            :init="tinyInit"
            :plugins="tinyPlugins"
            :toolbar="tinyToolbars"
          />
        </template>
        <v-select
          label="Style"
          clearable
          :items="stylesList"
          v-model="form.style"
          item-value="value"
          item-text="title"
        >
        </v-select>
        <v-checkbox
          color="primary"
          hide-details
          class="mt-0 mb-6 admins-copies_checkbox user-invitation_Checkbox"
          v-model="form.active"
          label="Active"
        >
        </v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="secondary" @click="close">Cancel</v-btn>
        <v-btn color="primary" @click="save" :loading="loading" :disabled="loading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SystemMessageService from '@/services/SystemMessageService'
import Editor from '@tinymce/tinymce-vue'
import TinyConfig from "@/mixins/tiny-mce-config";

export default {
  name: "SystemMessageModal",
  components: {
    'editor': Editor
  },
  props: {
    stylesList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  mixins: [SystemMessageService, TinyConfig],
  data() {
    return {
      errors: {},
      dialog: false,
      loading: false,
      isEditorInit: true,
      form: {
        id: '',
        content: '',
        active: 0,
        style: []
      },
    }
  },
  computed: {
    disabled() {
      return !this.form.content
    }
  },
  methods: {
    open(id) {
      this.dialog = true
      this.form = {}
      if (id > 0) {
        this.loading = true

        let data = {
          id: id,
          unlimit: 1
        }
        this.getSystemMessages(data).then(response => {
          this.form = response.data[0]
          this.loading = false
        })
      } else {
        this.loading = false
      }

      this.isRenderEditor()
    },
    close() {
      this.dialog = false
      this.loading = true
      this.form = {}
    },
    save() {
      this.loading = true
      let data = {}

      if (this.form.id) {
        data = {
          id: this.form.id,
          content: this.form.content,
          style: this.form.style,
          active: this.form.active
        }
      } else {
        data = {
          content: this.form.content,
          style: this.form.style,
          active: this.form.active ? this.form.active : 0
        }
      }

      this.storeSystemMessage(data).then(() => {
        this.onCallToastr('success', 'System message list has been updated.', 'Success!')
        this.$emit('update')
        this.close()
        this.loading = false
        this.errors = false
      }, (err) => {
        this.errors = err.response.data.errors
        this.loading = false
      })
    },
    isRenderEditor(){
      this.isEditorInit = false;
      setTimeout(()=>{
        this.isEditorInit = true;
      })
    }
  }
}
</script>
