<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog max-width="750" v-model="isModalOpen">
    <v-card>
      <v-card-title>
        <span class="title">Message Stats</span>
        <v-spacer></v-spacer>
        <v-icon @click="isModalOpen = false">close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
        <v-text-field
          class="mx-5 mt-2 mb-3"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
          solo
        ></v-text-field>
      <v-card-text class="mt-3">
        <v-data-table
          v-if="!loading"
          class="elevation-1"
          :options.sync="pagination"
          :headers="statsTableHeader"
          :items="statsList"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :search="search"
        >
          <template v-slot:item.recipient.fullname="{ item }">
            <div @click="toggleMemberModal(item.recipient)" style="cursor: pointer;">
              {{ item.recipient.fullname }}
              <sup v-if="item.recipient.is_kp1 && item.recipient.activeKP1">KP1</sup>
              <v-tooltip bottom v-if="item.recipient.kp_preference !== 'unknown' && isVIP">
                <template v-slot:activator="{ on }">
                  <v-avatar v-on="on" :color="kpPreferenceColor(item.recipient)" size="10"></v-avatar>
                </template>
                <span>{{ kpPreferenceTitle(item.recipient) }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.recipient.political_leaning_id !== null && isVIP">
                <template v-slot:activator="{ on }">
                  <v-avatar class="mx-1" v-on="on" :color="politicalLearningsColor(item.recipient)" size="10"></v-avatar>
                </template>
                <span>{{ politicalLearningsTitle(item.recipient) }}</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:item.is_sms="{ item }">
              <v-icon v-if="item.is_sms" color="primary" size="35" class="mx-1 method_icon">phone_iphone</v-icon>
              <v-icon v-else color="primary" size="35" class="mx-1 method_icon">mail_outlined</v-icon>
          </template>

          <template v-slot:item.updated_at="{ item }">
            <div>
              <v-tooltip
                v-if="item.is_sms && item.error_code"
                :max-width="item.error_code !== 30006 ? '190' : '215'"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-chip
                    small
                    class="ma-2"
                    :color="item.error_code !== 30006 ? 'warning' : 'error'"
                    text-color="white"
                    v-on="on"
                  >
                    {{ item.error_code !== 30006 ? 'Filtered' : 'Failed' }}
                  </v-chip>
                </template>
                <span>{{ errorTooltip(item) }}</span>
              </v-tooltip>
              <span v-else>{{ getFormattedDate(item.updated_at, 'MM/DD/YY h:mm a') }}</span>
            </div>
          </template>

          <template v-slot:item.opened_at="{ item }">
            <div>
              <span>{{ item.opened_at ? getFormattedDate(item.opened_at, 'MM/DD/YY h:mm a') : '-' }}</span>
            </div>
          </template>

          <template v-slot:item.action="{ item }">
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="warning"
                    :loading="isBusy"
                    v-on="on"
                    small
                    :disabled="!!item.opened_at || item.is_sms === 1"
                    @click="onRemind(item)"
                  >
                    Resend
                  </v-btn>
                </template>
                <span>Resend {{ item.recipient.fullname }} this message</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:no-data>
            <p class="text-xs-center mb-0">No stats yet.</p>
          </template>
        </v-data-table>
        <v-skeleton-loader
          v-if="loading"
          v-bind="attrs"
          type="table-heading, list-item-two-line, image, table-tfoot"
        ></v-skeleton-loader>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
            <v-tooltip v-if="isNotOpened" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="warning"
                  :loading="isBusy"
                  v-on="on"
                  small
                  @click="onRemindAll()"
                >
                  Resend to Unopened
                </v-btn>
              </template>
              <span>Resend to all recipients who have not yet opened the email</span>
            </v-tooltip>
        <v-spacer></v-spacer>
        <v-btn outlined color="secondary" @click.native="close()">Close</v-btn>
      </v-card-actions>
    </v-card>
    <member-detail-modal ref="memberDetailModal" :selected-client="selectedClient"></member-detail-modal>
  </v-dialog>
</template>

<script>
import AdvocacyMessageService from '@/services/AdvocacyMessageService'
import ClientService from '@/services/ClientService'

import swal from 'sweetalert2'
import MemberService from "@/services/MemberService";
import politicalLeaningsBorder from "@/mixins/politicalLeaningsBorder";
import SuperAdminService from "@/services/SuperAdminService";
import MemberDetailModal from '@/components/members/member-detail-modal';

export default {
  name: 'SuperAdminStatsModal',
  mixins: [AdvocacyMessageService, ClientService, MemberService, politicalLeaningsBorder, SuperAdminService],
  components: {
    'member-detail-modal': MemberDetailModal,
  },
  data () {
    return {
      isModalOpen: false,
      politicalLearnings: [],
      status: '',
      statsList: {},
      loading: false,
      pagination: {
        itemsPerPage: 10
      },
      sortBy: "",
      sortDesc: true,
      search: '',
      message: {},
      isBusy: false,
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
    }
  },
  mounted() {
    this.getPoliticalLeanings()
        .then(response => {
          this.politicalLearnings = response.data
        })
  },
  computed: {
    statsTableHeader () {
      return [
        { text: 'Member', value: 'member.fullname', align: 'left' },
        { text: 'Method', value: 'is_sms', align: 'center', sortable: false },
        { text: 'Send At', value: 'updated_at', align: 'center' },
        { text: 'Opened At', value: 'opened_at', align: 'center' },
        { text: '', value: 'action' },
      ]
    },
    isNotOpened() {
      if (this.statsList && this.statsList.length) {
        return this.statsList.filter(stat => stat.opened_at == null && stat.is_sms === 0).length > 0
      }
       return null
    },
    selectedClient() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
  },
  methods: {
    async toggle (message) {
      this.isModalOpen = true
      this.loading = true
      this.message = message
      this.search = ''
      try {
        this.statsList = (await this.getScheduledStats(message.id)).data
        this.statsList.map(stat => {
          if (stat.member) {
            stat.member.fullname = stat.member.nickname + ' ' + stat.member.lastname
            stat.recipient = stat.member
          }
          if ((stat.user)) {
            stat.user.fullname = stat.user.nickname + ' ' + stat.user.lastname
            stat.recipient = stat.user
          }
          return stat
        })
        this.loading = false
      }
      catch (e) {
        this.loading = false
        console.error(e)
      }
    },
    errorTooltip (item) {
      const failed = 'Landline or unreachable carrier. User cell blocked. Contact user for updated number.'
      const filtered = 'Filtered as possible spam, please see texting guidelines.'
      const stopped = 'Recipient has replied STOP'
      if (item.error_code === 30006) {
        return failed
      } else if (item.error_code === 30007) {
        return filtered
      } else if (item.error_code === 21610) {
        return stopped
      } else {
        return item.error_text
      }
    },
    isActive () {
      if (!this.message.active) return false
      if (this.message.deadline < moment().format('YYYY-MM-DD')) return false
      return true
    },
    onRemindAll() {
      swal({
        title: `Resend to all recipients who have not opened the email?`,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#4caf50',
        cancelButtonColor: '#9E9E9E',
        confirmButtonText: '&#10003 Resend',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.statsList.forEach(stat => {
            if (stat.opened_at == null && stat.is_sms === 0) {
              this.remindEmail(stat)
            }
          })
        }
      })
    },
    remindEmail(message) {
      this.isBusy = true
      this.remindMessage(message.id).then(() => {
        this.onCallToastr('success', 'Email has been sent.', 'Success!')
        this.isBusy = false
      }, () => {
        this.onCallToastr('success', 'Something went wrong!', 'Error!')
        this.isBusy = false
      })
    },
    onRemind(message) {
      swal({
        title: `Resend ${message.recipient.fullname} this email?`,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#4caf50',
        cancelButtonColor: '#9E9E9E',
        confirmButtonText: '&#10003 Resend',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.remindEmail(message)
        }
      })
    },
    toggleMemberModal(item) {
      this.$refs.memberDetailModal.toggle(item)
    },
    close() {
      this.statsList = []
      this.isModalOpen = false
    }
  },
}
</script>
