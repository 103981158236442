<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-y
    :max-height="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="selectedDistrictNames"
        label="District"
        readonly
        v-bind="attrs"
        v-on="on"
        outlined
        dense
        clearable
        @click:clear="clearSelected"
      ></v-text-field>
    </template>

    <v-list>
      <!-- Search Field -->
      <v-text-field
        v-model="searchQuery"
        label="Search Districts"
        prepend-icon="mdi-magnify"
        dense
        hide-details
        class="px-4"
      ></v-text-field>

      <v-list-group
        v-for="(group, index) in filteredDistrictList"
        :key="index"
        v-model="group.open"
        no-action
      >
        <template v-slot:activator>
          <v-checkbox
            :input-value="isGroupSelected(group.children)"
            @change="toggleGroup(group.children)"
            :label="`${group.group.toUpperCase()}`"
          />
        </template>

        <v-list-item
          v-for="(item, childIndex) in group.children"
          :key="childIndex"
        >
          <v-checkbox
            :label="item.district"
            :input-value="selectedDistrict.includes(item)"
            @change="toggleItem(item)"
          />
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    districtList: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      menu: false,
      searchQuery: "", // Store search input
      // districtList: [
      //   {
      //     group: "State A",
      //     children: [
      //       { district: "District 1", value: "district1" },
      //       { district: "District 2", value: "district2" },
      //     ],
      //   },
      //   {
      //     group: "State B",
      //     children: [
      //       { district: "District 3", value: "district3" },
      //       { district: "District 4", value: "district4" },
      //     ],
      //   },
      // ],
      selectedDistrict: [],
    };
  },
  computed: {
    selectedDistrictNames: {
      get() {
        // Display selected district names in the text field
        return this.selectedDistrict
          .map((value) => {
            const group = this.districtList.find((group) =>
            group.children.some((item) => item.id === value.id)
          );
          const item = group
            ? group.children.find((item) => item.id === value.id)
            : null;
          
          return item ? item.district + " (" + item.state.state_abbrev.toUpperCase() + ")" : "";
        })
        .filter(Boolean)
        .join(", ");
      },
      set(newVal) {
        //
      }
    },
    selectedDistrictIds(){
      return this.selectedDistrict.map(district => district.id)
    },
    filteredDistrictList() {
      // Filter districts based on the search query
      if (!this.searchQuery) {
        return this.districtList;
      }
      const query = this.searchQuery.toLowerCase();
      return this.districtList
        .map((group) => ({
          ...group,
          children: group.children.filter((child) =>
            child.district.toLowerCase().includes(query)
          ),
        }))
        .filter((group) => group.children.length > 0); // Only include groups with matching children
    },
  },
  watch: {
    selectedDistrictNames: {
      handler(newVal) {
        this.$emit('input', newVal.split(', '));
      },
      deep: true,
    },
    selectedDistrictIds: {
      handler(newVal) {
        this.$emit('onDistrictIdChanges', newVal)
      },
    } 
  },
  methods: {
    toggleGroup(groupItems) {
      const allSelected = this.isGroupSelected(groupItems);
      if (allSelected) {
        // Remove all items in the group
        this.selectedDistrict = this.selectedDistrict.filter(
          (value) => !groupItems.map((item) => item.id).includes(value.id)
        );
      } else {
        // Add all items in the group
        this.selectedDistrict = [
          ...new Set([
            ...this.selectedDistrict,
            ...groupItems.map((item) => item),
          ]),
        ];
      }
    },
    toggleItem(itemValue) {
      const index = this.selectedDistrict.findIndex(item => item.id === itemValue.id);
      if (index > -1) {
        this.selectedDistrict.splice(index, 1);
      } else {
        this.selectedDistrict.push(itemValue);
      }
    },
    isGroupSelected(groupItems) {
      return groupItems.every((item) => this.selectedDistrict.indexOf(item) !== -1);
    },
    clearSelected() {
      this.selectedDistrict = [];
      this.searchQuery = ""; // Clear search when clearing selections
    },
  },
  mounted() {
    this.selectedDistrict = this.value;
  },
};
</script>

<style scoped>
.district-selector {
  max-width: 400px;
}
</style>
