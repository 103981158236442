<template>
  <v-dialog persistent max-width="650" v-model="isModalOpen" scrollable :fullscreen="isMobile">
    <v-card>
      <v-card-title>
        Send Message
        <v-spacer/>
        <v-icon @click="isModalOpen = false">close</v-icon>
      </v-card-title>
      <v-card-subtitle>Any replies from legislator will go to your personal email address</v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text class="message-card-text">
        <v-layout wrap>
          <v-flex xs12 class="px-2 mb-2">
            <v-autocomplete
                label="Sending to"
                :hint="currentLegislator.official_email"
                persistent-hint
                item-text="fullname"
                item-value="id"
                :items="legislatorList"
                v-model="message.legislator_id"
                @input="onChangeLegislator"
            >
            </v-autocomplete>
          </v-flex>
          <v-flex xs12 class="px-2">
            <v-textarea auto-grow label="Subject" rows="1" v-model="message.subject"></v-textarea>
          </v-flex>
          <v-flex xs12 class="px-2">
            <div class="mb-1 caption grey--text">Message</div>
            <div class="tiny_editor">
              <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
              <editor
                  v-if="!isBusy"
                  :api-key="TINY_MCE_API_KEY"
                  :plugin="tinyPlugins"
                  :toolbar="tinyToolbars"
                  :init="tinyInit"
                  v-model="message.message"
                  :disabled="disabledEditor"
                  @keyup="inputEmail"
              />
            </div>
          </v-flex>
          <v-flex xs12 v-if="attachedFiles != false" class="ma-3">
            <div>
                    <span>
                      Attachment(s): {{ attachedFiles.length }}
                    </span>
            </div>
            <v-chip
              v-for="(attachedFile, index) in attachedFiles"
              :key="index"
              class="mr-2 mt-2"
            >
              {{ `${attachedFile.name}` }}
            </v-chip>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="secondary" @click.native="isModalOpen = false">Cancel</v-btn>
        <v-btn
            color="primary"
            ref="sendMessageButton"
            :loading="isBusy"
            @click.native="$refs.ConfirmationAlert.open()"
            :disabled="!message.legislator_id || !message.subject || !message.message"
        >
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
    <confirmation-alert
      ref="ConfirmationAlert"
      :title="alertTitle"
      :textSuccessButton="'Send Now'"
      :textCancelButton="'Continue Editing'"
      :question="true"
      @onSuccess="onSend"
    >
    </confirmation-alert>
  </v-dialog>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import TinyConfig from "@/mixins/tiny-mce-config"
import ConfirmationAlert from '@/components/global/confirmation-alert'

import AdvocacyMessageService from '@/services/AdvocacyMessageService'
import ShorterUrl from "@/mixins/shorterUrl";

export default {
  name: 'ReplyMessageModal',
  mixins: [AdvocacyMessageService, TinyConfig, ShorterUrl],
  components: {
    'editor': Editor, // Do not change, this is a requirement of tinyMCE
    'confirmation-alert': ConfirmationAlert
  },
  data() {
    return {
      isModalOpen: false,
      advocacyMessage: {},
      legislatorList: [],
      attachedFiles: [],
      message: {},
      isBusy: false,
    }
  },
  computed: {
    alertTitle() {
      return 'Are you ready to send this message directly to your legislator?'
    },
    currentLegislator() {
      return this.legislatorList.find(item => item.id == this.message.legislator_id) || ''
    }
  },
  methods: {
    async toggle(advocacyMessage) {
      this.isBusy = true
      this.isModalOpen = true
      this.advocacyMessage = advocacyMessage
      this.attachedFiles = advocacyMessage.files.filter(file => file.pivot.for_legislator)
      this.message = {}
      await this.fetchLegislatorList()
      this.isBusy = false
    },
    async fetchLegislatorList() {
      this.legislatorList = (await this.getRemainingLegislators(this.advocacyMessage.id, {member_id: this.userId})).data
      if (this.legislatorList.length > 0) {
        this.message = {
          legislator_id: this.legislatorList[0].id
        }
        await this.onChangeLegislator()
      }
    },
    async inputEmail() {
      this.$nextTick(async () => {
        const newContent = await this.parsUrl(this.message.message)

        if (newContent !== this.message.message) {

          this.message.message = newContent
          this.disabledEditor = false
          document.activeElement.blur()
        }
      })
    },
    async onChangeLegislator() {
      this.message.subject = (await this.getFormattedSubject(this.advocacyMessage.id, {
        legislator_id: this.message.legislator_id,
        member_id: this.userId,
      })).data
      this.message.message = (await this.getFormattedMessage(this.advocacyMessage.id, {
        legislator_id: this.message.legislator_id,
        member_id: this.userId,
      })).data
      this.message = {...this.message}
    },
    onSend() {
      this.isBusy = true
      const payload = {
        ...this.message,
        member_id: this.userId,
      }
      this.sendReplyAdvocacyMessage(this.advocacyMessage.id, payload)
        .then(() => {
          this.isBusy = false
          this.isModalOpen = false
          this.$emit('refresh')
          this.$emit('success')
        })
        .catch((err) => {
          // this.handleError(err, true)
          // this is intentionally showing a success message even if it failed (Business requirement)
          this.isBusy = false
          this.isModalOpen = false
          this.$emit('refresh')
          this.$emit('success')
        })
    }
  }
}
</script>
