<template>
  <div>
    <v-form ref="textForm">
      <v-flex xs12 class="d-flex justify-end mb-2 align-center px-2 body_variables">
        <v-flex xs12 sm8 :class="!isMobile ? 'd-flex justify-space-between align-end' :
        'd-flex justify-space-between align-end w-100'">
          <div class="caption grey--text">Message*</div>
          <v-spacer/>
          <v-btn
              v-if="badVariables"
              x-small
              outlined
              color="primary"
              @click="onCheckVariables"
          >
            Show Error
          </v-btn>
          <v-switch
            color="primary"
            v-model="isPreview"
            :label="isPreview ? 'Edit' : 'Show Preview'"
            :loading="isBusy"
            class="d-inline-block px-2 sms-preview-switch"
            hide-details
            @change="onPreviewMessage"
          >
          </v-switch>
        </v-flex>
        <v-flex xs12 sm4>
          <v-select
              v-model="variable"
              :items="variableList"
              class="subject_variable-select text_subject_variable-select"
              label="Body Variables"
              hide-details
              outlined
              dense
          >
            <template v-slot:item="data">
              <v-list-item-content @click="onAddVariable(data.item)" v-text="data.item"></v-list-item-content>
            </template>
          </v-select>
        </v-flex>
      </v-flex>
      <p v-if="badVariables" class="red--text mb-0">You have bad variables! Please click on 'Show Error' button and fix them.</p>
      <v-flex xs12 id="editorContainer" class="px-2">
        <div class="tiny_editor" v-show="!isPreview">
          <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
          <editor
              v-if="!isBusy"
              v-model="message"
              :api-key="TINY_MCE_API_KEY"
              :plugins="tinyPlugins"
              :toolbar="tinyToolbars"
              :init="tinyInit"
              :disabled="disabledEditor"
              @click="onChangeText"
              @keyup="onChangeText"
          ></editor>
        </div>
        <div v-show="isPreview">
          <editor
              v-if="!isBusy"
              :value="text.formattedMessage"
              :api-key="TINY_MCE_API_KEY"
              :plugins="tinyPlugins"
              :toolbar="tinyToolbars"
              :init="tinyInit"
              disabled
          ></editor>
        </div>

        <div class="d-flex justify-end mt-2">
          <a @click="onOpenTestMessageModal" class="mr-4">
            Send test to {{ isEmail ? userData.email : userData.cell_phone }}
          </a>
          <span class="text-counter" :class="text.finalMessageLength > 320 ? 'error--text' : ''">
            {{ text.finalMessageLength }}/320
          </span>
        </div>
      </v-flex>
    </v-form>
    <TestMessageModal
      ref="TestMessageModal"
      :isEmail="isEmail"
      :memberId="memberId"
      :email="text"
      :message="message"
      onlyMembers
    >
    </TestMessageModal>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import TinyConfig from "@/mixins/tiny-mce-config"
import ShorterUrl from "@/mixins/shorterUrl"

import AppService from '@/services/AppService'
import he from "he";
import TestMessageModal from '@/components/global/test-message-modal'
import ClientService from "@/services/ClientService";



export default {
  name: 'SendText',
  mixins: [AppService, TinyConfig, ClientService, ShorterUrl],
  components: {
    'editor': Editor, // Do not change, this is a requirement of tinyMCE
    TestMessageModal
  },
  props: {
    setMessage: {
      type: String,
      default: ''
    },
    files: {
      type: Array,
      default: function () {
        return []
      }
    },
    text: {
      type: Object,
      default: function () {
        return {}
      }
    },
    client: {
      type: Object,
      default: function () {
        return {}
      }
    },
    members: {
      type: Array,
      default: function () {
        return []
      }
    },
    isEmail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isPreview: false,
      variable: '',
      isBusy: false,
    }
  },
  computed: {
    tinyToolbars() {
      return []
    },
    variableList() {
      return [
        '{advocate_first}',
        '{advocate_last}',
        '{update_profile_link}',
        '{home_house_leg_nick}',
        '{home_house_leg_last}',
        '{home_house_leg_fullname}',
        '{home_house_leg_salutation}',
        '{home_house_leg_email}',
        '{home_house_leg_ph}',
        '{home_house_leg_local_phone}',
        '{home_senate_leg_nick}',
        '{home_senate_leg_last}',
        '{home_senate_leg_fullname}',
        '{home_senate_leg_salutation}',
        '{home_senate_leg_email}',
        '{home_senate_leg_ph}',
        '{home_senate_leg_local_phone}',
        '{work_house_leg_nick}',
        '{work_house_leg_last}',
        '{work_house_leg_fullname}',
        '{work_house_leg_salutation}',
        '{work_house_leg_email}',
        '{work_house_leg_ph}',
        '{work_house_leg_local_phone}',
        '{work_senate_leg_nick}',
        '{work_senate_leg_last}',
        '{work_senate_leg_fullname}',
        '{work_senate_leg_salutation}',
        '{work_senate_leg_email}',
        '{work_senate_leg_ph}',
        '{work_senate_leg_local_phone}',
      ]
    },
    message: {
      get() {
        return this.setMessage
      },
      set(newValue) {
        this.$emit('getMessage', newValue)
      }
    },
    memberId() {
      return this.members.length > 0 ? this.members[0].id : 0
    }
  },
  methods: {
    onCheckVariables() {
      this.checkVariablesForEditor(this.message, this.variableList)
    },
    async onPreviewMessage() {
      if (!this.isPreview || this.message === '') {
        return
      }
      let payload = {}
      if (this.text.id) {
        payload = {
          member_id: this.members.length > 0 ? this.members[0].pivot.member_id : 0,
          message: this.message,
        }
      } else {
        payload = {
          member_id: this.members.length > 0 ? this.members[0].id : 0,
          message: this.message,
        }
      }

      this.isBusy = true
      this.text.formattedMessage = (await this.getAppFormattedMessage(payload)).data
      this.isBusy = false
    },
    onAddVariable(variable) {
      variable += ' '
      tinymce.activeEditor.execCommand('mceInsertContent', false, variable);

      this.finalMessageLength()
    },
    html2Text(html) {
      let find = /(<p><br[\/]?><[\/]?p>)/,
          re = new RegExp(find, 'g');

      let txt = html.replace(re, '\n');
      txt = txt.replace(/<[^>]*>?/gm, '')
      return he.decode(txt)
    },
    finalMessageLength() {
      let member_nick = ''
      let member_last = ''
      let update_profile_link = 'https://kpda.sh/ez/xxxxxxxx?prof=true'
      let home_house_leg_nick = ''
      let home_house_leg_last = ''
      let home_house_leg_fullname = ''
      let home_house_leg_salutation = ''
      let home_house_leg_email = ''
      let home_house_leg_ph = ''
      let home_house_leg_local_phone = ''
      let home_senate_leg_nick = ''
      let home_senate_leg_last = ''
      let home_senate_leg_fullname = ''
      let home_senate_leg_salutation = ''
      let home_senate_leg_email = ''
      let home_senate_leg_ph = ''
      let home_senate_leg_local_phone = ''
      let work_house_leg_nick = ''
      let work_house_leg_last = ''
      let work_house_leg_fullname = ''
      let work_house_leg_salutation = ''
      let work_house_leg_email = ''
      let work_house_leg_ph = ''
      let work_house_leg_local_phone = ''
      let work_senate_leg_nick = ''
      let work_senate_leg_last = ''
      let work_senate_leg_fullname = ''
      let work_senate_leg_salutation = ''
      let work_senate_leg_email = ''
      let work_senate_leg_ph = ''
      let work_senate_leg_local_phone = ''
      this.members.forEach(member => {
        if (member_nick.length < (member.nickname || '').length) member_nick = member.nickname
        if (member_last.length < (member.lastname || '').length) member_last = member.lastname

        if (typeof member.homeHouseLegislator !== "undefined") {
          const homeHouseSalutation = `${this.client.formal_house} ${member.homeHouseLegislator.lastname}`
          if (home_house_leg_nick.length < (member.homeHouseLegislator.nickname || '').length) home_house_leg_nick = member.homeHouseLegislator.nickname
          if (home_house_leg_last.length < (member.homeHouseLegislator.lastname || '').length) home_house_leg_last = member.homeHouseLegislator.lastname
          if (home_house_leg_fullname.length < (member.homeHouseLegislator.fullname || '').length) home_house_leg_fullname = member.homeHouseLegislator.fullname
          if (home_house_leg_salutation.length < (homeHouseSalutation || '').length) home_house_leg_salutation = homeHouseSalutation
          if (home_house_leg_email.length < (member.homeHouseLegislator.official_email || '').length) home_house_leg_email = member.homeHouseLegislator.official_email
          if (home_house_leg_ph.length < (member.homeHouseLegislator.official_phone || '').length) home_house_leg_ph = member.homeHouseLegislator.official_phone
          if (home_house_leg_local_phone.length < (member.homeHouseLegislator.local_phone || '').length) home_house_leg_local_phone = member.homeHouseLegislator.local_phone
        }

        if (typeof member.homeSenateLegislator !== "undefined") {
          const homeSenateSalutation = `${this.client.formal_senate} ${member.homeSenateLegislator.lastname}`
          if (home_senate_leg_nick.length < (member.homeSenateLegislator.nickname || '').length) home_senate_leg_nick = member.homeSenateLegislator.nickname
          if (home_senate_leg_last.length < (member.homeSenateLegislator.lastname || '').length) home_senate_leg_last = member.homeSenateLegislator.lastname
          if (home_senate_leg_fullname.length < (member.homeSenateLegislator.fullname || '').length) home_senate_leg_fullname = member.homeSenateLegislator.fullname
          if (home_senate_leg_salutation.length < (homeSenateSalutation || '').length) home_senate_leg_salutation = homeSenateSalutation
          if (home_senate_leg_email.length < (member.homeSenateLegislator.official_email || '').length) home_senate_leg_email = member.homeSenateLegislator.official_email
          if (home_senate_leg_ph.length < (member.homeSenateLegislator.official_phone || '').length) home_senate_leg_ph = member.homeSenateLegislator.official_phone
          if (home_senate_leg_local_phone.length < (member.homeSenateLegislator.local_phone || '').length) home_senate_leg_local_phone = member.homeSenateLegislator.local_phone
        }

        if (typeof member.workHouseLegislator !== "undefined") {
          const workHouseSalutation = `${this.client.formal_house} ${member.workHouseLegislator.lastname}`
          if (work_house_leg_nick.length < (member.workHouseLegislator.nickname || '').length) work_house_leg_nick = member.workHouseLegislator.nickname
          if (work_house_leg_last.length < (member.workHouseLegislator.lastname || '').length) work_house_leg_last = member.workHouseLegislator.lastname
          if (work_house_leg_fullname.length < (member.workHouseLegislator.fullname || '').length) work_house_leg_fullname = member.workHouseLegislator.fullname
          if (work_house_leg_salutation.length < (workHouseSalutation || '').length) work_house_leg_salutation = workHouseSalutation
          if (work_house_leg_email.length < (member.workHouseLegislator.official_email || '').length) work_house_leg_email = member.workHouseLegislator.official_email
          if (work_house_leg_ph.length < (member.workHouseLegislator.official_phone || '').length) work_house_leg_ph = member.workHouseLegislator.official_phone
          if (work_house_leg_local_phone.length < (member.workHouseLegislator.local_phone || '').length) work_house_leg_local_phone = member.workHouseLegislator.local_phone
        }

        if (typeof member.workSenateLegislator !== "undefined") {
          const workSenateSalutation = `${this.client.formal_senate} ${member.workSenateLegislator.lastname}`
          if (work_senate_leg_nick.length < (member.workSenateLegislator.nickname || '').length) work_senate_leg_nick = member.workSenateLegislator.nickname
          if (work_senate_leg_last.length < (member.workSenateLegislator.lastname || '').length) work_senate_leg_last = member.workSenateLegislator.lastname
          if (work_senate_leg_fullname.length < (member.workSenateLegislator.fullname || '').length) work_senate_leg_fullname = member.workSenateLegislator.fullname
          if (work_senate_leg_salutation.length < (workSenateSalutation || '').length) work_senate_leg_salutation = workSenateSalutation
          if (work_senate_leg_email.length < (member.workSenateLegislator.official_email || '').length) work_senate_leg_email = member.workSenateLegislator.official_email
          if (work_senate_leg_ph.length < (member.workSenateLegislator.official_phone || '').length) work_senate_leg_ph = member.workSenateLegislator.official_phone
          if (work_senate_leg_local_phone.length < (member.workSenateLegislator.local_phone || '').length) work_senate_leg_local_phone = member.workSenateLegislator.local_phone
        }
      })
      let message = this.message || ''
      message = message.replace(/{advocate_first}/g, member_nick)
      message = message.replace(/{advocate_last}/g, member_last)
      message = message.replace(/{home_house_leg_nick}/g, home_house_leg_nick)
      message = message.replace(/{home_house_leg_last}/g, home_house_leg_last)
      message = message.replace(/{home_house_leg_fullname}/g, home_house_leg_fullname)
      message = message.replace(/{home_house_leg_salutation}/g, home_house_leg_salutation)
      message = message.replace(/{home_house_leg_email}/g, home_house_leg_email)
      message = message.replace(/{home_house_leg_ph}/g, home_house_leg_ph)
      message = message.replace(/{home_house_leg_local_phone}/g, home_house_leg_local_phone)
      message = message.replace(/{home_senate_leg_nick}/g, home_senate_leg_nick)
      message = message.replace(/{home_senate_leg_last}/g, home_senate_leg_last)
      message = message.replace(/{home_senate_leg_fullname}/g, home_senate_leg_fullname)
      message = message.replace(/{home_senate_leg_salutation}/g, home_senate_leg_salutation)
      message = message.replace(/{home_senate_leg_email}/g, home_senate_leg_email)
      message = message.replace(/{home_senate_leg_ph}/g, home_senate_leg_ph)
      message = message.replace(/{home_senate_leg_local_phone}/g, home_senate_leg_local_phone)
      message = message.replace(/{work_house_leg_nick}/g, work_house_leg_nick)
      message = message.replace(/{work_house_leg_last}/g, work_house_leg_last)
      message = message.replace(/{work_house_leg_fullname}/g, work_house_leg_fullname)
      message = message.replace(/{work_house_leg_salutation}/g, work_house_leg_salutation)
      message = message.replace(/{work_house_leg_email}/g, work_house_leg_email)
      message = message.replace(/{work_house_leg_ph}/g, work_house_leg_ph)
      message = message.replace(/{work_house_leg_local_phone}/g, work_house_leg_local_phone)
      message = message.replace(/{work_senate_leg_nick}/g, work_senate_leg_nick)
      message = message.replace(/{work_senate_leg_last}/g, work_senate_leg_last)
      message = message.replace(/{work_senate_leg_fullname}/g, work_senate_leg_fullname)
      message = message.replace(/{work_senate_leg_salutation}/g, work_senate_leg_salutation)
      message = message.replace(/{work_senate_leg_email}/g, work_senate_leg_email)
      message = message.replace(/{work_senate_leg_ph}/g, work_senate_leg_ph)
      message = message.replace(/{work_senate_leg_local_phone}/g, work_senate_leg_local_phone)
      message = message.replace(/{update_profile_link}/g, update_profile_link)

      message = this.html2Text(message)
      this.text.finalMessageLength = message.length
    },
    sendImage(file) {
      let data = new FormData()
      data.append('file', file)
      return this.uploadImage(data).then((response) => {
        this.$emit('saveFiles', response.data.file)
        return response.data.link
      })
    },
    removeImage(content) {
      let images = content.match(/<img .+>/g)
      this.files.forEach(file => {
        let url = file.path.replace('public', '');

        if (!(images && images.length)) return this.$emit('deleteFile', file.id)
        for (let image of images) {
          let result = image.indexOf(url)
          if (result > 0) return true
        }
        return this.$emit('deleteFile', file.id)
      })
    },
    async onChangeText() {
      this.removeImage(this.message)
      this.finalMessageLength()
      this.searchIncorrectVariables(this.message, this.variableList)
      this.$nextTick(async () => {
        const newContent = await this.parsUrl(this.message)

        if (newContent.length < this.message.length) {

          this.message = newContent
          this.disabledEditor = false
          document.activeElement.blur()
        }
      })
      this.$emit('error')
    },
    onOpenTestMessageModal() {
      this.$refs.TestMessageModal.open()
    },
  },
  mounted() {
    this.isBusy = true
    this.isPreview = false
    this.badVariables = false
    setTimeout(() => {
      this.isBusy = false
    }, 500)
  },
  watch: {
    badVariables: function () {
      this.searchIncorrectVariables(this.message, this.variableList)
      if (!this.badVariables) {
        this.checkVariablesForEditor(this.message, this.variableList)
      }
      this.$emit('badVariables', this.badVariables)
    },
    'message': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.finalMessageLength()
        this.$emit('finalMessageLength', this.text.finalMessageLength)
      }
    },
  }
}
</script>
