<template>
  <v-navigation-drawer fixed temporary right touchless width="600" v-model="isModalOpen" class="pa-2">
    <v-layout fill-height>
      <v-form ref="userForm" @submit.prevent="onUpdateUser()">
        <v-card>
          <v-card-title>
            <span class="title">Edit User</span>
            <v-spacer />
            <v-icon @click="isModalOpen = false">close</v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="my-2">
            <v-alert type="error" outlined class="mb-2" :value="true" v-if="error">{{ error }}</v-alert>
            <v-layout wrap>
              <v-flex xs6 class="px-2">
                <v-select
                  outlined
                  dense
                  hide-details
                  label="Role*"
                  :items="roleList"
                  item-value="value"
                  item-text="title"
                  :rules="roleRules"
                  :error-messages="getErrorText('role')"
                  v-model="user.role"
                >
                </v-select>
              </v-flex>
              <v-flex xs6 class="px-2">
                <v-checkbox
                  color="primary"
                  :disabled="userId == user.id || user.team_owner === 1"
                  label="Active"
                  v-model="user.active"
                  class="mt-0"
                  hide-details
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 class="px-2 py-0" v-if="user.role == 'VIP'">
                <v-checkbox
                  color="primary"
                  v-model="clientMemberActive"
                  label="Connect this user to a member"
                ></v-checkbox>
              </v-flex>
              <v-flex v-if="(clientMemberActive && user.role == 'VIP') || user.role == 'Coordinator'" xs12 class="px-2">
                <!-- 
                  :item-text="item => `${item.nickname} ${item.lastname} ${hasCoordinator(item)}`"
                
                -->
                <v-autocomplete
                  label="Member*"
                  item-text="fullname"
                  item-value="id"
                  outlined
                  dense
                  hide-details
                  :items="clientMembers"
                  :rules="memberRules"
                  v-model="user.member_id"
                  :search-input.sync="memberSearchInput"
                  :loading="isMemberSearchLoading"
                  @input="searchMember(memberSearchInput)"
                >
                  <template v-slot:selection="data">
                    {{ data && data.item && `${data.item.nickname} ${data.item.lastname} ${hasCoordinator(data.item)}` }}
                  </template>
                  <template v-slot:item="data">
                    {{ data && data.item && `${data.item.nickname} ${data.item.lastname} ${hasCoordinator(data.item)}` }}
                  </template>
              </v-autocomplete>
              </v-flex>
              <v-flex xs12 sm6 class="px-2">
                <v-text-field
                  label="Last Name*"
                  outlined
                  dense
                  hide-details
                  :rules="lastNameRules"
                  :error-messages="getErrorText('lastname')"
                  v-model="user.lastname"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 class="px-2">
                <v-text-field
                  label="Nick Name*"
                  outlined
                  dense
                  hide-details
                  :rules="nickNameRules"
                  :error-messages="getErrorText('nickname')"
                  v-model="user.nickname"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 class="px-2">
                <v-text-field
                  :key="'email'"
                  label="Email"
                  outlined
                  dense
                  hide-details
                  :rules="emailRules"
                  :error-messages="getErrorText('email')"
                  v-model="user.email"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 class="px-2">
                <v-text-field
                  label="Cell Phone"
                  outlined
                  dense
                  hide-details
                  v-mask="'(###) ###-####'"
                  :rules="phoneNumberRules"
                  v-model="user.cell_phone"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 class="px-2">
                <address-input
                  placeholder="Mailing Address for Donation Requests"
                  dense="dense"
                  v-model="user.mailing_address"
                  class="mb-0"
                ></address-input>
              </v-flex>
              <v-flex xs12 class="px-2 py-0" v-if="user.role == 'VIP'">
                <v-switch
                  color="primary"
                  hide-details
                  label="Receive all KP task completion notifications"
                  v-model="user.receive_task_notifications"
                ></v-switch>
              </v-flex>
              <v-flex xs12 class="px-2 py-0" v-if="user.role == 'VIP'">
                <v-tooltip  bottom>
                  <template v-slot:activator="{ on }">
                    <v-switch
                      color="primary"
                      hide-details
                      label="Receive copies of outgoing email messages"
                      v-model="user.receive_copy_messages"
                      v-on="on"
                    ></v-switch>
                  </template>
                  <span>Checking this will allow this Admin to receive copies of<br> outgoing messages if the box is checked before sending.</span>
                </v-tooltip>
              </v-flex>
              <v-flex xs12 class="px-2 py-0" v-if="user.role == 'VIP'">
                <v-switch
                  color="primary"
                  hide-details
                  label="Receive all incoming message notifications"
                  v-model="user.receive_message_notifications"
                ></v-switch>
              </v-flex>
              <v-flex xs12 class="px-2 py-0" v-if="user.role == 'VIP' || user.role == 'Coordinator'">
                <v-switch
                  color="primary"
                  hide-details
                  label="Receive all incoming system notifications"
                  v-model="user.receive_system_notifications"
                ></v-switch>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="mt-2">
            <v-spacer></v-spacer>
            <v-btn outlined color="secondary" @click.native="isModalOpen = false">Cancel</v-btn>
            <v-btn type="submit" color="primary" :loading="isBusy">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
import UserService from '@/services/UserService'
import ClientService from '@/services/ClientService'

import AddressInput from '@/components/common/address-input.vue'

export default {
  props: {
    client: {
      type: Object,
      default: () => {
        return {}
      }
    },
    memberList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  mixins: [UserService, ClientService],
  components: {
    AddressInput,
  },
  data () {
    return {
      isModalOpen: false,
      user: {},
      error: '',
      errors: false,
      isBusy: false,
      clientMembers: [],
      clientMemberActive: false,
      memberSearchInput: null,
      isInSearchQueue: null,
      isMemberSearchLoading: false,
    }
  },
  watch: {
    'user.member_id'() {
      if (this.user.member_id && this.user.member_id != null) {
        this.clientMemberActive = true
      } else {
        this.clientMemberActive = false
      }
    },
    memberSearchInput: {
      handler(newVal) {
        if(this.isInSearchQueue) {
          clearTimeout(this.isInSearchQueue)
        }
        this.isInSearchQueue = setTimeout(() => {
          this.searchMember({
            name: newVal,
          })
          this.isInSearchQueue = null
        }, 300)
      },
    }
  },
  computed: {
    isEditUserVIPorSuperAdmin () {
      return this.user.role === 'VIP' || this.user.role === 'Super Admin'
    },
    roleRules () {
      return [
        v => !!v || 'Role is required.',
      ]
    },
    memberRules () {
      return [
        v => !!v || 'Associated member is required.',
      ]
    },
    lastNameRules () {
      return [
        v => !!v || 'Last name is required.',
      ]
    },
    nickNameRules () {
      return [
        v => !!v || 'Nickname is required.',
      ]
    },
    emailRules () {
      return [
        v => !!v || 'E-mail address is required.',
        v => this.validateEmail(v) || 'E-mail address must be valid.',
      ]
    },
    roleList () {
      let roleList = [
        { title: 'Super Admin', value: 'Super Admin' },
        { title: 'Admin', value: 'VIP' },
        { title: 'Coordinator', value: 'Coordinator' },
        { title: 'KP', value: 'KP' },
        { title: 'Leg Support', value: 'Leg Support' }
      ]
      if (this.isVIP) {
        roleList = [
          { title: 'Admin', value: 'VIP' },
          { title: 'Coordinator', value: 'Coordinator' },
        ]
      }
      if (this.isSuperAdmin) {
        roleList = [
          { title: 'Admin', value: 'VIP' },
          { title: 'Coordinator', value: 'Coordinator' },
          { title: 'Leg Support', value: 'Leg Support' }
        ]
      }
      if (!(this.client && this.client.uses_regions)) roleList = roleList.filter(role => role.value !== 'Coordinator')
      return roleList
    },
  },
  methods: {
    async searchMember(query){
      const payload = {
        limit: 10,
        ...query
      }
      try {
        this.isMemberSearchLoading = true
        const response = await this.getClientMembers(this.client.id, payload)
        let data = response.data
        if(!this.isEditUserVIPorSuperAdmin){
          data = data.filter(member => member.active)
          .filter(member => member && member.regions && member.regions.length)
        }
        this.clientMembers = data
        this.isMemberSearchLoading = false
      } catch (error) {
        this.clientMembers = []
        this.isMemberSearchLoading = false
      }
    },
    hasCoordinator (user) {
      if (user && user.coordinator) return ` (Region ${user.coordinator.toString().padStart(2, '0')})`
      return ``
    },
    toggle (user) {
      this.isModalOpen = true
      this.error = ''
      this.user = _.cloneDeep(user)
      this.user.role = this.user.roles[0].name
      this.user = {...this.user}

      let member = this.user.member
      let fullname = null;
      if(member && member.fullname){
        fullname = member.fullname
      }
      if(!!this.client) {
        this.$nextTick().then(() => {
          this.getClientMembers(this.user.client_id, {
            limit: 10,
            name: fullname,
          }).then(response => {
            this.clientMembers = response.data
          })
        })
      }
    },
    onChangeMember (list) {
      if (!this.user.member_id) return
      const member = list.find(member => member.id === this.user.member_id)
      this.user = {
        id: this.user.id,
        role: this.user.role,
        member_id: this.user.member_id,
        lastname: member.lastname,
        nickname: member.nickname,
        email: member.email,
        mailing_address: member.work_address,
        cell_phone: member.cell,
        receive_task_notifications: this.user.receive_task_notifications,
        receive_copy_messages: this.user.receive_copy_messages,
        receive_message_notifications: this.user.receive_message_notifications,
        receive_system_notifications: this.user.receive_system_notifications
      }
    },
    onUpdateUser () {
      if (!this.$refs.userForm.validate()) return
      this.isBusy = true
      this.errors = false

      this.updateUser(this.user)
        .then(() => {
          this.isBusy = false
          this.onCallToastr('success', 'User info has been updated.', 'Success!')
          this.$emit('refresh')
          this.isModalOpen = false
        })
        .catch((err) => {
          this.errors = err.response.data.errors
          this.isBusy = false
        })
    }
  }
}
</script>
