import * as storage from '@/utils/storage'

const state = {
  userData: storage.user.get(),
  refreshedAt: null,
  selectedClientToLogin: null,
}

const getters = {
  userId: state => state.userData ? state.userData.id : false,
  userMemberId: state => state.userData && state.userData.member_id ? state.userData.member_id : getters.userId(state),
  userIsKP1: state => state.userData && state.userData.member_id,
  userClientId: state => state.userData ? state.userData.client_id : false,
  userClient: state => state.userData ? state.userData.client : null,
  userName: state => state.userData ? `${state.userData.nickname} ${state.userData.lastname}` : '',
  userEmail: state => state.userData ? state.userData.email : '',
  userRole: state => state.userData ? state.userData.role : '',
  userMailingAddress: state => state.userData ? state.userData.mailing_address : '',
  userCellPhone: state => state.userData ? state.userData.cell_phone : '',
  userPermissions: state => state.userData ? state.userData.permissions : [],
  userAuthyId: state => state.userData ? state.userData.twilio_authy_id : [],
  userTwofactorEnabled: state => state.userData ? state.userData.two_factor_verified : [],
  selectedClientToLogin: state => state.selectedClientToLogin ? state.selectedClientToLogin : null,

}

const actions = {
  setUserData ({ commit }, payload) {
    if(payload.token) {
      commit('setUserData', payload.user)
      storage.user.set(payload.user)
      storage.token.set(payload.token.accessToken)
    } else {
      commit('setUserData', payload)
      storage.user.set(payload)
    }

  },
  refreshSession ({ commit }, payload) {
    commit('refreshSession', payload)
  },
  logout({ commit }, payload) {
    commit('setUserData', payload)
    storage.user.remove()
    storage.token.remove()
  },
  setSelectedClientToLogin({ commit }, payload) {
    commit('setSelectedClientToLogin', payload)
  }
}

const mutations = {
  setUserData (state, payload) {
    state.userData = payload
  },
  refreshSession (state, payload) {
    state.refreshedAt = payload
  },
  setSelectedClientToLogin(state, payload) {
    state.selectedClientToLogin = payload
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}