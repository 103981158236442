<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container v-if="client && client.id" fluid grid-list-lg class="district_lookup_page">
    <v-layout wrap>
      <v-flex>
        <div class="d-flex align-center">
          <h1>{{ pageTitle }}</h1>
          <help-modal></help-modal>
          <v-spacer></v-spacer>
          <v-menu offset-y v-if="isMobile">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" icon small dark v-on="on"><v-icon>more_vert</v-icon></v-btn>
            </template>
            <v-card>
              <v-card-text>
                <export-members
                    v-if="isSuperAdmin || isVIP"
                    class="my-1"
                    :params="exportProps"
                    :district="selectedDistrict"
                    :disabled="filteredMemberList.length"
                    :clientId="client"
                ></export-members>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <div>
          <span >
            <span  v-for="(item, index) in selectedDistrict" :key="index">
              <span  v-if="fAddress = newFormalAddress(item)" style="padding-right: 5px;">
                <v-chip>
                  {{ fAddress.address ? item + "-" + fAddress.address : item }} 
                  <a v-if="fAddress.fullname && (!item.startsWith('CD') || isFederalClient)" @click="openLegislatorModal(item)" style="padding-left: 5px;">{{ fAddress.fullname }}</a>
                  {{ isFederalClient ? getStateAbbrevFromDistrictName(item) : '' }}
                </v-chip>
              </span>
            </span>
          </span>
        </div>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex class="d-flex align-center justify-end flex-wrap" v-if="isDesktop || isDevice">
        <v-btn
          v-if="isSuperAdmin && selectedDistrict.length ==1"
          color="primary"
          class="mr-2"
          :block="isMobile"
          small
          @click="onUpdateDistricts"
          :loading="isUpdating"
          :disabled="!(filteredMemberList.length && selectedDistrict)"
        >
          Update Districts
          <v-icon dark class="ml-2">update</v-icon>
        </v-btn>
        <export-members
          v-if="isSuperAdmin || isVIP"
          :block=false
          :params="exportProps"
          :district="selectedDistrict"
          :disabled="!!filteredMemberList.length && !enabledImportExport()"
          :client="client"
        ></export-members>
        <v-menu offset-y max-width="350px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="warning"
              class="ml-2"
              small
              :disabled="!filteredMemberList.length"
              v-bind="attrs"
              v-on="on"
            >
              Send Each a Message
                  <v-icon
                    right
                    dark
                  >
                    mail_outline
                  </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              link
              three-line
            >
              <v-list-item-content @click="$refs.blankEmailModal.toggle('blank', null)">
                <v-list-item-title>Blank Message</v-list-item-title>
                <v-list-item-subtitle>Admins only - send any message to district constituents</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                v-if="districtLegislator && selectedDistrict && selectedDistrict.length == 1"
                v-for="(message, index) in messageTemplates"
                :key="index"
                link
                three-line
            >
              <v-list-item-content @click="$refs.blankEmailModal.toggle('', message)">
                <v-list-item-title v-html="message.name"></v-list-item-title>
                <v-list-item-subtitle v-html="message.description"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 sm6 class="d-flex align-center flex-wrap">
        <autocomplete-group
          v-if="isFederalClient && (client && (isSuperAdmin || isVIP || isCoordinator))"
          :districtList="stateGroupWiseDistrictList" 
          v-model="selectedDistrict"
          @onDistrictIdChanges="onDistrictIdChangesHandler"
        ></autocomplete-group>
        <v-autocomplete
          label="District"
          multiple
          hide-details
          outlined
          dense
          class="district-selector"
          :item-text="(district) => {
            if(this.isFederalClient && district.state && district.state.state_abbrev){
              return district.district + ` (${district.state.state_abbrev.toUpperCase()})`
            }
            return district.district;
          }"
          item-value="district"
          :clearable=true
          :items="districtList"
          v-model="selectedDistrict"
          @change="refreshMemberList"
          @click:clear="clearDistrict"
          :loading="isDistrictsLoading"
          v-if="!isFederalClient && (client && (isSuperAdmin || isVIP || isCoordinator))"
        ></v-autocomplete>
      </v-flex>
      <v-flex xs12 sm6 v-if="isMobile">
        <v-flex class="d-flex align-center flex-wrap pa-0">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="warning"
                block
                small
                :disabled="!filteredMemberList.length"
                v-bind="attrs"
                v-on="on"
              >
                Messages
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  link
                  three-line
              >
                <v-list-item-content @click="$refs.blankEmailModal.toggle('blank', null)">
                  <v-list-item-title>Blank Message</v-list-item-title>
                  <v-list-item-subtitle>Admins only - send any message to district constituents</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                  v-if="districtLegislator && selectedDistrict && selectedDistrict.length == 1"
                  v-for="(message, index) in messageTemplates"
                  :key="index"
                  link
                  three-line
              >
                <v-list-item-content @click="$refs.blankEmailModal.toggle('', message)">
                  <v-list-item-title v-html="message.name"></v-list-item-title>
                  <v-list-item-subtitle v-html="message.description"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-flex>
        <div class="district_lookup_filters_list pt-3" v-if="checkSelectedFilters">
          <div
            v-for="(filter, index) in checkSelectedFilters"
            :key="index"
            class="district_lookup_filters_list_item"
          >
            <v-chip
              v-if="filter.type == 'checkbox'"
              v-for="(data, i) in filter.value"
              :key="i"
              class="ma-2"
              small
              close
              color="primary"
              outlined
              @click:close="removeFilterItem(filter, data)"
            >
              {{ data }}
            </v-chip>
            <v-chip
              v-if="filter.type =='text' || filter.type == 'toggle'"
              class="ma-2"
              small
              close
              color="primary"
              outlined
              @click:close="removeFilterItem(filter)"
            >
              <span v-if="filter.type == 'text'">{{ filter.value }}</span>
              <span v-else>{{ filter.name }}</span>
            </v-chip>
            <v-chip
              v-if="filter.type == 'dropdown'"
              v-for="(data, j) in filter.value"
              :key="j"
              class="ma-2"
              small
              close
              color="primary"
              outlined
              @click:close="removeFilterItem(filter, data)"
            >
              {{ data }}
            </v-chip>
          </div>
        </div>
      </v-flex>
      <v-flex xs12 sm6 class="d-flex align-end justify-end filter_container">
        <v-spacer></v-spacer>
        <v-text-field
          class="search-input mr-2"
          :disabled="!selectedDistrict"
          append-icon="search"
          label="Search"
          single-line
          clearable
          hide-details
          outlined
          dense
          v-model="search"
          @input="onSearchInputHandler"
        ></v-text-field>
        <div class="legislators_filter__additional--filter" :class="{ 'w-100': isMobile }">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            left
            offset-y
            min-width="300"
            max-width="300"
          >
            <template v-slot:activator="{ on }" v-if="(isVIP || isSuperAdmin)">
              <v-btn :disabled="!selectedDistrict" small rounded :outlined="isDesktop || isDevice" color="primary" v-on="on">
                <span>Filters</span>
                <v-icon>filter_list</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text class="members_filter-overflow">
                <div class="legislators_filter__additional--item_wrap">
                  <h4>Filters:</h4>
                </div>
                <div class="mb-4">
                  <h4>KP Preference</h4>
                </div>
                <div v-for="item in kpPreference">
                  <v-checkbox
                    color="primary"
                    hide-details
                    class="ma-0"
                    :label="item.title"
                    :value="kpPreferenceResults.indexOf(item.value) >= 0"
                    @change="editKpPreferenceResults($event, item.value)"
                  ></v-checkbox>
                </div>
                <br />
                <div
                  v-if="filter.customFields && filter.customFields.length"
                  v-for="(item, index) in filter.customFields"
                  :key="index"
                  class="legislators_filter__additional--item_wrap"
                >
                  <div v-if="item.type == 'text'" class="mb-4">
                    <h4>{{ item.name }}</h4>
                    <v-text-field v-model="item.value" label="Search" hide-details></v-text-field>
                  </div>
                  <div v-if="item.type == 'checkbox' && item.metadata && item.metadata.length">
                    <h4>{{ item.name }}</h4>
                    <v-radio-group
                      v-for="(data, j) in item.metadata"
                      :key="j"
                      v-model="data.type"
                      :label="data.name"
                    >
                      <v-radio
                        color="primary"
                        v-for="(label, key) in checkboxLabels"
                        :key="key"
                        :label="label.name"
                        :value="label.value"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                  <div v-if="item.type == 'toggle'">
                    <h4>{{ item.name }}</h4>
                    <v-checkbox
                      color="primary"
                      :label="item.name"
                      class="mt-0"
                      v-model="item.value"
                    ></v-checkbox>
                  </div>
                  <div v-if="item.type == 'dropdown' && item.metadata && item.metadata.length">
                    <h4 class="my-2">{{ item.name }}</h4>
                    <v-checkbox
                      color="primary"
                      v-for="(data, j) in item.metadata"
                      :key="j"
                      :label="data.name"
                      class="mt-0"
                      v-model="data.checked"
                    >
                    </v-checkbox>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn color="error" text @click="clearFilter">Clear</v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="secondary" @click="menu = false">Cancel</v-btn>
                <v-btn color="primary" text @click="doFilter">Apply</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
      </v-flex>
    </v-layout>

    <v-data-table
        v-if="selectedDistrict"
        :loading="isLoading"
        class="elevation-1 table_default__mobile"
        :options="pagination"
        :headers="memberTableHeader"
        :items="memberList"
        :custom-filter="customFilter"
        :search="search"
        :mobile-breakpoint="0"
        :footer-props="footerProps"
        :server-items-length="pagination.total"
        @update:options="onUpdatePaginationHandler"
    >
      <template v-slot:item.lastname="{ item }">
        <div class="d-flex align-center" :class="{'inactive': !item.active}">
          <a class="mr-1 no-line-break" @click="openMemberDetailModal(item)">
            {{ item.nickname }} {{ item.lastname }}
          </a>
          <sup v-if="item.is_kp1 && item.activeKP1">KP1</sup>
          <v-tooltip bottom v-if="item.kp_preference !== 'unknown'">
            <template v-slot:activator="{ on }">
              <v-avatar v-on="on" :color="kpPreferenceColor(item)" size="10"></v-avatar>
            </template>
            <span>KP Preference: {{ kpPreferenceTitle(item) }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.political_leaning_id !== null">
            <template v-slot:activator="{ on }">
              <v-avatar class="mx-1" v-on="on" :color="politicalLearningsColor(item)" size="10"></v-avatar>
            </template>
            <span>Political Leaning: {{ politicalLearningsTitle(item) }}</span>
          </v-tooltip>
          <div v-if="item.silence">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-icon
                    color="#cc38b8"
                    size="18"
                    class="mx-1"
                  >
                    mdi-comment-remove-outline
                  </v-icon>
                </div>
              </template>
              <span>Silenced</span>
            </v-tooltip>
          </div>
          <div v-else>
            <v-tooltip bottom v-if="!!item.cell">
              <template v-slot:activator="{ on }">
                <a :href="'tel:' + item.cell" v-on="on">
                  <v-icon
                    v-if="item.bad_cell"
                    color="error"
                    size="18"
                    class="mx-1"
                  >
                    no_cell
                  </v-icon>
                  <v-icon
                    v-else
                    color="primary"
                    size="18"
                    class="mx-1"
                  >
                    phone_iphone
                  </v-icon>
                </a>
              </template>
              <span>{{ item.cell }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!!item.email">
              <template v-slot:activator="{ on }">
                <a :href="'mailto:' + item.email" v-on="on">
                  <v-icon :color="isBad(item.email) ? 'error' : 'primary'" size="18" class="mx-1">email
                  </v-icon>
                </a>
              </template>
              <span>{{ item.email }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!!item.notes" max-width="450">
              <template v-slot:activator="{ on }">
                <v-icon size="18" color="primary" class="mx-1" v-on="on">note</v-icon>
              </template>
              <span>{{ item.notes }}</span>
            </v-tooltip>
            <v-icon size="18" color="primary" v-if="item.has_reply" class="mx-1">forum</v-icon>
          </div>
        </div>
        <!-- New student chip for advocates with member_type "student" -->
        <v-chip
          v-if="isMemberStudent(item)"
          outlined
          color="warning"
          x-small
        >
          student
        </v-chip>
      </template>

      <template v-slot:item.eligible="{ item }" v-if="isSuperAdmin || isVIP || isCoordinator">
        <div :class="{ 'inactive': !item.active }">
          <v-icon color="success" v-if="item.eligible">check_circle</v-icon>
          <v-icon color="error" v-else>cancel</v-icon>
        </div>
      </template>

      <<!-- Home House District -->
      <template v-slot:item.home_house_district="{ item }" v-if="isHouseDistrictsRequired">
        <div class="d-flex flex-column">
          <template v-if="isFederalClient">
            <template v-if="item.home_con_districts_with_state && item.home_con_districts_with_state[0] !== null">
              <div
                v-for="dist in item.home_con_districts_with_state"
                :key="dist.district"
                :class="{ 'matched': selectedDistrictIds.includes(dist.id) }"
              >
                {{ dist.district }}{{ dist && dist.state && dist.state.state_abbrev ? ` (${dist.state.state_abbrev.toUpperCase()})` : '' }}
              </div>
            </template>
            <v-tooltip
              bottom
              v-if="item.home_address && (!item.home_con_districts_with_state || item.home_con_districts_with_state[0] === null || typeof item.home_con_districts_with_state[0] === 'undefined')"
            >
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">cancel</v-icon>
              </template>
              <span>Unknown address or outside {{ selectedClient.state.state }}</span>
            </v-tooltip>
          </template>
          <template v-else>
            <div
              v-for="dist in item.home_house_district"
              :key="dist"
              :class="{ 'inactive': !item.active, 'matched': selectedDistrict.includes(dist) }"
            >
              {{ dist }}
            </div>
            <v-tooltip
              bottom
              v-if="item.home_address && (item.home_house_district[0] === null || typeof item.home_house_district[0] === 'undefined')"
            >
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">cancel</v-icon>
              </template>
              <span>Unknown address or outside {{ client.state.state }}</span>
            </v-tooltip>
          </template>
        </div>
      </template>

      <!-- Home Senate District -->
      <template v-slot:item.home_sen_district="{ item }">
        <div class="d-flex flex-column">
          <template v-if="isFederalClient">
            <template v-if="item.federal_home_sen_districts && item.federal_home_sen_districts[0] !== null">
              <div
                v-for="dist in item.federal_home_sen_districts"
                :key="dist.district"
                :class="{ 'matched': selectedDistrictIds.includes(dist.id) }"
              >
              {{ dist.district }}
              </div>
            </template>
            <div v-if="item.home_con_districts_with_state[0] !== null && (item.federal_home_sen_districts[0] === null || typeof item.federal_home_sen_districts[0] == 'undefined')">
              --
            </div>
            <v-tooltip
              bottom
              v-else-if="item.home_address && (!item.home_con_districts_with_state || item.home_con_districts_with_state[0] === null || typeof item.home_con_districts_with_state[0] === 'undefined')"
            >
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">cancel</v-icon>
              </template>
              <span>Unknown address or outside {{ selectedClient.state.state }}</span>
            </v-tooltip>
          </template>
          <template v-else>
            <div
              v-for="dist in item.home_sen_district"
              :key="dist"
              :class="{ 'inactive': !item.active, 'matched': selectedDistrict.includes(dist) }"
            >
              {{ dist }}
            </div>
            <v-tooltip
              bottom
              v-if="item.home_address && (item.home_sen_district[0] === null || typeof item.home_sen_district[0] === 'undefined')"
            >
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">cancel</v-icon>
              </template>
              <span>Unknown address or outside {{ client.state.state }}</span>
            </v-tooltip>
          </template>
        </div>
      </template>

      <!-- Work House District -->
      <template v-slot:item.work_house_district="{ item }" v-if="isHouseDistrictsRequired">
        <div class="d-flex flex-column">
          <template v-if="isFederalClient">
            <template v-if="item.work_con_districts_with_state && item.work_con_districts_with_state[0] !== null">
              <div
                v-for="dist in item.work_con_districts_with_state"
                :key="dist.district"
                :class="{ 'matched': selectedDistrictIds.includes(dist.id) }"
              >
                {{ dist.district }} {{ dist && dist.state && dist.state.state_abbrev ? ` (${dist.state.state_abbrev.toUpperCase()})` : '' }}
              </div>
            </template>
            <v-tooltip
              bottom
              v-if="item.work_address && (!item.work_con_districts_with_state || item.work_con_districts_with_state[0] === null || typeof item.work_con_districts_with_state[0] === 'undefined')"
            >
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">cancel</v-icon>
              </template>
              <span>Unknown address or outside {{ selectedClient.state.state }}</span>
            </v-tooltip>
          </template>
          <template v-else>
            <div
              v-for="dist in item.work_house_district"
              :key="dist"
              :class="{ 'inactive': !item.active, 'matched': selectedDistrict.includes(dist) }"
            >
              {{ dist }}
            </div>
            <v-tooltip
              bottom
              v-if="item.work_address && (item.work_house_district[0] === null || typeof item.work_house_district[0] === 'undefined')"
            >
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">cancel</v-icon>
              </template>
              <span>Unknown address or outside {{ client.state.state }}</span>
            </v-tooltip>
          </template>
        </div>
      </template>

      <!-- Work Senate District -->
      <template v-slot:item.work_sen_district="{ item }">
        <div class="d-flex flex-column">
          <template v-if="isFederalClient">
            <template v-if="item.federal_work_sen_districts && item.federal_work_sen_districts[0] !== null">
              <div
                v-for="dist in item.federal_work_sen_districts"
                :key="dist.district"
                :class="{ 'matched': selectedDistrictIds.includes(dist.id) }"
              >
                {{ dist.district }}
              </div>
            </template>
            <div v-if="item.work_con_districts_with_state[0] !== null && (item.federal_work_sen_districts[0] === null || typeof item.federal_work_sen_districts[0] == 'undefined')">
              --
            </div>
            <v-tooltip
              bottom
              v-else-if="item.work_address && (!item.work_con_districts_with_state || item.work_con_districts_with_state[0] === null || typeof item.work_con_districts_with_state[0] === 'undefined')"
            >
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">cancel</v-icon>
              </template>
              <span>Unknown address or outside {{ selectedClient.state.state }}</span>
            </v-tooltip>
          </template>
          <template v-else>
            <div
              v-for="dist in item.work_sen_district"
              :key="dist"
              :class="{ 'inactive': !item.active, 'matched': selectedDistrict.includes(dist) }"
            >
              {{ dist }}
            </div>
            <v-tooltip
              bottom
              v-if="item.work_address && (item.work_sen_district[0] === null || typeof item.work_sen_district[0] === 'undefined')"
            >
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">cancel</v-icon>
              </template>
              <span>Unknown address or outside {{ client.state.state }}</span>
            </v-tooltip>
          </template>
        </div>
      </template>

      <!-- Work2 House District -->
      <template v-slot:item.work2_house_district="{ item }" v-if="isHouseDistrictsRequired && is2ndWorkDistrictRequired">
        <div class="d-flex flex-column">
          <template v-if="isFederalClient">
            <template v-if="item.work2_con_districts_with_state && item.work2_con_districts_with_state[0] !== null">
              <div
                v-for="dist in item.work2_con_districts_with_state"
                :key="dist.district"
                :class="{ 'matched': selectedDistrictIds.includes(dist.id) }"
              >
                {{ dist.district }}{{ dist && dist.state && dist.state.state_abbrev ? ` (${dist.state.state_abbrev.toUpperCase()})` : '' }}
              </div>
            </template>
            <v-tooltip
              bottom
              v-if="item.work2_address && (!item.work2_con_districts_with_state || item.work2_con_districts_with_state[0] === null || typeof item.work2_con_districts_with_state[0] === 'undefined')"
            >
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">cancel</v-icon>
              </template>
              <span>Unknown address or outside {{ selectedClient.state.state }}</span>
            </v-tooltip>
          </template>
          <template v-else>
            <div
              v-for="dist in item.work2_house_district"
              :key="dist"
              :class="{ 'inactive': !item.active, 'matched': selectedDistrict.includes(dist) }"
            >
              {{ dist }}
            </div>
            <v-tooltip
              bottom
              v-if="item.work2_address && (item.work2_house_district[0] === null || typeof item.work2_house_district[0] === 'undefined')"
            >
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">cancel</v-icon>
              </template>
              <span>Unknown address or outside {{ client.state.state }}</span>
            </v-tooltip>
          </template>
        </div>
      </template>

      <!-- Work2 Senate District -->
      <template v-slot:item.work2_sen_district="{ item }" v-if="is2ndWorkDistrictRequired">
        <div class="d-flex flex-column">
          <template v-if="isFederalClient">
            <template v-if="item.federal_work2_sen_districts && item.federal_work2_sen_districts[0] !== null">
              <div
                v-for="dist in item.federal_work2_sen_districts"
                :key="dist.district"
                :class="{ 'matched': selectedDistrictIds.includes(dist.id) }"
              >
                {{ dist.district }}
              </div>
            </template>
            <div v-if="item.work2_con_districts_with_state[0] !== null && (item.federal_work2_sen_districts[0] === null || typeof item.federal_work2_sen_districts[0] == 'undefined')">
              --
            </div>
            <v-tooltip
              bottom
              v-else-if="item.work2_address && (!item.work2_con_districts_with_state || item.work2_con_districts_with_state[0] === null || typeof item.work2_con_districts_with_state[0] === 'undefined')"
            >
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">cancel</v-icon>
              </template>
              <span>Unknown address or outside {{ selectedClient.state.state }}</span>
            </v-tooltip>
          </template>
          <template v-else>
            <div
              v-for="dist in item.work2_sen_district"
              :key="dist"
              :class="{ 'inactive': !item.active, 'matched': selectedDistrict.includes(dist) }"
            >
              {{ dist }}
            </div>
            <v-tooltip
              bottom
              v-if="item.work2_address && (item.work2_sen_district[0] === null || typeof item.work2_sen_district[0] === 'undefined')"
            >
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">cancel</v-icon>
              </template>
              <span>Unknown address or outside {{ client.state.state }}</span>
            </v-tooltip>
          </template>
        </div>
      </template>

      <!-- Work3 House District -->
      <template v-slot:item.work3_house_district="{ item }" v-if="isHouseDistrictsRequired && is3rdWorkDistrictRequired">
        <div class="d-flex flex-column">
          <template v-if="isFederalClient">
            <template v-if="item.work3_con_districts_with_state && item.work3_con_districts_with_state[0] !== null">
              <div
                v-for="dist in item.work3_con_districts_with_state"
                :key="dist.district"
                :class="{ 'matched': selectedDistrictIds.includes(dist.id) }"
              >
                {{ dist.district }}{{ dist && dist.state && dist.state.state_abbrev ? ` (${dist.state.state_abbrev.toUpperCase()})` : '' }}
              </div>
            </template>
            <v-tooltip
              bottom
              v-if="item.work3_address && (!item.work3_con_districts_with_state || item.work3_con_districts_with_state[0] === null || typeof item.work3_con_districts_with_state[0] === 'undefined')"
            >
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">cancel</v-icon>
              </template>
              <span>Unknown address or outside {{ selectedClient.state.state }}</span>
            </v-tooltip>
          </template>
          <template v-else>
            <div
              v-for="dist in item.work3_house_district"
              :key="dist"
              :class="{ 'inactive': !item.active, 'matched': selectedDistrict.includes(dist) }"
            >
              {{ dist }}
            </div>
            <v-tooltip
              bottom
              v-if="item.work3_address && (item.work3_house_district[0] === null || typeof item.work3_house_district[0] === 'undefined')"
            >
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">cancel</v-icon>
              </template>
              <span>Unknown address or outside {{ client.state.state }}</span>
            </v-tooltip>
          </template>
        </div>
      </template>

      <!-- Work3 Senate District -->
      <template v-slot:item.work3_sen_district="{ item }" v-if="is3rdWorkDistrictRequired">
        <div class="d-flex flex-column">
          <template v-if="isFederalClient">
            <template v-if="item.federal_work3_sen_districts && item.federal_work3_sen_districts[0] !== null">
              <div
                v-for="dist in item.federal_work3_sen_districts"
                :key="dist.district"
                :class="{ 'matched': selectedDistrictIds.includes(dist.id) }"
              >
                {{ dist.district }}
              </div>
            </template>
            <div v-if="item.work3_con_districts_with_state[0] !== null && (item.federal_work3_sen_districts[0] === null || typeof item.federal_work3_sen_districts[0] == 'undefined')">
              --
            </div>
            <v-tooltip
              bottom
              v-else-if="item.work3_address && (!item.work3_con_districts_with_state || item.work3_con_districts_with_state[0] === null || typeof item.work3_con_districts_with_state[0] === 'undefined')"
            >
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">cancel</v-icon>
              </template>
              <span>Unknown address or outside {{ selectedClient.state.state }}</span>
            </v-tooltip>
          </template>
          <template v-else>
            <div
              v-for="dist in item.work3_sen_district"
              :key="dist"
              :class="{ 'inactive': !item.active, 'matched': selectedDistrict.includes(dist) }"
            >
              {{ dist }}
            </div>
            <v-tooltip
              bottom
              v-if="item.work3_address && (item.work3_sen_district[0] === null || typeof item.work3_sen_district[0] === 'undefined')"
            >
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">cancel</v-icon>
              </template>
              <span>Unknown address or outside {{ client.state.state }}</span>
            </v-tooltip>
          </template>
        </div>
      </template>

      <!-- Work4 House District -->
      <template v-slot:item.work4_house_district="{ item }" v-if="isHouseDistrictsRequired && is4thWorkDistrictRequired">
        <div class="d-flex flex-column">
          <template v-if="isFederalClient">
            <template v-if="item.work4_con_districts_with_state && item.work4_con_districts_with_state[0] !== null">
              <div
                v-for="dist in item.work4_con_districts_with_state"
                :key="dist.district"
                :class="{ 'matched': selectedDistrictIds.includes(dist.id) }"
              >
                {{ dist.district }}{{ dist && dist.state && dist.state.state_abbrev ? ` (${dist.state.state_abbrev.toUpperCase()})` : '' }}
              </div>
            </template>
            <v-tooltip
              bottom
              v-if="item.work4_address && (!item.work4_con_districts_with_state || item.work4_con_districts_with_state[0] === null || typeof item.work4_con_districts_with_state[0] === 'undefined')"
            >
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">cancel</v-icon>
              </template>
              <span>Unknown address or outside {{ selectedClient.state.state }}</span>
            </v-tooltip>
          </template>
          <template v-else>
            <div
              v-for="dist in item.work4_house_district"
              :key="dist"
              :class="{ 'inactive': !item.active, 'matched': selectedDistrict.includes(dist) }"
            >
              {{ dist }}
            </div>
            <v-tooltip
              bottom
              v-if="item.work4_address && (item.work4_house_district[0] === null || typeof item.work4_house_district[0] === 'undefined')"
            >
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">cancel</v-icon>
              </template>
              <span>Unknown address or outside {{ client.state.state }}</span>
            </v-tooltip>
          </template>
        </div>
      </template>

      <!-- Work4 Senate District -->
      <template v-slot:item.work4_sen_district="{ item }" v-if="is4thWorkDistrictRequired">
        <div class="d-flex flex-column">
          <template v-if="isFederalClient">
            <template v-if="item.federal_work4_sen_districts && item.federal_work4_sen_districts[0] !== null">
              <div
                v-for="dist in item.federal_work4_sen_districts"
                :key="dist.district"
                :class="{ 'matched': selectedDistrictIds.includes(dist.id) }"
              >
                {{ dist.district }}
              </div>
            </template>
            <div v-if="item.work4_con_districts_with_state[0] !== null && (item.federal_work4_sen_districts[0] === null || typeof item.federal_work4_sen_districts[0] == 'undefined')">
              --
            </div>
            <v-tooltip
              bottom
              v-else-if="item.work4_address && (!item.work4_con_districts_with_state || item.work4_con_districts_with_state[0] === null || typeof item.work4_con_districts_with_state[0] === 'undefined')"
            >
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">cancel</v-icon>
              </template>
              <span>Unknown address or outside {{ selectedClient.state.state }}</span>
            </v-tooltip>
          </template>
          <template v-else>
            <div
              v-for="dist in item.work4_sen_district"
              :key="dist"
              :class="{ 'inactive': !item.active, 'matched': selectedDistrict.includes(dist) }"
            >
              {{ dist }}
            </div>
            <v-tooltip
              bottom
              v-if="item.work4_address && (item.work4_sen_district[0] === null || typeof item.work4_sen_district[0] === 'undefined')"
            >
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">cancel</v-icon>
              </template>
              <span>Unknown address or outside {{ client.state.state }}</span>
            </v-tooltip>
          </template>
        </div>
      </template>

      <template v-slot:no-data>
        <p class="text-xs-center mb-0">No member filtered.</p>
      </template>
    </v-data-table>

    <member-detail-modal
        ref="memberDetailModal"
        :selected-client="client"
    ></member-detail-modal>
    <legislator-detail-modal
        ref="legislatorDetailModal"
        :refresh="init"
    ></legislator-detail-modal>
    <blank-email-modal
      v-if="selectedDistrict"
      ref="blankEmailModal"
      :selectedClient="client"
      :selectedDistrict="selectedDistrict[0]"
      :districtLegislator="districtLegislator[0]"
      :memberList="filteredMemberListByPossibleKP"
      :formalAddress="newFormalAddress(selectedDistrict[0])"
    ></blank-email-modal>
  </v-container>
</template>

<script>
  import ClientService from '@/services/ClientService'
  import MemberService from '@/services/MemberService'

  import help from '@/components/help-modals/district-lookup-page-help-modal'
  import MemberDetailModal from '@/components/members/member-detail-modal.vue'
  import legislatorDetailModal from '@/components/legislators/legislator-detail-modal'
  import exportMembers from '@/components/common/export-button'
  import blankEmailModal from '@/components/district-lookup/blank-email-modal'

  import CustomFieldsFilter from '@/mixins/custom-fields-filter'
  import swal from "sweetalert2";
  import {mapGetters} from "vuex";
  import CustomMessageService from "@/services/CustomMessageService";
  import politicalLeaningsBorder from "@/mixins/politicalLeaningsBorder";
  import debounce from 'lodash/debounce'
  import autocompleteGroup from '@/components/legislators/autocomplete-group.vue'
import { startsWith } from 'lodash'

  export default {
    name: 'DistrictLookupPage',
    mixins: [ClientService, MemberService, CustomFieldsFilter, CustomMessageService, politicalLeaningsBorder],
    components: {
      'help-modal': help,
      'member-detail-modal': MemberDetailModal,
      'legislator-detail-modal': legislatorDetailModal,
      'export-members': exportMembers,
      'blank-email-modal': blankEmailModal,
      'autocomplete-group': autocompleteGroup
    },
    data() {
      return {
        menu: false,
        isLoading: false,
        isDistrictsLoading: false,
        search: '',
        selectedDistrict: [],
        districtLegislator: [],
        kpPreferenceResults: [],
        politicalLearnings: [],
        messageTemplates: [],
        isUpdating: false,
        districtList: [],
        memberList: [],
        exportProps: {},
        checkSelectedFilters: [],
        filter: {
          search: '',
          customFields: []
        },
        pagination: {
          total: 0,
          page: 1,
          itemsPerPage: 25,
        },
        footerProps: {
          'items-per-page-options': [25, 50, 100],
        },
        checkboxLabels: [
          { value: 'default', name: 'All' },
          { value: 1, name: 'Yes' },
          { value: 0, name: 'No' },
        ],
        selectedDistrictIds: []
      }
    },
    computed: {
      ...mapGetters('app', ['superAdminSelectedClient']),
      client() {
        return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
      },
      selectedClient(){
        return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
      },
      isFederalClient(){
        return this.client && this.client.is_use_state === 0;
      },
      stateGroupWiseDistrictList(){
        return this.groupDistrictListByStateAbbreviation(this.districtList);
      },
      pageTitle() {
        if (!this.selectedDistrict || !this.client) return 'District Lookup'
        return `${this.client.assoc_abbrev} Constituents of District`
      },
      isHouseDistrictsRequired() {
        return this.client && this.client.state && this.client.state.house_districts
      },
      is2ndWorkDistrictRequired() {
        return this.client && this.client.uses_2nd_work
      },
      is3rdWorkDistrictRequired() {
        return this.client && this.client.uses_3rd_work
      },
      is4thWorkDistrictRequired() {
        return this.client && this.client.uses_4th_work
      },
      memberTableHeader() {
        let tableHeader = [
          {text: 'Member', value: 'lastname', align: 'left'},
          {text: 'KP Eligible?', value: 'eligible', align: 'center'},
          {text: 'HHD', value: 'home_house_district', align: 'center'},
          {text: 'HSD', value: 'home_sen_district', align: 'center'},
          {text: 'WHD', value: 'work_house_district', align: 'center'},
          {text: 'WSD', value: 'work_sen_district', align: 'center'},
          {text: 'W2HD', value: 'work2_house_district', align: 'center'},
          {text: 'W2SD', value: 'work2_sen_district', align: 'center'},
          {text: 'W3HD', value: 'work3_house_district', align: 'center'},
          {text: 'W3SD', value: 'work3_sen_district', align: 'center'},
          {text: 'W4HD', value: 'work4_house_district', align: 'center'},
          {text: 'W4SD', value: 'work4_sen_district', align: 'center'},
        ]
        let hiddenColumns = []
        if (this.isKP) {
          hiddenColumns.push('KP Eligible?')
        }
        if (!this.isHouseDistrictsRequired) {
          hiddenColumns.push('HHD', 'WHD', 'W2HD', 'W3HD', 'W4HD')
        }
        if (!this.is2ndWorkDistrictRequired) {
          hiddenColumns.push('W2HD', 'W2SD')
        }
        if (!this.is3rdWorkDistrictRequired) {
          hiddenColumns.push('W3HD', 'W3SD')
        }
        if (!this.is4thWorkDistrictRequired) {
          hiddenColumns.push('W4HD', 'W4SD')
        }
        tableHeader = tableHeader.filter(header => !hiddenColumns.includes(header.text))
        return tableHeader
      },
      filteredMemberList() {
        return this.memberList.filter(member => member.active)
      },
      eligibleMemberList() {
        return this.filteredMemberList.filter(member => !!member.eligible)
      },
      filteredMemberListByPossibleKP() {
        return  this.memberList
        .filter(member => member.active)
          .map(member => {
            if (!member.eligible || (member.member_legislator && member.member_legislator.some(memberLegislator => memberLegislator.kp_position == 'rules out'))) {
              member.possible_kp = 2
            } else if (member.member_legislator && member.member_legislator.some(memberLegislator => memberLegislator.kp_position == 'short list')) {
              member.possible_kp = 0
            } else {
              member.possible_kp = 1
            }
            return member
          }).filter(member => member.possible_kp == 0 || member.possible_kp == 1);

      },
    },
    mounted() {
      if (!(this.client && this.client.id)) return
      this.getPoliticalLeanings()
          .then(response => {
            this.politicalLearnings = response.data
          })
      this.init()
    },
    methods: {
      enabledImportExport() {
        return !(this.isSuperAdmin || (this.userData && this.userData.allow_import_export));
      },
      isMemberStudent(memberItem) {
        let isStudent = false;
        const customFields = memberItem && memberItem.custom_fields || [];

        if (customFields.length) {
          const memberTypeField = customFields.find(field => field.name === "Member Type");

          if (memberTypeField && memberTypeField.pivot && memberTypeField.pivot.metadata) {
            try {
              const metadata = JSON.parse(memberTypeField.pivot.metadata);
              if (metadata.name === "STUDENT") {
                isStudent = true;
              }
            } catch (error) {
              console.error("Error parsing metadata:", error);
            }
          }
        }

        return isStudent;
      },
      onDistrictIdChangesHandler(ids){
        this.selectedDistrictIds = ids
        this.$nextTick(() => {
          this.refreshMemberList()
        })
      },
      groupDistrictListByStateAbbreviation(inputDistrictList) {
          // Group by state abbreviation
          const groupedByState = inputDistrictList.reduce((acc, item) => {
              const stateAbbrev = item.state.state_abbrev;

              // Check if the group for the state already exists
              if (!acc[stateAbbrev]) {
                  acc[stateAbbrev] = [];
              }
              
              // Add item to the relevant group
              acc[stateAbbrev].push(item);

              return acc;
          }, {});

          // Format the output as expected
          const expectedList = Object.keys(groupedByState).map(stateAbbrev => ({
              group: stateAbbrev,
              children: groupedByState[stateAbbrev]
          }))

          // order by group
          expectedList.sort((a, b) => a.group.localeCompare(b.group))

        return expectedList;
      },
      getStateAbbrevFromDistrictName(districtName) {
        if (this.isFederalClient) {
          return this.selectedClient && this.selectedClient.state && this.selectedClient.state.state_abbrev
            ? `(${this.selectedClient.state.state_abbrev.toUpperCase()})`
            : '';
        }
        const district = this.districtList.find(d => d.district === districtName);
        return district && district.state && district.state.state_abbrev
          ? `(${district.state.state_abbrev.toUpperCase()})`
          : '';
      },
      onSearchInputHandler(value) {
        const debouncedRefreshMemberList = debounce(() => {
          this.refreshMemberList()
        }, 1000)
        debouncedRefreshMemberList()
      },
      onUpdatePaginationHandler(options) {
        const isNotSamePageOrItemsPerPage = this.pagination.page !== options.page || this.pagination.itemsPerPage !== options.itemsPerPage

        this.pagination.itemsPerPage = options.itemsPerPage
        this.pagination.page = options.page
        
        if (isNotSamePageOrItemsPerPage) {
          this.refreshMemberList()
        }
      },
      resetMemberList() {
        this.memberList = []
      },
      resetPagination() {
        this.pagination.total = 0
        this.pagination.page = 1
        this.pagination.itemsPerPage = 25
      },
      newFormalAddress(dis) {
        if (!dis) return null
        dis = dis.split(' ')[0];
        let formalAddress = ''
        if (dis[0] === 'H') {
          formalAddress = `${this.client.formal_house} `
        } else if (dis[0] === 'S') {
          formalAddress = `${this.client.formal_senate} `
        }
        let legislator = this.districtLegislator.find(leg => leg.district.district === dis)

        return {
          address: formalAddress,
          fullname: legislator ? legislator.fullname : '',
          legislator: legislator
        }
      },
      async refreshDistrictList() {
        this.isDistrictsLoading = true
        this.isLoading = true
        this.resetMemberList()
        this.isLoading = false
        this.districtList = (await this.getClientDistricts(this.client.id, true, true)).data
          .filter(district => {
            if(this.isFederalClient){
             return district; 
            }
            return /^(H|S|CD)[A-Za-z0-9_]+[\w-]*$/.test(district.district)
          })
        this.isDistrictsLoading = false
      },
      clearDistrict() {
        this.selectedDistrict = []
        this.resetMemberList()
        this.districtLegislator = []
        this.messageTemplates= []
      },
      async refreshMemberList() {
        try {
          this.isLoading = true;
          this.resetMemberList()
          this.districtLegislator = [];
          this.messageTemplates= [];

          if (!this.client || !this.selectedDistrict) return;
          
          this.exportProps = {
            custom_fields: this.collectCustomFieldsFilter(),
          };

          const res = (await this.searchMembersByMultiDistrict({
            client_id: this.client.id,
            district: this.selectedDistrict,
            withFederal: true,
            custom_fields: this.collectCustomFieldsFilter(),
            kp_preference: this.kpPreferenceResults,
            page: this.pagination.page,
            per_page: this.pagination.itemsPerPage,
            name: this.search,
            district_ids: this.selectedDistrictIds
          })).data;
          
          this.districtLegislator = res.district_legislator || [];
          await this.getNewMessageTemplates();
          this.memberList = res.member_list.data || [];
          this.pagination.total = res.member_list.total || 0;
          this.pagination.itemsPerPage = res.member_list.per_page || 25;
          this.pagination.page = res.member_list.current_page || 1;
          this.checkSelectedFilters = this.getCheckSelectedFilters();
        } catch (error) {
          console.error('Error refreshing member list:', error)
        }finally {
          this.isLoading = false;
        }
        
      },
      customFilter(value, search, item) {
        search = search.toString().toLowerCase()
        return item.nickname.toLowerCase().includes(search) || item.lastname.toLowerCase().includes(search)
      },
      openMemberDetailModal(item) {
        this.$refs.memberDetailModal.toggle(item)
      },
      openLegislatorModal(item){
        item = item.split(' ')[0];
        const legislator = this.districtLegislator.find(leg => leg.district.district === item);
        this.$refs.legislatorDetailModal.toggle(legislator)
      },
      doFilter() {
        this.menu = false
        this.refreshMemberList()
      },
      editKpPreferenceResults(event, value) {
        if (event) {
          this.kpPreferenceResults.push(value)
        } else {
          const index = this.kpPreferenceResults.indexOf(value)
          if (index >= 0) {
            this.kpPreferenceResults.splice(index,1)
          }
        }
      },
      removeFilterItem (item, key = null) {
        this.filter.customFields.forEach(field => {
          if (field.id == item.id) {
            if (item.type == 'checkbox' || item.type == 'dropdown') {
              field.metadata.forEach( data => {
                if (data.name == key) data.checked = false
              })
            } else if (item.type == 'text') {
              field.value = ''
            } else {
              field.value = false
            }
          }
        })
        this.doFilter()
      },
      clearFilter() {
        this.kpPreferenceResults = []
        this.loadCustomFields(this.client.id).then(() => {
          this.getCheckSelectedFilters()
          this.doFilter()
        })
      },
      onUpdateDistricts() {
        swal({
          title: 'Are you sure?',
          html: `
          This action will overwrite all of the districts for all Advocates from this district that have a valid address.
          For blank addresses, or invalid addresses for which you have already manually set districts, no changes will be made.
          This is useful after an initial import, or if your state has recently redistricted.<br />
          <b>It cannot be undone.</b>
        `,
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, proceed',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.isUpdating = true
            const payload = {
              members: this.memberList
            }
            this.updateMembersDistricts(this.client.id, payload)
              .then(() => {
                this.isUpdating = false
                swal({
                  title: 'Updating Districts in progress',
                  type: 'info',
                  html: `You've started update of districts. Task will run in the background`,
                  confirmButtonColor: '#3085d6',
                })
                this.$modals.get('notifications').openAndLoad()
              })
              .catch((err) => {
                this.isUpdating = false
                this.handleError(err)
              })
          }
        })
      },
      getCheckSelectedFilters () {
        let selectedFilters = []

        this.filter.customFields.forEach(item => {
          if (item.type == 'text' || item.type == 'toggle')
            if (item.value)
              selectedFilters.push({ id: item.id, name: item.name, type: item.type, value: item.value })
          if (item.type == 'checkbox' || item.type == 'dropdown') {
            let value = item.metadata.filter(meta => meta.checked).map(meta => meta.name)
            if (value && value.length)
              selectedFilters.push({ id: item.id, type: item.type, name: item.name, value: value })
          }
        })

        return selectedFilters
      },
      processMessage(message, legislator) {
        let legSalutation = '';
        let dis = legislator.district.district;
        if (dis[0] === 'H') {
          legSalutation = this.client.formal_house + ' ' + legislator.nickname + ' ' + legislator.lastname;
        }
        if (dis[0] === 'S') {
          legSalutation = this.client.formal_senate + ' ' + legislator.nickname + ' ' + legislator.lastname;
        }
        message.name = message.name.replace(/{leg_fullname}/g, `${legislator.nickname} ${legislator.lastname}`)
        message.description = message.description
            .replace(/{leg_fullname}/g, `${legislator.nickname} ${legislator.lastname}`)
            .replace(/{leg_salutation}/g, legSalutation)
    
        return message
      },
      getNewMessageTemplates() {
        const params = {dlu: 'true'}
        
        this.getCustomMessages(this.client.id, params)
            .then((response) => {
              let messages = response.data
              let newMessagesList = [];

              if (this.districtLegislator && this.districtLegislator.length > 0) {
                  let legislator = this.districtLegislator[0];
                  for(let message of messages){
                      let newMessage = {...message};
                      let msg= this.processMessage(newMessage, legislator)
                      newMessagesList.push(msg);
                  }
              }
              this.messageTemplates = newMessagesList
            })
            .catch((err) => {
              this.error = err.response.data.message
            })
      },
      async init() {
        await this.refreshDistrictList()
        await this.refreshMemberList()
      },
    },
    watch: {
      'client': function () {
        this.clearDistrict()
        this.init()
        this.selectedDistrictIds = []
      },
      search: function() {
        this.exportProps.search = this.search
      }
    },
  }
</script>
