<template>
  <div class="member-message-modal">
    <v-form ref="emailForm">
      <v-layout wrap>
      <v-flex xs12 class="px-2">
        <v-text-field
          outlined
          dense
          v-model="email.from"
          label="From*"
          :rules="fromRules"
          @input="$emit('error')"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 v-if="!client.mailgun_email">
        <v-select
          outlined
          dense
          v-model="email.reply_to_email"
          :items="replyToEmailList"
          :rules="replyToEmailRules"
          label="Reply To*"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm8 class="px-2">
        <v-text-field
          outlined
          dense
          v-show="!isPreview"
          v-model="email.subject"
          :rules="subjectRules"
          id="subject"
          label="Subject*"
          xs12
          sm6
          @input="$emit('error')"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          v-show="isPreview"
          :value="formattedSubject"
          label="Subject*"
          readonly
          xs12
          sm6
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm4 class="px-2">
        <v-select
          outlined
          dense
          v-model="subjectVariable"
          :items="variableSubjectList"
          label="Subject Variables"
          class="subject_variable-select"
        >
          <template v-slot:item="data">
            <v-list-item-content  @click="onAddVariableToSubject(data.item)" v-text="data.item"></v-list-item-content>
          </template>
        </v-select>
      </v-flex>
      <v-flex xs12 id="messageBlock">
        <v-layout class="mb-2">
          <v-flex xs12 sm8 class="d-flex align-center px-2">
            <div class="caption grey--text">Message*</div>
            <v-spacer/>
            <v-btn
                v-if="badVariables"
                x-small
                outlined
                color="primary"
                @click="onCheckVariables"
            >
              Show Error
            </v-btn>
            <v-switch
              color="primary"
              class="d-inline-block px-2 pt-0 sms-preview-switch"
              v-model="isPreview"
              :label="isPreview ? 'Edit' : 'Show Preview'"
              :loading="isBusy"
              hide-details
              :disabled="isBusy || (members && !members.length)"
              @change="onPreviewMessage"
            >
            </v-switch>
          </v-flex>
          <v-flex xs12 sm4 class="px-2">
            <v-select
              class="subject_variable-select"
              v-model="bodyVariable"
              :items="variableList"
              label="Body Variables"
              hide-details
              outlined
              dense
            >
              <template v-slot:item="data">
                  <v-list-item-content  @click="onAddVariable(data.item)" v-text="data.item"></v-list-item-content>
              </template>
            </v-select>
          </v-flex>
        </v-layout>
        <p v-if="badVariables" class="red--text mb-0">You have bad variables! Please click on 'Show Error' button and fix them.</p>
        <div class="tiny_editor" v-show="!isPreview">
          <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
          <editor
            v-if="!isBusy"
            v-model="message"
            :api-key="TINY_MCE_API_KEY"
            :plugins="tinyPlugins"
            :toolbar="tinyToolbars"
            :init="tinyInit"
            :disabled="disabledEditor"
            @keyup="inputEmail"
          ></editor>
        </div>
        <div v-show="isPreview">
          <editor
            v-if="!isBusy"
            :value="email.formattedMessage"
            :api-key="TINY_MCE_API_KEY"
            :plugins="tinyPlugins"
            :toolbar="tinyToolbars"
            :init="tinyInit"
            disabled
          ></editor>
        </div>
        <span class="text-counter">
          {{ email.finalMessageLength }}
        </span>
        <div class="d-flex" :class="$vuetify.breakpoint.xs ? 'flex-column pl-2' :
        'align-baseline justify-space-between'">
          <div class="mt-2">
            <v-flex
              v-if="!isFilesInput"
              class="d-flex align-baseline"
              @click="isFilesInput = !isFilesInput"
            >
              <v-icon class="pt-4 icon_files">mdi-paperclip</v-icon>
              <a class="v-label theme--light">Attach files</a>
            </v-flex>
            <v-flex
              v-if="isFilesInput && !isUploadingFiles"
              class="d-flex align-baseline"
              @click="removeAllAttachedFiles"
            >
              <v-icon class="pt-4 icon_files">mdi-paperclip</v-icon>
              <a class="v-label red--text">Cancel</a>
            </v-flex>
            <v-file-input
              v-if="isFilesInput && !isUploadingFiles"
              v-model="email.files"
              ref="fileInput"
              :rules="fileRules"
              :show-size="1000"
              placeholder="Select your files"
              label="Max size of all files is 15MB"
              color="primary"
              class="pt-4"
              :clearable="false"
              outlined
              multiple
              counter
              dense
              @change="filesInputChanged"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip
                  color="primary"
                  dark
                  label
                  small
                  close
                  @click:close="removeAttachedFile(index)"
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
            <div v-if="maxFileSize && isFilesInput">
              <v-alert
                type="info"
                outlined
                text
              >
                To reduce the size of the files, you can use
                <a href="https://shrinkpdf.com/">shrinkpdf.com</a> or
                <a href="https://imagecompressor.com/">imagecompressor.com</a>
              </v-alert>
            </div>
            <v-flex v-if="isUploadingFiles" class="pa-5 d-flex align-center justify-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
              <div><span class="ml-3">Please wait, uploading files...</span></div>
            </v-flex>
            <v-flex v-if="attachedFiles != false" class="pt-5 pb-2">
              <div>
                <span>
                  Attached files: {{ attachedFiles.length + ' files (' + bytesToMb(attachedFilesSize) + ' MB)'}}
                </span>
              </div>
              <v-chip
                v-for="(attachedFile, index) in attachedFiles"
                :key="index"
                class="mr-2 mt-2"
                close
                @click:close="removeAttachedScheduledFile(attachedFile, index)"
              >
                {{`${attachedFile.name} (${bytesToMb(attachedFile.size)} MB)`}}
              </v-chip>
            </v-flex>
          </div>
          <a @click="onOpenTestMessageModal" class="mt-2">
            Send test to {{ !isSms ? userData.email : userData.cell_phone }}
          </a>
        </div>
      </v-flex>
      </v-layout>
    </v-form>
    <TestMessageModal
      ref="TestMessageModal"
      :email="email"
      :isEmail="!isSms"
      :memberId="memberId"
      :message="message"
      onlyMembers
    >
    </TestMessageModal>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import TinyConfig from "@/mixins/tiny-mce-config"
import ShorterUrl from "@/mixins/shorterUrl"

import ClientService from "@/services/ClientService";
import AppService from '@/services/AppService'
import TestMessageModal from '@/components/global/test-message-modal'
import he from 'he'

export default {
  name: 'SendEmail',
  mixins: [AppService, ClientService, TinyConfig, ShorterUrl],
  components: {
    'editor': Editor, // Do not change, this is a requirement of tinyMCE
    TestMessageModal
  },
  props: {
    setMessage: {
      type: String,
      default: ''
    },
    email: {
      type: Object,
      default: function () {
        return {}
      }
    },
    members: {
      type: Array,
      default: function () {
        return []
      }
    },
    client: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isUploadingFiles: {
      type: Boolean,
      default: false
    },
    legislatorList: {
      type: Array,
      default: function () {
        return []
      }
    },
    attachedFiles: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      isFilesInput: false,
      isPreview: false,
      isBusy: false,
      attachedFilesSize: 0,
      currFiles: [],
      formattedSubject: '',
      subjectVariable: '',
      bodyVariable: '',
      isChild: true,
    }
  },
  computed: {
    getAttachedFiles: {
      get() {
        return this.attachedFiles
      },
      set(newValue) {
        this.$emit('onAttachedFiles', newValue)
      }
    },
    message: {
      get() {
        return this.setMessage
      },
      set(newValue) {
        this.$emit('getMessage', newValue)
      }
    },
    validationForm() {
      return this.$refs.emailForm.validate()
    },
    fromRules() {
      return [
        v => !!v || 'From name is required.',
      ]
    },
    replyToEmailRules() {
      return [
        v => !!v || 'Reply to email address is required.',
      ]
    },
    subjectRules() {
      return [
        v => !!v || 'Subject is required.',
      ]
    },
    replyToEmailList() {
      const emailList = []
      if (this.client && this.client.reply_to_email) {
        emailList.push(this.client.reply_to_email)
      }
      emailList.push(this.userEmail)
      return emailList
    },
    variableSubjectList() {
      return [
        '{advocate_first}',
        '{advocate_last}',
        '{home_house_leg_nick}',
        '{home_house_leg_last}',
        '{home_house_leg_fullname}',
        '{home_house_leg_salutation}',
        '{home_house_leg_email}',
        '{home_house_leg_ph}',
        '{home_house_leg_local_phone}',
        '{home_senate_leg_nick}',
        '{home_senate_leg_last}',
        '{home_senate_leg_fullname}',
        '{home_senate_leg_salutation}',
        '{home_senate_leg_email}',
        '{home_senate_leg_ph}',
        '{home_senate_leg_local_phone}',
        '{work_house_leg_nick}',
        '{work_house_leg_last}',
        '{work_house_leg_fullname}',
        '{work_house_leg_salutation}',
        '{work_house_leg_email}',
        '{work_house_leg_ph}',
        '{work_house_leg_local_phone}',
        '{work_senate_leg_nick}',
        '{work_senate_leg_last}',
        '{work_senate_leg_fullname}',
        '{work_senate_leg_salutation}',
        '{work_senate_leg_email}',
        '{work_senate_leg_ph}',
        '{work_senate_leg_local_phone}',
      ]
    },
    variableList() {
      return [
        '{advocate_first}',
        '{advocate_last}',
        '{update_profile_link}',
        '{home_house_leg_nick}',
        '{home_house_leg_last}',
        '{home_house_leg_fullname}',
        '{home_house_leg_salutation}',
        '{home_house_leg_email}',
        '{home_house_leg_ph}',
        '{home_house_leg_local_phone}',
        '{home_senate_leg_nick}',
        '{home_senate_leg_last}',
        '{home_senate_leg_fullname}',
        '{home_senate_leg_salutation}',
        '{home_senate_leg_email}',
        '{home_senate_leg_ph}',
        '{home_senate_leg_local_phone}',
        '{work_house_leg_nick}',
        '{work_house_leg_last}',
        '{work_house_leg_fullname}',
        '{work_house_leg_salutation}',
        '{work_house_leg_email}',
        '{work_house_leg_ph}',
        '{work_house_leg_local_phone}',
        '{work_senate_leg_nick}',
        '{work_senate_leg_last}',
        '{work_senate_leg_fullname}',
        '{work_senate_leg_salutation}',
        '{work_senate_leg_email}',
        '{work_senate_leg_ph}',
        '{work_senate_leg_local_phone}',
      ]
    },
    isSms() {
      return this.email.is_sms
    },
    memberId() {
      return this.members.length > 0 ? this.members[0].id : 0
    },
  },
  methods: {
    bytesToMb(size) {
      return parseFloat(size/1048576).toFixed(2)
    },
    filesSize(files) {
      let sum = 0;
      this.attachedFilesSize = 0;
      files.forEach(file => {
        sum += file.size;
      })
      this.attachedFiles.forEach(file => {
        this.attachedFilesSize += file.size;
      })
      this.currentFilesSize = sum + this.attachedFilesSize
      this.$emit('updateFilesSize', this.currentFilesSize)
      return this.currentFilesSize;
    },
    onAddVariableToSubject(variable) {
      let field = document.querySelector('#subject')
      if (!this.isPreview) {
        if (field.selectionStart || field.selectionStart == '0') {
          let startPos = field.selectionStart;
          let endPos = field.selectionEnd;
          this.formattedSubject = field.value.substring(0, startPos)
            + variable
            + field.value.substring(endPos, field.value.length);
          this.email.subject = field.value.substring(0, startPos)
            + variable
            + field.value.substring(endPos, field.value.length);
        } else {
          this.email.subject = variable
          this.formattedSubject = variable
        }
      }
    },
    async inputEmail() {
      this.$nextTick(async () => {
        this.searchIncorrectVariables(this.message, this.variableList)
        const newContent = await this.parsUrl(this.message)

        if (newContent !== this.message) {
          this.message = newContent
          this.disabledEditor = false
          document.activeElement.blur()
        }
        this.finalMessageLength()
      })
    },
    onCheckVariables() {
      this.checkVariablesForEditor(this.message, this.variableList)
    },
    onAddVariable(variable) {
      this.bodyVariable = ''
      variable += ' '
      tinymce.activeEditor.execCommand('mceInsertContent', false, variable);
      this.finalMessageLength()
    },
    async onPreviewMessage() {
      if (!this.isPreview) {
        return
      }

      let message = {}
      if (this.email.id) {
        message = {
          member_id: this.members.length > 0 ? this.members[0].pivot.member_id : 0,
          message: ''
        }
      } else {
        message = {
          member_id: this.members.length > 0 ? this.members[0].id : 0,
          message: ''
        }
      }

      this.isBusy = true
      if (!this.message) {
        this.email.formattedMessage = ''
      } else {
        message.message = this.message
        this.email.formattedMessage = (await this.getAppFormattedMessage(message)).data
        this.isBusy = false
      }

      if (!this.email.subject) {
        this.formattedSubject = ''
      } else {
        message.message = this.email.subject
        this.formattedSubject = (await this.getAppFormattedMessage(message)).data
        this.isBusy = false
      }
    },
    filesInputChanged() {
      this.currFiles = [
        ...this.currFiles,
        ...this.email.files
      ]
      this.currFiles = [...new Set(this.currFiles)]
      this.email.files = [...new Set(this.currFiles)]
      this.$nextTick().then(() => {
        this.$emit('maxFileSize', this.maxFileSize)
      })
    },
    removeAttachedFile(index) {
      this.email.files.splice(index, 1)
      this.currFiles.splice(index, 1)
    },
    removeAllAttachedFiles() {
      this.currFiles = []
      this.email.files = []
      this.currentImgsSize = 0
      this.updatedImgSize = 0
      this.isFilesInput = false
    },
    async removeAttachedScheduledFile(file, index) {
      try {
        await this.deleteScheduledFile(file.id)
        this.getAttachedFiles.splice(index, 1)
      } catch (e) {
        console.error(e)
        this.onCallToastr('error', 'File hasn\'t been deleted', 'Error!')
      }
    },
    onOpenTestMessageModal() {
      this.$refs.TestMessageModal.open()
    },
    finalMessageLength() {
      let member_nick = ''
      let member_last = ''
      let update_profile_link = 'https://kpda.sh/ez/xxxxxxxx?prof=true'
      let home_house_leg_nick = ''
      let home_house_leg_last = ''
      let home_house_leg_fullname = ''
      let home_house_leg_salutation = ''
      let home_house_leg_email = ''
      let home_house_leg_ph = ''
      let home_house_leg_local_phone = ''
      let home_senate_leg_nick = ''
      let home_senate_leg_last = ''
      let home_senate_leg_fullname = ''
      let home_senate_leg_salutation = ''
      let home_senate_leg_email = ''
      let home_senate_leg_ph = ''
      let home_senate_leg_local_phone = ''
      let work_house_leg_nick = ''
      let work_house_leg_last = ''
      let work_house_leg_fullname = ''
      let work_house_leg_salutation = ''
      let work_house_leg_email = ''
      let work_house_leg_ph = ''
      let work_house_leg_local_phone = ''
      let work_senate_leg_nick = ''
      let work_senate_leg_last = ''
      let work_senate_leg_fullname = ''
      let work_senate_leg_salutation = ''
      let work_senate_leg_email = ''
      let work_senate_leg_ph = ''
      let work_senate_leg_local_phone = ''
      this.members.forEach(member => {
        if (member_nick.length < (member.nickname || '').length) member_nick = member.nickname
        if (member_last.length < (member.lastname || '').length) member_last = member.lastname

        if (typeof member.homeHouseLegislator !== "undefined") {
          const homeHouseSalutation = `${this.client.formal_house} ${member.homeHouseLegislator.lastname}`
          if (home_house_leg_nick.length < (member.homeHouseLegislator.nickname || '').length) home_house_leg_nick = member.homeHouseLegislator.nickname
          if (home_house_leg_last.length < (member.homeHouseLegislator.lastname || '').length) home_house_leg_last = member.homeHouseLegislator.lastname
          if (home_house_leg_fullname.length < (member.homeHouseLegislator.fullname || '').length) home_house_leg_fullname = member.homeHouseLegislator.fullname
          if (home_house_leg_salutation.length < (homeHouseSalutation || '').length) home_house_leg_salutation = homeHouseSalutation
          if (home_house_leg_email.length < (member.homeHouseLegislator.official_email || '').length) home_house_leg_email = member.homeHouseLegislator.official_email
          if (home_house_leg_ph.length < (member.homeHouseLegislator.official_phone || '').length) home_house_leg_ph = member.homeHouseLegislator.official_phone
          if (home_house_leg_local_phone.length < (member.homeHouseLegislator.local_phone || '').length) home_house_leg_local_phone = member.homeHouseLegislator.local_phone
        }

        if (typeof member.homeSenateLegislator !== "undefined") {
          const homeSenateSalutation = `${this.client.formal_senate} ${member.homeSenateLegislator.lastname}`
          if (home_senate_leg_nick.length < (member.homeSenateLegislator.nickname || '').length) home_senate_leg_nick = member.homeSenateLegislator.nickname
          if (home_senate_leg_last.length < (member.homeSenateLegislator.lastname || '').length) home_senate_leg_last = member.homeSenateLegislator.lastname
          if (home_senate_leg_fullname.length < (member.homeSenateLegislator.fullname || '').length) home_senate_leg_fullname = member.homeSenateLegislator.fullname
          if (home_senate_leg_salutation.length < (homeSenateSalutation || '').length) home_senate_leg_salutation = homeSenateSalutation
          if (home_senate_leg_email.length < (member.homeSenateLegislator.official_email || '').length) home_senate_leg_email = member.homeSenateLegislator.official_email
          if (home_senate_leg_ph.length < (member.homeSenateLegislator.official_phone || '').length) home_senate_leg_ph = member.homeSenateLegislator.official_phone
          if (home_senate_leg_local_phone.length < (member.homeSenateLegislator.local_phone || '').length) home_senate_leg_local_phone = member.homeSenateLegislator.local_phone
        }

        if (typeof member.workHouseLegislator !== "undefined") {
          const workHouseSalutation = `${this.client.formal_house} ${member.workHouseLegislator.lastname}`
          if (work_house_leg_nick.length < (member.workHouseLegislator.nickname || '').length) work_house_leg_nick = member.workHouseLegislator.nickname
          if (work_house_leg_last.length < (member.workHouseLegislator.lastname || '').length) work_house_leg_last = member.workHouseLegislator.lastname
          if (work_house_leg_fullname.length < (member.workHouseLegislator.fullname || '').length) work_house_leg_fullname = member.workHouseLegislator.fullname
          if (work_house_leg_salutation.length < (workHouseSalutation || '').length) work_house_leg_salutation = workHouseSalutation
          if (work_house_leg_email.length < (member.workHouseLegislator.official_email || '').length) work_house_leg_email = member.workHouseLegislator.official_email
          if (work_house_leg_ph.length < (member.workHouseLegislator.official_phone || '').length) work_house_leg_ph = member.workHouseLegislator.official_phone
          if (work_house_leg_local_phone.length < (member.workHouseLegislator.local_phone || '').length) work_house_leg_local_phone = member.workHouseLegislator.local_phone
        }

        if (typeof member.workSenateLegislator !== "undefined") {
          const workSenateSalutation = `${this.client.formal_senate} ${member.workSenateLegislator.lastname}`
          if (work_senate_leg_nick.length < (member.workSenateLegislator.nickname || '').length) work_senate_leg_nick = member.workSenateLegislator.nickname
          if (work_senate_leg_last.length < (member.workSenateLegislator.lastname || '').length) work_senate_leg_last = member.workSenateLegislator.lastname
          if (work_senate_leg_fullname.length < (member.workSenateLegislator.fullname || '').length) work_senate_leg_fullname = member.workSenateLegislator.fullname
          if (work_senate_leg_salutation.length < (workSenateSalutation || '').length) work_senate_leg_salutation = workSenateSalutation
          if (work_senate_leg_email.length < (member.workSenateLegislator.official_email || '').length) work_senate_leg_email = member.workSenateLegislator.official_email
          if (work_senate_leg_ph.length < (member.workSenateLegislator.official_phone || '').length) work_senate_leg_ph = member.workSenateLegislator.official_phone
          if (work_senate_leg_local_phone.length < (member.workSenateLegislator.local_phone || '').length) work_senate_leg_local_phone = member.workSenateLegislator.local_phone
        }
      })
      let message = this.message || ''
      message = message.replace(/{advocate_first}/g, member_nick)
      message = message.replace(/{advocate_last}/g, member_last)
      message = message.replace(/{home_house_leg_nick}/g, home_house_leg_nick)
      message = message.replace(/{home_house_leg_last}/g, home_house_leg_last)
      message = message.replace(/{home_house_leg_fullname}/g, home_house_leg_fullname)
      message = message.replace(/{home_house_leg_salutation}/g, home_house_leg_salutation)
      message = message.replace(/{home_house_leg_email}/g, home_house_leg_email)
      message = message.replace(/{home_house_leg_ph}/g, home_house_leg_ph)
      message = message.replace(/{home_house_leg_local_phone}/g, home_house_leg_local_phone)
      message = message.replace(/{home_senate_leg_nick}/g, home_senate_leg_nick)
      message = message.replace(/{home_senate_leg_last}/g, home_senate_leg_last)
      message = message.replace(/{home_senate_leg_fullname}/g, home_senate_leg_fullname)
      message = message.replace(/{home_senate_leg_salutation}/g, home_senate_leg_salutation)
      message = message.replace(/{home_senate_leg_email}/g, home_senate_leg_email)
      message = message.replace(/{home_senate_leg_ph}/g, home_senate_leg_ph)
      message = message.replace(/{home_senate_leg_local_phone}/g, home_senate_leg_local_phone)
      message = message.replace(/{work_house_leg_nick}/g, work_house_leg_nick)
      message = message.replace(/{work_house_leg_last}/g, work_house_leg_last)
      message = message.replace(/{work_house_leg_fullname}/g, work_house_leg_fullname)
      message = message.replace(/{work_house_leg_salutation}/g, work_house_leg_salutation)
      message = message.replace(/{work_house_leg_email}/g, work_house_leg_email)
      message = message.replace(/{work_house_leg_ph}/g, work_house_leg_ph)
      message = message.replace(/{work_house_leg_local_phone}/g, work_house_leg_local_phone)
      message = message.replace(/{work_senate_leg_nick}/g, work_senate_leg_nick)
      message = message.replace(/{work_senate_leg_last}/g, work_senate_leg_last)
      message = message.replace(/{work_senate_leg_fullname}/g, work_senate_leg_fullname)
      message = message.replace(/{work_senate_leg_salutation}/g, work_senate_leg_salutation)
      message = message.replace(/{work_senate_leg_email}/g, work_senate_leg_email)
      message = message.replace(/{work_senate_leg_ph}/g, work_senate_leg_ph)
      message = message.replace(/{work_senate_leg_local_phone}/g, work_senate_leg_local_phone)
      message = message.replace(/{update_profile_link}/g, update_profile_link)

      message = this.html2Text(message)
      this.email.finalMessageLength = message.length
    },
    html2Text(html) {
      let find = /(<br>|<\/p><p>)/,
        re = new RegExp(find, 'g');

      let txt = html.replace(re, '\n');
      txt = txt.replace(/<[^>]*>?/gm, '')
      return he.decode(txt)
    },
  },
  mounted() {
    this.isBusy = true
    this.badVariables = false
    this.isPreview = false
    setTimeout(() => {
      this.isBusy = false
    }, 500)
  },
  watch: {
    badVariables: function () {
      this.searchIncorrectVariables(this.message, this.variableList)
      if (!this.badVariables) {
        this.checkVariablesForEditor(this.message, this.variableList)
      }
      this.$emit('badVariables', this.badVariables)
    },
    'message': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.finalMessageLength()
        this.$emit('finalMessageLength', this.email.finalMessageLength)
      }
    },
  }
}
</script>
