<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container v-if="client && client.id" fluid grid-list-lg>
        <v-layout wrap>
          <v-flex class="d-flex align-center">
            <h1>Session Dashboard</h1>
            <session-dashboard-help-modal></session-dashboard-help-modal>
            <v-spacer></v-spacer>
            <!-- mobile menu -->
            <v-menu offset-y v-if="isMobile">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  icon
                  small
                  dark
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-btn
                    color="primary"
                    class="my-1"
                    block
                    small
                    :loading="isLoading"
                    @click="refresh"
                  >
                    Refresh
                    <v-icon dark class="ml-2">refresh</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="false"
                    color="primary"
                    class="my-1"
                    block
                    small
                    @click="$refs.taskModal.toggle()"
                  >
                    Show Tasks
                    <v-icon dark class="ml-2">list</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="(checkShowExpandedButton && isExpanded === 1)"
                    color="primary"
                    class="my-1"
                    block
                    small
                    @click="onExpandAll"
                    >
                    Ungroup
                    <v-icon dark class="ml-2">expand_more</v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="my-1"
                    block
                    small
                    @click="onCollapseAll"
                    v-if="(checkShowExpandedButton && isExpanded === 2)">
                    Group Regions
                    <v-icon dark class="ml-2">expand_less</v-icon>
                  </v-btn>
                  <v-btn
                    color="warning"
                    class="my-1"
                    block
                    small
                    @click="$refs.LargeSendMessage.toggle()"
                    :disabled="!client || isLoading"
                  >
                    Send Message
                    <v-icon dark class="ml-2">mail_outline</v-icon>
                  </v-btn>
                  <v-menu
                    bottom
                    origin="center center"
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        class="my-1"
                        block
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        Export
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        @click="onExportCSV"
                      >
                        <v-list-item-title>Export CSV</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="onExportXLSX"
                      >
                        <v-list-item-title>Export XLSX</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-flex>
          <v-spacer v-if="isDesktop || isDevice"></v-spacer>
          <!-- desktop buttons -->
          <v-flex class="d-flex align-center justify-end flex-wrap" v-if="isDesktop || isDevice">
            <v-btn icon color="primary" :loading="isLoading" @click="refresh">
              <v-icon dark>refresh</v-icon>
            </v-btn>
            <v-btn color="primary" small @click="$refs.taskModal.toggle()" v-if="false">
              Show Tasks
              <v-icon dark class="ml-2">list</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              small
              class="ml-2"
              @click="onExpandAll"
              v-if="(checkShowExpandedButton && isExpanded === 1)"
            >
              Ungroup
              <v-icon dark class="ml-2">expand_more</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              small
              class="ml-2"
              @click="onCollapseAll"
              v-if="(checkShowExpandedButton && isExpanded === 2)"
            >
              Group Regions
              <v-icon dark class="ml-2">expand_less</v-icon>
            </v-btn>
            <v-btn
              class="ml-2"
              color="primary"
              small
              :disabled="!client || isLoading"
              @click="$refs.LargeSendMessage.toggle()"
            >
              Send Message
            </v-btn>
            <v-menu
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  icon
                  small
                  dark
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list class="px-3">
              <!-- Exports -->
              <v-menu
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="my-1"
                    block
                    small
                    v-bind="attrs"
                    v-on="on"
                    :disabled="enabledImportExport()"
                  >
                    Export
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="onExportCSV"
                  >
                    <v-list-item-title>Export CSV</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="onExportXLSX"
                  >
                    <v-list-item-title>Export XLSX</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <!-- End exports -->
              <!-- Visible columns -->
              <v-menu
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="my-1"
                    block
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click.prevent="columnsSearch = ''"
                  >
                    Edit Columns
                  </v-btn>
                </template>

                <v-list style="max-width: 300px">
                  <v-list-item>
                    <v-text-field
                      label="Search"
                      v-model="columnsSearch"
                    ></v-text-field>
                  </v-list-item>
                  <v-divider/>
                  <v-list-item
                    class="d-flex flex-wrap my-2"
                    style="min-height: 100%"
                    v-for="(columnGroup, index) in columnDefs"
                    :key="index"
                  >
                    <div
                      class="full-width"
                      v-if="columnGroup.children"
                      v-for="(column, key) in columnGroup.children"
                      :key="key"
                    >
                      <div
                        v-if="conditionsToSearchColumnNames(column)"
                      >
                        <v-checkbox
                          class="ma-0 pa-0"
                          :label="column.headerName"
                          v-model="column.visible"
                          @change="onColumnVisible(column)"
                          hide-details
                        ></v-checkbox>
                      </div>
                    </div>
                  </v-list-item>
                </v-list>
              </v-menu>
              </v-list>
            </v-menu>
          </v-flex>
        </v-layout>

    <v-layout>
      <v-flex class="d-flex align-center flex-wrap">
        <v-select
          v-if="!isActiveSession"
          label="Past sessions"
          hide-details
          class="client-selector ml-3"
          item-text="name"
          item-value="id"
          :items="sessionList"
          v-model="sessionId"
          @input="refresh"
        >
        </v-select>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <v-alert
        v-if="!isActiveSession"
        class="text-center"
        type="error"
        outlined
        dense
        text
    >
      No current session found so the most-recent Session was loaded.
      Click <a @click="$refs.editSessionModal.toggle(client, sessionList[sessionList.length - 1])">here</a> to edit last Session End Date or <a @click="$refs.newSessionModal.toggle(client)">here</a> to start a new Session.
    </v-alert>
    <!-- adam removed 8.23.23 -->
    <!--<div>Please apply any filters prior to selecting KPs to message <v-icon size="16" color="#9e9aa7">{{svgPath}}</v-icon></div>-->
    <ag-grid-vue
      ref="grid"
      v-show="columnDefs.length > 1"
      class="ag-theme-balham kp-task-table"
      :gridOptions="gridOptions"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :frameworkComponents="frameworkComponents"
      :groupRowInnerRenderer="groupRowInnerRenderer"
      :isRowSelectable="isRowSelectable"
    ></ag-grid-vue>
    <v-layout v-if="isLoading" class="align-center column pa-5">
      <v-progress-circular
        indeterminate
        color="primary"
        size="50"
      ></v-progress-circular>
      {{ 'Loading active Session Dashboard...' }}
    </v-layout>
    <!-- removed by Adam 3.27.2024 
    <v-alert type="info" outlined :value="true" v-show="columnDefs.length === 1 && !error">
      Loading active Session Dashboard....
    </v-alert>
    -->
    <legislator-detail-modal ref="legislatorDetailModal" :refresh="refresh"></legislator-detail-modal>
    <member-detail-modal
      ref="memberDetailModal"
      :selected-client="client"
      :refresh="refresh"
      @openDistrictModal="openDistrictModal"
    ></member-detail-modal>
    <notes-modal
        ref="notesModal"
        :tasks="tasks"
        @refresh="refresh"
    ></notes-modal>
    <send-message-modal
      ref="SendMessageModal"
      :unique="isShowingUnique"
      :client="client"
      :legislators="legislatorsList"
    ></send-message-modal>
    <task-modal ref="taskModal" :task-list="taskList"></task-modal>
    <district-modal v-if="districtModalEnabled"></district-modal>
    <showing-unique-modal
      ref="showingUniqueModal"
      @onShowUnique="onShowUnique"
      @onShowDuplicate="onShowDuplicate"
      @switchBeforeShowingUnique="switchBeforeShowingUnique"
    ></showing-unique-modal>
    <upgrade-functionality-modal
      ref="UpgradeFunctionalityModal"
      @closeModal="isOpenUpgradeFunctionalityModal = false"
    >
    </upgrade-functionality-modal>
    <donation-modal
      ref="DonationModal"
      :client="client"
      @refresh="refresh"
    >
    </donation-modal>
    <large-send-message
      ref="LargeSendMessage"
      :client="client"
    >
    </large-send-message>
    <edit-session-modal
      ref="editSessionModal"
      :active-session="lastSession"
      :session-list="sessionList"
      :committee-list="committeeList"
      @refresh="refresh"
    ></edit-session-modal>
    <new-session-modal 
      ref="newSessionModal" 
      :active-session="activeSession && activeSession.id ? activeSession : null" 
      :session-list="sessionList" 
      :committee-list="committeeList" 
      :refresh="refresh"
      :legislators="sessionLegislatorsList">
    </new-session-modal>
  </v-container>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import { mdiFilterOutline} from '@mdi/js';

import ClientService from '@/services/ClientService'
import SessionService from '@/services/SessionService'
import StateService from '@/services/StateService'
import TaskService from '@/services/TaskService'

import CommitteeCell from '@/components/ag-grid-cells/committee-cell'
import DistrictCell from '@/components/ag-grid-cells/district-cell'
import LegislatorVip from '@/components/ag-grid-cells/legislator-vip'
import LegislatorLameDuck from '@/components/ag-grid-cells/legislator-lame-duck'
import FVoteEditor from '@/components/ag-grid-cells/f-vote-editor'
import LegislatorCell from '@/components/ag-grid-cells/legislator-cell'
import LegislatorDetailModal from '@/components/legislators/legislator-detail-modal'
import NotesModal from '@/components/ag-grid-cells/notes-modal'
import PrimaryKPCell from '@/components/ag-grid-cells/primary-kp-cell'
import PrimaryKPMemberCell from '@/components/ag-grid-cells/primary-kp-member-cell'
import PrimaryKPMemberEmail from '@/components/ag-grid-cells/primary-kp-member-email'
import PrimaryKPMemberLogin from '@/components/ag-grid-cells/primary-kp-member-login'
import PVoteEditor from '@/components/ag-grid-cells/p-vote-editor'
import newSessionModal from '@/components/sessions/new-session-modal'
import SendMessageModal from '@/components/kp-tasks/send-message-modal'
import SessionDashboardHelpModal from '@/components/help-modals/session-dashboard-help-modal'
import TaskCell from '@/components/ag-grid-cells/task-cell'
import TaskModal from '@/components/kp-tasks/task-modal'
import TaskGroupRow from '@/components/ag-grid-cells/task-group-row'
import DistrictService from '@/services/DistrictService'
import DistrictModal from '@/components/common/district-modal'
import MemberDetailModal from '@/components/members/member-detail-modal'
import ShowingUniqueModal from '@/components/kp-tasks/showing-unique-modal'
import UpgradeFunctionalityModal from '@/components/kp-tasks/upgrade-functionality-modal'
import DonationModal from '@/components/kp-tasks/donation-modal'
import LargeSendMessage from '@/components/kp-tasks/large-message-modal'
import swal from "sweetalert2";
import editSessionModal from "@/components/sessions/edit-session-modal";
import {mapGetters} from "vuex";
import PoliticalLearningsMixin from '@/mixins/politicalLeaningsBorder';
import MemberService from "@/services/MemberService";
import CommitteeService from "@/services/CommitteeService";

export default {
  name: 'KPTasksPage',
  mixins: [ClientService, SessionService, StateService, TaskService, DistrictService, PoliticalLearningsMixin, MemberService, CommitteeService],
  components: {
    'edit-session-modal': editSessionModal,
    'new-session-modal': newSessionModal,
    DistrictModal,
    AgGridVue,
    LegislatorDetailModal,
    NotesModal,
    SendMessageModal,
    SessionDashboardHelpModal,
    TaskModal,
    LargeSendMessage,
    MemberDetailModal,
    ShowingUniqueModal,
    UpgradeFunctionalityModal,
    'donation-modal': DonationModal
  },
  data () {
    return {
      isLoading: false,
      isExpanded: 2,
      isShowingUnique:false,
      beforeShowingUnique: false,
      isOpenUpgradeFunctionalityModal: false,
      committeeList: [],
      legislatorCommitteeList: [],
      selectedLegislators: [],
      politicalLearnings: [],
      taskList: [],
      columnDefs: [],
      rowData: [],
      gridOptions: {},
      isRowSelectable: null,
      clientRegions: {},
      legislatorsList: [],
      sessionLegislatorsList: [],
      result: [],
      error: false,
      sessionList: [],
      sessionId: null,
      activeSession: null,
      isActiveSession: true,
      svgPath: mdiFilterOutline,
      columnsSearch: '',
      tasks: [],
      sessionCommitteesIds: [],
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    lastSession() {
      return this.sessionList[this.sessionList.length - 1]
    },
    selectedEmailList () {
      return this.selectedLegislators.filter(legislator => legislator.member_legislators[0] && legislator.member_legislators[0].member.email)
        .map(legislator => legislator.member_legislators[0].member.email)
    },
    selectedCoordinatorsList() {
      if (this.legislatorsList) {
        let array = this.legislatorsList.filter(legislator =>
          legislator.fullDistrict &&
          legislator.fullDistrict.region &&
          legislator.fullDistrict.region.member
        ).map(legislator => legislator.fullDistrict.region.member)

        return array.filter((coordinator, index, self) =>
          index === self.findIndex((item) => (
            item.id === coordinator.id && item.name === coordinator.name && item.id
          ))
        )
      }
    },
    selectedContactList () {
      const selectedContactList = {
        contactCount: 0,
        emailList: [],
        cellList: [],
      }

      this.selectedLegislators.forEach((legislator) => {
        if (legislator.member_legislators[0] && legislator.member_legislators[0].member.cell){
          selectedContactList.contactCount += 1
          selectedContactList.cellList.push(legislator.member_legislators[0].member.cell)
        } else if (legislator.member_legislators[0] && legislator.member_legislators[0].member.email) {
          selectedContactList.contactCount += 1
          selectedContactList.emailList.push(legislator.member_legislators[0].member.email)
        }
      })
      return selectedContactList
    },
    isClientUsingRegion () {
      if (!this.client.id) return false
      return this.client.uses_regions
    },
    checkShowExpandedButton() {
      return (this.isVIP || this.isSuperAdmin || (this.isCoordinator && this.client.show_all)) && this.isClientUsingRegion
    },
    frameworkComponents () {
      return {
        groupRowInnerRenderer: TaskGroupRow,
        fVoteEditor: FVoteEditor,
        pVoteEditor: PVoteEditor,
      }
    },
    groupRowInnerRenderer () {
      return 'groupRowInnerRenderer'
    },
    isFederalClient(){
      return this.client && this.client.is_use_state === 0;
    }
  },
  beforeMount () {
    this.gridOptions = {
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter',
      },
      groupDefaultExpanded: 1,
      autoGroupColumnDef: {
        headerName: 'Region',
      },
      floatingFilter: true,
      groupUseEntireRow: true,
      rowHeight: 48,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      columnDefs: this.columnDefs,
      rowData: this.rowData,
      onFirstDataRendered: (event) => {
        if (event.columnApi.columnController.bodyWidth > event.columnApi.columnController.scrollWidth && event.columnApi.columnController.primaryColumnTree.length > 1) {
          event.columnApi.autoSizeColumns(event.columnApi.columnController.primaryColumnTree[1].children.map(col => col.colId))
        } else {
          event.api.sizeColumnsToFit()
        }
      },
      onSelectionChanged: (event) => {
        if (!(this.isSuperAdmin || (this.client && this.client.tariff &&  this.client.tariff.checkboxes_sd_greed)) && !this.isOpenUpgradeFunctionalityModal ) {
          this.openModalUpgradeFunctionality()
          event.api.deselectAll();
        } else if (!this.beforeShowingUnique && event.api.getSelectedNodes().length) {
          this.openModalShowingUnique()
          event.api.deselectAll();
        } else {
          this.selectedLegislators = event.api.getSelectedNodes()
            .filter(node => !node.group)
            .map(node => {
              let data = Object.assign({}, node.data)
              data.primary_kp = node.data.member_legislators[0]
              return data
            })
        }
      },
      onCellValueChanged: (event) => {
        if (event.colDef.dataType && (event.colDef.dataType.data_type === 'String' || event.colDef.dataType.data_type === 'Select')) {
          if (event.newValue && event.newValue !== event.oldValue) {
            const payload = {
              legislator_id: event.data.id,
              kp_id: event.data.primary_kp.id,
              task_id: event.colDef.taskId,
              result: event.newValue,
            }
            this.addTaskResult(payload)
              .then(() => {
                this.onCallToastr('success', 'Task Result has been updated.', 'Success!')
              })
            .catch(err => {
              console.log('err', err)
              this.onCallToastr('error', 'Something went wrong.', 'Error!')
            })
          }
        }
      },
      onCellClicked: (params) => {
        if (!params.node.data) return

        //Open District modal
        if(params.colDef.field === 'district'){
          this.openDistrictModal(params.data.district)
        }

        if (!params.colDef.dataType) {
          if (params.colDef.headerName === 'Primary KP' && params.data.member_legislators.length) {
            this.$refs.memberDetailModal.toggle(
              params.data.member_legislators.length ? params.data.member_legislators[0].member : null
            )
          }
        }

        if (!params.colDef.dataType) {
          if (params.colDef.headerName === 'Legislator' && !this.isShowingUnique) {
            this.$refs.legislatorDetailModal.toggle(params.data, false)
          }
        }

        if (params.colDef.dataType && params.colDef.dataType.data_type === 'Boolean') {
          if (params.value === undefined) {
            return
          } else {
            const vewValue = params.value === 'YES' ? 'NO' : 'YES'
            params.node.setDataValue(`task${params.colDef.taskId}`, vewValue)

            const payload = {
              legislator_id: params.data.id,
              kp_id: params.data.primary_kp.id,
              task_id: params.colDef.taskId,
              result: vewValue,
            }
            this.addTaskResult(payload)
              .then(() => {
                this.onCallToastr('success', 'Task Result has been updated.', 'Success!')
              })
            .catch(err => {
              console.log('err', err)
              this.onCallToastr('error', 'Something went wrong.', 'Error!')
            })
          }
        }

        if (params.colDef.dataType && params.colDef.dataType.data_type === 'Notes') {
          this.$refs.notesModal.toggle(params)
        }
        
        if (params.colDef.dataType && params.colDef.dataType.data_type === 'Currency') {
          if (params.data.member_legislators && params.data.member_legislators.length) {
            const currentTask = {...this.tasks.find(task => task.id == params.colDef.taskId)}
            this.$refs.DonationModal.open(params.data, params.data.primary_kp, currentTask)
          }

          if (params.value === undefined) {
            params.colDef.editable = false
          } else {
            params.colDef.editable = true
          }
        }
        if (params.colDef.dataType && params.colDef.dataType.data_type === 'String') {
          if (params.value === undefined) {
            params.colDef.editable = false
          } else {
            params.colDef.editable = true
          }
        }
        if (params.colDef.dataType && params.colDef.dataType.data_type === 'Select') {
          if (params.value === undefined) {
            params.colDef.editable = false
          } else {
            params.colDef.editable = true
          }
        }
      },
      //Tariq edit
      onFilterChanged:(event) => {
        let curSel = event.api.getSelectedNodes();
        event.api.deselectAll();
        if(curSel.length>0)
          event.api.selectAllFiltered();
      },
      onSortChanged:(params) => {
        this.setTableSettings(false)
      },
      enableRangeSelection: true,
    }
    this.columnDefs = [
      {
        headerName: '',
        children: [
          {
            headerName: '',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            suppressSizeToFit: true,
            lockPosition: true,
            lockVisible:true,
            hide:true,
            visible: false,
            maxWidth: 40,
            width: 40,
            filter: false,
          },
          {
            headerName: 'Primary KP',
            field: `primary_kp.fullname`,
            cellRendererFramework: PrimaryKPCell,
            keyCreator: (params) => {
              if (!params.value) return null
              return `${params.value.member.nickname} ${params.value.member.lastname}`
            },
            suppressSizeToFit: true,
            visible: true,
            lockPosition: true,
            width: 200,
          },
          {
            headerName: 'Primary KP phone',
            field: 'primary_kp.office_phone',
            cellRendererFramework: PrimaryKPMemberCell,
            lockPosition: true,
            visible: false,
            hide: true,
            width: 150,
          },
          {
            headerName: 'Last login',
            field: 'primary_kp.last_login_at',
            cellRendererFramework: PrimaryKPMemberLogin,
            lockPosition: true,
            visible: false,
            hide: true,
            width: 150,
          },
          {
            headerName: 'Primary KP email',
            field: 'primary_kp.email',
            cellRendererFramework: PrimaryKPMemberEmail,
            lockPosition: true,
            visible: false,
            hide: true,
            width: 200,
          },
          {
            headerName: 'Legislator',
            field: 'search_fullname',
            cellRendererFramework: LegislatorCell,
            suppressSizeToFit: true,
            lockPosition: true,
            visible: true,
            width: 200,
          },
          {
            headerName: 'Lame Duck',
            field: 'lame_duck',
            cellRendererFramework: LegislatorLameDuck,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            lockPosition: true,
            visible: false,
            hide: true,
            width: 100,
          },
          {
            headerName: 'Vip',
            field: 'vip',
            cellRendererFramework: LegislatorVip,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            lockPosition: true,
            visible: true,
            width: 100,
          },
          {
            headerName: 'Legislator cell',
            field: 'cell_phone',
            lockPosition: true,
            visible: false,
            hide: true,
            width: 150,
          },
          {
            headerName: 'Legislator phone',
            field: 'official_phone',
            lockPosition: true,
            visible: false,
            hide: true,
            width: 150,
          },{
            headerName: 'Legislator email',
            field: 'official_email',
            lockPosition: true,
            visible: false,
            hide: true,
            width: 200,
          },
          {
            headerName: 'District',
            field: 'district',
            suppressSizeToFit: true,
            lockPosition: true,
            visible: true,
            width: 100,
            cellRendererFramework: DistrictCell,
          },
          {
            headerName: 'Committees',
            field: 'committeeText',
            suppressSizeToFit: true,
            lockPosition: true,
            width: 175,
            visible: true,
            filter: 'agSetColumnFilter',
            cellRendererFramework: CommitteeCell,
          },
          {
            headerName: 'Unique',
            field: 'unique',
            hide: true,
            width: 40,
            visible: false,
            filter: true,
          }
        ]
      }
    ]
    this.isRowSelectable = (rowNode) => {
      return rowNode.data && rowNode.data.member_legislators[0] && rowNode.data.member_legislators[0].member
        ? !rowNode.data.member_legislators[0].member.silence : false;
    }
  },
  async mounted () {
    if (!(this.client && this.client.id)) return
    this.politicalLearnings = (await this.getPoliticalLeanings()).data
    await this.getLegislatorsList()
    await this.refresh()
  },
  methods: {
    switchBeforeShowingUnique() {
      this.beforeShowingUnique = !this.beforeShowingUnique
    },
    onExportCSV() {
      this.gridOptions.api.exportDataAsCsv(this.gridOptions);
    },
    onExportXLSX() {
      this.gridOptions.api.exportDataAsExcel(this.gridOptions);
    },
    enabledImportExport() {
      return !(this.isSuperAdmin || (this.userData && this.userData.allow_import_export));
    },
    openModalShowingUnique() {
      swal({
        title: 'Have you already applied all your filters?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#1bc943',
        cancelButtonColor: '#4d79e3',
        confirmButtonText: 'Yes',
        cancelButtonText: 'Go Back',
        reverseButtons: true,
      })
        .then((result) => {
          if (!result.value) return
          this.beforeShowingUnique = true
          this.$refs.showingUniqueModal.isModalOpen = true
        })
    },
    conditionsToSearchColumnNames(column) {
      return column.headerName && column.headerName.toLowerCase().includes(this.columnsSearch.toLowerCase())
    },
    openModalUpgradeFunctionality() {
      this.isOpenUpgradeFunctionalityModal = true
      this.$refs.UpgradeFunctionalityModal.isModalOpen = true
    },
    setTableSettings(isGroup = true) {
      if(!isGroup) {
        this.columnDefs[0].children[1] = false
        this.rowData = this.rowData.map(item => {
          if(item.leg_region_flag && (item.leg_region_sort || item.leg_region_sort == 0)) {
            delete item.leg_region_sort
            delete item.leg_region_flag
          }
          return item
        })
        this.isExpanded = 0
        return
      }
      this.error = true
      this.columnDefs = this.columnDefs.slice(0, 1)
      this.columnDefs[0].children = this.columnDefs[0].children.filter(child => child.headerName !== 'Region')
      
      if (this.isClientUsingRegion) {
        this.columnDefs[0].children.splice(1, 0, {
           headerName: 'Region',
           field: 'leg_region_flag',
           rowGroup: true,
           hide: true,
        })
      }
      if (this.result.session) {
        this.error = false
        this.columnDefs.push({
          headerName: `${this.result.session.name} Tasks`,
          children: []
        })

        const houseCommittees = this.client.state.house_districts ? (this.result.session.house_committees || []) : []
        const senateCommittees = this.client.state.sen_districts ? (this.result.session.senate_committees || []) : []
        const jointCommittees = this.result.session.joint_committees || []
        this.legislatorCommitteeList = [...houseCommittees, ...senateCommittees, ...jointCommittees]
          .map((committeeId) => this.committeeList.find(committee => committee.id === committeeId))
          .filter(committee => !!committee)
      } else {
        this.legislatorCommitteeList = []
      }
      if (this.legislatorCommitteeList.length === 0) {
        this.columnDefs[0].children = this.columnDefs[0].children.filter(column => column.headerName !== 'Committees')
      }

      this.tasks = this.result && this.result.session && this.result.session.tasks ? this.result.session.tasks : []

      const notesIndex = this.tasks.findIndex(task => task.task_type.data_type === 'Notes')
      if (notesIndex >= 0) {
        this.tasks.splice(0, 0, this.tasks[notesIndex])
        this.tasks.splice(notesIndex + 1, 1)
      }
      this.tasks.forEach((task) => {
        if (task.task_type.data_type !== 'Notes' && task.task_type.data_type !== 'Currency') {
          this.taskList.push(task)
        }

        const columnDef = {
          headerName: task.name === 'Currency' ? 'Donations' : task.name,
          headerTooltip: task.tooltip,
          field: `task${task.id}`,
          visible: true,
          width: 100,
          suppressSizeToFit: task.task_type.data_type === 'Boolean',
          lockPosition: true,
          taskId: task.id,
          dataType: task.task_type,
          cellRendererFramework: TaskCell,
          cellEditorSelector: (params) => {
            let cellEditor = null
            if (task.task_type.data_type === 'Boolean') {
              cellEditor = {
                component: 'agSelectCellEditor',
                params: {
                  values: ['NO', 'YES']
                }
              }
            } else if (task.task_type.id === 2) {
              cellEditor = {
                component: 'agLargeTextCellEditor',
                cellEditorPopup: true,
                cellEditorParams: {
                  rows: 3,
                  cols: 50
                }
              }
            } else if (task.task_type.id === 6) {
              cellEditor = {
                component: 'fVoteEditor',
              }
            } else if (task.task_type.id === 5) {
              cellEditor = {
                component: 'pVoteEditor',
              }
            } else if (task.task_type.data_type === 'Select') {
              cellEditor = {
                component: 'agRichSelectCellEditor',
                params: {
                  values: task.task_type.options
                }
              }
            }
            return cellEditor
          }
        }
        if (task.task_type.data_type === 'Boolean') {
          columnDef.filter = 'agSetColumnFilter'
          columnDef.filterParams = {
            suppressSorting: true,
            values: ['YES', 'NO'],
          }
        }
        if (task.task_type.data_type === 'Currency') {
          columnDef.filter = 'agNumberColumnFilter'
        }
        if (task.task_type.data_type === 'Select') {
          columnDef.filter = 'agSetColumnFilter'
          columnDef.filterParams = {
            suppressSorting: true,
            values: task.task_type.options,
          }
          if (task.name === 'P-Vote' || task.name === 'F-Vote') {
            columnDef.width = 70
          }
        }
        this.columnDefs[1].children.push(columnDef)
      })
      // Ag-grid Rows
      this.rowData = []
      this.duplicateKps =[];
      if ( this.result && this.result.legislators) {
        this.result.legislators.forEach((legislator) => {
          let tempKp = legislator.member_legislators[0] != null
          && typeof legislator.member_legislators[0].member_id  != 'undefined'
          &&  legislator.member_legislators[0].member_id
            ? legislator.member_legislators[0].member_id
            : null;

          legislator.unique ='N';
          if (tempKp != null && !this.duplicateKps.includes(tempKp)) {
            this.duplicateKps.push(tempKp);
            legislator.unique ='Y';
          }

          legislator.vip = legislator.vip ? 'VIP' : 'non-VIP'

          let region = legislator.district.region
          legislator.fullDistrict = legislator.district ? legislator.district : {}
          if (legislator.district && legislator.district.district) {
            legislator.district = legislator.district.district
          }

          if (legislator.legislator_committees && legislator.legislator_committees.length > 0) {
            legislator.defaultCommittes = []
            legislator.legislator_committees.forEach(legislatorCommittee => {
              legislator.defaultCommittes.push({...legislatorCommittee.committee})
            })

            legislator.committees = legislator.legislator_committees.filter(item => (this.sessionCommitteesIds.includes(item.committee_id)))
              .map((legislatorCommittee) => {
                const committee = legislatorCommittee.committee
                const parent = committee.parent
                
                let shortName = ''
                let fullName = ''
                if(parent){
                  shortName = `${parent.shortname}(${committee.shortname})`
                  fullName = `${parent.fullname}(${committee.fullname})`
                }else {
                  shortName = committee.shortname
                  fullName = committee.fullname
                }

                return {
                  shortname: shortName,
                  fullname: fullName,
                  type: legislatorCommittee.committee.type,
                  memberships: legislatorCommittee.membership_states.map(membershipStates => {
                    return {...membershipStates.membership, 'subcommittee': membershipStates.pivot.subcommittee}
                  }),
                  color: legislatorCommittee.committee.color,
                  subcommittee_color: legislatorCommittee.committee.subcommittee_color,
                };
              })
            legislator.committeeText = legislator.committees.map(committee => {
              if (committee.memberships && committee.memberships.some(membership => membership.subcommittee === 1) && this.client && this.client.state && this.client.state.has_subcommittees) {
                return committee.shortname + '(sub)';
              }
              return committee.shortname
            })

          } else {
            legislator.committees = []
          }

          if(legislator.member_legislators[0]) {
            const member = legislator.member_legislators[0].member
            legislator.primary_kp = member
            legislator.politicalLearnings = this.politicalLearnings
            legislator.primary_kp.fullname = `${member.nickname} ${member.lastname}`
            legislator.primary_kp.activeKP1 = member.member_legislator.some(item => item.kp_position == 1)
          }

          this.tasks.forEach((task) => {
            if (
              legislator.member_legislators.length
              && legislator.member_legislators[0].kp_position == 1
              && legislator.member_legislators[0].session_tasks.findIndex(item => (item.id == task.id)) != -1
            ) {
              if (task.task_type.data_type === 'Currency') {
                legislator[`task${task.id}`] = legislator.donations_in_session.reduce((total, donation) => total + donation.total, 0)
              } else {
                legislator[`task${task.id}`] = this.findResult(task.task_results, legislator.id, task.id, legislator.member_legislators[0].member.id)
              }

              if (!legislator[`task${task.id}`] && task.task_type.data_type === 'Boolean') {
                legislator[`task${task.id}`] = 'NO'
              }
            } else {
              if (!legislator[`task${task.id}`] && task.task_type.data_type === 'Boolean') {
                legislator[`task${task.id}`] = undefined
              }
              if (!legislator[`task${task.id}`] && task.task_type.data_type === 'Currency') {
                legislator[`task${task.id}`] = undefined
              }
              if (!legislator[`task${task.id}`] && task.task_type.data_type === 'Notes') {
                legislator[`task${task.id}`] = undefined
              }
              if (!legislator[`task${task.id}`] && task.task_type.data_type === 'String') {
                legislator[`task${task.id}`] = undefined
              }
            }
          })
          
          legislator.client = this.client

          if(this.isClientUsingRegion && region && legislator.district_id) {
            let clientRegion = this.clientRegions.find(item => item.id == region.id)
            if(clientRegion.districts && clientRegion.districts.some(district => district.id == legislator.district_id)){
              this.rowData.push({
                ...legislator,
                leg_region_sort: region.sort_order,
                leg_region_flag: region.name,
                search_fullname: `${legislator.nickname} ${legislator.lastname}`
              })
            }
          } else {
            this.rowData.push({
                ...legislator,
                search_fullname: `${legislator.nickname} ${legislator.lastname}`
              })
          }
        })
      }

      this.isShowingUnique=false
      this.isExpanded = 2
      this.isLoading = false
      if (this.isCoordinator || this.isVIP || this.isSuperAdmin) {
        this.$nextTick( function() {
          this.onExpandAll()
        })
      }
    },
    async refresh () {
      if (!(this.client && this.client.id)) return

      if (this.isSuperAdmin) {
        this.$store.dispatch('app/setSuperAdminSelectedClient', this.client)
      }

      this.isLoading = true
      this.taskList = []
      await this.getClientRegionsList()
      let result = []
      this.committeeList = (await this.getStateCommittees(this.client.state_id)).data
      let params
      try {
        if(this.$route.query.session) {
          params = {session_id: this.$route.query.session}
          if(!this.isClientUsingRegion){
            params.no_region = 1 
          }
        }
        result = (await this.getClientKPTasks(this.client.id, params)).data
        this.activeSession = (await this.getClientActiveSession(this.client.id)).data
        this.isActiveSession = this.activeSession && this.activeSession.id
      
        // Get and set active session committee ids
        // Displayed only sessions's committee
        const houseCommittees = this.activeSession.house_committees || [];
        const senateCommittees = this.activeSession.senate_committees || [];
        const jointCommittees = this.activeSession.joint_committees || [];
        this.sessionCommitteesIds = [...houseCommittees, ...senateCommittees, ...jointCommittees];
 
      }
      catch (e) {
        console.error(e)
      }
      if (!this.sessionList.length) {
        try {
          this.sessionList = (await this.getClientSessions(this.client.id)).data
          this.sessionId = this.sessionList && this.sessionList.length
              ? this.sessionList[this.sessionList.length - 1].id
              : null
        } catch (e) {
          console.error(e)
        }
        if(this.sessionId && !this.$route.query.session) {
          try {
            params = {session_id: this.sessionId}

            if(!this.isClientUsingRegion){
              params.no_region = 1 
            }
            await this.$router.push({ path: '/dashboard', query: {session: this.sessionId}})
            result = (await this.getClientKPTasks(this.client.id, params)).data
          }
          catch (e) {
            console.error(e)
          }
        }
      }
      this.result = result
      this.legislatorsList = result.legislators ? result.legislators : []

      this.legislatorsList = this.legislatorsList.map(legislator => {
        if(this.isFederalClient && legislator.district && legislator.district.state && legislator.district.state.state_abbrev){
          legislator.district.district += ` (${legislator.district.state.state_abbrev.toUpperCase()})`
        }
        return legislator;
      })
      
      await this.setTableSettings()

      this.rowData.sort((a, b) => {
        return a.leg_region_sort - b.leg_region_sort
      })
    },
    findResult (results, legislatorId, taskId, memberId) {
      const result = results.filter(
        result => result.legislator_id === legislatorId && result.task_id === taskId && result.kp_id === memberId
      )
      return result && result.length ? result[result.length - 1].result : ''
    },
    findCurrencyResult (task, legislatorId, taskId, memberId) {
      let result = 0
      task.task_results
        .filter(result => result.legislator_id === legislatorId && result.task_id === taskId && result.kp_id === memberId)
        .forEach(taskResult => result += +taskResult.result)
      return result
    },
    onExpandAll () {
      if (this.gridOptions && this.gridOptions.api) {
        this.isExpanded = 2
        this.gridOptions.api.forEachNode((node) => {
          if (node.group) node.setExpanded(true)
        })
      }
    },
    onColumnVisible(column) {
      const api = this.gridOptions.columnApi;
      api.setColumnVisible(column.field, column.visible);
    },
    onCollapseAll () {
      if (this.gridOptions && this.gridOptions.api) {
        this.isExpanded = 1
        this.gridOptions.api.forEachNode((node) => {
          if (node.group) node.setExpanded(false)
        })
      }
    },
    onShowUnique () {
      swal({
        title: 'Dashboard has been filtered to only show unique KPs ensuring each will only get one message. You may now select KPs for messaging.',
        type: 'info',
        showCancelButton: false,
        confirmButtonColor: '#1bc943',
        cancelButtonColor: '#4d79e3',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      })
        .then((result) => {
          if (!result.value) return
          this.isShowingUnique = true
          this.gridOptions.columnApi.setColumnVisible('1', true)
          let rowDataFilter = []
          this.gridOptions.api.forEachNodeAfterFilter(function(rowNode, index) {
            if (!!rowNode.data) {
              rowDataFilter.push(rowNode.data)
            }
          })

          this.duplicateKps =[];
          rowDataFilter.forEach((legislator) => {
            let tempKp = legislator.member_legislators[0] != null
            && typeof legislator.member_legislators[0].member_id  != 'undefined'
            &&  legislator.member_legislators[0].member_id
              ? legislator.member_legislators[0].member_id
              : null;

            legislator.unique ='N';
            if (tempKp != null && !this.duplicateKps.includes(tempKp)) {
              this.duplicateKps.push(tempKp);
              legislator.unique ='Y';
            }
          })

          let filterComponent = this.gridOptions.api.getFilterInstance('unique');
          filterComponent.selectNothing();
          filterComponent.selectValue('Y');

          this.rowData = rowDataFilter
            .filter(legislator => legislator.unique == 'Y')
            .map(legislator => {
              return {...legislator, hideFullName : true}
            })

          this.gridOptions.api.onFilterChanged();
          this.$refs.showingUniqueModal.closeModal()
          this.beforeShowingUnique = true
        })
    },
    onShowDuplicate () {
      swal({
        title: 'You may now select KPs from the table to whom the message will be sent.',
        type: 'info',
        showCancelButton: false,
        confirmButtonColor: '#1bc943',
        cancelButtonColor: '#4d79e3',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      })
        .then((result) => {
          this.gridOptions.columnApi.setColumnVisible('1', true)
          this.isShowingUnique = false
          let filterComponent = this.gridOptions.api.getFilterInstance('unique');
          filterComponent.selectNothing();
          filterComponent.selectValue('N');
          filterComponent.selectValue('Y');
          this.gridOptions.api.onFilterChanged();
          this.$refs.showingUniqueModal.closeModal()
          this.beforeShowingUnique = true
        })
    },
    async getClientRegionsList() {
      if(!this.isClientUsingRegion) return
      this.clientRegions = []

      if(this.client && this.client.id) {
        this.getClientRegions(this.client.id).then(response => {
          this.clientRegions = response.data
        })
      }

    },
    async getLegislatorsList() {
      let param = {
        order: 'lastname'
      }
      this.sessionLegislatorsList = await this.getClientLegislators(this.client && this.client.id, param).then((response) => {
        return response.data ? response.data : []
      })
    },
  },
  watch: {
    'client': async function () {
      this.sessionList = []
      await this.refresh()
    }
  }
}
</script>
