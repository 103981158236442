<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container fluid grid-list-lg class="my-kps pa-0">
    <v-progress-linear
      style="z-index: 6"
      color="warning"
      indeterminate
      rounded
      height="3"
      fixed
      top
      v-if="isLoading"
    ></v-progress-linear>
      <v-card-text>
        <v-layout wrap>
          <v-flex :class="isDesktop || isDevice? 'd-flex' : ''">
            <div>
              <h1>{{ coordinator.nickname }} {{ coordinator.lastname }}</h1>
              {{ regionName }} Coordinator
            </div>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!isSuperAdmin && userClient && userClient.tariff &&  userClient.tariff.message_kps"
              :class="isDesktop || isDevice? 'mx-1' : 'my-2'" color="primary" :block="isMobile" small
              @click="$refs.SendMessageModal.toggle()"
              :disabled="!taskList.length">
              <v-icon dark small class="mr-2 hidden-xs-only">chat</v-icon>
              Message My KPs
            </v-btn>
            <v-tooltip bottom  v-if="!(!isSuperAdmin && userClient && userClient.tariff &&  userClient.tariff.message_kps)">
              <template v-slot:activator="{ on }">
                <v-btn
                  class="disabled-button"
                  :class="isDesktop || isDevice? 'mx-1' : 'my-2'"
                  :block="isMobile"
                  small
                  v-on="on"
                >
                  <v-icon small class="disabled-button-icon mr-2 hidden-xs-only">chat</v-icon>
                  Message My KPs
                </v-btn>
              </template>
              <span>Upgrade for this functionality</span>
            </v-tooltip>
            <v-btn
              :class="isDesktop || isDevice? 'mx-1' : 'my-2'" color="primary" :block="isMobile" small
              @click="dialogMessage = true"
              :disabled="!taskList.length">
              <v-icon dark small class="mr-2 hidden-xs-only">fas fa-comments</v-icon>
              View Messages
            </v-btn>
            <v-btn
              :class="isDesktop || isDevice? 'mx-1' : 'my-2'" color="primary" :block="isMobile" small
              @click="dialogArticle = true"
              :disabled="!taskList.length">
              <v-icon dark small class="mr-2 hidden-xs-only">fas fa-folder-open</v-icon>
              Resources
            </v-btn>
            <v-btn
              v-if="isKp1 !== false"
              @click="$router.replace({ name: 'my-tasks-page' })"
              :class="isDesktop || isDevice? 'mx-1' : 'my-2'"
              color="primary"
              :block="isMobile"
              small
            >
              <v-icon dark small class="mr-2 hidden-xs-only">fact_check</v-icon>
              My Legislators
            </v-btn>
          </v-flex>
        </v-layout>
        <div class="kp-card">
          <div class="card-width">
            <div class="mt-4">
              <span class="subtitle-2 ">Your KPs</span>
            </div>

            <div class="mb-4" v-for="(member, index) in kpList" :key="index">
              <v-card>
                <v-card-title class="px-4 py-2">
                  <v-tooltip bottom v-if="member.avatar_url">
                    <template v-slot:activator="{ on }">
                      <v-avatar
                          size="60"
                          tile
                          class="bordered_avatar mr-4"
                          @click="openUploadImagePopup(member)"
                      >
                        <img alt="Avatar" :src="getThumbImageMedium(member.avatar_url)"/>
                        <v-btn icon class="avatar_overlay">
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>
                      </v-avatar>
                    </template>
                    <span>Click above to upload this member's head shot.</span>
                  </v-tooltip>
                  <v-tooltip bottom  v-else>
                    <template v-slot:activator="{ on }">
                      <v-avatar
                          size="60"
                          tile
                          class="cursor-pointer bordered_avatar mr-4"
                          @click="$refs.UploadAvatar.open(member)"
                      >
                        <img alt="Avatar" src="/images/member-avatar_old.png"/>
                        <v-btn icon class="avatar_overlay">
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>
                      </v-avatar>
                    </template>
                    <span>Click above to upload this member's head shot.</span>
                  </v-tooltip>
                  <div :class="isDesktop || isDevice? 'row ml-4' : '' ">
                    <div>{{ member.fullname }}
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn-toggle class="mr-4">
                      <v-btn small :disabled="isBad(member.email)" @click="$refs.sendText.toggle(member, true)">
                        <v-icon>mdi-email</v-icon>
                      </v-btn>
                      <v-btn :href="'tel:' + member.cell" small :disabled="!!member.bad_cell">
                        <v-icon>mdi-phone</v-icon>
                      </v-btn>
                      <v-btn
                        :href="isMobile ? 'sms:' + member.cell : '' "
                        small
                        :disabled="!!member.bad_cell"
                        @click="sendingSms(member)"
                      >
                        <v-icon>mdi-text</v-icon>
                      </v-btn>
                    </v-btn-toggle>
                  </div>
                </v-card-title>
                <v-card-actions>
                  <v-expansion-panels focusable>
                    <v-expansion-panel
                      v-for="memberLegislator in member.member_legislator"
                      :key="memberLegislator.id"
                      @change="setActivePanel(index, memberLegislator.id)"
                    >
                      <v-expansion-panel-header :class="isMobile? 'row ma-0' : ''" disable-icon-rotate>
                        <div class="mobile-width leg-salutation">
                          {{ memberLegislator.legislator.salutation }}
                          <vip-chip
                            v-if="memberLegislator.legislator.vip_status.some(vip => vip.end_date == null && vip.client_id === userClient.id)"
                            class="mx-2 "
                            :legislator="memberLegislator.legislator"
                            :show="'badge_code'"
                          >
                          </vip-chip>
                          <v-tooltip bottom v-if="memberLegislator.legislator.lame_duck">
                            <template v-slot:activator="{ on }">
                              <a v-on="on">
                                <v-icon color="#EECB23" size="16" class="ml-1">fa-solid fa-duck</v-icon>
                              </a>
                            </template>
                            <span>Lame Duck</span>
                          </v-tooltip>
                          
                          <!-- Notes -->
                          <v-tooltip v-if="!getNotesTask(memberLegislator).length" max-width="450" bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon class="mx-1" color="primary" small v-on="on">mdi-note-outline</v-icon>
                            </template>
                            <span>No notes</span>
                          </v-tooltip>
                          <v-tooltip v-else class="mx-1" max-width="450" bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon class="mx-1" color="primary" small v-on="on">mdi-note</v-icon>
                            </template>
                            <span>{{ getNotesTask(memberLegislator)[0].result }}</span>
                          </v-tooltip>

                          <!-- Currency -->
                          <div v-for="task in currencyTasks" :key="task.id" class="d-flex mx-1 align-center">
                            <v-tooltip v-if="isCurrencyTaskResult(memberLegislator,task)" bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon class="ml-1" color="teal" small v-on="on">paid
                                </v-icon>
                              </template>
                              <span>KP donated this Session</span>
                            </v-tooltip>
                            <v-tooltip v-else bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon class="ml-1" color="error" small v-on="on">paid
                                </v-icon>
                              </template>
                              <span>KP has yet to donate this Session</span>
                            </v-tooltip>
                          </div>
                          
                          <!-- kp assignment button -->
                          <v-tooltip v-if="(isSuperAdmin || isVIP) || (isCoordinator && userClient.uses_assignment_kp_c && isFederalClient)" bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn class="ml-1" v-on="on" outlined x-small color="primary" @click="$refs.assignmentModal.toggle(memberLegislator.legislator)">
                                Edit KPs
                              </v-btn>
                            </template>
                            <span>Update or Edit KPs</span>
                          </v-tooltip>
                        </div>
                        
                        <!-- show task dots -->
                        <div class="d-flex wrap justify-center mobile-width leg-tasks">
                          <div class="ma-1" v-for="task in memberLegislator.memberTasks" :key="task.id">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-avatar size="14" :color="colorTask(memberLegislator, task)" v-on="on"></v-avatar>
                              </template>
                              <span>{{ tooltipTask(memberLegislator, task) }}</span>
                            </v-tooltip>
                          </div>
                        </div>
                        <v-spacer></v-spacer>
                        <template class="mobile-width" v-slot:actions>

                          <!-- District -->
                          <v-btn
                            class="mx-1"
                            style="color: white"
                            :color="colorDistrictButton(memberLegislator.legislator)"
                            @click.native.stop="((isSuperAdmin || isVIP) || (isCoordinator && userClient.uses_assignment_kp_c && !isFederalClient)) ? openDistrictModal(memberLegislator.legislator.district.district, memberLegislator.legislator.district.id) : null"
                            small
                          >
                            {{ memberLegislator.legislator.district.district }}
                            <span v-if="isFederalClient">
                              ({{ memberLegislator && memberLegislator.legislator && memberLegislator.legislator.district && memberLegislator.legislator.district.state && memberLegislator.legislator.district.state.state_abbrev }})
                            </span>
                          </v-btn>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <my-tasks-tag
                          ref="myTasksTag"
                          :legislator="memberLegislator.legislator"
                          :member="memberLegislator"
                          :isMemberTasks="true"
                          @handleFormChange="handleFormChange($event, memberLegislator)"
                          @handleNoteChange="handleNoteChange($event, memberLegislator)"
                          @updatingTaskResult="updatingTaskResult($event)"
                          @refresh="refresh"
                        ></my-tasks-tag>
                        <div
                          v-if="formChanged && memberLegislator.id === activeLegislator ||
                          noteChanged && memberLegislator.id === activeLegislator"
                          :class="isDesktop || isDevice? 'd-flex justify-center': '' ">
                          <v-btn
                            outlined
                            color="secondary"
                            class="expansion-button ma-1"
                            @click="refresh"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            class="expansion-button ma-1"
                            color="primary"
                            @click="onSave(memberLegislator, memberLegislator.member_id)"
                            :disabled="isLoading"
                          >
                            Save when done
                          </v-btn>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                </v-card-actions>
              </v-card>
            </div>
          </div>
        </div>
<!-- show legislators without a KP -->
        <div v-if="legislatorsWithoutKp1 && legislatorsWithoutKp1.length" class="kp-card">
          <div class="card-width">
            <div class="mt-4">
              <span class="subtitle-2 ">Legislators without a KP</span>
            </div>


            <div class="mb-4" v-for="(legislator, index) in legislatorsWithoutKp1" :key="index">
              <v-card>
                <v-card-title class="lighten-4 px-4 py-2">
                  <v-avatar
                    size="60"
                    tile
                    class="bordered_avatar mr-4"
                  >
                    <img v-if="legislator.official_image" alt="Avatar"
                         :src="getThumbImageMedium(legislator.official_image)"/>
                    <img v-else alt="Avatar" :src="'/images/member-avatar.png'"/>
                  </v-avatar>
                    <p>{{ legislator.fullname }}</p>
                  <vip-chip
                      v-if="legislator.vip_status.some(vip => vip.end_date == null && vip.client_id === userClient.id)"
                      class="mx-2 "
                      :legislator="legislator"
                      :show="'badge_code'"
                  >
                  </vip-chip>
                  <v-tooltip bottom v-if="legislator.lame_duck">
                    <template v-slot:activator="{ on }">
                      <a v-on="on">
                        <v-icon color="#EECB23" size="16" class="ml-1">fa-solid fa-duck</v-icon>
                      </a>
                    </template>
                    <span>Lame Duck</span>
                  </v-tooltip>
                  <div :class="isDesktop || isDevice? 'row ml-4' : '' ">
                    <div>{{ legislator.salutation }}</div>
                    <v-spacer></v-spacer>
                        <v-btn v-if="(isSuperAdmin || isVIP) || (isCoordinator && userClient.uses_assignment_kp_c)" outlined :text="isDesktop || isDevice" small color="primary" @click="$refs.assignmentModal.toggle(legislator)">
                          <v-icon left color="primary">group_add</v-icon>
                          Assign a KP
                        </v-btn>
                    <v-btn
                      class="mx-1"
                      style="color: white"
                      :color="colorDistrictButton(legislator)"
                      @click.native.stop="(isSuperAdmin || isVIP) || (isCoordinator && userClient.uses_assignment_kp_c && !isFederalClient) ? openDistrictModal(legislator.district.district, legislator.district.id) : $event.preventDefault()"
                      small
                    >
                      {{ legislator.district.district }}
                      <span v-if="isFederalClient">
                        ({{ legislator && legislator.district && legislator.district.state && legislator.district.state.state_abbrev }})
                      </span>
                    </v-btn>
                  </div>
                </v-card-title>
              </v-card>
            </div>
          </div>
        </div>
      </v-card-text>
    <district-modal v-if="districtModalEnabled"></district-modal>
    <send-text-modal ref="sendText"></send-text-modal>
    <upload-avatar-modal ref="UploadAvatar"></upload-avatar-modal>
    <send-message-modal
      ref="SendMessageModal"
      :client="this.userClient"
      :Kps="kpList"
    ></send-message-modal>
    <v-dialog v-model="dialogArticle" :fullscreen="isMobile">
      <articles-index
        :dialog="dialogArticle"
        @closeModal="closeArticleModal"
      ></articles-index>
    </v-dialog>

    <v-dialog v-model="dialogMessage" :fullscreen="isMobile">
      <messages-component
        :dialog="dialogMessage"
        @closeModal="dialogMessage = false"
      ></messages-component>
    </v-dialog>
    <assignment-modal
        ref="assignmentModal"
        @getRefreshedAssignment="refresh"
        @updateKps="refresh"
        @updateLegislators="refresh"
    ></assignment-modal>
  </v-container>
</template>

<script>
  import ClientService from '@/services/ClientService'
  import DistrictService from "@/services/DistrictService"
  import districtModal from '@/components/common/district-modal'
  import MyTasksTag from "@/components/my-tasks/my-tasks-tag";
  import MemberService from "@/services/MemberService";
  import SendText from "@/components/my-kp/send-text-modal";
  import CloudinaryService from "@/services/CloudinaryService";
  import UploadAvatar from "@/components/my-kp/upload-avatar-modal";
  import ArticleIndex from '@/components/articles/articles-index'
  import MessagesComponent from '@/components/messages/messages-component'
  import SendMessageModal from '@/components/my-kp/send-message-modal'
  import TaskService from "@/services/TaskService";
  import VipChip from "@/components/legislators/vip-setting/vip-chip";
  import assignmentModal from '@/components/legislators/assignment-modal'

  export default {
    name: 'MyKPsPage',
    mixins: [ClientService, DistrictService, MemberService, CloudinaryService, TaskService],
    components: {
      'district-modal': districtModal,
      'my-tasks-tag': MyTasksTag,
      'send-text-modal': SendText,
      'upload-avatar-modal': UploadAvatar,
      'articles-index': ArticleIndex,
      'messages-component': MessagesComponent,
      'send-message-modal': SendMessageModal,
      'assignment-modal': assignmentModal,
      'vip-chip': VipChip,
    },
    data() {
      return {
        formChanged: false,
        noteChanged: false,
        isLoading: false,
        taskList: [],
        kpList: [],
        kpTasks: [],
        taskResult: 0,
        legislatorsWithoutKp1: [],
        currencyTasks: [],
        coordinator: {},
        dialogArticle: false,
        dialogMessage: false,
        activeLegislator: null,
        activePanel: null,
        activePanelHeader: null,
        isKp1: false,
        newNote: {}
      }
    },
    computed: {
      regionName() {
        if (this.coordinator && this.coordinator.member && this.coordinator.member.regions[0] && this.coordinator.member.regions[0].name) {
          return this.coordinator.member.regions[0].name
        }

        return ''
      },
      currentTime() {
        return moment().format('YYYY-MM-DD')
      },
      isFederalClient() {
        return this.userClient && this.userClient.is_use_state === 0
      }
    },
    async mounted() {
      await this.refresh()
      await this.isMemberKp1()
    },
    methods: {
      async refresh() {
        this.activeLegislator = null
        this.activePanel = null
        this.activePanelHeader = null
        this.isLoading = true
        this.formChanged = false
        this.kpList = []
        try {
          const res = (await this.getMyKPs(this.userClient.id)).data
          this.taskList = res.tasks
          this.kpList = res.myKPList
          this.coordinator = res.coordinator

          this.legislatorsWithoutKp1 = res.legislatorsWithoutKp.map(legislator => {
            legislator.salutation = ''
            if (legislator.district.district[0] === 'H') {
              legislator.salutation = this.userClient.formal_house + ' ' + legislator.fullname
            } else if (legislator.district.district[0] === 'S') {
              legislator.salutation = this.userClient.formal_senate + ' ' + legislator.fullname
            }
            return legislator
          })

          this.kpTasks = res.tasks.filter((task) => {
            return task.hide_from_kp === 0 && (task.task_type.data_type === 'Boolean' || task.task_type.data_type === 'Select')
          })

          this.currencyTasks = res.tasks.filter((task) => {
            return task.hide_from_kp === 0 && (task.task_type.data_type === 'Currency')
          })

          this.kpList.map(members => this.mapMembers(members))
        } catch (error) {
          this.loading = false
          console.error(error)
        }
        this.isLoading = false
      },
      closeArticleModal() {
        this.dialogArticle = false;
      },
      sendingSms(member) {
        if (!this.isMobile) this.$refs.sendText.toggle(member, false)
      },
      mapMembers(member) {
        member.member_legislator.map(memberLegislator => this.mapMemberLegislators(memberLegislator))
        return member
      },
      mapMemberLegislators(memberLegislator) {
        memberLegislator.legislator.salutation = memberLegislator.legislator.fullname
        if (memberLegislator.legislator.district.district[0] === 'H') {
          memberLegislator.legislator.salutation = this.userClient.formal_house + ' ' + memberLegislator.legislator.fullname
        } else if (memberLegislator.legislator.district.district[0] === 'S') {
          memberLegislator.legislator.salutation = this.userClient.formal_senate + ' ' + memberLegislator.legislator.fullname
        }

        memberLegislator.legislator.tasks = this.taskList.map(task => {
          task = {...task}
          task.result = memberLegislator.task_results.filter(result => {
            return result.task_id == task.id
          })[0]
          return task
        })

        memberLegislator['memberTasks'] = []
        memberLegislator.memberTasks = memberLegislator.sessionTasks.filter(task => {
          return (task.task_type.data_type === 'Boolean' ||
            task.task_type.data_type === 'Select')
        })

        memberLegislator.sessionTasks = memberLegislator.sessionTasks.map(task => {
          task.result = memberLegislator.task_results.filter(result => {
            return result.task_id == task.id
          })[0]
          return task
        })

        return memberLegislator
      },
      colorTask(memberLegislator, task) {
        const result = memberLegislator.task_results.filter(result => result.task_id === task.id)

        if (result.length) {
          if (result[0].result === 'NO') {
            return 'red'
          }
          if (result[0].result === 'YES' || result[0].result) {
            return 'green'
          }
        } else if (task.deadline < this.currentTime) {
            return 'red'
        } else return 'grey'
      },
      tooltipTask(memberLegislator, task) {
        const result = memberLegislator.task_results.some(result => result.task_id === task.id)

        if (result) {
          const taskResult = memberLegislator.task_results.find(result => result.task_id === task.id)
          return `${task.name} completed ${moment(taskResult.created_at).format('MMM D, YYYY')}`
        } else {
          if (task.deadline < this.currentTime) {
            return `${task.name} incomplete, deadline was ${moment(task.deadline).format('MMM D, YYYY')}`
          } else {
            return `${task.name} deadline coming up ${moment(task.deadline).format('MMM D, YYYY')}`
          }
        }
      },
      getNotesTask(memberLegislator) {
        const notesTask = this.taskList.filter(task => task.task_type.data_type === 'Notes')
        return memberLegislator.task_results.filter(result => result.task_id === notesTask[0].id)
      },
      isCurrencyTaskResult(memberLegislator, task) {
        return memberLegislator.task_results.some(result => result.task_id === task.id)
      },
      colorDistrictButton(legislator) {
        return this.partyColorList[legislator.party]
      },
      generateResultsPayload(resultsArray, noteTaskId){
        return resultsArray.filter(item => item.id !== noteTaskId && item.result)
          .map(task => {
            return {
              result: task.result.result,
              task_id: task.result.task_id
            }
          })
      },
      updatingTaskResult(taskResultId)
      {
        this.taskResult = taskResultId
      },
      editTaskResult() {
        const payload = {
          result: this.newNote.result,
        }

        this.updateTaskResult(payload, this.taskResult)
          .then(() => {
            this.formChanged = false
            this.noteChanged = false
            this.newNote = {}
            this.$refs.myTasksTag.forEach(tag => {
              tag.clearNoteField()
            })
            this.isBusy = false
          })
          .catch((err) => {
            this.isBusy = false
            this.handleError(err, true)
          })

      },
      async onSave(member, memberId) {
        this.isLoading = true
        let results = []
        if (this.taskResult > 0) {
          await this.editTaskResult()
        }

        if (this.newNote && this.newNote.result && this.newNote.result.length && this.taskResult === 0) {
          results = [...this.generateResultsPayload(member.sessionTasks, this.newNote.task_id), { ...this.newNote }]
        } else {
          const notesTask = member.sessionTasks.find(item =>item.name === 'Notes')
          results = this.generateResultsPayload(member.sessionTasks, notesTask.id)
        }

        const payload = {
          legislator_id: member.legislator.id,
          results: results,
          id: memberId
        }

        this.addKPTaskResults(payload)
          .then(() => {
            this.newNote = {}
            this.formChanged = false
            this.noteChanged = false
            this.isLoading = false
            this.$refs.myTasksTag[0].clearNoteField()
            this.onCallToastr('success', `Success! Task Saved for ${member.legislator && member.legislator.fullname ?
                member.legislator.fullname :
                'legislator'}`, 'Success!')
            this.refresh()
          })
          .catch((err) => {
            this.isLoading = false
            this.handleError(err)
          })
      },
      handleFormChange(event, memberLegislator) {
        this.activeLegislator = memberLegislator.id
        this.formChanged = true
      },
      handleNoteChange(task, memberLegislator) {
        this.activeLegislator = memberLegislator.id
        this.newNote = task.result
        this.noteChanged = true
      },
      setActivePanel(index, memberLegislatorId) {
        if (this.activePanel === null) {
          this.activePanel = index
          this.activePanelHeader = memberLegislatorId
        } else if (memberLegislatorId !== this.activePanelHeader) {
          this.activePanelHeader = memberLegislatorId
          this.activePanel = index
        } else {
          this.activePanelHeader = null
          this.activePanel = null
        }
      },
      openUploadImagePopup(member) {
        if (this.isSuperAdmin || this.isVIP) {
          this.$refs.UploadAvatar.open(member)
        }
      },
      async isMemberKp1() {
        const member = await (this.getMembers({ 'id': this.userData.member_id }))
        this.isKp1 = Boolean(member.data.is_kp1 && member.data.activeKP1)
      }
    }
  }
</script>
