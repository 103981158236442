<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog content-class="advocacy-modal" persistent max-width="950" v-model="isModalOpen">
    <v-card class="message-modal-content" :loading="loading">
      <v-icon class="d-flex ml-auto pt-2 pr-4" @click="onModalCloseHandler">close</v-icon>
      <v-card-title>
        <span class="title text-wrap">Create a new Advocacy Message</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <div class="pa-3" v-if="error">
          <v-alert type="error" outlined :value="true">{{ error }}</v-alert>
        </div>
        <v-stepper v-model="currentStep" vertical>

          <v-stepper-step step="1" :complete="currentStep > 1">
            <div class="d-flex align-center">
              <span class="text-4xl">1</span>
              <div class="d-flex flex-column">
                <span>Choose Legislator(s)</span>
                <small class="mt-1">Select the Legislators to whom the selected Advocates will send this Advocacy
                  Message</small>
              </div>
            </div>
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-form ref="step1Form" @submit.prevent="onNextStep()">
              <v-layout wrap>
                <v-flex xs12 class="px-2">
                  <v-radio-group
                      row
                      v-model="messageData.chooseLegislatorType"
                      hide-details
                  >
                    <v-radio
                        color="primary"
                        label="All Legislators"
                        value="all_legislators"
                    ></v-radio>
                    <v-radio
                        color="primary"
                        label="By Chamber/Committee"
                        value="by_committee"
                    ></v-radio>
                    <v-radio
                        color="primary"
                        label="Select my own"
                        value="my_own"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex xs4 class="px-2"
                        v-if="messageData.isByCommittee && selectedClient && (selectedClient.state.house_districts > 0)">
                  <v-checkbox
                      color="primary"
                      :disabled="loading"
                      label="House"
                      hide-details
                      v-model="messageData.isHouseDistrictsOnly"
                      @change="onSelectDistrictType"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs4 class="px-2" v-if="messageData.isByCommittee">
                  <v-checkbox
                      color="primary"
                      :disabled="loading"
                      label="Senate"
                      hide-details
                      v-model="messageData.isSenateDisctrictsOnly"
                      @change="onSelectDistrictType"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs4 class="px-2"
                        v-if="messageData.isByCommittee && selectedClient && selectedClient.state.has_joint_committees">
                  <v-checkbox
                      color="primary"
                      :disabled="loading"
                      label="Joint"
                      hide-details
                      v-model="messageData.isJointDisctrictsOnly"
                      @change="onSelectDistrictType"
                  ></v-checkbox>
                </v-flex>

                <v-flex xs12 class="px-2" v-if="messageData.isByCommittee">
                  <div class="d-flex flex-nowrap" v-for="committee in selectedCommitteeList" :key="committee.id">
                    <v-flex xs12 sm6>
                      <v-checkbox
                          color="primary"
                          @change="onSelectCommittee(committee)"
                          :input-value="selectedCommitteeIdList.includes(committee.id)"
                          :label="committee.label"
                          :disabled="selectedSubCommitteeIdList.includes(committee.id) || !!committee.subcommittee"
                          hide-details
                      ></v-checkbox>
                    </v-flex>
                    <v-flex
                        v-if="selectedClient && selectedClient.state && selectedClient.state.has_subcommittees"
                        xs12
                        sm12
                    >
                      <!-- <v-checkbox
                          color="primary"
                          :disabled="loading"
                          v-model="committee.isSubCommittee"
                          @change="onSelectCommittee(committee, true)"
                          label="Subcommittee only"
                          hide-details
                      ></v-checkbox> -->
                      <v-select
                        v-model="committee.subcommittee"
                        :items="committee.subcommittees"
                        :disabled="loading"
                        item-text="fullname"
                        label="Select Subcommittee"
                        hide-details
                        return-object
                        clearable
                        @change="onSelectSubcommitteeHandler"
                        @click:clear="onClickClearSubcommitteeHandler(committee)"
                      ></v-select>
                    </v-flex>
                  </div>
                </v-flex>

                <v-flex xs12 class="px-2 pt-5" v-if="messageData.isMyOwn">
                  <v-autocomplete
                      :label="`Select or search legislator(s)`"
                      v-model="messageData.selectedLegislators"
                      :items="legislatorList"
                      item-text="fullname"
                      :search-input.sync="searchInputFull"
                      @change="searchInputFull=''"
                      color="blue-grey lighten-2"
                      item-value="id"
                      return-object
                      hide-details
                      outlined
                      multiple
                      dense
                  >
                    <template v-slot:selection="{ item }"></template>
                    <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-avatar
                            class="avatar_image"
                            v-if="data.item.official_image"
                          >
                            <img
                              :alt="data.item.fullname"
                              :src="data.item.official_image ? getThumbImage(data.item.official_image) : '/images/member-avatar.png'"
                              :style="kpPreferenceBorder(data.item)"
                            >
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ data.item.fullname }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                    </template>
                  </v-autocomplete>
                </v-flex>

                <v-flex xs12 class="mt-3 px-2">
                  <div class="mt-2 pt-1"
                       v-if="messageData.selectedLegislators && messageData.selectedLegislators.length">
                    <v-layout wrap :class="isMobile ? 'flex-column' : ''">
                      <v-flex xs12 sm12>
                        <v-expansion-panels accordion>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              Recipient Legislators ({{ messageData.selectedLegislators.length }})
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <div class="mb-2 pt-1">
                                <div class="d-flex flex-wrap">
                                  <div v-for="item of messageData.selectedLegislators" class="mr-2">
                                    <v-menu
                                        v-model="displaySelected[getIndex(item.id, messageData.selectedLegislators)]"
                                        bottom
                                        left
                                        origin="top left"
                                        content-class="role_card"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-chip
                                            close
                                            class="mt-2"
                                            pill
                                            v-on="on"
                                            :style=colorsLegislatorsParties(item,legislatorList)
                                            @click:close="removeLegislator(getIndex(item.id, messageData.selectedLegislators), messageData.selectedLegislators, item)"
                                        >

                                          <v-avatar left>
                                            <v-img class="avatar_image"
                                                   :src="item.official_image ? getThumbImage(item.official_image) : '/images/member-avatar.png'"></v-img>

                                          </v-avatar>
                                          <v-icon
                                              left
                                              v-if="!item.official_email"
                                              :color="'error'"
                                          >
                                            mdi-email
                                          </v-icon>
                                          <vip-chip
                                              v-if="item.vip"
                                              class="mr-2 mb-1"
                                              :legislator="item"
                                              :show="'chip_code'"
                                          >
                                          </vip-chip>
                                          {{ item.fullname }}
                                        </v-chip>
                                      </template>
                                    </v-menu>
                                  </div>
                                </div>
                              </div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-flex>

                      <v-flex class="mt-2" xs12 sm12 v-if="messageData.exceptLegislators && messageData.exceptLegislators.length">
                        <h3>Except:</h3>
                        <div class="mb-2 pt-1">
                          <div class="d-flex flex-wrap">
                            <div v-for="item of messageData.exceptLegislators" :key="item.id" class="mr-2">
                              <v-menu
                                  v-model="displayExceptSelected[getIndex(item.id, messageData.exceptLegislators)]"
                                  bottom
                                  left
                                  origin="top left"
                                  content-class="role_card"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-chip
                                      close
                                      class="mt-2"
                                      pill
                                      v-on="on"
                                      :style=colorsLegislatorsParties(item,legislatorList)
                                      @click:close="removeExceptLegislator(getIndex(item.id, messageData.exceptLegislators), messageData.exceptLegislators)"
                                  >
                                    <v-avatar left>
                                      <v-img class="avatar_image"
                                             :src="item.official_image ? getThumbImage(item.official_image) : '/images/member-avatar.png'"></v-img>
                                    </v-avatar>
                                    <vip-chip
                                        v-if="item.vip"
                                        class="mr-2 mb-1"
                                        :legislator="item"
                                        :show="'chip_code'"
                                    >
                                    </vip-chip>
                                    {{ item.fullname }}
                                  </v-chip>
                                </template>
                              </v-menu>
                            </div>
                          </div>
                        </div>
                      </v-flex>

                    </v-layout>
                  </div>
                </v-flex>
              </v-layout>
              <v-layout class="mt-3">
                <v-btn type="submit" color="primary"
                       :disabled="!this.messageData.selectedLegislators || this.messageData.selectedLegislators.length === 0">
                  Next
                </v-btn>
              </v-layout>
            </v-form>
          </v-stepper-content>

          <v-stepper-step step="2" :complete="currentStep > 2">
            <div class="d-flex align-center">
              <span class="text-4xl">2</span>
              <div class="d-flex flex-column">
                <span>Choose Senders</span>
                <small>Select which Advocates will send this message. X out any in the Recipients list if you'd lke to remove
                  them.</small>
              </div>
            </div>
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-form ref="step2Form" @submit.prevent="onNextStep()">
              <v-layout wrap>
                <v-flex xs12 class="px-2">
                  <v-radio-group
                      row
                      v-model="messageData.isKPOnly"
                      @change="onSearchMembers(true)"
                      hide-details
                  >
                    <v-radio
                        color="primary"
                        label="All Constituents"
                        :value="0"
                    ></v-radio>
                    <v-radio
                        color="primary"
                        label="Specific Constituents"
                        :value="2"
                    ></v-radio>
                    <v-radio
                        color="primary"
                        label="KPs Only"
                        :value="1"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>

                <div v-if="messageData.isKPOnly === 2" class="w-100">
                  <v-layout wrap>
                    <div
                        class="pt-2 pb-3"
                        v-if="messageData.selectedMembers && messageData.selectedMembers.length && status !== 'LOADING_MEMBERS'"
                    >
                      <div class="d-flex flex-wrap">
                        <div v-for="(item, index) of messageData.selectedMembers" class="mr-2">
                            <v-chip
                                class="mt-2"
                                pill
                                :key="item.id"
                            >
                              <v-avatar left>
                                <v-img class="avatar_image" :src="getAvatar(item.avatar_url)"></v-img>
                              </v-avatar>
                              {{ item.fullname }}
                            </v-chip>
                        </div>
                      </div>
                    </div>
                  </v-layout>
                  <v-layout wrap class="mt-2">

                    <v-autocomplete
                        v-if="messageData.isKPOnly === 2"
                    v-model="messageData.selectedMembers"
                        :items="memberList"
                        outlined
                        dense
                        clearable
                        color="blue-grey lighten-2"
                        label="Select Constituents"
                        item-text="fullname"
                        multiple
                        return-object
                        :disabled="loading"
                        :loading="loading"
                        :search-input.sync="searchInputFull"
                        hide-details
                        :menu-props="{maxHeight: '35%'}"
                    >
                      <template v-slot:selection="{item}">
                      </template>
                      <template v-slot:item="data">
                          <template v-if="typeof data.item !== 'object'">
                            <v-list-item-content v-text="data.item"></v-list-item-content>
                          </template>
                          <template v-else>
                            <v-list-item-avatar class="avatar_image">
                              <img :src="getAvatar(data.item.avatar_url)" >
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ data.item.fullname }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                      </template>
                    </v-autocomplete>
                  </v-layout>
                </div>


              </v-layout>
              <v-layout wrap v-if="messageData.isKPOnly !== 2">
                <v-flex xs12 sm4 class="px-2" v-if="selectedClient && selectedClient.uses_dob">
                  <v-checkbox
                      color="primary"
                      label="Filter by age"
                      hide-details
                      v-model="messageData.usesAge"
                      @change="onSearchMembers(true)"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs12 sm4 class="px-2" v-if="messageData.usesAge">
                  <v-select
                      label="From"
                      outlined
                      dense
                      hide-details
                      :items="ageList"
                      @change="onSearchMembers(true)"
                      v-model="messageData.fromAge"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm4 class="px-2" v-if="messageData.usesAge">
                  <v-select
                      label="To"
                      outlined
                      dense
                      hide-details
                      :items="ageList"
                      @change="onSearchMembers(true)"
                      v-model="messageData.toAge"
                  >
                  </v-select>
                </v-flex>
              </v-layout>
              <v-layout wrap  v-if="messageData.isKPOnly !== 2">
                <v-flex xs12 sm4 class="px-2" v-if="selectedClient && selectedClient.uses_grad_year">
                  <v-checkbox
                      color="primary"
                      label="Filter by grad year"
                      hide-details v-model="messageData.usesGradYear"
                      @change="onSearchMembers(true)"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs12 sm4 class="px-2 mt-3" v-if="messageData.usesGradYear">
                  <v-select
                      label="From"
                      outlined
                      dense
                      hide-details
                      :items="gradYearList"
                      @change="onSearchMembers(true)"
                      v-model="messageData.fromGradYear"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm4 class="px-2 mt-3" v-if="messageData.usesGradYear">
                  <v-select
                      label="To"
                      hide-details
                      dense
                      outlined
                      :items="gradYearList"
                      @change="onSearchMembers(true)"
                      v-model="messageData.toGradYear"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 class="mt-3 px-2"  v-if="messageData.isKPOnly !== 2">
                  <v-expansion-panels class="my-3" v-model="isRecipientsStep2Open">
                    <v-expansion-panel>
                      <v-expansion-panel-header>Senders ({{
                          (messageData.selectedMembers || []).length
                        }})
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-autocomplete
                            multiple
                            chips
                            hide-details
                            outlined
                            dense
                            :label="'Advocates who will be requested to send a message to their legislator(s) (' + (messageData.selectedMembers || []).length + ')'"
                            class="chip-select"
                            :loading="status === 'LOADING_MEMBERS'"
                            :item-text="member => (member.nickname + ' ' + member.lastname)"
                            item-value="id"
                            :items="memberList"
                            v-model="messageData.selectedMembers"
                        >
                          <template v-slot:selection="props">
                            <v-chip
                                close
                                :input-value="props.selected"
                                class="chip--select-multi"
                                :style="kpPreferenceBorder(props.item)"
                                :key="props.item.id"
                                @click:close="props.parent.selectItem(props.item)"
                            >
                              <v-icon left size="16" :color="isBad(props.item.email) ? 'error' : 'primary'">
                                email
                              </v-icon>
                              <v-icon
                                  v-if="!props.item.bad_cell"
                                  left
                                  size="16"
                                  color="primary">
                                phone_iphone
                              </v-icon>
                              <v-icon
                                  v-else
                                  left
                                  size="16"
                                  color="error">
                                no_cell
                              </v-icon>
                              {{ props.item.nickname }} {{ props.item.lastname }}
                            </v-chip>
                          </template>
                          <template v-slot:progress>
                            <v-progress-linear indeterminate height="2"></v-progress-linear>
                          </template>
                        </v-autocomplete>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-flex>
              </v-layout>
              <v-layout class="mt-3">
                <v-btn color="secondary" outlined class="mr-2" @click="currentStep = 1">
                  Previous
                </v-btn>
                <v-btn type="submit" color="primary"
                       :disabled="!this.messageData.selectedMembers || this.messageData.selectedMembers.length === 0">
                  Next
                </v-btn>
              </v-layout>
            </v-form>
          </v-stepper-content>


          <v-stepper-step step="3" :complete="currentStep > 3">
            <div class="d-flex align-center">
              <span class="text-4xl">3</span>
              <div class="d-flex flex-column">
                <span>Create Advocate Action Request</span>
                <small>This is the message that will be sent to each ADVOCATE requesting action. *The 'Subject' field is only
                  used for emails.*</small>
              </div>
            </div>
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-alert v-if="badCellAndEmails.length && messageData.via_sms" dense type="error" outlined :value="true">
              Advocates without a valid cell OR email will not receive this message.
            </v-alert>
            <v-alert v-if="badEmails.length && !messageData.via_sms" dense type="error" outlined :value="true">
              Advocates without a valid email will not receive this message.
            </v-alert>
            <v-form ref="step3Form" @submit.prevent="onNextStep()">
              <v-layout wrap>
                <v-flex xs12 class="px-2">
                  <v-radio-group v-model="messageData.via_sms" @change="onChangeMessageType">
                    <v-radio
                        color="primary"
                        label="Send action request as a text & if no cell listed, send as email"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        color="primary"
                        label="Send action request via email"
                        :value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex xs12 sm8 class="px-2 d-flex flex-wrap">
                  <v-text-field xs12 id="invitationSubject" outlined dense label="Email Subject to Advocates*"
                                v-model="messageData.invitation_subject" v-show="!isPreview"></v-text-field>
                  <v-text-field xs12 id="invitationSubject" outlined dense label="Email Subject to Advocates*"
                                :value="formattedInvitationSubject" readonly v-show="isPreview"></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 class="px-2 d-flex flex-wrap">
                  <v-select
                      v-if="!messageData.via_sms"
                      v-model="invitationSubjectVariable"
                      outlined
                      dense
                      hide-details
                      label="Subject Variables"
                      :items="variableSubjectList"
                      class="subject_variable-select"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content  @click="onAddVariableToInvitationSubject(data.item)" v-text="data.item"></v-list-item-content>
                    </template>
                  </v-select>
                </v-flex>
                <v-layout xs12 v-if="!messageData.via_sms" wrap>
                  <v-layout wrap class="mb-3 body_variables">
                    <v-flex xs12 sm8 :class="!isMobile ? 'd-flex justify-space-between align-end' :
                    'd-flex justify-space-between align-end'" class="px-2">
                      <div class="caption grey--text">Email Message to ADVOCATES*</div>
                      <v-spacer />
                      <v-btn
                          v-if="badVariables"
                          class="mr-2"
                          x-small
                          outlined
                          color="primary"
                          @click="checkVariablesForEditor(messageData.invitation_message, variableList2)"
                      >
                        Show Error
                      </v-btn>
                      <v-switch
                          color="primary"
                          hide-details
                          :label="isPreview ? 'Edit' : 'Show Preview'"
                          class="d-inline-block mt-0 sms-preview-switch"
                          @change="onPreviewMessage(messageData)"
                          v-model="isPreview"
                          :loading="isMessageLoading"
                          :disabled="isMessageLoading"
                      >
                      </v-switch>
                    </v-flex>
                    <v-flex xs12 sm4 class="px-2">
                      <v-select
                          v-model="variable2"
                          label="Variables"
                          :items="variableList2"
                          hide-details
                          dense
                          outlined
                          class="subject_variable-select text_subject_variable-select"
                      >
                        <template v-slot:item="data">
                          <v-list-item-content  @click="onAddVariable(data.item, 2)" v-text="data.item"></v-list-item-content>
                        </template>
                      </v-select>
                    </v-flex>
                  </v-layout>
                  <p v-if="badVariables" class="red--text mb-0">You have bad variables! Please click on 'Show Error' button and fix them.</p>
                  <v-flex xs12>
                    <div class="tiny_editor" v-show="!isPreview">
                      <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
                      <editor
                          v-if="currentStep === 3"
                          ref="messageEditor2"
                          :api-key="TINY_MCE_API_KEY"
                          :plugins="tinyPlugins"
                          :toolbar="tinyToolbars"
                          :init="tinyInit"
                          v-model="messageData.invitation_message"
                          @keyup="inputInvitation"
                      />
                    </div>
                    <div v-show="isPreview && !isMessageLoading">
                      <editor
                          v-if="currentStep === 3 && !isMessageLoading"
                          :api-key="TINY_MCE_API_KEY"
                          :plugins="tinyPlugins"
                          :toolbar="tinyToolbars"
                          :init="tinyInit"
                          :value="formattedInvitationMessage"
                          disabled
                      />
                    </div>
                  </v-flex>
                  <v-flex v-if="!isFilesInput" v-model="isFilesInput" @click="isFilesInput = !isFilesInput"
                          class="d-flex align-baseline">
                    <v-icon color="primary" class="pt-4 icon_files">mdi-paperclip</v-icon>
                    <a>Attach files</a>
                  </v-flex>
                  <v-flex xs12 v-if="isFilesInput" @click="removeAllAttachedFiles" class="d-flex align-baseline">
                    <v-icon class="pt-4 icon_files">mdi-paperclip</v-icon>
                    <span class="v-label red--text">Cancel</span>
                  </v-flex>
                  <v-file-input
                      v-if="isFilesInput"
                      class="pt-4"
                      v-model="files"
                      color="primary"
                      counter
                      label="File input"
                      multiple
                      placeholder="Select your files"
                      prepend-icon=""
                      outlined
                      :show-size="1000"
                      :rules="fileRules"
                      dense
                      @change="filesInputChanged"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                          color="primary"
                          dark
                          label
                          small
                          close
                          @click:close="removeAttachedFile(index)"
                      >
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                  <v-flex xs12 v-if="attachedScheduledFiles != false" class="pb-2">
                    <div>
                    <span>
                      Attached files: {{
                        attachedScheduledFiles.length + ' files (' + bytesToMb(attachedFilesSize) + ' MB)'
                      }}
                    </span>
                    </div>
                    <v-chip
                        v-for="(attachedFile, index) in attachedScheduledFiles"
                        :key="index"
                        class="mr-2 mt-2"
                        close
                        @click:close="removeAttachedScheduledFile(attachedFile, index)"
                    >
                      {{ `${attachedFile.name} (${bytesToMb(attachedFile.size)} MB)` }}
                    </v-chip>
                  </v-flex>
                  <div v-if="maxFileSize">
                    <v-alert
                        outlined
                        type="info"
                        text
                    >
                      To reduce the size of the files, you can use
                      <a href="https://shrinkpdf.com/">shrinkpdf.com</a> or
                      <a href="https://imagecompressor.com/">imagecompressor.com</a>
                    </v-alert>
                  </div>
                </v-layout>
                <v-flex id="advocacyMsgSmsContainer" xs12 class="px-2" v-else>
                  <v-layout align-end class="mb-2">
                    <v-flex xs12 xs8 :class="!isMobile ? 'd-flex justify-space-between align-end' :
                    'd-flex justify-space-between align-end'" class="px-2">
                      <div class="mb-1 caption grey--text">Text/Email Message to ADVOCATES*</div>
                      <v-spacer />
                      <v-btn
                          v-if="badVariables"
                          class="mr-2"
                          x-small
                          outlined
                          color="primary"
                          @click="checkVariablesForEditor(messageData.invitation_message, variableList2)"
                      >
                        Show Error
                      </v-btn>
                      <v-switch
                          color="primary"
                          hide-details
                          :label="isPreview ? 'Edit' : 'Show Preview'"
                          class="d-inline-block mt-0"
                          @change="onPreviewMessage(messageData)"
                          v-model="isPreview"
                          :loading="isMessageLoading"
                          :disabled="isMessageLoading"
                      >
                      </v-switch>
                    </v-flex>
                    <v-flex xs12 sm4 class="px-2">
                      <v-select
                          v-model="variable2"
                          label="Variables"
                          :items="variableList2"
                          hide-details
                          dense
                          outlined
                          class="subject_variable-select text_subject_variable-select"
                      >
                        <template v-slot:item="data">
                          <v-list-item-content @click="onAddVariable(data.item, 2)"
                                               v-text="data.item"></v-list-item-content>
                        </template>
                      </v-select>
                    </v-flex>
                  </v-layout>
                  <p v-if="badVariables" class="red--text mb-0">You have bad variables! Please click on 'Show Error' button and fix them.</p>
                  <div v-show="!isPreview">
                    <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
                    <editor
                        v-if="!loading && currentStep === 3"
                        v-model="messageData.invitation_message"
                        :api-key="TINY_MCE_API_KEY"
                        :plugins="tinyPlugins"
                        :toolbar="tinySmsToolbars"
                        :init="tinyInit"
                        :disabled="disabledEditor"
                        @click="inputInvitation"
                        @keyup="inputInvitation"
                    ></editor>
                  </div>
                  <div v-show="isPreview">
                      <editor
                          v-if="!isMessageLoading"
                          :api-key="TINY_MCE_API_KEY"
                          :plugins="tinyPlugins"
                          :toolbar="tinySmsToolbars"
                          :init="tinyInit"
                          :value="formattedInvitationMessage"
                          disabled
                      />
                  </div>
                  <div id="editorContainer">
                    <span class="text-counter" :style="colorTextCounter">{{ finalMessageLength }}/320</span>
                  </div>
                </v-flex>
              </v-layout>
              <v-layout class="mt-3">
                <v-btn color="secondary" outlined class="mr-2" @click="currentStep = 2">
                  Previous
                </v-btn>
                <v-btn type="submit" color="primary" :disabled="!isForm3Valid || maxFileSize || badVariables">
                  Next
                </v-btn>
              </v-layout>
            </v-form>
          </v-stepper-content>

          <v-stepper-step step="4" :complete="currentStep > 4">
            <div class="d-flex align-center">
              <span class="text-4xl font-weight-bold me-3">4</span>
              <div class="d-flex flex-column">
                <span>Create Advocacy Message</span>
                <small>This is the message that will be sent to each LEGISLATOR from Advocates</small>
              </div>
            </div>
          </v-stepper-step>
          <v-stepper-content step="4">
            <v-form ref="step4Form" @submit.prevent="onNextStep()">
              <v-layout wrap class="ma-1">
                <v-flex xs12 sm8 class="px-2">
                  <v-text-field
                      persistent-hint
                      id="subject"
                      outlined
                      dense
                      label="Email Subject to Legislators*"
                      v-model="messageData.subject"
                      v-show="!isSecondPreview"
                      hint="THIS IS A SAMPLE, PLEASE EDIT"
                  ></v-text-field>
                  <v-text-field
                      persistent-hint
                      id="subject"
                      outlined
                      dense
                      label="Email Subject to Legislators*"
                      :value="formattedSubject"
                      readonly
                      v-show="isSecondPreview"
                      hint="THIS IS A SAMPLE, PLEASE EDIT"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 class="px-2">
                  <v-select
                      v-model="subjectVariable"
                      outlined
                      dense
                      hide-details
                      label="Subject Variables"
                      :items="variableSubjectList"
                      class="subject_variable-select"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content  @click="onAddVariableToSubject(data.item)" v-text="data.item"></v-list-item-content>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex xs12 class="mt-3">
                  <v-layout wrap class="mb-3 body_variables">
                    <v-flex xs12 sm8 class="px-2"
                            :class="!isMobile ? 'd-flex justify-space-between align-end' : 'd-flex justify-space-between align-end w-100'">
                      <div class="mb-1 caption grey--text">Email Message to LEGISLATORS - PLEASE EDIT*</div>
                      <v-spacer />
                      <v-btn
                          v-if="badVariables"
                          class="mr-2"
                          x-small
                          outlined
                          color="primary"
                          @click="checkVariablesForEditor(messageData.message, variableList1)"
                      >
                        Show Error
                      </v-btn>
                      <v-switch
                          color="primary"
                          hide-details
                          :label="isSecondPreview ? 'Edit' : 'Show Preview'"
                          class="d-inline-block mt-0"
                          @change="onPreviewMessage(messageData)"
                          v-model="isSecondPreview"
                          :loading="isMessageLoading"
                          :disabled="isMessageLoading"
                      >
                      </v-switch>
                    </v-flex>
                    <v-flex xs12 sm4 class="px-2">
                      <v-select
                          v-model="variable"
                          outlined
                          dense
                          label="Body Variables"
                          :items="variableList1"
                          hide-details
                          class="subject_variable-select text_subject_variable-select"
                      >
                        <template v-slot:item="data">
                          <v-list-item-content  @click="onAddVariable(data.item, 1)" v-text="data.item"></v-list-item-content>
                        </template>
                      </v-select>
                    </v-flex>
                  </v-layout>
                  <p v-if="badVariables" class="red--text mb-0">You have bad variables! Please click on 'Show Error' button and fix them.</p>
                  <div v-show="!isSecondPreview">
                    <editor
                        v-if="currentStep === 4"
                        ref="messageEditor1"
                        :api-key="TINY_MCE_API_KEY"
                        :plugins="tinyPlugins"
                        :toolbar="tinyToolbars"
                        :init="tinyInit"
                        v-model="messageData.message"
                        @keyup="inputMessage"
                    />
                  </div>
                  <div v-show="isSecondPreview">
                    <editor
                        v-if="currentStep === 4"
                        :api-key="TINY_MCE_API_KEY"
                        :plugins="tinyPlugins"
                        :toolbar="tinyToolbars"
                        :init="tinyInit"
                        :value="formattedMessage"
                        disabled
                    />
                  </div>
                </v-flex>
                <v-flex v-if="!isFilesForLegislatorInput" v-model="isFilesForLegislatorInput"
                        @click="isFilesForLegislatorInput = !isFilesForLegislatorInput"
                        class="d-flex align-baseline">
                  <v-icon color="primary" class="pt-4 icon_files">mdi-paperclip</v-icon>
                  <a>Attach files</a>
                </v-flex>
                <v-flex xs12 v-if="isFilesForLegislatorInput" @click="removeAttachedFilesForLegislator"
                        class="d-flex align-baseline">
                  <v-icon class="pt-4 icon_files">mdi-paperclip</v-icon>
                  <span class="v-label red--text">Cancel</span>
                </v-flex>
                <v-file-input
                    v-if="isFilesForLegislatorInput"
                    class="pt-4"
                    v-model="filesForLegislator"
                    color="primary"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon=""
                    outlined
                    :show-size="1000"
                    :rules="fileRules"
                    dense
                    @change="filesForLegislatorInputChanged"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                        color="primary"
                        dark
                        label
                        small
                        close
                        @click:close="removeAttachedFileForLegislator(index)"
                    >
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
                <v-flex xs12 v-if="attachedScheduledFilesForLegislator != false" class="pb-2">
                  <div>
                    <span>
                      Attached files: {{ attachedScheduledFilesForLegislator.length + ' files (' + bytesToMb(attachedFilesSizeForLegislator) + ' MB)' }}
                    </span>
                  </div>
                  <v-chip
                      v-for="(attachedFile, index) in attachedScheduledFilesForLegislator"
                      :key="index"
                      class="mr-2 mt-2"
                      close
                      @click:close="removeAttachedScheduledFileForLegislator(attachedFile, index)"
                  >
                    {{ `${attachedFile.name} (${bytesToMb(attachedFile.size)} MB)` }}
                  </v-chip>
                </v-flex>
                <div v-if="maxFileSize">
                  <v-alert
                      outlined
                      type="info"
                      text
                  >
                    To reduce the size of the files, you can use
                    <a href="https://shrinkpdf.com/">shrinkpdf.com</a> or
                    <a href="https://imagecompressor.com/">imagecompressor.com</a>
                  </v-alert>
                </div>
                <v-flex xs12 class="mt-2 px-2 mb-4 full-width">
                  <div class="mb-1 caption grey--text">Choose a deadline after which time Advocates will not be able to
                    send
                  </div>
                  <v-row>
                    <v-col
                        cols="12"
                        xs="12"
                        sm="6"
                    >
                      <v-menu
                          ref="dateMenu"
                          v-model="dateMenu"
                          :close-on-content-click="false"
                          :return-value.sync="date"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="date"
                              outlined
                              dense
                              label="Deadline date"
                              prepend-icon="mdi-calendar"
                              readonly
                              :clearable="!!date"
                              v-bind="attrs"
                              v-on="on"
                              hide-details
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            color="primary"
                            v-model="date"
                            :min="currentDate"
                            :max="activeSessionDate"
                            no-title
                            scrollable
                            @change="$refs.dateMenu.save(date)"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                        cols="12"
                        xs="12"
                        sm="6"
                    >
                      <v-dialog
                          ref="timeMenu"
                          v-model="timeMenu"
                          :return-value.sync="time"
                          persistent
                          max-width="290px"
                          min-width="290px"
                          :disabled="!date"
                          offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              :value="getFormattedTime"
                              outlined
                              dense
                              label="Deadline time of day"
                              prepend-icon="mdi-clock-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :clearable="!!time"
                              :disabled="!date"
                              hide-details
                          ></v-text-field>
                        </template>
                        <v-time-picker
                            color="primary"
                            v-if="timeMenu"
                            v-model="time"
                            :min="currentDate == date ? currentTime : ''"
                            full-width
                            ampm-in-title
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                              text
                              color="primary"
                              @click="timeMenu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.timeMenu.save(time)"
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-btn color="secondary" outlined class="mr-2" @click="currentStep = 3">
                  Previous
                </v-btn>
                <v-btn type="submit" color="primary"
                       :disabled="!messageData.subject || !messageData.message || !time || maxFileSize || badVariables"
                       @click="saveDeadline">
                  Next
                </v-btn>
              </v-layout>
            </v-form>
          </v-stepper-content>

          <v-stepper-step step="5" :complete="currentStep > 5">
            <div class="d-flex align-center">
              <span class="text-4xl font-weight-bold me-3">5</span>
              <div class="d-flex flex-column">
                <span>Final Review</span>
                <small>Use PREVIOUS button if you need to revise before sending. You can always inactivate this AM after
                  sending to Advocates if needed by clicking "Edit".</small>
              </div>
            </div>
          </v-stepper-step>
          <v-stepper-content step="5">
            <v-layout wrap>
              <v-flex xs12 class="mt-3 px-2">
                <v-text-field
                  hide-details
                  outlined
                  dense
                  label="Email subject to Legislator(s)"
                  v-model="messageData.subject"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 class="mt-3 px-2">
                <div class="mb-1 caption grey--text">Email Message to Legislator(s)</div>
                <div class="readonly message-container" v-html="messageData.message"></div>
              </v-flex>
              <v-file-input
                v-if="isFilesForLegislatorInput"
                class="pt-4"
                v-model="filesForLegislator"
                color="primary"
                counter
                label="File input"
                multiple
                placeholder="Select your files"
                prepend-icon=""
                outlined
                :show-size="1000"
                :rules="fileRulesForLegislator"
                dense
                disabled
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    color="primary"
                    dark
                    label
                    small
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
              <v-flex xs12 v-if="attachedScheduledFilesForLegislator != false" class="pb-2">
                <div>
                    <span>
                      Attached files: {{ attachedScheduledFilesForLegislator.length }}
                    </span>
                </div>
                <v-chip
                  v-for="(attachedFile, index) in attachedScheduledFilesForLegislator"
                  :key="index"
                  class="mr-2 mt-2"
                  close
                  @click:close="removeAttachedScheduledFileForLegislator(attachedFile, index)"
                >
                  {{ `${attachedFile.name}` }}
                </v-chip>
              </v-flex>
              <v-flex xs12 class="mt-3 px-2">
                <v-text-field
                    outlined
                    dense
                    label="Deadline"
                    :value="getFormattedDate(messageData.deadline, 'dddd, MMMM D, YYYY, hh:mm A ')"
                    readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 class="mt-3 px-2">
                <v-alert
                    type="warning"
                    prominent
                    text
                    outlined
                    v-if="hasSelectedLegislatorEmail(messageData.selectedLegislators)"
                >
                  The legislators with red icons have no email listed in their profile and will not receive this message!
                </v-alert>
                <v-autocomplete
                    multiple
                    chips
                    outlined
                    dense
                    hide-details
                    :label="`Legislators who will receive this Message from their consituent Advocates (${validSendersLength})`"
                    class="chip-select"
                    item-text="fullname"
                    item-value="id"
                    return-object
                    :items="legislatorList"
                    v-model="messageData.selectedLegislators"
                    readonly
                >
                  <template v-slot:selection="props">
                    <v-chip
                        close
                        pill
                        class="chip--select-multi"
                        :input-value="props.selected"
                        :style=colorsLegislatorsParties(props.item)
                        @click:close="props.parent.selectItem(props.item)"
                        :title="props.item.official_email || props.item.personal_email"
                    >
                      <v-avatar left>
                        <img class="avatar_image"
                             :src="props.item.official_image ? getThumbImage(props.item.official_image) : '/images/member-avatar.png'"
                             :alt="props.item.nickname">
                      </v-avatar>
                      <!-- Start legislator envelops icon -->
                      <v-icon
                          left
                          v-if="!(props.item.personal_email || props.item.official_email)"
                          :color="'error'"
                      >
                        mdi-email
                      </v-icon>
                      <!-- End legislator envelops icon -->
                      <vip-chip
                          v-if="props.item.vip"
                          class="mr-2"
                          :legislator="props.item"
                          :show="'chip_code'"
                      ></vip-chip>
                      {{ props.item.nickname }} {{ props.item.lastname }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex xs12 class="mt-3 px-2">
                <v-expansion-panels class="my-3" v-model="isRecipientsStep5Open">
                  <v-expansion-panel>
                    <v-expansion-panel-header>Senders ({{
                        (messageData.selectedMembers || []).length
                      }})
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-autocomplete
                          multiple
                          chips
                          hide-details
                          :label="'Advocates who will be asked to send this Message (' + (messageData.selectedMembers || []).length + ')'"
                          class="chip-select"
                          :item-text="member => (member.nickname + ' ' + member.lastname)"
                          item-value="id"
                          :items="memberList"
                          v-model="messageData.selectedMembers"
                          readonly
                      >
                        <template v-slot:selection="props">
                          <v-chip
                              close
                              :input-value="props.selected"
                              class="chip--select-multi"
                              :key="props.item.id"
                              :style="kpPreferenceBorder(props.item)"
                              @click:close="props.parent.selectItem(props.item)"
                          >
                            <v-avatar left>
                              <img class="avatar_image"
                                   :src="getAvatar(props.item.avatar_url)"
                                   :alt="props.item.fullname">
                            </v-avatar>
                            {{ props.item.fullname}}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-file-input
                    v-if="isFilesInput"
                    class="pt-4"
                    v-model="files"
                    color="primary"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon=""
                    outlined
                    :show-size="1000"
                    :rules="fileRules"
                    dense
                    disabled
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                        color="primary"
                        dark
                        label
                        small
                    >
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
                <v-flex xs12 v-if="attachedScheduledFiles != false" class="pb-2">
                  <div>
                    <span>
                      Attached files: {{ attachedScheduledFiles.length }}
                    </span>
                  </div>
                  <v-chip
                      v-for="(attachedFile, index) in attachedScheduledFiles"
                      :key="index"
                      class="mr-2 mt-2"
                      close
                      @click:close="removeAttachedScheduledFile(attachedFile, index)"
                  >
                    {{ `${attachedFile.name}` }}
                  </v-chip>
                </v-flex>
              </v-flex>
            </v-layout>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions :class="currentStep === 5 && $vuetify.breakpoint.xsOnly ? 'd-flex flex-column align-center' :
      'd-flex'">
        <div :class="{'d-flex flex-column justify-start' : $vuetify.breakpoint.smAndDown && currentStep
        === 5, 'd-flex ml-auto mr-0': currentStep !== 5}">
          <v-btn v-if="currentStep === 5" color="secondary" outlined class="mr-2 mt-2" @click="currentStep = 4">
            Previous
          </v-btn>
          <v-btn outlined color="secondary" @click.native="closeModal" class="mr-2 mt-2">Cancel
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <div v-if="currentStep === 5"
             :class="$vuetify.breakpoint.smAndDown ? 'd-flex flex-column justify-end' : 'd-flex'">
          <v-btn v-if="isNewModal" color="primary" class="mr-2 mt-2" :disabled="maxFileSize || !messageData.selectedLegislators.some(leg => leg.official_email)" :loading="status ===
        'SAVING'"
                 @click="onSave">
            Save for Later
            <v-icon right dark>save</v-icon>
          </v-btn>
          <v-btn v-if="isNewModal" color="primary" class="mr-2 mt-2" :disabled="maxFileSize || !messageData.selectedLegislators.some(leg => leg.official_email)"
                 :loading="status === 'SENDING_NOW'" @click="onSendNow">
            Send Now
            <v-icon right dark>send</v-icon>
          </v-btn>
          <v-btn
              v-if="!isNewModal"
              color="primary"
              class="mr-2 mt-2"
              :disabled="maxFileSize"
              :loading="status === 'SENDING'"
              @click="onSend"
          >
            Send Now
            <v-icon right dark>send</v-icon>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import swal from 'sweetalert2'
import Editor from '@tinymce/tinymce-vue';
import TinyConfig from "@/mixins/tiny-mce-config"
import VipChip from "@/components/legislators/vip-setting/vip-chip"

import _ from 'lodash'

import AdvocacyMessageService from '@/services/AdvocacyMessageService'
import AppService from '@/services/AppService'

import VDatePicker2 from '@/components/common/date-picker'
import he from "he";
import ShorterUrl from "@/mixins/shorterUrl";
import CustomMessageService from "@/services/CustomMessageService";
import ClientService from "@/services/ClientService";

export default {
  name: 'MessageModal',
  props: ['refresh', 'committeeList', 'legislatorList', 'selectedClient'],
  mixins: [AdvocacyMessageService, AppService, TinyConfig, ShorterUrl, CustomMessageService, ClientService],
  components: {
    'editor': Editor, // Do not change, this is a requirement of tinyMCE
    VDatePicker2,
    'vip-chip': VipChip,
  },
  data() {
    return {
      isModalOpen: false,
      isNewModal: true,
      currentStep: 1,
      messageData: {},
      selectedCommitteeIdList: [],
      selectedSubCommitteeIdList: [],
      memberList: [],
      isPreview: false,
      isSecondPreview: false,
      formattedMessage: '',
      invitationSubjectVariable: '',
      formattedInvitationSubject: '',
      formattedInvitationMessage: '',
      formattedSubject: '',
      subjectVariable: '',
      searchInputFull: '',
      attachedFilesSize: 0,
      attachedFilesSizeForLegislator: 0,
      currentFilesSizeForLegislator: 0,
      displaySelected: {},
      attachedFiles: [],
      displayExceptSelected: [],
      attachedFilesForLegislator: [],
      attachedScheduledFiles: [],
      attachedScheduledFilesForLegislator: [],
      error: '',
      status: '',
      isFilesInput: false,
      activeSession: {},
      files: [],
      currFiles: [],
      filesForLegislator: [],
      isFilesForLegislatorInput: false,
      currFilesForLegislator: [],
      isMessageLoading: false,
      variable2: '',
      variable: '',
      isRecipientsStep5Open: false,
      isRecipientsStep2Open: false,
      dateMenu: false,
      timeMenu: false,
      time: null,
      date: null,
      loading: false,
    }
  },
  computed: {
    isFederalClient(){
      return this.selectedClient && this.selectedClient.is_use_state === 0
    },
    fileRulesForLegislator() {
      return [
        files => !files || this.filesSizeForLegislator(files) < this.fileSizeLimit || 'Files are too big! Max for all is 15MB.' +
            ' Shrink them or consider linking instead within the email.'
      ]
    },
    tinySmsToolbars() {
      return []
    },
    variableSubjectList() {
      return [
        '{advocate_first}',
        '{advocate_last}',
        '{leg_fullname}',
        '{leg_salutation}',
        '{leg_local_phone}',
        '{district}',
      ]
    },
    colorTextCounter() {
      return this.finalMessageLength > 320 ? 'color: red !important' : 'color: grey';
    },
    badCellAndEmails() {
      return this.memberList.filter(member => (!member.cell || member.bad_cell) && this.isBad(member.email))
    },
    badEmails() {
      return this.memberList.filter(member => this.isBad(member.email))
    },
    sortLegislatorsList() {
      const house = this.legislatorList.filter(legislator => legislator.district.district[0] === 'H')
      const senate = this.legislatorList.filter(legislator => legislator.district.district[0] === 'S')
      return [
        ...this.checkAndSortArray(house, 'House'),
        ...this.checkAndSortArray(senate, 'Senate')
      ]
    },
    getFormattedTime() {
      return this.time ? moment(this.time, "hh:mm").format('h:mm a') : ''
    },
    currentDate() {
      return new Date().toISOString().substr(0, 10)
    },
    activeSessionDate() {
      return  this.activeSession ?  moment(this.activeSession.end_at).toISOString().substr(0, 10) : this.currentDate
    },
    currentTime() {
      const timezone = this.getTimezone(this.selectedClient);
      return moment().tz(timezone).add(1, 'hour').format('HH:mm');
    },
    titleModalHasEzLink() {
      return 'Oops, the {ez_login} variable must be present!'
    },
    hasEzLogin() {
      let reg = /{ez_login}/
      if (this.messageData.invitation_message) {
        return reg.test(this.messageData.invitation_message)
      }
    },
    gradYearList() {
      const thisYear = moment().year()
      return _.range(1940, thisYear + 1)
    },
    ageList() {
      return _.range(25, 81)
    },
    variableList1() {
      return [
        '{advocate_first}',
        '{advocate_last}',
        '{leg_nick}',
        '{leg_last}',
        '{leg_fullname}',
        '{leg_salutation}',
        '{leg_email}',
        '{leg_ph}',
        '{leg_local_phone}',
        '{district}',
        '{live/work}',
      ]
    },
    variableList2() {
      return [
        '{advocate_first}',
        '{advocate_last}',
        '{leg_local_phone}',
        '{ez_login}',
      ]
    },
    defaultMessageContent() {
      if (this.messageData.via_sms) {
        return `${this.selectedClient.assoc_abbrev} ADVOCACY ALERT!\nHi {advocate_first},\nPlease click the link below to send an important Advocacy message to your legislator. Thank you!\n{ez_login}`
      }
      return `<p>Dear {advocate_first},</p>
              <p><br /></p>
              <p>Please click link below to send an important Advocacy message to your legislator.</p>
              <p>A template has been created for you which may be edited before sending.</p>
              <p>Thank you!</p>
              <p><br /></p>
              <p>{ez_login}</p>`
    },
    finalMessageLength() {
      let kpNick = ''
      let kpLast = ''
      let legNick = ''
      let legLast = ''
      let legFull = ''
      let legEmail = ''
      let legPh = ''
      let legLocPh = ''
      let district = ''
      const ezLogin = 'https://kpda.sh/ez/xxxxxxxx?s=adv'

      const selectedLegislators = this.legislatorList.filter(legislator => this.messageData.selectedLegislators.includes(legislator.id))
      const selectedMembers = this.memberList.filter(member => this.messageData.selectedMembers.includes(member.id))

      selectedMembers.forEach((member) => {
        if (kpNick.length < (member.nickname || '').length) kpNick = member.nickname
        if (kpLast.length < (member.lastname || '').length) kpLast = member.lastname
      })
      selectedLegislators.forEach((legislator) => {
        if (legNick.length < (legislator.nickname || '').length) legNick = legislator.nickname
        if (legLast.length < (legislator.lastname || '').length) legLast = legislator.lastname
        if (legFull.length < (legislator.fullname || '').length) legFull = legislator.fullname
        if (legEmail.length < (legislator.official_email || '').length) legEmail = legislator.official_email
        if (legPh.length < (legislator.official_phone || '').length) legPh = legislator.official_phone
        if (legLocPh.length < (legislator.local_phone || '').length) legLocPh = legislator.local_phone
        if (district.length < (legislator.district ? legislator.district.district : '').length) district = legislator.district.district
      })

      let message = this.messageData.invitation_message || ''
      message = message.replace(/{advocate_first}/g, kpNick)
      message = message.replace(/{advocate_last}/g, kpLast)
      message = message.replace(/{leg_nick}/g, legNick)
      message = message.replace(/{leg_last}/g, legLast)
      message = message.replace(/{leg_fullname}/g, legFull)
      message = message.replace(/{leg_email}/g, legEmail)
      message = message.replace(/{leg_ph}/g, legPh)
      message = message.replace(/{district}/g, district)
      message = message.replace(/{ez_login}/g, ezLogin)
      message = message.replace(/{leg_local_phone}/g, legLocPh)

      message = this.html2Text(message)

      return message.length
    },
    isForm3Valid() {
      return this.messageData.invitation_subject
          && this.finalMessageLength > 0
          && (this.messageData.via_sms === 0 || (this.messageData.via_sms === 1 && this.finalMessageLength <= 320))
    },
    selectedCommitteeList() {
      let selectedCommitteeList = []

      if (this.messageData.isHouseDistrictsOnly) {
        selectedCommitteeList = [...this.committeeList
            .filter(committee => committee.type === 0 && (this.selectedClient.house_committees || [])
                .includes(committee.id))
        ]
      }
      if (this.messageData.isSenateDisctrictsOnly) {
        selectedCommitteeList = [
          ...selectedCommitteeList,
          ...this.committeeList
              .filter(committee => committee.type === 1 && (this.selectedClient.senate_committees || [])
                  .includes(committee.id))
        ]
      }
      if (this.messageData.isJointDisctrictsOnly) {
        selectedCommitteeList = [
          ...selectedCommitteeList,
          ...this.committeeList
              .filter(committee => committee.type === 2 && (this.selectedClient.joint_committees || [])
                  .includes(committee.id))
        ]
      }

      const committeeTypeList = ['H', 'S', 'J']

      selectedCommitteeList.forEach((committee) => {
        committee.label = `${committee.fullname} (${committeeTypeList[committee.type]})`
        committee.isSubCommittee = false
      })

      return selectedCommitteeList
    },
    validSendersLength() {
      if (!this.messageData.selectedLegislators || !this.messageData.selectedLegislators.length) return 0

      return this.messageData.selectedLegislators
        .filter(legislator => legislator.personal_email || legislator.official_email)
        .length
    },
  },
  methods: {
    onSelectSubcommitteeHandler(committee) {
      if(!committee){
        return;
      }
      const parentCommittee = this.committeeList.find(_committee => _committee.id === committee.parent_id)

      const subcommitteeIds = parentCommittee.subcommittees.map(subcommittee => subcommittee.id)

      this.selectedSubCommitteeIdList = this.selectedSubCommitteeIdList.filter(subcommitteeId => !subcommitteeIds.includes(subcommitteeId))

      this.selectedCommitteeIdList = this.selectedCommitteeIdList.filter(committeeId => committeeId !== committee.parent_id)

      this.onSelectCommittee(committee, true)
    },
    onClickClearSubcommitteeHandler(parentCommittee){
      parentCommittee.subcommittees.forEach(subcommittee => {
        this.selectedSubCommitteeIdList = this.onSelectCommitteeIdList(this.selectedSubCommitteeIdList, subcommittee.id)
      })
      this.onFilterLegislators()
    },
    hasSelectedLegislatorEmail(legislatorsList){
      if(legislatorsList && legislatorsList.length) {
        return !legislatorsList.every(v => v.personal_email || v.official_email);
      } return false;

    },
    filterMemberList() {
      this.messageData.selectedMembers = this.memberList.filter(member => {
        return this.messageData.selectedMembers.some(_member => {
          // if _member is object
          let memberId = _member;
          if (typeof _member === 'object') {
            memberId = _member.id;
          }

          const memberLegislators = this.messageData.selectedLegislators
            .filter(legislator => legislator.member_legislators
              .find(member => member.member_id === memberId))
          // Check if all legislators have ANY email
          const isLegislatorsValid = !this.hasSelectedLegislatorEmail(memberLegislators)

          return memberId === member.id && isLegislatorsValid
        })
      })
    },
    onAddVariableToInvitationSubject(variable) {
      this.formattedSubject = ''
      let field = document.querySelector('#invitationSubject')
      if (!this.isPreview) {
        if (field.selectionStart || field.selectionStart == '0') {
          let startPos = field.selectionStart;
          let endPos = field.selectionEnd;
          this.formattedSubject = field.value.substring(0, startPos)
              + variable
              + field.value.substring(endPos, field.value.length);
          this.messageData.invitation_subject = field.value.substring(0, startPos)
              + variable
              + field.value.substring(endPos, field.value.length);
        } else {
          this.messageData.invitation_subject = variable
          this.formattedSubject = variable
        }
      }
    },
    getIndex(id, array) {
      return array.findIndex(item => item.id === id)
    },
    removeAttachedFilesForLegislator() {
      this.filesForLegislator = []
      this.isFilesForLegislatorInput = false
      this.currFilesForLegislator = []
    },
    filesForLegislatorInputChanged () {
      this.currFilesForLegislator = [
        ...this.currFilesForLegislator,
        ...this.filesForLegislator
      ]
      this.currFilesForLegislator = [...new Set(this.currFilesForLegislator)]
      this.filesForLegislator = [...new Set(this.currFilesForLegislator)]
    },
    removeAttachedFileForLegislator(index) {
      this.filesForLegislator.splice(index, 1)
      this.currFilesForLegislator.splice(index, 1)
    },
    checkAndSortArray(array, name) {
      if (array.length) {
        array.sort((a, b) => a.lastname > b.lastname ? 1 : -1)
        array.push({divider: true})
        array.unshift({header: name})
      }
      return array
    },
    async inputInvitation() {
      this.$nextTick(async () => {
        this.searchIncorrectVariables(this.messageData.invitation_message, this.variableList2)
        const newContent = await this.parsUrl(this.messageData.invitation_message)
        if (newContent !== this.messageData.invitation_message) {
          this.messageData.invitation_message = newContent

          this.disabledEditor = false
          document.activeElement.blur()
        }
      })
    },
    async inputMessage() {
      this.$nextTick(async() => {
        this.searchIncorrectVariables(this.messageData.message, this.variableList1)
        const newContent = await this.parsUrl(this.messageData.message)
        if (newContent !== this.messageData.message) {
          this.messageData.message = newContent

          this.disabledEditor = false
          document.activeElement.blur()
        }
      })
    },
    removeLegislator(index, array, item) {
      swal({
        title: `Remove ${item.fullname}?`,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#4caf50',
        cancelButtonColor: '#9E9E9E',
        confirmButtonText: '&#10003 Remove',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          //When "select my own" option. Then only remove from list. will be not add expect list.
          if(!(this.messageData && this.messageData.isMyOwn)) {
            this.messageData.exceptLegislators.push(item)
          }
          array.splice(index, 1)
        }
      })
    },
    onAddVariableToSubject(variable) {
      let field = document.querySelector('#subject')
      if (!this.isPreview) {
        if (field.selectionStart || field.selectionStart == '0') {
          let startPos = field.selectionStart;
          let endPos = field.selectionEnd;
          this.formattedSubject = field.value.substring(0, startPos)
              + variable
              + field.value.substring(endPos, field.value.length);
          this.messageData.subject = field.value.substring(0, startPos)
              + variable
              + field.value.substring(endPos, field.value.length);
        } else {
          this.messageData.subject = variable
          this.formattedSubject = variable
        }
      }
    },
    saveDeadline() {
      this.messageData.deadline = this.date + ' ' + this.time
    },
    parseDate(date) {
      this.date = moment(date).format('YYYY-MM-DD')
    },
    parseTime(time) {
      this.time = moment(time).format('hh:mm')
    },
    bytesToMb(size) {
      return parseFloat(size / 1048576).toFixed(2)
    },
    getCurrentSession() {
      this.getClientActiveSession(this.selectedClient.id)
        .then(response => {
          this.activeSession = response.data
        })
        .catch(err => {
          console.log('err', err)
          this.onCallToastr('error', 'Something went wrong.', 'Error!')
        })
    },
    async toggle(advocacyMessage) {
      this.loading = true
      this.isModalOpen = true
      this.isPreview = false
      this.isSecondPreview = false
      this.files = []
      this.attachedFilesSize = 0
      this.attachedFilesSizeForLegislator = 0
      this.filesForLegislator = []
      this.attachedScheduledFiles = []
      this.attachedScheduledFilesForLegislator = []
      this.currentFilesSizeForLegislator = 0
      this.error = ''
      this.date = null
      this.time = null
      this.getCurrentSession()

      if (advocacyMessage && advocacyMessage.id) {
        this.isNewModal = false
        this.currentStep = 5
        const legislators = this.legislatorList.filter(leg => {
          return advocacyMessage.legislators.some(legislatorId => legislatorId === leg.id)
        })

        const additionalFilters = advocacyMessage.additional_filters || {};

        this.messageData = {
          id: advocacyMessage.id,
          subject: advocacyMessage.subject,
          message: advocacyMessage.message,
          isAllLegislators: additionalFilters.isAllLegislators * 1 || false,
          isByCommittee: additionalFilters.isByCommittee * 1 || false,
          isMyOwn: additionalFilters.isMyOwn * 1 || false,
          chooseLegislatorType: additionalFilters.chooseLegislatorType || 'all_legislators',
          selectedLegislators: legislators,
          exceptLegislators: [],
          isKPOnly: advocacyMessage.is_kp_only,
          usesAge: false,
          fromAge: 25,
          toAge: 80,
          deadline: advocacyMessage.deadline,
          usesGradYear: false,
          fromGradYear: 1940,
          toGradYear: moment().year(),
          selectedMembers: advocacyMessage.members,
          invitation_subject: advocacyMessage.invitation_subject,
          invitation_message: advocacyMessage.invitation_message,
          via_sms: advocacyMessage.via_sms,
        }

        if (advocacyMessage.files && advocacyMessage.files.length) {
          this.attachedScheduledFilesForLegislator = advocacyMessage.files.filter(file => file.pivot.for_legislator)
          this.attachedScheduledFiles = advocacyMessage.files.filter(file => !file.pivot.for_legislator);
          this.attachedScheduledFiles.forEach(file => this.attachedFilesSize = + file.size)
          this.attachedScheduledFilesForLegislator.forEach(file => this.attachedFilesSizeForLegislator = + file.size)
        }
        this.parseDate(advocacyMessage.deadline)
        this.parseTime(advocacyMessage.deadline)
        this.onSearchMembers(false, advocacyMessage.members || [])
        this.loading = false
      } else {

        this.isNewModal = true
        this.currentStep = 1
        this.messageData = {
          subject: 'A request from Dr. {advocate_first} {advocate_last} a constituent of District {district}',
          message: '<p>Dear {leg_salutation},</p><br /><p>Hello, my name is Dr. {advocate_first} {advocate_last} and I {live/work} in your district. I am writing you today to ask you to please support SBXXX. </p><br /><p>Sincerely,<br />Dr. {advocate_first} {advocate_last}<br />Doctor of Optometry</p>',
          isAllLegislators: false,
          isByCommittee: false,
          isMyOwn: false,
          chooseLegislatorType: 'all_legislators',
          selectedLegislators: [],
          exceptLegislators: [],
          isKPOnly: 0,
          usesAge: false,
          fromAge: 25,
          toAge: 80,
          usesGradYear: false,
          fromGradYear: 1940,
          toGradYear: moment().year(),
          selectedMembers: [],
          invitation_subject: `[URGENT] ${this.selectedClient.assoc_abbrev} Advocacy Alert!`,
          via_sms: 1,
        }
        this.messageData.invitation_message = this.defaultMessageContent
        this.messageData = {...this.messageData}
        this.onFilterLegislatorsType()
        this.loading = false
      }
    },
    uploadMessageTemplate(message) {
      this.messageData.invitation_message = message.body
    },
    filesSize(files) {
      let sum = 0;
      files.forEach(file => {
        sum += file.size;
      })
      this.currentFilesSize = sum + this.attachedFilesSize

      return sum;
    },
    filesSizeForLegislator(files) {
      let sum = 0;
      files.forEach(file => {
        sum += file.size;
      })
      this.currentFilesSizeForLegislator = sum + this.attachedFilesSizeForLegislator

      return sum;
    },
    async removeAttachedScheduledFile(file, index) {
      let size = this.attachedScheduledFiles[index].size
      this.attachedScheduledFiles.splice(index, 1)
      this.currentFilesSize -= size
      this.attachedFilesSize -= size
    },
    async removeAttachedScheduledFileForLegislator(file, index) {
      let size = this.attachedScheduledFilesForLegislator[index].size
      this.attachedScheduledFilesForLegislator.splice(index, 1)
      this.currentFilesSizeForLegislator -= size
      this.attachedFilesSizeForLegislator -= size
    },
    onAddVariable(variable, messageType) {
      variable += ' '
      tinymce.activeEditor.execCommand('mceInsertContent', false, variable);
    },
    onFilterLegislatorsType() {
      this.loading = true
      
      if (this.messageData.isByCommittee) {
        this.messageData.isHouseDistrictsOnly = false
        this.messageData.isSenateDisctrictsOnly = false
        this.messageData.isJointDisctrictsOnly = false
        this.messageData.selectedLegislators = []
        this.messageData.exceptLegislators = []
        this.selectedCommitteeIdList = []
      } else if(this.messageData.isMyOwn) {
        this.messageData.selectedLegislators = []
        this.messageData.exceptLegislators = []
      } else {
        this.messageData.selectedLegislators = this.legislatorList
        this.messageData.exceptLegislators = []
      }
      this.messageData = _.cloneDeep(this.messageData)
      this.loading = false
    },
    onSelectDistrictType() {
      this.loading = true
      this.messageData.exceptLegislators = []
      let selectedCommitteeIdList = []
      if (this.messageData.isJointDisctrictsOnly) {
        selectedCommitteeIdList = [
          ...selectedCommitteeIdList,
          ...this.committeeList.filter(committee => committee.type === 2 && (this.selectedClient.joint_committees || []).includes(committee.id)).map(committee => committee.id)
        ]
      }
      this.selectedCommitteeIdList = selectedCommitteeIdList
      this.onFilterLegislators()
      this.loading = false
    },
    onSelectCommittee(committee, sub = false) {
      this.messageData.exceptLegislators = []
      if (sub) {
        if (this.selectedSubCommitteeIdList.includes(committee.id)) {
          this.selectedSubCommitteeIdList = this.onSelectCommitteeIdList(this.selectedSubCommitteeIdList, committee.id)
        } else {
          this.selectedSubCommitteeIdList.push(committee.id)
          this.selectedCommitteeIdList = this.onSelectCommitteeIdList(this.selectedCommitteeIdList, committee.id)
        }
      } else {
        if (this.selectedCommitteeIdList.includes(committee.id)) {
          this.selectedCommitteeIdList = this.onSelectCommitteeIdList(this.selectedCommitteeIdList, committee.id)
        } else {
          this.selectedCommitteeIdList.push(committee.id)
        }
      }
      this.onFilterLegislators()
    },
    onSelectCommitteeIdList(arr, id) {
      return arr.filter(_committeeId => _committeeId !== id)
    },
    onFilterLegislators() {
      this.loading = true
      let selectedLegislators = this.legislatorList.filter((legislator) => {
        if (this.selectedCommitteeIdList.length === 0 && this.selectedSubCommitteeIdList.length === 0) {
          if (this.messageData.isHouseDistrictsOnly && this.messageData.isSenateDisctrictsOnly) {
            return legislator.district.district[0] === 'H' || legislator.district.district[0] === 'S'
          } else if (this.messageData.isHouseDistrictsOnly) {
            return legislator.district.district[0] === 'H'
          } else if (this.messageData.isSenateDisctrictsOnly) {
            return legislator.district.district[0] === 'S'
          }
        }

        if (!legislator.legislator_committees || legislator.legislator_committees.length < 1) return false

        const subCommitteeIds = legislator.legislator_committees
            // .filter(legislatorCommittee => legislatorCommittee.membership_states.some(membershipState => membershipState.pivot.subcommittee === 1))
            .map(legislatorCommittee => legislatorCommittee.committee_id)

        const subFilter = this.selectedSubCommitteeIdList.find(sub => subCommitteeIds.includes(sub))
        if (subFilter) return true

        const committeeId = legislator.legislator_committees
            .filter(legislatorCommittee => legislatorCommittee.membership_states.some(membershipState => membershipState.pivot.subcommittee === 0))
            .map(legislatorCommittee => legislatorCommittee.committee_id)

        const mainFilter = this.selectedCommitteeIdList.find(committee => committeeId.includes((committee)))
        if (mainFilter) return true

        if (subCommitteeIds.length === 0 && committeeId.length === 0) return false
      })

      this.messageData.selectedLegislators = selectedLegislators
      this.messageData = _.cloneDeep(this.messageData)
      this.loading = false
    },
    getLegislatorIds(legislators) {
      if (legislators.length) {
        return legislators.map(leg => leg.id ? leg.id : leg)
      }
      return legislators
    },
    removeExceptLegislator(index, array) {
      array.splice(index, 1)
    },
    onSearchMembers(needUpdate, selectedMembers = []) {
      this.status = 'LOADING_MEMBERS'
      const payload = {
        client_id: this.selectedClient.id,
        is_kp_only: (this.messageData.isKPOnly === 0 || this.messageData.isKPOnly === 2) ? 0 : 1,
        legislators: this.getLegislatorIds(this.messageData.selectedLegislators),
        reduceLegislators: this.getLegislatorIds(this.messageData.exceptLegislators),
      }
      this.searchMembers(payload)
          .then((res) => {
            this.status = ''
            this.memberList = res.data
            if (this.messageData.usesAge) {
              this.memberList = this.memberList.filter((member) => {
                if (!member.dob) return
                const age = moment().diff(member.dob, 'years');
                return age >= this.messageData.fromAge && age <= this.messageData.toAge
              })
            }
            if (this.messageData.usesGradYear) {
              this.memberList = this.memberList.filter(member => member.grad_year && member.grad_year >= this.messageData.fromGradYear && member.grad_year <= this.messageData.toGradYear)
            }
            if (needUpdate && this.messageData.isKPOnly !== 2) {
              this.messageData.selectedMembers = [...new Set(this.memberList.map(member => member.id))]
              this.messageData = _.cloneDeep(this.messageData)
            } else {
              this.messageData.selectedMembers = []
            }
            if (selectedMembers.length > 0) {
              this.messageData.selectedMembers = this.memberList.filter(member => {
                return selectedMembers.some(memberId => memberId === member.id)
              })
            }
          })
          .catch((err) => {
            this.status = ''
            this.handleError(err)
          })
    },
    onChangeMessageType() {
      this.$nextTick(() => {
        this.badVariables = false
        this.messageData = {
          ...this.messageData,
          invitation_message: this.defaultMessageContent,
        }
      })
    },
    async onPreviewMessage(message) {
      this.isMessageLoading = true
      let payload = {
        legislator_id: this.messageData.selectedLegislators[0].id,
        member_id: this.messageData.selectedMembers[0].id ? this.messageData.selectedMembers[0].id : this.messageData.selectedMembers[0],
      }

      if (this.isPreview) {
        if (!this.messageData.invitation_message) {
          this.formattedMessage = ''
        } else {
          payload.message = message.invitation_message
          this.formattedInvitationMessage = (await this.getAppFormattedMessage(payload)).data
          this.isMessageLoading = false
        }

        if (!this.messageData.invitation_subject) {
          this.formattedInvitationSubject = ''
        } else {
          payload.message = message.invitation_subject
          this.formattedInvitationSubject = (await this.getAppFormattedMessage(payload)).data
          this.isMessageLoading = false
        }
      } else if (this.isSecondPreview) {
        if (!this.messageData.message) {
          this.formattedMessage = ''
        } else {
          payload.message = message.message
          this.formattedMessage = (await this.getAppFormattedMessage(payload)).data
          this.isMessageLoading = false
        }

        if (!this.messageData.subject) {
          this.formattedSubject = ''
        } else {
          payload.message = message.subject
          this.formattedSubject = (await this.getAppFormattedMessage(payload)).data
          this.isMessageLoading = false
        }
      } else {
        this.isMessageLoading = false
        payload = {}
      }
    },
    onModalHasEzLink() {
      if (!this.hasEzLogin) {
        swal({
          text: this.titleModalHasEzLink,
          type: 'warning',
          showCancelButton: true,
          showConfirmButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Next',
          cancelButtonText: 'Ok, I will fix it',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.isPreview = false
            this.isSecondPreview = false
            this.currentStep = 4
          }
        })
      } else {
        this.isPreview = false
        this.isSecondPreview = false
        this.currentStep = 4
      }
    },
    onNextStep() {
      if (this.currentStep === 1) {
        if (!this.$refs.step1Form.validate()) return
        this.onSearchMembers(true)
        this.currentStep = 2
      } else if (this.currentStep === 2) {
        this.badVariables = false
        this.isPreview = false
        this.isSecondPreview = false
        this.currentStep = 3
      } else if (this.currentStep === 3) {
        this.messageData.invitation_message = this.removeHighlight(this.messageData.invitation_message)
        this.badVariables = false
        this.onModalHasEzLink()
      } else if (this.currentStep === 4) {
        this.messageData.message = this.removeHighlight(this.messageData.message)
        this.badVariables = false
        this.currentStep = 5
        this.filterMemberList()
      }
    },
    clearDateTime() {
      this.time = null
      this.date = null
    },
    onSave() {
      this.clearDateTime()
      this.status = 'SAVING'
      
      const additionalFilter = {
          isHouseDistrictsOnly: this.messageData.isHouseDistrictsOnly,
          isSenateDisctrictsOnly: this.messageData.isSenateDisctrictsOnly,
          isJointDisctrictsOnly: this.messageData.isJointDisctrictsOnly,
          isAllLegislators: this.messageData.isAllLegislators,
          isByCommittee: this.messageData.isByCommittee,
          isMyOwn: this.messageData.isMyOwn,
          chooseLegislatorType: this.messageData.chooseLegislatorType,
        };

      const payload = {
        client_id: this.selectedClient.id,
        subject: this.messageData.subject,
        message: this.messageData.message,
        deadline: this.messageData.deadline,
        legislators: this.getLegislatorIds(this.messageData.selectedLegislators),
        members: this.getLegislatorIds(this.messageData.selectedMembers),
        is_kp_only: (this.messageData.isKPOnly === 0 || this.messageData.isKPOnly === 2) ? 0 : 1,
        invitation_subject: this.messageData.invitation_subject,
        invitation_message: this.messageData.invitation_message,
        via_sms: +!!this.messageData.via_sms,
        files: this.files,
        filesForLegislator: this.filesForLegislator,
        additional_filters: additionalFilter
      }

      let data = this.packageData(payload)
      this.createAdvocacyMessage(data)
          .then(() => {
            this.status = ''
            this.onCallToastr('success', 'New message has been created.', 'Success!')
            this.refresh()
            this.isModalOpen = false
              this.$emit('closeModal')
          })
          .catch((err) => {
            this.status = ''
            this.handleError(err)
          })
    },
    onSendNow() {
      this.status = 'SENDING_NOW'
      this.clearDateTime()
      swal({
        title: 'Ready to send?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Send now',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          
          if (this.messageData.id){
            this.deleteAdvocacyMessage(this.messageData.id);
          }

          const additionalFilter = {
            isHouseDistrictsOnly: this.messageData.isHouseDistrictsOnly,
            isSenateDisctrictsOnly: this.messageData.isSenateDisctrictsOnly,
            isJointDisctrictsOnly: this.messageData.isJointDisctrictsOnly,
            isAllLegislators: this.messageData.isAllLegislators,
            isByCommittee: this.messageData.isByCommittee,
            isMyOwn: this.messageData.isMyOwn,
            chooseLegislatorType: this.messageData.chooseLegislatorType,
          };

          let payload = {
            client_id: this.selectedClient.id,
            subject: this.messageData.subject,
            message: this.messageData.message,
            deadline: this.messageData.deadline,
            legislators: this.getLegislatorIds(this.messageData.selectedLegislators),
            members: this.getLegislatorIds(this.messageData.selectedMembers),
            is_kp_only: (this.messageData.isKPOnly === 0 || this.messageData.isKPOnly === 2) ? 0 : 1,
            invitation_subject: this.messageData.invitation_subject,
            invitation_message: this.messageData.invitation_message,
            via_sms: +!!this.messageData.via_sms,
            files: this.files,
            filesForLegislator: this.filesForLegislator,
            additional_filters: additionalFilter
          }

          let data = this.packageData(payload)
          try {
            this.createAdvocacyMessage(data).then((response) => {
              this.sendAdvocacyMessage(response.data.id)
              this.onCallToastr('success', 'New message has been sent to assigned Advocates.', 'Success!')
              this.refresh()
              this.status = ''
              this.isModalOpen = false
              this.$emit('closeModal')
            })
          } catch (err) {
            this.status = ''
            this.handleError(err)
          }
        }
      })
    },
    onSend() {
      this.status = 'SENDING'
      this.clearDateTime()
      swal({
        title: 'Ready to send?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Send now',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {

          const attachedFiles = this.attachedScheduledFiles
              .concat(this.attachedScheduledFilesForLegislator)
              .map(item => item.id);

          const additionalFilter = {
            isHouseDistrictsOnly: this.messageData.isHouseDistrictsOnly,
            isSenateDisctrictsOnly: this.messageData.isSenateDisctrictsOnly,
            isJointDisctrictsOnly: this.messageData.isJointDisctrictsOnly,
            isAllLegislators: this.messageData.isAllLegislators,
            isByCommittee: this.messageData.isByCommittee,
            isMyOwn: this.messageData.isMyOwn,
            chooseLegislatorType: this.messageData.chooseLegislatorType,
          };

          const payload = {
            id: this.messageData.id,
            client_id: this.selectedClient.id,
            subject: this.messageData.subject,
            message: this.messageData.message,
            deadline: this.messageData.deadline,
            legislators: this.getLegislatorIds(this.messageData.selectedLegislators),
            members: this.getLegislatorIds(this.messageData.selectedMembers),
            is_kp_only: (this.messageData.isKPOnly === 0 || this.messageData.isKPOnly === 2) ? 0 : 1,
            invitation_subject: this.messageData.invitation_subject,
            invitation_message: this.messageData.invitation_message,
            via_sms: +!!this.messageData.via_sms,
            files: this.files,
            attachedFiles: attachedFiles,
            filesForLegislator: this.filesForLegislator,
            additional_filters: additionalFilter
          }

          try {
            this.updateAdvocacyMessage(payload).then(() => {
              this.sendAdvocacyMessage(payload.id)
              this.onCallToastr('success', 'Message has been sent to assigned Advocates.', 'Success!')
              this.refresh()
              this.status = ''
              this.isModalOpen = false
              this.$emit('closeModal')
            })
          } catch (err) {
            this.status = ''
            this.handleError(err)
          }
          this.status = ''
        }
      })
    },
    closeModal() {
      this.clearDateTime()
      this.removeAllAttachedFiles()
      this.filesForLegislator = []
      this.isModalOpen = false
      this.$emit('closeModal')
    },
    onModalCloseHandler(){
      this.isModalOpen = false
      this.$emit('closeModal')
    },
    html2Text(html) {
      let find = /(<p><br[\/]?><[\/]?p>)/,
          re = new RegExp(find, 'g');

      let txt = html.replace(re, '\n');
      txt = txt.replace(/<[^>]*>?/gm, '')
      return he.decode(txt)
    },
    getAvatar(avatar_url) {
      if (avatar_url === null) {
        return './images/member-avatar.jpg'
      }
      return avatar_url
    }
  },
  watch: {
    badVariables: function () {
      if (this.currentStep === 3) {
        this.searchIncorrectVariables(this.messageData.invitation_message, this.variableList2)
        if (!this.badVariables) {
          this.checkVariablesForEditor(this.messageData.invitation_message, this.variableList2)
        }
      } else if (this.currentStep === 4) {
        this.searchIncorrectVariables(this.messageData.message, this.variableList1)
        if (!this.badVariables) {
          this.checkVariablesForEditor(this.messageData.message, this.variableList1)
        }
      }
    },
    'messageData.chooseLegislatorType': function (newValue, oldValue){
      if(newValue !== oldValue) {
        this.messageData.isByCommittee = !!(newValue === 'by_committee');
        this.messageData.isMyOwn = !!(newValue === 'my_own');
        this.messageData.isAllLegislators = !!(newValue === 'all_legislators');

        this.onFilterLegislatorsType();
      }
    }
  }
}
</script>
