<template>
  <v-dialog max-width="500" scrollable persistent v-model="dialog" >
    <v-card class="message-modal-content">
      <v-card-title>
        <span class="title">
          {{ form.id > 0 ? 'Edit custom field' : 'Create custom field' }}
          <v-progress-circular
              indeterminate
              color="primary"
              size="20"
              width="2"
              v-if="loading"
          ></v-progress-circular>
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-3">
        <div v-if="!form.id">
          <v-select
              v-model="form.type"
              :error-messages="errorMessages('type')"
              :items="fields"
              item-value="value"
              item-text="title"
              label="Select field type"
          >
          </v-select>
        </div>
        <div v-if="form.type == 'text'">
          <v-text-field label="Field label" v-model="form.name" :error-messages="errorMessages('name')"></v-text-field>
        </div>
        <div v-if="form.type == 'checkbox'">
          <v-text-field label="Field label" v-model="form.name" :error-messages="errorMessages('name')"></v-text-field>
          <div v-if="form.metadata && form.metadata.length">
            <draggable tag="div" :list="form.metadata" handle=".handle1">
              <div
                style="display: flex; align-items: center"
                v-for="(item, index) in form.metadata"
                :key="index"
              >
                <i class="fa fa-align-justify dd-icon handle1"></i>

                <v-text-field
                  v-if="!form[item.name]"
                  v-model="item.name"
                  append-outer-icon="close"
                  @click:append-outer="removeOption(index)"
                  :label="`Option0 #${index + 1}`"
                >
                </v-text-field>
                <v-text-field
                  v-else
                  v-model="item.name"
                  disabled
                  append-outer-icon="close"
                  :label="`Option1 #${index + 1}`"
                >
                </v-text-field>
              </div>
            </draggable>
          </div>
          <div>
            <v-btn @click="addOption" small block color="info">add option</v-btn>
          </div>
        </div>
        <div v-if="form.type == 'toggle'">
          <v-text-field label="Field label" v-model="form.name" :error-messages="errorMessages('name')"></v-text-field>
        </div>
        <div v-if="form.type == 'dropdown'">
          <v-text-field label="Field label" v-model="form.name" :error-messages="errorMessages('name')"></v-text-field>
          <div v-if="form.metadata && form.metadata.length">
            <draggable tag="div" :list="form.metadata" handle=".handle2">
              <div 
                style="display: flex; align-items: center"
                v-for="(item, index) in form.metadata" 
                :key="index">

                <i class="fa fa-align-justify dd-icon handle2"></i>
                <v-text-field
                  v-if="!form[item.name]"
                  v-model="item.name"
                  append-outer-icon="close"
                  @click:append-outer="removeOption(index)"
                  :label="`Option #${index + 1}`"
                >
                </v-text-field>
                <v-text-field
                  v-else
                  v-model="item.name"
                  disabled
                  append-outer-icon="close"
                  :label="`Option #${index + 1}`"
                >
                </v-text-field>
              </div>
            </draggable>
          </div>
          <div>
            <v-btn @click="addOption" small block color="info">add option</v-btn>
          </div>
        </div>
        <div>
          <v-textarea 
            rows="2" 
            label="Description/Explanation for users" 
            v-model="form.hint" 
            :error-messages="errorMessages('hint')" 
            auto-grow
            maxlength="255"
          ></v-textarea>
        </div>

        <div class="mt-2">
          <div>
            

            <v-switch
              color="primary"
              hide-details
              v-model="form.is_conditional"
              label="Conditional field"
              class="mt-0"
              @change="conditionalFieldChange"
            ></v-switch>
          </div>
          <div class="mt-4" v-if="form.is_conditional">
            <v-select
                v-model="form.custom_field_id"
                :error-messages="errorMessages('custom_field_id')"
                :items="customFields"
                item-value="id"
                item-text="name"
                label="Fields"
                outlined
                @change="handleConditionalFieldChange(form.custom_field_id)"
            >
            </v-select>
          </div>
          <div v-if="form.is_conditional">
            <v-select
                v-model="form.conditional_custom_field_metadata"
                :error-messages="errorMessages('conditional_custom_field_metadata')"
                :items="customFieldOptions"
                item-value="name"
                item-text="name"
                label="Options"
                outlined
                :multiple="form.conditional_field_type == 'checkbox'"
            >
            </v-select>
          </div>
        </div>

        <v-row class="mt-2">
          <v-col>
            <v-switch
              color="primary"
              hide-details
              v-model="form.show_member"
              label="Show to user?"
              :error-messages="errorMessages('form.show_member')"
              class="mt-0"
            ></v-switch>
          </v-col>
          <v-col>
            <v-switch
              color="primary"
              v-show="form.show_member"
              hide-details
              v-model="form.edit_member"
              label="Allow user to edit"
              :error-messages="errorMessages('form.edit_member')"
              class="mt-0"
            ></v-switch>
          </v-col>
        </v-row>
        <v-switch
          color="primary"
          hide-details
          v-model="form.member_page"
          label="Show in Advocate Page Filter list?"
          :error-messages="errorMessages('form.member_page')"
        ></v-switch>
        <v-switch
          color="primary"
          hide-details
          v-model="form.legislator_page"
          label="Show in Legislator Page filter list?"
          :error-messages="errorMessages('form.legislator_page')"
        ></v-switch>
        <v-switch
          color="primary"
          hide-details
          v-model="form.message_page"
          label="Show in filters when sending Messages?"
          :error-messages="errorMessages('form.message_page')"
        ></v-switch>
        <v-switch
          color="primary"
          hide-details
          v-model="form.AM_page"
          label="Show in Advocacy Message window creation filter?"
          :error-messages="errorMessages('form.AM_page')"
        ></v-switch>
        <v-switch
          color="primary"
          hide-details
          v-model="form.kp_assignment_window"
          label="Show in KP Assignments Window"
          :error-messages="errorMessages('form.kp_assignment_window')"
        ></v-switch>
      </v-card-text>
      <v-card-text class="py-3">
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" outlined @click="close">Cancel</v-btn>
        <v-btn color="primary" @click="save" :loading="loading" :disabled="!valid || loading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import ClientService from '@/services/ClientService'
  import fields from '@/entities/custom-fields'
  import draggable from 'vuedraggable'

  export default {
    name: "CustomFieldsFormModal",
    mixins: [ClientService],
    props: ['selectedClientId'],
    components: { draggable },
    data() {
      return {
        dialog: false,
        errors: false,
        loading: false,
        fields: fields,
        form: {
          name: '',
          type: '',
          hint: '',
          show_member: false,
          edit_member: false,
          member_page: false,
          legislator_page: false,
          message_page: false,
          AM_page: false,
          kp_assignment_window: false,
          metadata: [],
          is_conditional: false,
          custom_field_id: null,
          conditional_custom_field_metadata: null,
          conditional_field_type: null,
        },
        customFields: [],
        customFieldOptions: [],
      }
    },
    computed: {
      valid() {
        return this.form.name && this.form.type
      },
      clientId() {
        return this.selectedClientId ? this.selectedClientId : this.userClientId
      }
    },
    watch: {
      // Watch first dropdown value
      "form.custom_field_id"(newValue) {
        if (newValue) {
          this.fetchCustomFieldOptions(newValue);
        } else {
          this.customFieldOptions = [];
          this.form.conditional_custom_field_metadata = null;
        }
      },
    },
    methods: {
      async open(id) {
        if (id > 0) {
          this.getClientField(id)
        }else{
          this.form = {
            name: '',
            type: '',
            hint: '',
            show_member: false,
            edit_member: false,
            member_page: false,
            legislator_page: false,
            message_page: false,
            AM_page: false,
            kp_assignment_window: false,
            metadata: [],
            is_conditional: false,
            custom_field_id: null,
            conditional_custom_field_metadata: null,
            conditional_field_type: null,
          }
        }
        this.errors = false
        this.dialog = true
      },
      close() {
        this.form = {
          name: '',
          type: '',
          hint: '',
          show_member: false,
          edit_member: false,
          member_page: false,
          legislator_page: false,
          message_page: false,
          AM_page: false,
          kp_assignment_window: false,
          is_conditional: false,
          custom_field_id: null,
          conditional_custom_field_metadata: null,
          conditional_field_type: null,
        }
        this.$emit('update')
        this.dialog = false
      },
      save() {
        this.loading = true;
        this.saveClientField(this.clientId, this.form).then(() => {
          if (this.form.id > 0) {
            // If the field is being edited
            this.onCallToastr('success', 'Field has been updated.', 'Success!');
          } else {
            // If the field is being created
            this.onCallToastr('success', 'New field has been created.', 'Success!');
          }
            this.close();
            this.loading = false;
            this.errors = false;
        },err => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
      },
      getClientField(id) {
        this.loading = true
        this.getClientFieldById(this.clientId, id).then(response => {
          this.fetchCustomFields();
          this.form = response.data
          this.form.custom_field_id = response.data.member_custom_field_id
          this.loading = false
          const conditionalFieldMetadata = JSON.parse(response.data.conditional_field_metadata)

          if (Array.isArray(conditionalFieldMetadata)) {
            // If it's an array of options, map them to get the names
            this.form.conditional_custom_field_metadata = conditionalFieldMetadata.map(option => option.name)
          } else if (conditionalFieldMetadata && conditionalFieldMetadata.value) {
            this.form.conditional_custom_field_metadata = conditionalFieldMetadata.value
          } else if (conditionalFieldMetadata && conditionalFieldMetadata.name) {
            this.form.conditional_custom_field_metadata = conditionalFieldMetadata.name
          }
        })
      },
      errorMessages(field) {
        return (this.errors && this.errors[field]) ? this.errors[field][0] : []
      },
      addOption() {
        this.form.metadata.push({ name: '' })
      },
      removeOption(index) {
        this.form.metadata.splice(index, 1)
      },
      conditionalFieldChange() {
        if (this.form.is_conditional) {
          this.fetchCustomFields();
        } else {
          this.customFields = [];
          this.customFieldOptions = [];
          this.form.custom_field_id = null;
          this.form.conditional_custom_field_metadata = null;
          this.form.conditional_field_type = null
        }
      },
      async fetchCustomFields() {
        const params = {
          is_conditional: true
        };
        this.getClientFields(this.clientId, params).then(response => {
          this.form.conditional_field_type = response.data.type
          this.customFields = response.data
        })
      },
      async fetchCustomFieldOptions(selectedValue) {
        this.getClientFieldById(this.clientId, selectedValue).then(response => {
            this.form.conditional_field_type = response.data.type
            if (response.data.type == 'checkbox' || response.data.type == 'dropdown') {
              this.customFieldOptions = response.data.metadata
            } else {
              this.customFieldOptions = [true, false]
            }
          })
      },
      handleConditionalFieldChange(value) {
        this.fetchCustomFieldOptions(value);
      }
    }
  }
</script>
<style>
.dd-icon{
  margin-right: 10px;
  cursor: pointer;
}
</style>