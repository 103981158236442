<template>
  <div class="fill-width">
    <div class="input_area" v-if="messageType == 0">
      <div v-if="isBad(selectedMember.email)">
        <v-alert :value="true" type="warning">
          Emails to {{ selectedMember.nickname }} {{ selectedMember.lastname }} unavailable due to an undeliverable
          email address. Please correct and try again.
        </v-alert>
      </div>
      <div v-else-if="selectedMember.silence">
        <v-alert :value="true" type="warning">
          This user is currently silenced and cannot receive messages at this time.
          <v-btn
              v-if="isSuperAdmin || isVIP"
              x-small
              depressed
              @click="updateUserMember()"
              class="ml-4"
          >
            Unsilence
          </v-btn>
        </v-alert>
      </div>
      <div v-else>
        <div>
          <v-alert v-if="isSummaryUploadLimit" class="mt-3" dense type="error" outlined :value="true">
            The size of images and attached files is more than 25MB
          </v-alert>
          <div
              v-if="isEditor && !userClient.is_reply_disabled"
              class="input_area__field input_area__editor"
          >
            <div class="input_area__field--ez-login-btn">
              <v-flex v-if="!isFilesInput" v-model="isFilesInput" @click="isFilesInput = !isFilesInput"
                      class="d-flex align-baseline">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon small color="primary" class="ml-0" @click="removeAllAttachedFiles" v-on="on"
                            :loading="linkInsertion">
                      <v-icon size="16">mdi-paperclip</v-icon>
                    </v-btn>
                  </template>
                  <span>Attach files</span>
                </v-tooltip>
              </v-flex>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon small color="primary" class="ml-0" @click="onAddVariableEmail('{ez_login}')" v-on="on"
                          :loading="linkInsertion">
                    <v-icon size="16">insert_link</v-icon>
                  </v-btn>
                </template>
                <span>Insert ezlogin link</span>
              </v-tooltip>
            </div>
            <div class="input_area__field--wrapper tiny_editor" style="width: calc(100% - 64px);">
              <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
              <editor
                  v-if="!isLoading"
                  :api-key="TINY_MCE_API_KEY"
                  :plugins="tinyPlugins"
                  :toolbar="tinyToolbars"
                  :init="tinyInit"
                  spell-check="false"
                  v-model="replyMessage"
                  :disabled="disabledEditor"
                  @keyup="inputEmail"
              />
            </div>
            <v-btn
                icon color="primary"
                @click="onSendMessage"
                :disabled="checkEmptyMessage.length <= 0 || linkInsertion || maxFileSize || isSummaryUploadLimit">
              <v-icon size="16">send</v-icon>
            </v-btn>
            <div class="input_area__cancel">
              <v-btn @click="hideEditor" color="error" icon text>
                <v-icon size="16">close</v-icon>
              </v-btn>
            </div>
          </div>
          <div v-else class="input_area__field input_area__default">
            <v-text-field
                placeholder="Enter the message to reply"
                class="mt-2"
                @focus="showEditor"
                v-if="!userClient.is_reply_disabled"
            >
            </v-text-field>
          </div>
        </div>
        <div>
          <v-flex v-if="isFilesInput && isEditor && !userClient.is_reply_disabled" class="d-flex align-baseline">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                    icon
                    small
                    color="red"
                    class="ml-0"
                    @click="removeAllAttachedFiles"
                    v-on="on"
                    :loading="linkInsertion"
                >
                  <v-icon size="16">mdi-paperclip</v-icon>
                </v-btn>
              </template>
              <span>Remove all attached files</span>
            </v-tooltip>
            <v-file-input
                class="pt-4"
                v-model="files"
                color="primary"
                counter
                label="File input"
                multiple
                placeholder="Select your files"
                prepend-icon=""
                outlined
                :loading="loadingFile"
                :disabled="loadingFile"
                :show-size="1000"
                :rules="fileRules"
                dense
                @change="filesInputChanged"
                @click:clear="removeAllAttachedFiles"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip
                    color="primary"
                    dark
                    label
                    small
                    close
                    @click:close="removeAttachedFile(index)"
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-flex>
          <div v-if="maxFileSize">
            <v-alert
                outlined
                type="info"
                text
            >
              To reduce the size of the files, you can use
              <a href="https://shrinkpdf.com/">shrinkpdf.com</a> or
              <a href="https://imagecompressor.com/">imagecompressor.com</a>
            </v-alert>
          </div>
        </div>
      </div>
    </div>
    <div class="input_area" v-else>
      <div
          v-show="!selectedMember.bad_cell && selectedMember.cell && selectedMember.cell.length"
          class="input_area__field input_area__default tiny_editor sms-editor-container"
      >
        <div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                  icon
                  small
                  color="primary"
                  class="mt-0 mb-5 ml-0"
                  @click="onAddVariableSms('{ez_login}')"
                  v-on="on"
                  :loading="linkInsertion"
              >
                <v-icon size="16">insert_link</v-icon>
              </v-btn>
            </template>
            <span>Insert ezlogin link</span>
          </v-tooltip>
        </div>
        <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
        <div class="sms-message-box">
          <editor
              v-show="!userClient.is_reply_disabled"
              :api-key="TINY_MCE_API_KEY"
              :plugins="''"
              :toolbar="[]"
              :init="tinyInit"
              spell-check="false"
              v-model="replyMessage"
              :disabled="linkInsertion"
              @input="inputEmail"
              placeholder="Enter the message to reply"
              class="sms-editor"
          />
          
          <span v-if="!!messageType" class="text-counter text-right" :class="replyMessage.length > 320 ? 'error--text' : ''">
            {{ replyMessage.length }}/320
          </span>
        </div>

          <template>
            <v-icon class="ml-2" :disabled="replyMessage.length > 320" @click="onSendMessage">
              send
            </v-icon>
          </template>
      </div>
      <v-alert  v-show="!(!selectedMember.bad_cell && selectedMember.cell && selectedMember.cell.length)" class="members_message__wrapper" type="error" outlined>
        This Advocate does not have a valid cell phone number
      </v-alert>
    </div>
    <div id="htmlConvertContainer" v-show="false"></div>
  </div>
</template>

<script>
import _ from 'lodash'
import he from "he";

import ClientService from '@/services/ClientService'
import UserService from '@/services/UserService'
import MemberService from '@/services/MemberService'
import DistrictService from '@/services/DistrictService'

import Editor from '@tinymce/tinymce-vue';
import TinyConfig from "@/mixins/tiny-mce-config"
import ShorterUrl from "@/mixins/shorterUrl";
import swal from "sweetalert2";
import {mapGetters} from "vuex";

export default {
  name: 'MessagesInputBox',
  mixins: [ClientService, UserService, TinyConfig, MemberService, DistrictService, ShorterUrl],
  components: {
    'editor': Editor,
  },
  props: {
    route: {
      type: String,
      default: ''
    },
    dialog: {
      type: Boolean,
      default: false
    },
    selectedMember: {
      type: Object,
      default: {}
    },
    messageType: {
      type: Number,
      default: 0
    },
    reFetchMessage: {
      type: Function,
      default: () => {}
    },
  },
  data() {
    return {
      isLoading: true,
      loadingFile: false,
      replyMessage: '',
      isEditor: false,
      linkInsertion: false,
      isFilesInput: false,
      files: [],
      currFiles: [],
      members: {
        pagination: {
          page: 1,
          itemsPerPage: 10,
          last_page: 0,
        },
        items: []
      },
      messages: {
        pagination: {
          page: 1,
          itemsPerPage: 15,
          last_page: 0,
        },
        items: []
      },
    }
  },
  async mounted() {
    this.isLoading = true
    await this.refresh()
    this.isLoading = false
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    isApproval() {
      return this.client.uses_approval_coordinator_m && this.isCoordinator
    },
    checkEmptyMessage() {
      return this.replyMessage
          .replace(/[&]nbsp[;]/gi, '')
          .replace(/<p>[\s]*<\/p>/gi, '')
    },
    maxImgsSize() {
      if (this.currentImgsSize > this.imgSizeLimit) {
        this.onCallToastr('error', 'The size of the image is more than 25MB!', 'Failed!')
        return true
      } else return false
    },
    isSummaryUploadLimit() {
      return (this.currentImgsSize + this.currentFilesSize) > this.imgSizeLimit;
    }
  },
  filters: { },
  watch: {
    'messageType': function (newValue, oldValue) {
      if(newValue !== oldValue) {
        this.onSelectMember(newValue);
      }
    }
  },
  methods: {
    async inputEmail() {
      this.$nextTick(async () => {
        const newContent = await this.parsUrl(this.replyMessage)

        if (newContent !== this.replyMessage) {
          this.replyMessage = newContent
          this.disabledEditor = false
          document.activeElement.blur()
        }
      })
    },
    async refresh() {
      if (!(this.client && this.client.id)) return
      this.isLoading = true
      this.messages.items = []
      this.members.items = []
    },
    async onSelectMember(messageType) {
      this.messageType = messageType
      
      let startText = `Hi ${this.selectedMember.nickname}, <br> - ${this.userData.nickname}`
      if(!this.replyMessage) {
        this.replyMessage = this.userData && this.userData.nickname ? startText : ''
      }
        
      if (messageType === 0) {
        if (this.selectedMember.unseen_email_count > 0) {
          this.onCallToastr('success', `Please check ${this.selectedMember.unseen_email_count} new emails`, 'New Messages!')
        }
        this.selectedMember.unseen_email_count = 0
      } else {
        if (this.selectedMember.unseen_text_count > 0) {
          this.onCallToastr('success', `Please check ${this.selectedMember.unseen_text_count} new texts`, 'New Texts!')
        }
        this.selectedMember.unseen_text_count = 0
      }
    },
    getMessageFromText() {
      if (this.userData.nickname && this.userData.lastname) {
        return `${this.userData.nickname} ${this.userData.lastname}`
      }
      return false
    },
    html2Text(html) {
      let find = /(<br>|<\/p><p>)/,
          re = new RegExp(find, 'g');
      let txt = html.replace(re, '\n');
      txt = txt.replace(/<[^>]*>?/gm, '')
      return he.decode(txt)
    },
    send() {
      if (this.messageType === 0) {
        const message = {
          is_reply: 0,
          is_sms: 0,
          is_admin_send: false,
          members: [this.selectedMember.id],
          message: this.replyMessage,
        }
        this.messages.items.push(message)
        // Send Reply Email
        let payload = {
          clientId: this.userClientId,
          tos: [this.selectedMember.email],
          reply_to_email: this.userClient.reply_to_email,
          is_approval: this.isApproval,
          subject: '-',
          is_sms: 0,
          is_kps_send: 0,
          is_admin_send: false,
          members: [this.selectedMember.id],
          message: this.replyMessage,
          files: []
        }

        if (this.getMessageFromText()) {
          payload.from = this.getMessageFromText()
        }

        this.addFiles().then(() => {
          this.loadingFile = false
          payload.files = this.files
          this.saveClientScheduleMessage(payload)
              .then(() => {
                this.onCallToastr('success', 'Message has been sent.', 'Success!')
                this.onSelectMember(0)
                this.removeAllAttachedFiles()
                this.$emit('reFetchMessage');
              })
        })
      } else {
        const message = {
          is_reply: 0,
          is_sms: 1,
          is_approval: this.isApproval,
          is_admin_send: false,
          members: [this.selectedMember.id],
          message: this.html2Text(this.replyMessage),
        }
        this.messages.items.push(message)
        // Send Reply Text
        let payload = {
          clientId: this.userClientId,
          tos: [this.selectedMember.cell],
          message: this.html2Text(this.replyMessage),
          is_sms: 1,
          is_kps_send: 0,
          is_admin_send: false,
          members: [this.selectedMember.id],
          files: []
        }
        this.addFiles().then(() => {
          this.loadingFile = false
          payload.files = this.files
          this.saveClientScheduleMessage(payload)
              .then(() => {
                this.onCallToastr('success', 'Message has been sent.', 'Success!')
                this.onSelectMember(1)
                this.$emit('reFetchMessage');
              })
        })
      }
      this.replyMessage = ''
    },
    onSendMessage() {
      if (!this.replyMessage) return
      if (this.isApproval) {
        swal({
          text: 'Ready to place this message into the queue for approval? You will be notified once it has been approved and sent.',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Queue for Approval',
          cancelButtonText: 'Go Back',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.send()
          }
        })
      } else {
        this.send()
      }
    },
    hideEditor() {
      this.isEditor = false
      this.replyMessage = ''
    },
    showEditor() {
      this.onSelectMember(0);
      this.isEditor = true
    },
    onAddVariableEmail(variable) {
      variable += ' '
      tinymce.activeEditor.execCommand('mceInsertContent', false, variable);

      setTimeout(() => {
        this.getFormatedText()
      }, 200)
    },
    onAddVariableSms(variable) {
      variable += ' '
      tinymce.activeEditor.execCommand('mceInsertContent', false, variable);

      setTimeout(() => {
        this.getFormatedText()
      }, 200)
    },
    getFormatedText() {
      this.linkInsertion = true
      let payload = {
        legislator_id: this.selectedMember.client_id,
        member_id: this.selectedMember.id,
        message: this.replyMessage,
      }

      this.getAppFormattedMessage(payload).then(response => {
        this.replyMessage = response.data
        this.linkInsertion = false
      })
    },
    filesInputChanged() {
      this.currFiles = [
        ...this.currFiles,
        ...this.files
      ]
      this.currFiles = [...new Set(this.currFiles)]
      this.files = [...new Set(this.currFiles)]
    },
    removeAttachedFile(index) {
      this.files.splice(index, 1)
      this.currFiles.splice(index, 1)
    },
    removeAllAttachedFiles() {
      this.currFiles = []
      this.files = []
      this.isFilesInput = false
    },
    async addFiles() {
      if (this.files.length) {
        let promises = []
        this.loadingFile = true

        for (let file of this.files) {
          let data = new FormData()
          data.append('file', file)
          promises.push(this.saveScheduleMessageFile(data))
        }

        this.files = (await Promise.all(promises)).map(res => {
          return res.data
        })
      }
    },
    updateUserMember() {
      this.isLoading = true
      this.selectedMember.silence = 0
      this.updateMember(this.selectedMember).then(response => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
$mobile-screen-width: 768px;

.activeLight {
  background-color: #f4f5fa;
}

.activeDark {
  background-color: #28243d;
}

.vipDark {
  background-color: rgba(145, 85, 253, 0.3);
}

.vipLight {
  background-color: rgba(197, 172, 246, 0.87);
}

.close-modal {
  position: absolute;
  right: 10px;
  top: 10px;
}

.card-container {
  display: flex;

  .member-list-sidebar {
    max-width: 380px;
    width: 100%;
    @media(max-width: 767px) {
      max-width: 320px;
    }

    header {
      background-color: #FAFAFA;
    }

    .member-list {

      overflow-y: auto;
      height: calc(100vh - 270px);
      @media(max-width: 767px) {
        height: calc(100vh - 150px);
      }

      .v-list-item {
        padding: 0;
      }
    }

    @media screen and (max-width: $mobile-screen-width) {
      max-width: inherit;
    }

  }

  .member {
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }

    &.active {
      background-color: #eee;
    }
  }

  .conversation-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: calc(100vh - 150px);

    .reply-tabbar {
      border-bottom: 2px solid #eee;
    }

    .message-list-view {
      flex: 1;
      overflow-y: auto;

      .message {
        margin: 0 8px;
        max-width: 600px;
        min-width: 200px;
        border-radius: 8px;
        position: relative;

        &.opened {
          margin: 0 8px 20px;
        }

        &_read-at {
          position: absolute;
          right: 0;
          bottom: -20px;
          font-style: italic;
          font-size: 12px;
        }

        &_mark-unseen {
          position: absolute;
          right: 0;
          opacity: 0;
          z-index: -1;
          top: 0;
          transition: all 0.2s;
          @media (max-width: 767px) {
            position: absolute;
            left: -57px;
            opacity: 1;
            z-index: 1;
            top: 55px;
          }
        }

        &:hover {
          .message_mark-unseen {
            right: -30px;
            opacity: 1;
            z-index: 1;
          }
        }
      }
    }
  }
}

.input {
  &_area {
    &__field {
      display: flex;
      align-items: flex-end;
      position: relative;

      &--wrapper {
        width: 100%;
      }

      .tiny-editor {
        width: 100%;
      }

      &--counter {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #666;
        margin-top: 4px;

        &.error-color {
          color: red;
        }

        span {
          &:last-child {
            margin-left: auto;
          }
        }
      }
    }

    &__cancel {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.sms-message-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>

<style lang="scss">
#messagePage {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;

  .member-list-sidebar {
    .v-list__tile__action {
      min-width: 75px;
    }
  }

  .conversation-container {
    .v-avatar {
      min-width: 48px;
    }
  }

  .message {
    img {
      max-width: 100%;
    }
  }

}
</style>
